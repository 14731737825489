import {
  ExchangeRatesListRequestDto,
  ExchangeRatesPairRequestDto
} from '../../../interfaces/exchange-rates-list-dto.interface';
import { CardActionConfirmDto, CardBlockDto, CardLimitDto } from '../../../interfaces/products.interface';
import { OrderCallRequestDto } from '../../../interfaces/questionnaire.interface';
import { BlockCustomizeUnits } from '../../../interfaces/settings.interface';
import { DivisionType } from '../../../enums/questionnaire.enum';


export namespace ServicesActions {
  export class GetExchangeRatesAll {
    public static readonly type = '[Services] GetExchangeRatesAll';


    constructor(
      public readonly requestData: ExchangeRatesListRequestDto) {
    }
  }


  export class GetExchangeRatesPair {
    public static readonly type = '[Services] getExchangeRatesPair';


    constructor(
      public readonly requestData: ExchangeRatesPairRequestDto) {
    }
  }


  export class GetExchangeRatesPairForEquivalent {
    public static readonly type = '[Services] GetExchangeRatesPairForEquivalent';


    constructor(
      public readonly requestData: ExchangeRatesPairRequestDto) {
    }
  }


  export class GetCustomizeByUser {
    public static readonly type = '[Services] getCustomizeByUser';
  }


  export class ClearServices {
    public static readonly type = '[Services] ClearServices';
  }


  export class CreateOrUpdateCustomize {
    public static readonly type = '[Services] createOrUpdateCustomize';


    constructor(
      public readonly requestData: { blockCustomizeUnits: BlockCustomizeUnits[] }) {
    }
  }


  export class SendCardActionCvv {
    public static readonly type = '[Services] SendCardActionCvv';


    constructor(
      public readonly requestData: CardActionConfirmDto) {
    }
  }


  export class SendCardLimit {
    public static readonly type = '[Services] SendCardLimit';


    constructor(
      public readonly requestData: CardLimitDto) {
    }
  }


  export class ReceiveCardLimits {
    public static readonly type = '[Services] ReceiveCardLimits';


    constructor(
      public readonly requestData: CardActionConfirmDto) {
    }
  }


  export class SendBlockCard {
    public static readonly type = '[Services] SendBlockCard';


    constructor(
      public readonly requestData: CardBlockDto) {
    }
  }


  export class SetSmsAlreadySent {
    public static readonly type = '[Services] SetSmsAlreadySent';


    constructor() {
    }
  }


  export class SendSmsConfirmation {
    public static readonly type = '[Services] SendSmsConfirmation';


    constructor() {
    }
  }


  export class SendInfoCountries {
    public static readonly type = '[Services] SendInfoCountries';
  }


  export class GetBankDivisions {
    public static readonly type = '[Services] GetBankDivisions';


    constructor(public readonly divisionTypes?: DivisionType[]) {}
  }


  export class GetBranches {
    public static readonly type = '[Services] GetBranches';


    constructor() {}
  }


  export class GetATMS {
    public static readonly type = '[Services] GetATMS';


    constructor() {}
  }


  export class GetATMDetail {
    public static readonly type = '[Services] GetATMDetail';


    constructor(public readonly id: string) {}
  }


  export class GetBankDivisionById {
    public static readonly type = '[Services] GetBankDivisionById';


    constructor(public readonly id: string) {}
  }


  export class GetCallOrder {
    public static readonly type = '[Services] GetCallOrder';


    constructor(public readonly requestData: OrderCallRequestDto) {}
  }
}
