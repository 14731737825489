import { Directive, ElementRef, HostListener } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';


@Directive({
  selector: '[phoneMask]',
  standalone: true
})
export class PhoneMaskDirective {
  private operators: string[] = [ '25', '33', '29', '44' ];

  private uniqueCode: string = '+375';


  constructor(private el: ElementRef, private ngControl: NgControl) {}


  @HostListener('input', [ '$event', '$event.target.value' ]) onInput(event: any, value: string): void {
    const input = this.el?.nativeElement;
    const control: AbstractControl<string> | null = this.ngControl?.control;

    if (value.startsWith(this.uniqueCode)) {
      this.checkBYNPhoneNumber(event, value, control as AbstractControl);
    } else {
      if (input && control) {
        this.checkForeignPhoneNumber(event, value, control as AbstractControl);
      }
    }
  }


  private checkBYNPhoneNumber(event: any, value: string, control: AbstractControl): void {
    if (value.length > 19) {
      control?.setValue(event.target.oldvalue);
    } else {
      const formattedValue: string = this.formatPhoneNumber(value);

      event.target.oldvalue = formattedValue;
      control?.setValue(formattedValue);
    }
  }


  private checkForeignPhoneNumber(event: any, value: string, control: AbstractControl): void {
    if (value.length > 16) {
      control?.setValue(event.target.oldvalue);
    } else {
      const formattedValue = '+' + value.replace(/\D/g, '').substring(0, 16);

      event.target.oldvalue = formattedValue;
      control.setValue(formattedValue);
    }
  }


  private formatPhoneNumber(value: string): string {
    let formattedValue: string = this.uniqueCode;
    const removeFromOutside: boolean = value.endsWith(') ');

    if (value.endsWith(')')) {
      value = value.substring(0, value.length - 2);
    }

    value = value.replace(/\D/g, '');

    if (value.length < 3) {
      if (formattedValue.startsWith('+'.concat(value))) {
        return '+'.concat(value);
      } else {
        return '+';
      }
    }
    if (value.length > 3) {
      const inputValue: string = value.substring(3, 5);
      const find: string | undefined = this.operators.find((operator: string) => operator.startsWith(inputValue));

      if (find) {
        if (inputValue.length > 1 && !removeFromOutside) {
          formattedValue += ` (${ inputValue }) `;
        } else {
          formattedValue += ` (${ inputValue }`;
        }
      } else {
        return formattedValue;
      }
    }
    if (value.length > 5) {
      formattedValue += `${ value.substring(5, 8) }`;
    }
    if (value.length > 8) {
      formattedValue += `-${ value.substring(8, 10) }`;
    }
    if (value.length > 10) {
      formattedValue += `-${ value.substring(10, 12) }`;
    }

    return formattedValue;
  }

}
