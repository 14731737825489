import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { SnackbarType } from '../../../../enums/snackbar.enum';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';


export interface SnackbarOptions {
  type?: string;
  duration?: number;
}


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

  private componentRef!: ComponentRef<SnackbarComponent> | null;
  private durationTimeoutId!: number | null;


  constructor(
    private readonly injector: Injector,
    private readonly applicationRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver) {
  }


  public show(text: string, options?: SnackbarOptions): void {
    if (!this.componentRef) {
      const cfr = this.componentFactoryResolver.resolveComponentFactory(SnackbarComponent);
      this.componentRef = cfr.create(this.injector);
      this.applicationRef.attachView(this.componentRef.hostView);

      const instance = this.componentRef.instance;
      instance.text = text;
      instance.closeEvent.subscribe(() => this.hide());
      instance.type = options?.type ? options?.type : SnackbarType.Default;

      if (options?.duration) {
        this.durationTimeoutId = window.setTimeout(() => this.hide(), options?.duration);
      }
      document.body.appendChild(this.componentRef.location.nativeElement);
    } else {
      if (this.durationTimeoutId) {
        clearTimeout(this.durationTimeoutId);
      }
      this.hide();
      this.show(text, options);
    }
  }


  public hide(): void {
    if (this.componentRef) {
      this.applicationRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef.changeDetectorRef.detectChanges();
      this.componentRef = null;
      this.durationTimeoutId = null;
    }
  }
}
