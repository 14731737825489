import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { PreloaderService } from '../services/preloader.service';
import { PreloaderActions } from './preloader.actions';


export interface PreloaderStateModel {
  running: boolean;
  showPreloaderPriority: boolean;
}


@State<PreloaderStateModel>({
  name: 'preloader',
  defaults: { running: false, showPreloaderPriority: false }
})
@Injectable()
export class PreloaderState {


  constructor(
    private readonly service: PreloaderService) {
  }


  @Selector()
  public static selectShowPreloaderPriority(state: PreloaderStateModel): boolean {
    return state.showPreloaderPriority;
  }


  @Action(PreloaderActions.Start)
  public start(ctx: StateContext<PreloaderStateModel>): void {
    this.service.start();
    ctx.patchState({ running: true });
  }


  @Action(PreloaderActions.Stop)
  public stop(ctx: StateContext<PreloaderStateModel>): void {
    this.service.stop();
    ctx.patchState({ running: false });
  }


  @Action(PreloaderActions.SetPriority)
  public setPriority(ctx: StateContext<PreloaderStateModel>, { priority }: PreloaderActions.SetPriority): void {
    ctx.patchState({ showPreloaderPriority: priority });
  }
}
