import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEripBodyTwoRequest } from 'src/app/interfaces/erip-navigation.interface';
import { EripInterface } from '../../../interfaces/erip.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import { EripResource } from '../resources/erip.resource';


@Injectable({
  providedIn: 'root'
})
export class EripService {

  constructor(private readonly resource: EripResource) {
  }


  public getEripPayments(body: IEripBodyTwoRequest): Observable<EripInterface.EripResponse> {
    return this.resource.getEripPayments(body);
  }


  public getEripPaymentsGmpl(body: PaymentInterface.GetPayListFilterRequestDto): Observable<PaymentInterface.GetPayListFilterResponseDto> {
    return this.resource.getEripPaymentsGmpl(body);
  }


  public getEripPaymentsRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.resource.getEripPaymentsRoc(body, headers);
  }


  public byRequisitesGpl(body: IEripBodyTwoRequest): Observable<EripInterface.EripResponse> {
    return this.resource.byRequisitesGpl(body);
  }


  public byRequisitesRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.resource.byRequisitesRoc(body, headers);
  }
}
