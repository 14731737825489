import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appTimeFormat]',
  standalone: true
})
export class TimeFormatDirective {

  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Удаляем все символы, кроме цифр и ":"
    value = value.replace(/[^0-9:]/g, '');

    // Ограничиваем ввод до 5 символов (00:00)
    if (value.length > 5) {
      value = value.substr(0, 5);
    } else if (value.length === 2 && !value.includes(':')) {
      value = value + ':';
    }

    // Обновляем значение поля ввода
    input.value = value;

    // Перемещаем курсор при переходе через двоеточие
    if (value.length === 2 || value.length === 3) {
      input.setSelectionRange(value.length + 1, value.length + 1);
    } else {
      input.setSelectionRange(value.length, value.length);
    }
  }

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;
    const key = event.key;

    // Разрешаем удаление всего содержимого при нажатии на Backspace или Delete
    if (key === 'Backspace' || key === 'Delete') {
      input.value = ''; // Удаляем все
      input.setSelectionRange(0, 0); // Устанавливаем курсор в начало
    }

    // Запрещаем ввод недопустимых символов
    if (key < '0' || key > '9') {
      event.preventDefault();
    }
  }
}
