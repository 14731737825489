export namespace MenuActions {


  export class SetCurrentURL {
    public static readonly type = '[Menu] SetCurrentURL';


    constructor(
      public readonly payload: string) {}
  }


  export class SetPreviousURL {
    public static readonly type = '[Menu] SetPreviousURL';


    constructor(
      public readonly payload: string) {}
  }


  export class SetDeeplinkUrl {
    public static readonly type = '[Menu] SetDeeplinkUrl';


    constructor(
      public readonly url: string) {
    }
  }

}
