import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpError } from '../../../interfaces/http.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import {
  DisplayOnMainPayload, GetAccountBalanceDtoRq, GetAccountBalanceDtoRs,
  GetCardBalanceDtoRq,
  GetCardBalanceDtoRs,
  MaskedCardNumberResponse,
  ProductCustomizeDto,
  ProductRequestDto,
  SetSortIndexRequestDto
} from '../../../interfaces/product-liability.interface';
import {
  CardActionConfirmDto,
  CardActionDto,
  CardActivateRequestDto,
  CardBlockDto,
  CardManageSmsRequestDto,
  CardPinGenerationCostRs,
  CardSmsCostRs,
  CardSmsStatusRs,
  CreateVisaAliasRequestDto,
  CreateVisaAliasResponseDto,
  DeleteVisaAliasRequestDto, LoanApplicationsRs, Products,
  ResolveVisaAliasRequestDto,
  ResolveVisaAliasResponseDto,
  UpdateVisaAliasRequest
} from '../../../interfaces/products.interface';
import { ProductCustomizeNameDto } from '../../../interfaces/settings.interface';
import { ProductsResource } from '../resources/products.resource';


@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(
    private readonly resource: ProductsResource) {
  }


  public getProducts(): Observable<Products> {
    return this.resource.getProducts();
  }


  public getCardBalance(requestData: GetCardBalanceDtoRq): Observable<GetCardBalanceDtoRs> {
    return this.resource.getCardBalance(requestData);
  }


  public updateVisibleBalance(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    return this.resource.updateVisibleBalance(requestData);
  }


  public updateProductName(requestData: ProductCustomizeNameDto): Observable<ProductCustomizeDto> {
    return this.resource.updateProductName(requestData);
  }


  public makeMainProduct(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    return this.resource.makeMainProduct(requestData);
  }


  public setSortIndexes(requestData: SetSortIndexRequestDto): Observable<{ status: string }> {
    return this.resource.setSortIndexes(requestData);
  }


  public makeDisplayOnMain(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    return this.resource.makeDisplayOnMain(requestData);
  }


  public customizeDisplayOnMain(requestData: DisplayOnMainPayload): Observable<{ status: string }> {
    return this.resource.customizeDisplayOnMain(requestData);
  }


  public cardActivate(requestData: CardActivateRequestDto): Observable<HttpError> {
    return this.resource.cardActivate(requestData);
  }


  public cardPinGenerationCost(requestData: CardActionDto): Observable<CardPinGenerationCostRs> {
    return this.resource.cardPinGenerationCost(requestData);
  }


  public cardPinGenerate(requestData: CardActionConfirmDto): Observable<HttpError> {
    return this.resource.cardPinGenerate(requestData);
  }


  public cardSmsStatus(requestData: CardActionDto): Observable<CardSmsStatusRs> {
    return this.resource.cardSmsStatus(requestData);
  }


  public blockCard(requestData: CardBlockDto, headers: HttpHeaders): Observable<HttpError> {
    return this.resource.blockCard(requestData, headers);
  }


  public cardSmsManage(requestData: CardManageSmsRequestDto, headers: HttpHeaders): Observable<HttpError> {
    return this.resource.cardSmsManage(requestData, headers);
  }


  public cardSmsCost(requestData: CardActionDto): Observable<CardSmsCostRs> {
    return this.resource.cardSmsCost(requestData);
  }


  public cardNumberMasked(requestData: CardActionDto): Observable<MaskedCardNumberResponse> {
    return this.resource.cardNumberMasked(requestData);
  }


  public visaAliasResolve(requestData: ResolveVisaAliasRequestDto): Observable<ResolveVisaAliasResponseDto> {
    return this.resource.visaAliasResolve(requestData);
  }


  public getLoanApplications(): Observable<LoanApplicationsRs> {
    return this.resource.getLoanApplications();
  }


  public visaAliasCreate(requestData: CreateVisaAliasRequestDto, headers: HttpHeaders): Observable<CreateVisaAliasResponseDto> {
    return this.resource.visaAliasCreate(requestData, headers);
  }


  public visaAliasUpdate(requestData: UpdateVisaAliasRequest, headers: HttpHeaders): Observable<CreateVisaAliasResponseDto> {
    return this.resource.visaAliasUpdate(requestData, headers);
  }


  public visaAliasDelete(requestData: DeleteVisaAliasRequestDto, headers: HttpHeaders): Observable<CreateVisaAliasResponseDto> {
    return this.resource.visaAliasDelete(requestData, headers);
  }


  public paymentCardNumberTransfer(requestData: PaymentInterface.TransferCardNumberTransferDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.paymentCardNumberTransfer(requestData, headers);
  }


  public paymentCardPhoneTransfer(requestData: PaymentInterface.TransferCardNumberTransferDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.resource.paymentCardPhoneTransfer(requestData, headers);
  }


  public depositWithdrawalAccount(requestData: PaymentInterface.DepositWithdrawalRequestDto): Observable<PaymentInterface.DepositWithdrawalResponseDto> {
    return this.resource.depositWithdrawalAccount(requestData);
  }


  public depositWithdrawalTarget(requestData: PaymentInterface.DepositWithdrawalRequestDto): Observable<PaymentInterface.DepositWithdrawalResponseDto> {
    return this.resource.depositWithdrawalTarget(requestData);
  }


  public getAccountBalance(requestData: GetAccountBalanceDtoRq): Observable<GetAccountBalanceDtoRs> {
    return this.resource.getAccountBalance(requestData);
  }
}
