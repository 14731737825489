export namespace NumberUtils {


  export function transformAmount(amount: number, type: string): string {
    if (amount === undefined) {
      return '0';
    }

    switch (type) {
      case 'left':
        return insertNthFromEnd(Math.floor(amount).toString(), 3, ' ');
      case 'integer':
        return Math.floor(amount).toString();
      case 'decimal':
        return type && amount ? (amount % 1).toFixed(2).slice(2).padStart(2, '0') : '00';
      default:
        return amount.toFixed(2);
    }
  }


  export function addedSymbols(inputString: string): string {
    const parts: string[] = inputString.split('.');

    if (!parts[1]) {
      parts.push('0000')
    } else if (parts[1] && parts[1].length === 1) {
      parts[1] += '000';
    } else if (parts[1] && parts[1].length === 2) {
      parts[1] += '00';
    } else if (parts[1] && parts[1].length === 3) {
      parts[1] += '0';
    }

    return parts.join(',');
  }


  export function mathAmount(inputString: number, scale: number): string {
    let amount: string;

    if (inputString === 0 || scale === 0) {
      return '';
    }

    amount = (inputString * scale).toString();

    return amount.slice(0, amount.indexOf('.') + 5); // TODO: отсекаю всё, полсе 5-го знака, после точки
  }


  export function formatSpacedAmount(amount: string): string {
    if (amount) {
      const split = amount.replace('.', ',').split(',');
      return insertNthFromEnd(split[0], 3, ' ').concat(split[1] ? ','.concat(split[1]) : ',00')
    } else return '';
  }


  export function formatSpacedAmountNoDecimal(amount: string): string {
    if (amount) {
      const split = amount.replace('.', ',').split(',');
      return insertNthFromEnd(split[0], 3, ' ')
    } else return amount;
  }


  export function formatSpacedAmountPostfix(amount: string, postfix: string): string {
    if (amount) {
      const split = amount.replace('.', ',').split(',');
      return insertNthFromEnd(split[0], 3, ' ').concat(split[1] ? ','.concat(split[1]) : ',00').concat(postfix ? ' ' + postfix : '')
    } else return amount;
  }


  export function insertNthFromEnd(str: string, n: number, toInsert: string): string {
    if (str && str.length > n) {
      const k = str.length % n;
      const matchRegex = new RegExp('.{1,'.concat(n.toString()).concat('}'), 'g')
      const remaining = str.substring(k, str.length).match(matchRegex)?.join(toInsert);
      return str.substring(0, k).concat(toInsert).concat(remaining ?? '')
    } else return str;
  }


  export function transformPhoneNumber(input: string): string {
    return input.replace(/\D/g, '');
  }


  export function transformStringArrayToNumberArray(stringArray: string[]): number[] {
    const numberArray: number[] = stringArray.map((item: any) => {
      const transformedItem = item.replace(',', '.').replace('%', '');

      return parseFloat(transformedItem);
    });

    return numberArray;
  }


  export function transformExpireDate(input: string): { month: string, year: string } {
    const month: string = input.substring(0, 2);
    let year: string = input.substring(2).replaceAll('/', '');

    if (year.length === 2) {
      const prefix: string = new Date().getFullYear().toString().slice(0, 2);
      year = prefix + year;
    }

    return { month, year };
  }


  export function getListWithoutZero(inputData: number[]): number {
    // Находим минимальное число в списке
    const minNumber: number = Math.min(...inputData);

    if (inputData.includes(0)) {
      const numbersWithoutZero: number[] = inputData.filter((num: number): boolean => num !== 0);

      return Math.min(...numbersWithoutZero);
    } else {
      return minNumber;
    }
  }


  export function calculateSumm(value: number): number {
    return Math.round((value + Number.EPSILON) * 100) / 100;
  }
}
