import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SnackbarType } from '../../../../../enums/snackbar.enum';


@Component({
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: [ './snackbar.component.scss' ]
})
export class SnackbarComponent {
  @Input()
  public text!: string;

  @Input()
  public type!: string;

  @Output()
  public closeEvent = new EventEmitter<void>();


  public onClose(): void {
    this.closeEvent.emit();
  }
}
