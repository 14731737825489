export const ValidationConfig = {
  passportId: {
    pattern: '^[A-Za-z0-9]+$',
    minLength: 14,
    maxLength: 14
  },
  passportNumber: {
    pattern: '^[A-Za-z0-9]{4,15}$',
    minLength: 4,
    maxLength: 15
  },
  cardNumber: {
    length: 16
  },
  cardExpireDate: {
    length: 5
  },
  cardName: {
    maxLength: 35
  },
  payCardToPhone: {
    cvv: {
      minLength: 3,
      maxLength: 3
    },
    phoneNumber: {
      maxLength: 17
    }
  },
  payCardToCard: {
    length: 3
  }
};
