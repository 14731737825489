import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


interface IInputType {
  inputType: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' |
    'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'textarea' |
    'tel' | 'text' | 'time' | 'url' | 'week';
}


@Component({
  selector: 'app-input-search',
  templateUrl: './input-search.component.html',
  styleUrls: [ './input-search.component.scss' ]
})
export class InputSearchComponent<T> implements OnInit {

  public searchTerm: string = '';

  public showDropdown: boolean = false;

  public emptyText!: string;

  public height = '336px';

  private debounceTimer!: ReturnType<typeof setTimeout>;

  @Input()
  public labelList!: string;

  @Input()
  public searchResults: any[] = [];

  @Input()
  public searchTermValue!: string;

  @Input()
  public type!: IInputType | any;

  @Input()
  public placeholder: string = '';

  @Input()
  public isError!: boolean;

  @Input()
  public empty: string = '';

  @Input()
  public errorMsg = '';

  @Input()
  public mask = '';

  @Output()
  public emitInputValue: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public emitInputValueImmediate: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public selected: EventEmitter<T> = new EventEmitter();


  @HostListener('document:click', [ '$event' ])
  public onClick(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.showDropdown = false;
    }
  }


  constructor(
    private eRef: ElementRef,
    private translateService: TranslateService
  ) {}


  public ngOnInit(): void {
    this.searchTerm = this.searchTermValue ? this.searchTermValue : '';
    this.translateService.get('PARAM_REQUEST_NOT_FOUND', { searchTerm: 'example' })
      .subscribe((translation: string) => {
        this.empty = translation;
      });
  }


  public onClear(): void {
    this.searchTerm = '';
    this.emitInputValue.emit(this.searchTerm);
    this.emitInputValueImmediate.emit(this.searchTerm);
    this.isError = false;
    this.emptyText = this.empty;
  }


  public onInputFocus(): void {
    this.showDropdown = true;
  }


  public selectResult(result: any): void {
    this.selected.emit(result);
    this.showDropdown = false;
  }


  public onSearch(immediate: boolean): void {
    if (this.searchTerm.length && this.searchResults.length) {
      this.height = this.searchResults.length > 3 ? '336px' : 'auto';
    }

    if (this.searchTerm.length) this.isError = false;

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }

    this.debounceTimer = setTimeout(() => {
      const input = document.getElementById('inputFilter') as HTMLInputElement;
      this.emitInputValue.emit(input.value);
      if (immediate) this.emitInputValueImmediate.emit(input.value);
    }, 1000);

    this.emptyText = this.empty;
  }


  public onKeyUp($event: KeyboardEvent) {
    if ($event.key === 'Enter') {
      this.onSearch(true);
    }
  }
}
