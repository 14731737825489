import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { DepositInterface } from '../../../interfaces/deposit.interface';


@Injectable({
  providedIn: 'root'
})
export class DepositResource {


  constructor(
    private readonly http: HttpClient) {
  }


  public getDepositCloseInfo(requestData: DepositInterface.DepositInfoRequestDto): Observable<DepositInterface.DepositInfoResponseDto> {
    return this.http.post<DepositInterface.DepositInfoResponseDto>(ApiConfig.DEPOSIT_CLOSE_INFO, requestData);
  }


  public getDepositTopUpInfo(requestData: DepositInterface.DepositInfoRequestDto): Observable<DepositInterface.DepositTopUpResponseDto> {
    return this.http.post<DepositInterface.DepositTopUpResponseDto>(ApiConfig.DEPOSIT_TOP_UP_INFO, requestData);
  }


  public getDepositWithdrawalInfo(requestData: DepositInterface.DepositInfoRequestDto): Observable<DepositInterface.DepositTopUpResponseDto> {
    return this.http.post<DepositInterface.DepositTopUpResponseDto>(ApiConfig.DEPOSIT_WITHDRAWAL_INFO, requestData);
  }


  public depositSourcingInfo(requestData: DepositInterface.DepositSourcingInfoRequestDto): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(ApiConfig.DEPOSIT_SOURCING_INFO, requestData);
  }


  public getDepositSourcingInfo(): Observable<DepositInterface.DepositSourcingInfoResponseDto> {
    return this.http.get<DepositInterface.DepositSourcingInfoResponseDto>(ApiConfig.DEPOSIT_SOURCING_INFO);
  }
}
