import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { HttpEnum } from '../../../enums/http.enum';
import { ProductsEnum } from '../../../enums/products.enum';
import { HttpError } from '../../../interfaces/http.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import {
  DisplayOnMainPayload, GetAccountBalanceDtoRq, GetAccountBalanceDtoRs,
  GetCardBalanceDtoRq,
  GetCardBalanceDtoRs,
  MaskedCardNumberResponse,
  ProductCustomizeDto,
  ProductRequestDto,
  SetSortIndexRequestDto
} from '../../../interfaces/product-liability.interface';
import {
  CardActionConfirmDto,
  CardActionDto,
  CardActivateRequestDto,
  CardBlockDto,
  CardManageSmsRequestDto,
  CardPinGenerationCostRs,
  CardSmsCostRs,
  CardSmsStatusRs,
  CreateVisaAliasRequestDto,
  CreateVisaAliasResponseDto,
  DeleteVisaAliasRequestDto, LoanApplicationsRs,
  Products,
  ResolveVisaAliasRequestDto,
  ResolveVisaAliasResponseDto,
  UpdateVisaAliasRequest
} from '../../../interfaces/products.interface';
import { ProductCustomizeNameDto } from '../../../interfaces/settings.interface';


@Injectable({
  providedIn: 'root'
})
export class ProductsResource {

  private httpParams = HttpEnum.HttpParams;


  constructor(
    private readonly http: HttpClient) {
  }


  public getProducts() {
    const params = new HttpParams()
      .set('getCreditDetail', false);

    // if (environment.mock.products) {
    //   return this.http.get<Products>('/assets/mock/get-products.json', { params });
    // }

    return this.http.get<Products>(ApiConfig.PRODUCTS_GET_ALL, { params });
  }


  public getCardBalance(requestData: GetCardBalanceDtoRq): Observable<GetCardBalanceDtoRs> {
    return this.http.post<GetCardBalanceDtoRs>(ApiConfig.PRODUCT_CARD_BALANCE, requestData);
  }


  public updateVisibleBalance(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    const params: HttpParams = new HttpParams()
      .set(this.httpParams.VISIBLE_BALANCE, requestData.visible as boolean);

    return this.http.post<ProductCustomizeDto>(ApiConfig.PRODUCTS_UPDATE_VISIBLE_BALANCE, { productId: requestData.productId }, { params });
  }


  public updateProductName(requestData: ProductCustomizeNameDto): Observable<ProductCustomizeDto> {
    return this.http.post<ProductCustomizeDto>(ApiConfig.PRODUCT_CUSTOMIZE_SET_NAME, requestData);
  }


  public makeMainProduct(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    const paramData: boolean = !!(requestData.action && requestData.action === ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT);
    const params: HttpParams = new HttpParams()
      .set(this.httpParams.MAKE_MAIN, paramData);

    return this.http.post<ProductCustomizeDto>(ApiConfig.PRODUCT_CUSTOMIZE_MAKE_MAIN, { productId: requestData.productId }, { params });
  }


  public setSortIndexes(requestData: SetSortIndexRequestDto): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(ApiConfig.PRODUCT_CUSTOMIZE_SET_SORT_IDX, requestData);
  }


  public makeDisplayOnMain(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    const params: HttpParams = new HttpParams()
      .set(this.httpParams.DISPLAY_ON_MAIN, requestData.visible as boolean);

    return this.http.post<ProductCustomizeDto>(ApiConfig.PRODUCT_CUSTOMIZE_DISPLAY_ON_MAIN, { productId: requestData.productId }, { params });
  }


  public customizeDisplayOnMain(requestData: DisplayOnMainPayload): Observable<{ status: string }> {
    return this.http.post<{ status: string }>(ApiConfig.PRODUCT_CUSTOMIZE_DISPLAY_ON_MAIN_BULK, requestData);
  }


  public cardActivate(requestData: CardActivateRequestDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.CARD_ACTIVATE, requestData);
  }


  public cardPinGenerationCost(requestData: CardActionDto): Observable<CardPinGenerationCostRs> {
    return this.http.post<CardPinGenerationCostRs>(ApiConfig.CARD_PIN_GENERATION_COST, requestData);
  }


  public cardPinGenerate(requestData: CardActionConfirmDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.CARD_PIN_GENERATE, requestData);
  }


  public cardSmsStatus(requestData: CardActionDto): Observable<CardSmsStatusRs> {
    return this.http.post<CardSmsStatusRs>(ApiConfig.CARD_SMS_SERVICE_STATUS, requestData);
  }


  public blockCard(requestData: CardBlockDto, headers: HttpHeaders): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.CARD_BLOCK, requestData, { headers: headers });
  }


  public cardSmsManage(requestData: CardManageSmsRequestDto, headers: HttpHeaders): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.CARD_SMS_SERVICE_MANAGE, requestData, { headers: headers });
  }


  public cardSmsCost(requestData: CardActionDto): Observable<CardSmsCostRs> {
    return this.http.post<CardSmsCostRs>(ApiConfig.CARD_SMS_SERVICE_COST, requestData);
  }


  public cardNumberMasked(requestData: CardActionDto): Observable<MaskedCardNumberResponse> {
    return this.http.post<MaskedCardNumberResponse>(ApiConfig.CARD_NUMBER_MASKED, requestData);
  }


  public visaAliasResolve(requestData: ResolveVisaAliasRequestDto): Observable<ResolveVisaAliasResponseDto> {
    return this.http.post<ResolveVisaAliasResponseDto>(ApiConfig.VISA_ALIAS_RESOLVE, requestData);
  }


  public getLoanApplications(): Observable<LoanApplicationsRs> {
    return this.http.post<LoanApplicationsRs>(ApiConfig.GET_L0AN_APPLICATIONS, {});
  }


  public visaAliasCreate(requestData: CreateVisaAliasRequestDto, headers: HttpHeaders): Observable<CreateVisaAliasResponseDto> {
    return this.http.post<CreateVisaAliasResponseDto>(ApiConfig.VISA_ALIAS_CREATE, requestData, { headers });
  }


  public visaAliasUpdate(requestData: UpdateVisaAliasRequest, headers: HttpHeaders): Observable<CreateVisaAliasResponseDto> {
    return this.http.post<CreateVisaAliasResponseDto>(ApiConfig.VISA_ALIAS_UPDATE, requestData, { headers });
  }


  public visaAliasDelete(requestData: DeleteVisaAliasRequestDto, headers: HttpHeaders): Observable<CreateVisaAliasResponseDto> {
    return this.http.post<CreateVisaAliasResponseDto>(ApiConfig.VASA_ALIAS_DELETE, requestData, { headers });
  }


  public paymentCardNumberTransfer(requestData: PaymentInterface.TransferCardNumberTransferDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.PAYMENT_CARD_NUMBER_TRANSFER, requestData, { headers });
  }


  public paymentCardPhoneTransfer(requestData: PaymentInterface.TransferCardNumberTransferDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.PAYMENT_TRANSFER_CARD_OR_PHONE, requestData, { headers });
  }


  public depositWithdrawalAccount(requestData: PaymentInterface.DepositWithdrawalRequestDto): Observable<PaymentInterface.DepositWithdrawalResponseDto> {
    return this.http.post<PaymentInterface.DepositWithdrawalResponseDto>(ApiConfig.DEPOSIT_WITHDRAWAL_ACCOUNT, requestData);
  }


  public depositWithdrawalTarget(requestData: PaymentInterface.DepositWithdrawalRequestDto): Observable<PaymentInterface.DepositWithdrawalResponseDto> {
    return this.http.post<PaymentInterface.DepositWithdrawalResponseDto>(ApiConfig.DEPOSIT_WITHDRAWAL_TARGET, requestData);
  }


  public getAccountBalance(requestData: GetAccountBalanceDtoRq): Observable<GetAccountBalanceDtoRs> {
    return this.http.post<GetAccountBalanceDtoRs>(ApiConfig.PRODUCT_BALANCE_AMOUNT, requestData);
  }
}
