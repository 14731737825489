import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { ApiKeyInfo, Consent, ConsentInfo, ConsentStateModel } from '../../../interfaces/consent.interface';
import { ApiResponse } from '../../../interfaces/response.interface';
import { ConfirmationUtils } from '../../../utils/confirmation.utils';
import { ConsentService } from '../services/consent.service';
import { ConsentActions } from './consent.actions';


@State<ConsentStateModel>({
  name: 'consent'
})
@Injectable()
export class ConsentState {

  constructor(
    private readonly consentService: ConsentService) {
  }


  @Selector()
  public static selectConsents(state: ConsentStateModel): Consent[] {
    return state.consents || [];
  }


  @Selector()
  public static selectConsentInfo(state: ConsentStateModel): ConsentInfo {
    return state.consent;
  }


  @Selector()
  public static selectApiKey(state: ConsentStateModel): string {
    return state.apiKeyInfo.apiKey || '';
  }


  @Selector()
  public static selectApiKeyInfo(state: ConsentStateModel): ApiKeyInfo {
    return state.apiKeyInfo;
  }


  @Action(ConsentActions.GetAllConsents)
  public getAllConsents(ctx: StateContext<ConsentStateModel>): Observable<ConsentStateModel> {
    return this.consentService.getAllConsents().pipe(
      map(consents => ctx.patchState({ consents }))
    );
  }


  @Action(ConsentActions.GetConsentInfo)
  public getConsentInfo(ctx: StateContext<ConsentStateModel>, { payload }: ConsentActions.GetConsentInfo): Observable<ConsentStateModel> {
    return this.consentService.getConsentInfo(payload).pipe(
      map(consent => ctx.patchState({ consent }))
    );
  }


  @Action(ConsentActions.DeleteConsent)
  public deleteConsent(ctx: StateContext<ConsentStateModel>, { payload }: ConsentActions.DeleteConsent) {
    return this.consentService.deleteConsent(payload);
  }


  @Action(ConsentActions.GetAllConsentsAndApiKey)
  public getAllConsentsAndApiKey(ctx: StateContext<ConsentStateModel>): Observable<ConsentStateModel> {
    return this.consentService.getAllConsentsAndApiKey().pipe(
      map(apiKeyInfo => ctx.patchState({ apiKeyInfo }))
    );
  }


  @Action(ConsentActions.IssueLongTermConsent)
  public issueLongTermConsent(ctx: StateContext<ConsentStateModel>, { payload }: ConsentActions.IssueLongTermConsent): Observable<ConsentStateModel> {
    return this.consentService.issueLongTermConsent(ConfirmationUtils.addHeaderConfirmationData(payload)).pipe(
      map(apiKeyInfo => ctx.patchState({ apiKeyInfo }))
    );
  }


  @Action(ConsentActions.RevokeLongTermConsent)
  public revokeLongTermConsent(ctx: StateContext<ConsentStateModel>, { payload }: ConsentActions.RevokeLongTermConsent): Observable<ApiResponse> {
    return this.consentService.revokeLongTermConsent(ConfirmationUtils.addHeaderConfirmationData(payload));
  }
}
