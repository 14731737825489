export enum AppRoutes {
  App = 'app',
  Auth = 'auth',
  Sandbox = 'sandbox',
  Errors = 'error',
}


export enum SandboxRoutes {
  Buttons = 'buttons',
  Controls = 'controls',
  Accordion = 'accordion',
  Tabs = 'tabs',
  Segment = 'segment',
  Dropdown = 'dropdown',
  Modals = 'modals',
  Other = 'other',
}


export enum AuthRoutes {
  Login = 'login',
  Blocked = 'blocked',
  Reg = 'registration',
  Code = 'code',
  CreateCredentials = 'create-credentials',
  MsiInfo = 'msi-info',
  MsiCode = 'msi-code',
  MsiCreateCredentials = 'msi-create-credentials',
  Map = 'map',
}


export enum AuthRegRoutes {
  ByPassport = 'passport',
  ByPassportResident = 'resident',
  ByPassportNotResident = 'not-resident',
  ByCard = 'card',
}


export enum ErrorRoutes {
  NotFound = 'not-found',
}


export enum MainRoutes {
  Home = 'home',
  History = 'history',
  Payments = 'payments',
  Catalog = 'catalog',
  Help = 'help',
  Profile = 'profile',
  Extract = 'extract',
  Settings = 'settings',
  Products = 'product',
  Consents = 'consents'
}

export enum CatalogDetailRoutes {
  OpenAccount = 'open-account',
  MatchDeposit = 'match-deposit',
  OpenDeposit = 'open-deposit',
  ConditionCard = 'conditions',
  LoanIssue = 'loan-issue',
  LoanApplication = 'loan-application',
  LoanConsent = 'loan-consent',
  LoanPersonalData = 'loan-personal-data',
  LoanSign = 'loan-sign',
  LoanPaymentSchedule = 'loan-payment-schedule',
  IssueCard = 'issue',
  LoanAlternative = 'loan-alternative',
  LoanApplicationSign = 'loan-application-sign',
  LoanConditions = 'loan-conditions',
  LoanSignPartner = 'loan-sign-partner'
}

export enum CatalogRoutes {
  DepositsAndAccounts = 'deposit-and-accounts',
  Loans = 'loans',
  Cards = 'cards'
}


export enum HomeRoutes {
  NewProduct = 'new-product',
  Rates = 'rates',
  Map = 'map',
  CreateTemplate = 'create-template',
  UpdateTemplate = 'update-template',
  TemplatesAutoPayments = 'templates-auto-payments'
}


export enum ProductsRoutes {
  Deposit = 'deposit',
  Account = 'account',
  Card = 'card',
  Credit = 'credit',
  Cards = 'cars'
}


export enum DepositRoutes {
  Operations = 'operations'
}


export enum DepositOperations {
  CloseDeposit = 'close',
  TopUp = 'top-up',
  WithdrawInterest = 'withdraw-interest'
}


export enum ProductDetails {
  Requisites = 'requisites',
  TransferByPhone = 'transfer-by-phone',
  CVV = 'cvv',
  DepositInfo = 'info',
  AccountInfo = 'info',
  Close = 'close',
  DepositDocuments = 'documents',
  Percents = 'percents',
}


export enum PaymentsRoutes {
  TransferSelf = 'transfer-self',
  TransferSelfEmpty = 'transfer-self-empty',
  TransferClient = 'transfer-client',
  TransferInternal = 'transfer-internal',
  TransferInternalEmpty = 'transfer-internal-empty',
  TransferExternal = 'transfer-external',
  TransferExternalEmpty = 'transfer-external-empty',
  TransferOneButton = 'transfer-one-button',
  Finally = 'finally' // Финальный экран оплаты одной кнопкой
}


export enum TransferClientRoutes {
  ByCard = 'by-card',
  ByPhone = 'by-phone',
}


export enum TransferInternalRoutes {
  ByCard = 'by-card',
  ByPhone = 'by-phone',
  ManageByPhone = 'manage-by-phone',
  New = 'new',
  TransferErip = 'transfer-erip',
  TransferRequisites = 'transfer-requisites',
  TransferLoan = 'transfer-loan',
  TransferServices = 'transfer-services'
}


export enum TransferServices {
  Aigenis = 'aigenis',
  ParitetBroker = 'paritet-broker'
}


export enum ConsentRoutes {
  MyApps = 'my-apps',
  OtherApps = 'other-apps'
}
