import {
  DisplayOnMainPayload, GetAccountBalanceDtoRq,
  GetCardBalanceDtoRq,
  ProductRequestDto,
  SetSortIndexRequestDto
} from '../../../interfaces/product-liability.interface';
import {
  Account,
  CardActionConfirmDto,
  CardActionDto,
  CardActivateRequestDto, CardBlockDto,
  CardManageSmsRequestDto, CreateVisaAliasRequestDto, DeleteVisaAliasRequestDto, LoanApplicationsRs, OnlineApplication,
  ProductRouteProps, ResolveVisaAliasRequestDto, UpdateVisaAliasRequest
} from '../../../interfaces/products.interface';
import { ProductCustomizeNameDto } from '../../../interfaces/settings.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';


export namespace ProductsActions {
  export class GetProducts {
    public static readonly type = '[Products] GetProducts';
  }


  export class SetCardProducts {
    public static readonly type = '[Products] SetCardProducts';


    constructor(public readonly requestData: Account[]) {}
  }


  export class ClearProducts {
    public static readonly type = '[Products] ClearProducts';
  }


  export class GetCardBalance {
    public static readonly type = '[Products] GetCardBalance';


    constructor(public readonly requestData: GetCardBalanceDtoRq) {
    }
  }


  export class GetAccountBalance {
    public static readonly type = '[Products] GetAccountBalance';


    constructor(public readonly requestData: GetAccountBalanceDtoRq) {
    }
  }


  export class SetCardDetail {
    public static readonly type = '[Products] SetCardDetail';


    constructor(public payload: ProductRouteProps) {
    }
  }


  export class UpdateVisibleBalance {
    public static readonly type = '[Products] UpdateVisibleBalance';


    constructor(public readonly requestData: ProductRequestDto) {
    }
  }


  export class UpdateProductName {
    public static readonly type = '[Products] UpdateProductName';


    constructor(public readonly requestData: ProductCustomizeNameDto) {
    }
  }


  export class MakeMainProduct {
    public static readonly type = '[Products] MakeMainProduct';


    constructor(public readonly requestData: ProductRequestDto) {
    }
  }


  export class SetSortIndexes {
    public static readonly type = '[Products] SetSortIndexes';


    constructor(public readonly requestData: SetSortIndexRequestDto) {
    }
  }


  export class SendBlockCard {
    public static readonly type = '[Services] SendBlockCard';


    constructor(
      public readonly request: { requestData: CardBlockDto, modifications: any }) {
    }
  }


  export class MakeDisplayOnMain {
    public static readonly type = '[Products] MakeDisplayOnMain';


    constructor(public readonly requestData: ProductRequestDto) {
    }
  }


  export class CustomizeDisplayOnMain {
    public static readonly type = '[Products] CustomizeDisplayOnMain';


    constructor(public readonly requestData: DisplayOnMainPayload) {
    }
  }


  export class SendCardActivate {
    public static readonly type = '[Products] SendCardActivate';


    constructor(public readonly requestData: CardActivateRequestDto) {
    }
  }


  export class SendCardPinGenerationCost {
    public static readonly type = '[Products] SendCardPinGenerationCost';


    constructor(public readonly requestData: CardActionDto) {
    }
  }


  export class SendCardPinGenerate {
    public static readonly type = '[Products] SendCardPinGenerate';


    constructor(public readonly requestData: CardActionConfirmDto) {
    }
  }


  export class SendCardSmsStatus {
    public static readonly type = '[Products] SendCardSmsStatus';


    constructor(public readonly requestData: CardActionDto) {
    }
  }


  export class DestroyCardSmsStatusRs {
    public static readonly type = '[Products] DestroyCardSmsStatusRs';


    constructor() {
    }
  }


  export class DestroyCardActionRs {
    public static readonly type = '[Products] DestroyCardActionRs';


    constructor() {
    }
  }


  export class SendCardSmsManage {
    public static readonly type = '[Products] SendCardSmsManage';


    constructor(public readonly requestData: CardManageSmsRequestDto) {
    }
  }


  export class SendCardSmsCost {
    public static readonly type = '[Products] SendCardSmsCost';


    constructor(public readonly requestData: CardActionDto) {
    }
  }


  export class CardNumberMasked {
    public static readonly type = '[Products] CardNumberMasked';


    constructor(public readonly requestData: CardActionDto) {}
  }


  export class PatchProduct {
    public static readonly type = '[Products] PatchProduct';


    constructor(public readonly cardId: number, public readonly patch: any) {}
  }


  export class VisaAliasResolve {
    public static readonly type = '[Products] VisaAliasResolve';


    constructor(public readonly requestData: ResolveVisaAliasRequestDto) {}
  }


  export class VisaAliasCreate {
    public static readonly type = '[Products] VisaAliasCreate';


    constructor(public readonly requestData: CreateVisaAliasRequestDto) {}
  }


  export class VasaAliasUpdate {
    public static readonly type = '[Products] VasaAliasUpdate';


    constructor(public readonly requestData: UpdateVisaAliasRequest) {}
  }


  export class VisaAliasDelete {
    public static readonly type = '[Products] VisaAliasDelete';


    constructor(public readonly requestData: DeleteVisaAliasRequestDto) {}
  }


  export class PaymentCardTransferTransfer {
    public static readonly type = '[Payment] PaymentCardTransferTransfer';


    constructor(
      public readonly request: { requestData: PaymentInterface.TransferCardNumberTransferDto, modifications: any }) {}
  }


  export class PaymentCardPhoneTransfer {
    public static readonly type = '[Payment] PaymentCardPhoneTransfer';


    constructor(
      public readonly request: { requestData: PaymentInterface.TransferCardNumberTransferDto, modifications: any }) {}
  }


  export class SBMessage {
    public static readonly type = '[Payment] SBMessage';


    constructor(public readonly message: string) {}
  }


  export class GetWithdrawalAccount {
    public static readonly type = '[Payment] GetWithdrawalAccount';


    constructor(public readonly contractNumber: string) {}
  }


  export class SetWithdrawalAccount {
    public static readonly type = '[Payment] SetWithdrawalAccount';


    constructor(public readonly contractNumber: string,
                public readonly targetContractNumber: string) {}
  }


  export class RestoreLoanApplications {
    public static readonly type = '[Payment] RestoreLoanApplications';


    constructor(public readonly loanApplicationsRs: LoanApplicationsRs) {}
  }

  export class GetLoanApplications {
    public static readonly type = '[Payment] GetLoanApplications';


    constructor() {}
  }

  export class SwapLoanApplications {
    public static readonly type = '[Payment] SwapLoanApplications';


    constructor(public readonly data: OnlineApplication[] | undefined) {}
  }
}
