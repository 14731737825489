import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {
  AppRoutes,
  CatalogDetailRoutes,
  CatalogRoutes,
  MainRoutes,
  PaymentsRoutes,
  TransferInternalRoutes
} from '../config/routes.config';
import { AuthState } from '../models/auth/state/auth.state';
import { MenuActions } from '../models/menu/state/menu.actions';
import { MenuState } from '../models/menu/state/menu.state';


const mainRoutes: string[] = [
  MainRoutes.History,
  MainRoutes.Payments,
  MainRoutes.Catalog
];

const paymentRoutes: string[] = [
  PaymentsRoutes.TransferOneButton,
  PaymentsRoutes.TransferSelf,
  PaymentsRoutes.TransferClient,
  PaymentsRoutes.TransferInternal,
  TransferInternalRoutes.TransferRequisites,
  TransferInternalRoutes.TransferLoan,
  TransferInternalRoutes.TransferServices
];

const catalogRoutes: string[] = [
  CatalogRoutes.Cards,
  CatalogRoutes.DepositsAndAccounts,
  CatalogRoutes.Loans
];


@Injectable({
  providedIn: 'root'
})
export class DeeplinkService {

  public isExecution = true;
  private paths: string[] = [];
  private deeplinkUrl = '';


  constructor(
    private readonly store: Store,
    private readonly router: Router) {
  }


  public checkAndSaveDeeplink(url: string): void {
    this.deeplinkUrl = '';

    this.paths = url.split('/').filter(item => !!item);

    this.paths.forEach((path, i) => {
      if (!this.isExecution) return;

      this.deeplinkUrl = `${ this.deeplinkUrl }/${ path }`;

      switch (i) {
        case 0: {
          this.isExecution = this.paths[0] === AppRoutes.App;
          break;
        }
        case 1: {
          this.isExecution = this.checkMainRoutes();
          break;
        }
        case 2: {
          this.isExecution = this.checkPaymentRoutes();
          break;
        }
        case 3: {
          break;
        }
        default: {
          this.isExecution = false;
          break;
        }
      }
    });

    if (this.isExecution) this.setDeeplinkUrl();

    if (!this.store.selectSnapshot(AuthState.selectSessionToken)) {
      this.router.navigate([ AppRoutes.App ]);
    }

  }


  public checkAndNavFromDeepLink(): void {
    let url = this.store.selectSnapshot(MenuState.selectDeeplinkUrl);
    let productId = '';

    if (!url) return;


    const urlArr = url.split('/').filter(item => !!item);
    if (urlArr.length === 4 && urlArr[1] === MainRoutes.Catalog && urlArr[3] !== CatalogDetailRoutes.OpenAccount) {
      productId = urlArr.pop() || '';

      url = urlArr.join('/');
      this.store.dispatch(new MenuActions.SetDeeplinkUrl(url));
    }

    this.router.navigate([ url ], { queryParams: { productId } });
    this.store.dispatch(new MenuActions.SetDeeplinkUrl(''));
  }


  private setDeeplinkUrl(): void {
    this.store.dispatch(new MenuActions.SetDeeplinkUrl(this.deeplinkUrl));
  }


  private checkMainRoutes(): boolean {
    if (!mainRoutes.includes(this.paths[1])) return false;

    return !(this.paths[1] === MainRoutes.History && this.paths.length > 2);
  }


  private checkPaymentRoutes(): boolean {
    if (!paymentRoutes.includes(this.paths[2]) && !catalogRoutes.includes(this.paths[2])) return false;

    return !(paymentRoutes.includes(this.paths[2]) && this.paths.length > 3);
  }
}
