import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Lang } from '../../../enums/lang.enum';
import { StoryResponse } from '../../../interfaces/banner.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { ApiResponse } from '../../../interfaces/response.interface';
import {
  RemoveDeviceRequestDto,
  UpdateClientRequestDto,
  UserBlockingPlatformRequest,
  UserDevicesResponseDto,
  UserProfilePhotoDto
} from '../../../interfaces/user.interface';
import { UserResource } from '../resources/user.resource';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly resource: UserResource) {
  }


  public getClient() {
    return this.resource.getClient();
  }


  public getConfirmations() {
    return this.resource.getConfirmations();
  }


  public getConfirmationsKey() {
    return this.resource.getConfirmationsKey();
  }


  public updatePhoto(photo: UserProfilePhotoDto) {
    return this.resource.updatePhoto(photo);
  }


  public userBlockingPlatform(requestData: UserBlockingPlatformRequest, headers: HttpHeaders) {
    return this.resource.userBlockingPlatform(requestData, headers);
  }


  public updateEmail(requestData: UpdateClientRequestDto) {
    return this.resource.updateEmail(requestData);
  }


  public updateEripNumber(eripId: string) {
    return this.resource.updateEripNumber(eripId);
  }


  public setLanguage(language: Lang): Observable<ApiResponse> {
    return this.resource.setLanguage(language);
  }


  public getUserDevices(): Observable<UserDevicesResponseDto> {
    return this.resource.getUserDevices();
  }


  public removeDevices(requestBody: RemoveDeviceRequestDto, headers: HttpHeaders): Observable<HttpError> {
    return this.resource.removeDevices(requestBody, headers);
  }


  public getStories(): Observable<StoryResponse> {
    return this.resource.getStories();
  }


  public completeOnboarding(): Observable<ApiResponse> {
    return this.resource.completeOnboarding();
  }


  public storyViewed(mediaContentId: string): Observable<ApiResponse> {
    return this.resource.storyViewed(mediaContentId);
  }
}
