import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
  selector: '[appDateInput]',
  standalone: true
})
export class DateInputDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInput(event: Event): void {
    const input = event.target as HTMLInputElement;
    const inputVal = input.value;
    const cursorPos = input.selectionStart;

    const sanitizedVal = inputVal.replace(/[^0-9.]/g, '');
    const segments = sanitizedVal.split('.');

    const isNumeric = /^\d+$/;

    if (sanitizedVal !== inputVal) {
      input.value = sanitizedVal;
      input.dispatchEvent(new Event('input', { bubbles: true }));
      input.setSelectionRange(cursorPos, cursorPos);
    }

    if (segments.length > 1 && segments[0].length > 2) {
      segments[0] = segments[0].slice(0, 2);
    }

    if (segments.length > 2 && segments[1].length > 2) {
      segments[1] = segments[1].slice(0, 2);
    }

    if (segments.length > 0 && isNumeric.test(segments[0])) {
      if (Number(segments[0]) > 31) {
        segments[0] = '31';
      }
    }

    if (segments.length > 1 && isNumeric.test(segments[1])) {
      if (Number(segments[1]) > 12) {
        segments[1] = '12';
      }
    }

    if (segments.length > 2 && isNumeric.test(segments[2])) {
      if (segments[2].length > 4) {
        segments[2] = segments[2].slice(0, 4);
      }
    }

    input.value = segments.join('.');
    input.dispatchEvent(new Event('input', { bubbles: true }));
    input.setSelectionRange(cursorPos, cursorPos);
  }
}
