import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { hourList, minutesList } from '../../../../../config/date.config';
import { SharedEnum } from '../../../../../enums/shared.enum';


@Component({
  selector: 'app-time-date',
  templateUrl: './time-datepicker.component.html',
  styleUrls: [ './time-datepicker.component.scss' ]
})
export class TimeDatepickerComponent implements OnInit {

  public hoursArray: string[] = this.generateInfiniteArray(hourList, 4);

  public minutesArray: string[] = this.generateInfiniteArray(minutesList, 4);

  public type = SharedEnum.ButtonTypes;

  public isShowTimePicker: boolean = false;

  public middleHour!: string;

  public middleMinute!: string;

  public selectedHour: string = '';

  public formControl!: FormControl<string>;

  @Input()
  public formControlCustom: FormControl<string> | undefined | AbstractControl<string>;

  @Output()
  public emitTime: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('inputElement')
  public inputElement!: ElementRef;

  @ViewChild('containerTime')
  public containerTime!: ElementRef;

  @ViewChild('hour', { static: false })
  public hourDiv!: ElementRef;

  @ViewChild('minute', { static: false })
  public minuteDiv!: ElementRef;

  private selectedIndexHour = 0;
  private selectedIndexMin = 0;

  private selectedItemHeight = 32;
  private itemHeight = 24;
  private itemIndent = 4;

  constructor(
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef) {
  }


  public ngOnInit(): void {
    this.initFormControl();
  }


  @HostListener('document:click', ['$event'])
  public onClickOutside(event: Event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.isShowTimePicker = false;
      this.inputElement.nativeElement.classList.remove('focus');
    }
  }


  public setTime(): void {
    if (this.middleHour && !this.middleMinute) {
      const value: string = `${ this.middleHour }:00`;
      this.formControl.setValue(value, { emitEvent: false });
    } else if (!this.middleHour && this.middleMinute) {
      const value: string = `00:${ this.middleMinute }`;
      this.formControl.setValue(value, { emitEvent: false });
    } else if (this.middleHour && this.middleMinute) {
      const value: string = `${ this.middleHour }:${ this.middleMinute }`;
      this.formControl.setValue(value, { emitEvent: false });
    } else {
      this.formControl.setValue('00:00', { emitEvent: false });
    }

    this.emitTime.emit(this.formControl.value);

    this.isShowTimePicker = false;
  }


  public showTimePicker(): void {
    if (this.inputElement && this.containerTime) {
      const inputPosition = this.inputElement.nativeElement.getBoundingClientRect();
      const containerTimeEl = this.containerTime.nativeElement;

      const top = inputPosition.bottom + window.pageYOffset;
      const left = inputPosition.left;

      containerTimeEl.style.top = `${top}px`;
      containerTimeEl.style.left = `${left}px`;
    }

    if (this.formControl.touched) {
      this.inputElement.nativeElement.classList.add('focus');
    }

    this.isShowTimePicker = !this.isShowTimePicker;

    if (this.isShowTimePicker) {
      this.setTimeValues();
    }
  }


  public selectHour(hour: string, event: any): void {
    const currentHourElement: HTMLElement = event.target as HTMLElement;
    const parentContainer: HTMLElement = currentHourElement.closest('.column') as HTMLElement;
    const selectedIndex: number = this.hoursArray.indexOf(hour) + (this.hoursArray.length / 4);
    const hourElements = this.hourDiv.nativeElement.querySelectorAll('.hour');
    this.middleHour = hour;

    this.changeColorAfterScroll(hourElements, selectedIndex);

    if (parentContainer) {
      const containerHeight: number = parentContainer.clientHeight;
      const itemHeight: number = currentHourElement.clientHeight;
      const itemOffset: number = selectedIndex * (this.itemHeight + this.itemIndent);

      parentContainer.scrollTop = itemOffset - (containerHeight / 2) + (itemHeight / 2);
    }
  }


  public onScrollHour(event: any): void {
    const container = event.target;

    const selectedIndex: number = this.calculateScrolledIndex(container);
    const hourElements = this.hourDiv.nativeElement.querySelectorAll('.hour');
    this.middleHour = this.hoursArray[selectedIndex];
    this.changeColorAfterScroll(hourElements, selectedIndex);

    this.handleCircularScroll(this.hourDiv.nativeElement);

    if (selectedIndex !== this.selectedIndexHour) {
      this.selectedIndexHour = selectedIndex;
      const itemOffset: number = selectedIndex * (this.itemHeight + this.itemIndent);
      this.hourDiv.nativeElement.scrollTop = itemOffset - (this.hourDiv.nativeElement.clientHeight / 2) + (this.selectedItemHeight / 2);
    }
  }


  public selectMinutes(minute: string, event: any): void {
    const currentHourElement: HTMLElement = event.target as HTMLElement;
    const parentContainer: HTMLElement = currentHourElement.closest('.column') as HTMLElement;

    const selectedIndex: number = this.minutesArray.indexOf(minute) + (this.minutesArray.length / 4);
    const minuteElements = this.minuteDiv.nativeElement.querySelectorAll('.minute');

    this.middleMinute = minute;

    this.changeColorAfterScroll(minuteElements, selectedIndex);

    if (parentContainer) {
      const containerHeight: number = parentContainer.clientHeight;
      const itemHeight: number = currentHourElement.clientHeight;
      const itemOffset: number = selectedIndex * (this.itemHeight + this.itemIndent);

      parentContainer.scrollTop = itemOffset - (containerHeight / 2) + (itemHeight / 2);
    }
  }


  public onScrollMinutes(event: any): void {
    const container = event.target;

    const selectedIndex = this.calculateScrolledIndex(container);
    const minuteElements = this.minuteDiv.nativeElement.querySelectorAll('.minute');

    this.middleMinute = this.minutesArray[selectedIndex];
    this.changeColorAfterScroll(minuteElements, selectedIndex);
    this.handleCircularScroll(this.minuteDiv.nativeElement);

    if (selectedIndex !== this.selectedIndexMin) {
      this.selectedIndexMin = selectedIndex;
      const itemOffset: number = selectedIndex * (this.itemHeight + this.itemIndent);
      this.minuteDiv.nativeElement.scrollTop = itemOffset - (this.minuteDiv.nativeElement.clientHeight / 2) + (this.selectedItemHeight / 2);
    }
  }


  public focusIn(): void {
    this.inputElement.nativeElement.classList.add('focus');
  }


  public focusOut(): void {
    this.inputElement.nativeElement.classList.remove('focus');
  }


  private initFormControl(): void {
    if (!this.formControlCustom) {
      this.formControl = new FormControl<string>('', {nonNullable: true});
    } else {
      this.formControl = this.formControlCustom as FormControl;
    }
  }


  private changeColorAfterScroll(element: any, idxElement: number): void {
    element.forEach((el: any, index: number): void => {
      if (index === idxElement) {
        el.classList.add('scrolled');
      } else {
        el.classList.remove('scrolled');
      }
    });
  }


  private generateInfiniteArray(sourceArray: string[], repetitions: number): string[] {
    const result: string[] = [];
    for (let i = 0; i < repetitions; i++) {
      result.push(...sourceArray);
    }
    return result;
  }


  private handleCircularScroll(scrollElement: HTMLElement): void {
    if (scrollElement.scrollTop > scrollElement.scrollHeight / 4 * 3) {
      scrollElement.scrollTop = scrollElement.scrollHeight / 2;
    }

    if (scrollElement.scrollTop + scrollElement.clientHeight < scrollElement.scrollHeight / 4) {
      scrollElement.scrollTop += scrollElement.scrollHeight / 4;
    }
  }

  private calculateScrolledIndex(container: HTMLElement): number {
    const containerHeight = container.clientHeight;
    const itemHeight = this.itemHeight + this.itemIndent;
    const offset = container.scrollTop + containerHeight / 2;

    return Math.floor(offset / itemHeight);
  }


  private setTimeValues(): void {
    this.cdr.detectChanges();

    if (!this.hourDiv && !this.minuteDiv) return;

    const [hours, minutes] = this.formControl.value ? this.formControl.value.split(':') : ['00', '00'];

    const selectedHourIndex: number = this.hoursArray.indexOf(hours) + (this.hoursArray.length / 4);
    const selectedMinIndex: number = this.minutesArray.indexOf(minutes) + (this.minutesArray.length / 4);
    const parentHourContainer = this.hourDiv.nativeElement;
    const parentMinContainer = this.minuteDiv.nativeElement;

    const hourElements = parentHourContainer.querySelectorAll('.hour');
    this.changeColorAfterScroll(hourElements, selectedHourIndex);

    const minElements = parentMinContainer.querySelectorAll('.minute');
    this.changeColorAfterScroll(minElements, selectedMinIndex);

    if (parentHourContainer) {
      const containerHeight: number = parentHourContainer.clientHeight;
      const itemOffset: number = selectedHourIndex * (this.itemHeight + this.itemIndent);

      parentHourContainer.scrollTop = itemOffset - (containerHeight / 2) + (this.selectedItemHeight / 2);
    }

    if (parentMinContainer) {
      const containerHeight: number = parentMinContainer.clientHeight;
      const itemOffset: number = selectedMinIndex * (this.itemHeight + this.itemIndent);

      parentMinContainer.scrollTop = itemOffset - (containerHeight / 2) + (this.selectedItemHeight / 2);
    }
  }
}
