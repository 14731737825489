import { HttpErrorResponse, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { AcceptedHeaderNames } from '../config/http.config';
import { AppRoutes } from '../config/routes.config';
import { AuthActions } from '../models/auth/state/auth.actions';
import { AuthState } from '../models/auth/state/auth.state';
import { ProductsActions } from '../models/products/state/products.actions';


export function jwtInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const store = inject(Store);
  const router = inject(Router);

  const token: string | null = store.selectSnapshot(AuthState.selectSessionToken);
  const isAuthApiUrl = request.url.startsWith(environment.api.auth);
  const isCoreApiUrl = request.url.startsWith(environment.api.core);
  const isPaymentApiUrl = request.url.startsWith(environment.api.payment);
  const isNotLogin = !request.url.includes('authentication/login');
  const isNotTemporal = !request.url.includes('authentication/temporal');
  const isOpenApiUrl = request.url.startsWith(environment.api.openApi);

  if (token && isNotLogin && isNotTemporal && (isAuthApiUrl || isCoreApiUrl || isPaymentApiUrl || isOpenApiUrl)) {
    request = request.clone({
      setHeaders: {
        [AcceptedHeaderNames.Authorization]: `Bearer ${ token }`
      }
    });
  }

  return next(request).pipe(
    catchError(errorResponse => {
      if (errorResponse instanceof HttpErrorResponse && errorResponse.status === 401) {
        store.dispatch(new AuthActions.ClearSession());
        store.dispatch(new ProductsActions.ClearProducts());
        router.navigate([ AppRoutes.Auth ]);
      }
      return throwError(() => errorResponse);
    })
  );
}
