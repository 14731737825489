import { Directive, ElementRef, HostListener } from '@angular/core';


@Directive({
  selector: '[appValidAmount]',
  standalone: true
})
export class ValidInputAmountDirective {
  private regex = new RegExp(/^[0-9,]*$/); // Allow only numbers and comma
  private maxBeforeDecimal = 9;
  private maxDecimalPlaces = 2;

  constructor(private el: ElementRef) {}

  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    const input = this.el.nativeElement.value;
    const selectionStart = this.el.nativeElement.selectionStart;
    const key = event.key;

    // Allow navigation keys
    if (['Backspace', 'Delete', 'Tab', 'End', 'Home', 'ArrowLeft', 'ArrowRight'].indexOf(key) !== -1) {
      return;
    }

    //handle first character
    if ((key === '.' || key === ',') && input.length === 0) {
      this.insertCharacter('0,', 2)
      return;
    }

    // Replace dot with comma
    if (key === '.' && !(input.indexOf(',') !== -1 || input.length === 0 || input.slice(-1) === ',')) {
      event.preventDefault();
      this.insertCharacter(',');
      return;
    }

    // Restrict to allowed characters
    if (!this.regex.test(key)) {
      event.preventDefault();
      return;
    }

    // Prevent multiple commas
    if ((key === ',' || key === ".") && (input.indexOf(',') !== -1 || input.length === 0 || input.slice(-1) === ',')) {
      event.preventDefault();
      return;
    }

    // Handle leading zeros
    if (input === '0' && key !== ',') {
      event.preventDefault();
      this.el.nativeElement.value = key;
      return;
    }

    // Handle maximum digits before comma
    const [beforeComma, afterComma] = input.split(',');
    if (beforeComma.length >= this.maxBeforeDecimal && key !== ',' && selectionStart === 9) {
      event.preventDefault();
      return;
    }

    // Handle maximum decimal places
    if (afterComma && afterComma.length >= this.maxDecimalPlaces && selectionStart >= (input.length - 2)) {
      event.preventDefault();
      return;
    }

    // Handle maximum length with digits and comma
    if (input.length >= (this.maxBeforeDecimal + this.maxDecimalPlaces + 1)) {
      event.preventDefault();
      return;
    }
  }

  @HostListener('blur', ['$event']) onBlur(event: Event) {
    let input = this.el.nativeElement.value;

    // Remove multiple commas
    const commaIndex = input.indexOf(',');
    if (commaIndex !== -1) {
      input = input.substring(0, commaIndex + 1) + input.substring(commaIndex + 1).replace(/,/g, '');
    }

    // Add trailing zeros
    if (commaIndex !== -1) {
      const [beforeComma, afterComma] = input.split(',');
      if (afterComma.length < this.maxDecimalPlaces) {
        input = beforeComma + ',' + afterComma.padEnd(this.maxDecimalPlaces, '0');
      }
      if (afterComma.length > this.maxDecimalPlaces) {
        input = beforeComma + ',' + afterComma.substring(0, 2)
      }
    }

    // Update the value in the input field
    this.el.nativeElement.value = input;
  }

  @HostListener('input', ['$event']) onInput(event: Event) {
    let input = this.el.nativeElement.value;

    // Replace dot with comma
    input = input.replace('.', ',');

    // Add leading zero if starts with comma
    if (input.charAt(0) === ',') {
      input = '0' + input;
    }

    // Remove multiple commas
    const commaIndex = input.indexOf(',');
    if (commaIndex !== -1) {
      input = input.substring(0, commaIndex + 1) + input.substring(commaIndex + 1).replace(/,/g, '');
    }


    // Update the value in the input field
    this.el.nativeElement.value = input;
  }

  private insertCharacter(character: string, len: number = 1) {
    const el = this.el.nativeElement;
    const start = el.selectionStart;
    const end = el.selectionEnd;

    const beforeSelection = el.value.substring(0, start);
    const afterSelection = el.value.substring(end);

    el.value = beforeSelection + character + afterSelection;

    // Set the new cursor position
    el.setSelectionRange(start + len, start + len);
  }
}
