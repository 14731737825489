import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { ReceiptInterface } from '../../../interfaces/receipt.interface';


@Injectable({
  providedIn: 'root'
})
export class ReceiptResource {


  constructor(private readonly http: HttpClient) {}


  public getReceiptStatement(requestData: ReceiptInterface.StatementRequestDto): Observable<ReceiptInterface.ReceiptResponseDto> {
    return this.http.post<ReceiptInterface.ReceiptResponseDto>(ApiConfig.RECEIPT_GET_STATEMENT, requestData);
  }


  public detReceiptPaymentCheck(requestData: ReceiptInterface.CheckRequestDto): Observable<ReceiptInterface.ReceiptResponseDto> {
    return this.http.post<ReceiptInterface.ReceiptResponseDto>(ApiConfig.RECEIPT_GET_PAYMENT_CHECK, requestData);
  }


  public getDepositPaymentsReport(requestData: ReceiptInterface.DepositPaymentDto): Observable<ReceiptInterface.ReceiptResponseDto> {
    return this.http.post<ReceiptInterface.ReceiptResponseDto>(ApiConfig.RECEIPT_GET_DEPOSIT_PAYMENTS_REPORT, requestData);
  }
}
