import { Currency } from '../enums/currency.enum';


export function addZeros(amount: string): string {
  amount = amount.replace('.', ',');
  const value = amount?.split(',').slice(0, 2)[0] || '0';
  const decimalPart = amount?.split(',').slice(0, 2)[1]?.padEnd(2, '0') || '00';
  const matched = decimalPart.match(/\d{1,2}/);
  if (matched) {
    return `${ value },${ matched[0] }`;
  } else {
    return `${ value },${ decimalPart }`;
  }
}


export function addZerosNew(amount: string): string {
  const value = amount?.split('.').slice(0, 2)[0] || '0';
  const decimalPart = amount?.split('.').slice(0, 2)[1]?.padEnd(2, '0') || '00';
  return `${ value }.${ decimalPart }`;
}


export function getAmountArr(amount: string): string[] {
  return amount.split('.');
}


export function addSeparators(amount: number): string {
  let amountStr = String(amount);

  if (amountStr.length && amountStr.length > 3) {
    const iterations = Math.trunc((amountStr.length - 1) / 3);

    amountStr = amountStr.split('').reverse().join('');

    const amountArr = [];

    for (let i = 1; i <= iterations; i++) {
      amountArr.push(amountStr.slice((i - 1) * 3, 3 * i).split('').reverse().join(''));
      if (i === iterations) {
        amountArr.push(amountStr.slice(i * 3).split('').reverse().join(''));
      }
    }

    amountStr = amountArr.reverse().join(' ');
  }

  return amountStr;
}


export function processString(inputString: string): string {
  let result: string = '';

  if (inputString.includes('.')) {
    result = checkSplitAmount(inputString);
  } else if (inputString.includes(',')) {
    result = inputString;
  } else {
    result = inputString + ',00';
  }

  return result;
}


export function addZeroSimple(amount: string): string {
  return amount && amount.indexOf(',') < 0 ? amount.concat(',00') : amount;
}


export function addCurrency(amount: string, currency: string): string {
  return amount && currency ? amount.concat(' ').concat(currency) : amount;
}


export function removeCurrency(amount: string, currency: string): string {
  return amount && currency && amount.indexOf(currency) > 0 ? amount.substring(0, amount.indexOf(currency) - 1) : amount;
}


export function transformValue(formControlValue: string, maxLengthLeftSide: number, pattern: RegExp, prevValue?: string): string {
  const maxLengthRightSide: number = 2;

  if (formControlValue == null) {
    return '';
  }

  let value: string = formControlValue.replace(pattern, '');
  value = value.replaceAll('.', ',');

  const valueArr: string[] = value.split(',');

  if (valueArr.length > 2) {
    const tempRightSide = `${ valueArr[1] }${ valueArr[2] }`;

    return tempRightSide.length <= 2
      ? `${ valueArr[0] },${ tempRightSide }`
      : `${ valueArr[0] }${ valueArr[1] },${ valueArr[2] }`;
  }

  value = valueArr[0];

  if (value.length > 1 && value[0] === '0') {
    value = value.slice(1);
  }

  value = value.slice(0, maxLengthLeftSide);

  if (prevValue) {
    let prevValueLeftSide = prevValue.split(',')[0];
    prevValueLeftSide = prevValueLeftSide[0] === '0' ? value : prevValueLeftSide;
    value = value.length === prevValueLeftSide.length ? prevValueLeftSide : value;
  }

  if (valueArr.length > 1) {
    value = value === '' ? '0' : value;
    let rightSide = valueArr[1].slice(0, maxLengthRightSide);

    if (prevValue) {
      const prevValueRightSide = prevValue.split(',')[1];
      rightSide = prevValueRightSide && prevValueRightSide.length === rightSide.length
        ? prevValueRightSide
        : rightSide;
    }

    // TODO: поменять на точку когда перестанет использоваться InputAmountComponent
    value = value + ',' + rightSide;
  }

  return value;
}


export function formatToShow(value: string): string {
  return addZeros((value.startsWith('.') ? '0'.concat(value) : value).replace('.', ','));
}


export function currencyByCode(code: string): Currency | string{
  switch (code) {
    case '643':
      return Currency.RUB;
    case '840':
      return Currency.USD;
    case '978':
      return Currency.EUR;
    case '826':
      return Currency.GBP;
    case '985':
      return Currency.PLN;
    case '933':
      return Currency.BYN;
    default:
      return code;
  }
}


function checkSplitAmount(inputString: string): string {
  let result: string = '';
  const decimalPart = inputString.split('.')[1];

  if (decimalPart.length === 0) {
    result = inputString.replace('.', ',') + '00';
  } else if(decimalPart.length === 1) {
    result = inputString.replace('.', ',') + '0';
  } else {
    result = inputString.replace('.', ',');
  }

  return result;
}
