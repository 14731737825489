import { Currency } from '../enums/currency.enum';


export namespace AmountConfig {


  export const TotalAmountsDefault = {
    totalAmounts: [
      {
        currency: Currency.BYN,
        amount: undefined
      },
      {
        currency: Currency.EUR,
        amount: undefined
      },
      {
        currency: Currency.RUB,
        amount: undefined
      },
      {
        currency: Currency.USD,
        amount: undefined
      }
    ]
  };
}
