import { ReceiptInterface } from '../../../interfaces/receipt.interface';


export namespace ReceiptActions {

  export class GetReceiptStatement {
    public static readonly type = '[Receipt] GetReceiptStatement';


    constructor(public readonly requestData: ReceiptInterface.StatementRequestDto) {}
  }


  export class GetReceiptPaymentCheck {
    public static readonly type = '[Receipt] GetReceiptPaymentCheck';


    constructor(public readonly requestData: ReceiptInterface.CheckRequestDto) {}
  }


  export class GetDepositPaymentsReport {
    public static readonly type = '[Receipt] GetDepositPaymentsReport';


    constructor(public readonly requestData: ReceiptInterface.DepositPaymentDto) {}
  }
}
