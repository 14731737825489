<div class="app-radio"
     [class.checked]="checked"
     [class.disabled]="disabled"
     (click)="onCheck()"
>
  <div class="app-radio-button"></div>

  <div class="app-radio-title">
    <ng-content></ng-content>
  </div>
</div>
