import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { ParamsResponse } from '../../../interfaces/params.interface';


@Injectable({
  providedIn: 'root'
})
export class ParamsResource {

  constructor(
    private readonly http: HttpClient) {
  }


  public getParams(): Observable<ParamsResponse> {
    return this.http.get<any>(ApiConfig.REGISTRATION_GET_PARAMS);
  }

}
