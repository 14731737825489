<div class="date">
  <input class="date-input" [class.error]="invalid" type="text"
         #inputElement
         appDateInput
         [matDatepicker]="picker"
         [formControl]="formControl"
         [(ngModel)]="value"
         (dateInput)="isActionsNeed ? onDateChangeApply($event) : onDateChange($event)"
         [min]="min"
         [max]="max"
         (focusin)="focusIn()"
         (focusout)="focusOut()"
         [placeholder]="placeholder"
  >
  <div class="date-icon" (click)="openDatepicker(picker)">
    <i class="icon icon-calendar-empty"></i>
  </div>
  <mat-datepicker #picker (closed)="onClosed()">
    <mat-datepicker-actions *ngIf="isActionsNeed">
      <app-button
        matDatepickerApply
        [isCustomize]="true"
        (click)="applyDate()"
      >
        {{ 'READY' | translate }}
      </app-button>
    </mat-datepicker-actions>
  </mat-datepicker>
</div>
