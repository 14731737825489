import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, finalize, Observable, of } from 'rxjs';
import { PaymentActions } from '../models/payment/state/payment.actions';
import { PreloaderActions } from '../models/preloader/state/preloader.actions';
import { ServicesActions } from '../models/services/state/services.actions';


@Injectable({
  providedIn: 'root'
})
export class CustomizeByUserResolver implements Resolve<boolean> {
  constructor(
    private readonly store: Store) {
  }


  public resolve(): Observable<boolean> {
    // this.store.dispatch(new PreloaderActions.Start());

    return this.store.dispatch([
      new ServicesActions.GetCustomizeByUser()
    ]).pipe(
      catchError(() => {
        // this.store.dispatch(new PreloaderActions.Stop());
        return of(true);
      }),
      // finalize(() => this.store.dispatch(new PreloaderActions.Stop()))
    );
  }
}
