export namespace DateEnum {


  export enum DateFormat {
    MM_YY = 'MM/YY',
    MONTH = 'month',
    HH_MM_YY = 'YYYY-MM-DD',
    FULL_DATE_TIME = 'ddd MMM DD YYYY HH:mm:ss [GMT]Z',
    DATEPICKER_DATE_TIME = 'ddd MMM DD YYYY 00:00:00 [GMT]ZZ'
  }


  export enum PeriodDay {
    TODAY = 'TODAY',
    YESTERDAY = 'YESTERDAY'
  }


  export enum DateMonth {
    JANUARY = 'JANUARY',
    FEBRUARY = 'FEBRUARY',
    MARCH = 'MARCH',
    APRIL = 'APRIL',
    MAY = 'MAY',
    JUNE = 'JUNE',
    JULY = 'JULY',
    AUGUST = 'AUGUST',
    SEPTEMBER = 'SEPTEMBER',
    OCTOBER = 'OCTOBER',
    NOVEMBER = 'NOVEMBER',
    DECEMBER = 'DECEMBER'
  }


  export enum DateMonthEndings {
    JANUARY = 'JANUARY1',
    FEBRUARY = 'FEBRUARY1',
    MARCH = 'MARCH1',
    APRIL = 'APRIL1',
    MAY = 'MAY1',
    JUNE = 'JUNE1',
    JULY = 'JULY1',
    AUGUST = 'AUGUST1',
    SEPTEMBER = 'SEPTEMBER1',
    OCTOBER = 'OCTOBER1',
    NOVEMBER = 'NOVEMBER1',
    DECEMBER = 'DECEMBER1'
  }


  export enum MonthCount {
    JANUARY = '01',
    FEBRUARY = '02',
    MARCH = '03',
    APRIL = '04',
    MAY = '05',
    JUNE = '06',
    JULY = '07',
    AUGUST = '08',
    SEPTEMBER = '09',
    OCTOBER = '10',
    NOVEMBER = '11',
    DECEMBER = '12'
  }


  export enum DateDays {
    MON = 'MON',
    TUE = 'TUE',
    WED = 'WED',
    THU = 'THU',
    FRI = 'FRI',
    SAT = 'SAT',
    SUN = 'SUN'
  }


  export enum DateRange {
    WEEK = 'WEEK',
    MONTH = 'ONE_MONTH',
    THREE_MONTH = 'THREE_MONTH',
    SIX_MONTH = 'SIX_MONTH',
    YEAR = 'YEAR',
    OTHER_PERIOD = 'OTHER_PERIOD'
  }
}
