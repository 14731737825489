import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { AmountCurrentPaymentByCreditRespDto } from '../../../interfaces/credit.interface';
import { CreditService } from '../services/credit.service';
import { CreditActions } from './credit.actions';


interface CreditStateModel {
  creditCurrentPayment: AmountCurrentPaymentByCreditRespDto;
}


@State<CreditStateModel>({
  name: 'credit'
})
@Injectable()
export class CreditState {

  constructor(private readonly creditService: CreditService) {
  }


  @Action(CreditActions.AmountByCredit)
  public getAmountCurrentPaymentByCredit(ctx: StateContext<CreditStateModel>, { requestData }: CreditActions.AmountByCredit) {
    return this.creditService.getAmountCurrentPaymentByCredit(requestData)
      .pipe(
        tap(credit => {
          ctx.patchState({
            creditCurrentPayment: credit
          });
        })
      );
  }
}
