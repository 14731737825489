import { Routes } from '@angular/router';
import { AppRoutes } from './config/routes.config';
import { AuthGuard } from './guards/auth.guard';
import { CustomizeByUserResolver } from './resolvers/customize-by-user.resolver';
import { MainResolver } from './resolvers/main.resolver';


export const routes: Routes = [
  {
    path: '',
    redirectTo: AppRoutes.Auth,
    pathMatch: 'full'
  },
  {
    path: 'oauth2/callback',
    loadComponent: () => import('./views/modules/auth/components/auth-msi-redirect/auth-msi-redirect.component')
      .then(c => c.AuthMsiRedirectComponent)
  },
  {
    path: AppRoutes.Auth,
    loadChildren: () => import('./views/modules/auth/auth.routing').then(mod => mod.AuthRouting)
  },
  {
    path: AppRoutes.App,
    loadChildren: () => import('./views/modules/main/main.routing').then(m => m.MainRouting),
    resolve: [ MainResolver, CustomizeByUserResolver ],
    canActivate: [ AuthGuard ]
  },
  {
    path: AppRoutes.Sandbox,
    loadChildren: () => import('./views/modules/sandbox/sandbox.routing').then(m => m.SandboxRouting)
  },
  {
    path: AppRoutes.Errors,
    loadChildren: () => import('./views/errors/errors.routing').then(m => m.ErrorsRouting)
  },
  {
    path: '**',
    loadComponent: () => import('./views/modules/auth/components/auth-deeplink-redirect/auth-deeplink-redirect.component')
      .then(c => c.AuthDeeplinkRedirectComponent)
  }
];
