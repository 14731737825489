import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'customDate',
  standalone: true
})
export class CustomDatePipe implements PipeTransform {
  transform(value: Date | null): string {
    if (value) {
      const day = value.getDate();
      const month = value.getMonth() + 1;
      const year = value.getFullYear();
      return `${ day < 10 ? '0' : '' }${ day }.${ month < 10 ? '0' : '' }${ month }.${ year }`;
    } else {
      return '';
    }
  }
}
