<div class="date">
  <input #inputElement
         class="date-input"
         [class.error]="invalid"
         appTimeFormat
         [formControl]="formControl"
         (focusin)="focusIn()"
         (focusout)="focusOut()"
         placeholder="00:00"
  >

  <div class="date-icon" (click)="showTimePicker()">
    <i class="icon icon-clock"></i>
  </div>
</div>

<div #containerTime class="container-time" *ngIf="isShowTimePicker">
  <div class="container">
    <div class="column" #hour (scroll)="onScrollHour($event)">
      <div class="hour"
           setDefaultActive
           *ngFor="let hour of hoursArray"
           (click)="selectHour(hour, $event)"
      >
        {{ hour }}
      </div>
    </div>
    <div class="column" #minute (scroll)="onScrollMinutes($event)">
      <div class="minute"
           setDefaultActive
           *ngFor="let minute of minutesArray"
           (click)="selectMinutes(minute, $event)"
      >
        {{ minute }}
      </div>
    </div>
  </div>

  <div class="button-container" (click)="setTime()">
    {{ 'READY' | translate }}
  </div>
</div>

