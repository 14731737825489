import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Lang } from '../../../enums/lang.enum';
import { Theme } from '../../../enums/theme.enum';
import { SettingsActions } from './settings.actions';


export interface ThemeStateModel {
  theme: Theme;
  lang: Lang;
}


@State<ThemeStateModel>({
  name: 'settings',
  defaults: {
    theme: Theme.Light,
    lang: Lang.RU
  }
})
@Injectable()
export class SettingsState {

  @Selector()
  public static selectTheme(state: ThemeStateModel): Theme {
    return state.theme;
  }


  @Selector()
  public static selectLang(state: ThemeStateModel): Lang {
    return state.lang || Lang.RU;
  }


  @Action(SettingsActions.ChangeTheme)
  public changeTheme(ctx: StateContext<ThemeStateModel>, { theme }: SettingsActions.ChangeTheme): void {
    ctx.patchState({ theme });
  }


  @Action(SettingsActions.ChangeLang)
  public changeLang(ctx: StateContext<ThemeStateModel>, { lang }: SettingsActions.ChangeLang): void {
    ctx.patchState({ lang });
  }
}
