import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { MenuActions } from './menu.actions';


export interface MenuStateModel {
  shown: boolean;
  deepLinkUrl: string;
  currentURL?: string;
  previousURL?: string;
}


@State<MenuStateModel>({
  name: 'menu',
  defaults: {
    shown: false,
    deepLinkUrl: ''
  }
})
@Injectable()
export class MenuState {

  @Selector()
  public static isShown(state: MenuStateModel): boolean {
    return state.shown;
  }


  @Selector()
  public static selectCurrentURL(state: MenuStateModel): string {
    return state.currentURL as string;
  }


  @Selector()
  public static selectPreviousURL(state: MenuStateModel): string {
    return state.previousURL as string;
  }


  @Selector()
  public static selectDeeplinkUrl(state: MenuStateModel): string {
    return state.deepLinkUrl;
  }


  @Action(MenuActions.SetCurrentURL)
  public setCurrentURL(ctx: StateContext<MenuStateModel>, { payload }: MenuActions.SetCurrentURL): void {
    if (ctx.getState().shown) {
      ctx.setState(
        patch<MenuStateModel>({
          shown: false
        })
      );
    }
    ctx.setState(
      patch<MenuStateModel>({
        currentURL: payload
      })
    );
  }


  @Action(MenuActions.SetPreviousURL)
  public setPreviousURL(ctx: StateContext<MenuStateModel>, { payload }: MenuActions.SetPreviousURL): void {
    ctx.setState(
      patch<MenuStateModel>({
        previousURL: payload
      })
    );
  }


  @Action(MenuActions.SetDeeplinkUrl)
  public setDeeplinkUrl(ctx: StateContext<MenuStateModel>, { url }: MenuActions.SetDeeplinkUrl): void {
    ctx.patchState({ deepLinkUrl: url });
  }
}
