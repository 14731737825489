import { Component } from '@angular/core';
import { SharedEnum } from '../../../../enums/shared.enum';


@Component({
  selector: 'tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss']
})
export class TooltipComponent {

  public position: SharedEnum.TooltipPositions = SharedEnum.TooltipPositions.DEFAULT;

  public theme: SharedEnum.TooltipTheme = SharedEnum.TooltipTheme.DEFAULT;

  public tooltip = '';

  public left = 0;

  public top = 0;

  public visible!: boolean;

}
