import { DialogModule } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { AuthInfoMassagesMSI } from '../../../../../enums/auth.enum';
import { SharedEnum } from '../../../../../enums/shared.enum';
import { Consent, InfoMessage } from '../../../../../interfaces/params.interface';
import { ButtonsModule } from '../../../../lib/buttons/buttons.module';
import { ControlsModule } from '../../../../lib/controls/controls.module';


interface ConsentItem {
  key: string;
  description: string[];
}


export interface AuthAboutNewData {
  consents: Consent[];
  message: InfoMessage;
}


@Component({
  selector: 'app-auth-info-messages',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    ButtonsModule,
    ControlsModule,
    TranslateModule
  ],
  templateUrl: './auth-info-messages.component.html'
})
export class AuthInfoMessagesComponent {

  public title!: string;
  public desc!: string[];
  public consents!: ConsentItem[];
  public selected: ConsentItem[] = [];

  public type = SharedEnum.ButtonTypes;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    private readonly data: AuthAboutNewData,
    private readonly dialog: MatDialogRef<AuthInfoMessagesComponent>) {

    this.title = (data.message && data.message.header) ? data.message.header : AuthInfoMassagesMSI.HEADER;
    this.desc = (data.message && data.message.message) ? data.message.message.split('\n') : [ AuthInfoMassagesMSI.MESSAGE ];
    this.consents = this.replaceConsents(this.data.consents);
  }


  public onClose(): void {
    this.dialog.close();
  }


  public onNext(): void {
    this.dialog.close(this.selected.map(i => i.key));
  }


  public isMultidimensionalArray(arr: any): boolean {
    if (Array.isArray(arr)) {
      for (let i = 0; i < arr.length; i++) {
        if (Array.isArray(arr[i])) {
          return true;
        }
      }
    }
    return false;
  }


  public onChange(checked: boolean, item: ConsentItem): void {
    if (checked) {
      if (!this.selected.includes(item)) {
        this.selected.push(item);
      }
    } else {
      const index = this.selected.indexOf(item);
      this.selected.splice(index, 1);
    }
  }


  private replaceConsents(consents: Consent[]): ConsentItem[] {
    return consents.map(i => {
      const matches = i.description.match(/([^@]*)@{link=([^|]+)\|title=([^}]+)}/g);

      const descriptionArray: string[] = [];

      if (matches) {
        matches.forEach(match => {
          const parts = match.match(/([^@]*)@{link=([^|]+)\|title=([^}]+)}/);

          if (parts) {
            descriptionArray.push(parts[1].trim());
            descriptionArray.push(parts[2].trim());
            descriptionArray.push(parts[3].trim());
          }
        });
      }

      return {
        key: i.key,
        description: this.chunkArray(descriptionArray)
      };
    });
  }


  private chunkArray(description: any): any {
    if (description.length > 3) {
      const splitArray: any = [];
      for (let i = 0; i < description.length; i += 3) {
        splitArray.push(description.slice(i, i + 3));
      }
      return splitArray;
    } else {
      return description;
    }
  }
}
