import { CardType } from '../enums/card.enum';


export namespace CardConfig {
  const defaultIconsPath = '/assets/images/cards/default';
  export const defaultIcons = [
    { type: CardType.DEFAULT, src: `${ defaultIconsPath }/default.svg` },
    { type: CardType.MIR, src: `${ defaultIconsPath }/mir.svg` },
    { type: CardType.VISA, src: `${ defaultIconsPath }/visa.svg` },
    { type: CardType.MASTER, src: `${ defaultIconsPath }/master.svg` },
    { type: CardType.MAESTRO, src: `${ defaultIconsPath }/maestro.svg` },
    { type: CardType.BEL, src: `${ defaultIconsPath }/bel.svg` }
  ];
}
