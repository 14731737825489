import { AmountByCreditRequestDto } from '../../../interfaces/credit.interface';


export namespace CreditActions {
  export class AmountByCredit {
    public static readonly type = '[Credit] AmountByCredit';


    constructor(public readonly requestData: AmountByCreditRequestDto) {}
  }
}
