<span class="button-group_label">{{ label | translate }}</span>
<div class="button-group" [ngStyle]="style" *ngIf="!isOptions; else optionList">
    <div class="button-group_items" *ngFor="let item of buttonList; let i = index">
        <div class="button-group_items__one"
             [class.active]="activeIndex === i"
             (click)="setActiveIndex(i)"
        >
            <span class="button-group_items__one-text">{{ item | translate }}</span>
        </div>
    </div>
</div>

<ng-template #optionList>
    <div class="button-group" [ngStyle]="style">
        <div class="button-group_items" *ngFor="let item of options; let i = index">
            <div class="button-group_items__one"
                 [class.active]="activeIndex === i"
                 (click)="setActiveOptionIndex(i)"
            >
                <span class="button-group_items__one-text">{{ item.label | translate }}</span>
            </div>
        </div>
    </div>
</ng-template>
