import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs';
import { ReceiptInterface } from '../../../interfaces/receipt.interface';
import { ReceiptService } from '../services/receipt.service';
import { ReceiptActions } from './receipt.actions';


interface ReceiptStateModel {
  statement: ReceiptInterface.ReceiptResponseDto;
}


@State<ReceiptStateModel>({
  name: 'statement'
})
@Injectable()
export class ReceiptState {


  constructor(private readonly receiptService: ReceiptService) {}


  @Action(ReceiptActions.GetReceiptStatement)
  public getStatementReceipt(ctx: StateContext<ReceiptStateModel>, { requestData }: ReceiptActions.GetReceiptStatement) {
    return this.receiptService.getReceiptStatement(requestData)
      .pipe(
        map(receipt => {
          ctx.patchState({
            statement: receipt
          });
        })
      );
  }


  @Action(ReceiptActions.GetReceiptPaymentCheck)
  public getReceiptPaymentCheck(ctx: StateContext<ReceiptStateModel>, { requestData }: ReceiptActions.GetReceiptPaymentCheck) {
    return this.receiptService.detReceiptPaymentCheck(requestData)
      .pipe(
        map(receipt => {
          ctx.patchState({
            statement: receipt
          })
        })
      )
  }


  @Action(ReceiptActions.GetDepositPaymentsReport)
  public getDepositPaymentsReport(ctx: StateContext<ReceiptStateModel>, { requestData }: ReceiptActions.GetDepositPaymentsReport) {
    return this.receiptService.getDepositPaymentsReport(requestData)
      .pipe(
        map(receipt => {
          ctx.patchState({
            statement: receipt
          })
        })
      )
  }
}
