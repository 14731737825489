import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { FinanceInterface } from '../../../interfaces/finance.interface';


@Injectable({
  providedIn: 'root'
})
export class FinanceResource {

  constructor(
    private readonly http: HttpClient
  ) {
  }


  public getTotalAmounts(): Observable<FinanceInterface.TotalAmountsResponseDto> {
    return this.http.get<FinanceInterface.TotalAmountsResponseDto>(ApiConfig.FINANCE_TOTAL_AMOUNT);
  }


  public getProductBalanceAmount(requestData: FinanceInterface.BalanceAmountRequestDto): Observable<FinanceInterface.BalanceAmountResponseDto> {
    return this.http.post<FinanceInterface.BalanceAmountResponseDto>(ApiConfig.PRODUCT_BALANCE_AMOUNT, requestData);
  }
}
