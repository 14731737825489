<ul class="select-options">
  <li class="select-options-list"
      [class.selected]="selectUniq"
      [class.hidden]="!selectUniq"
      (click)="selectAllUniq()"
  >
    {{ 'SELECT_ALL' | translate }}

    <div class="select-options-list__check" [class.multiselect]="multiSelect">
      <i class="icon icon-check-bold"></i>
    </div>
  </li>

  <div *ngFor="let select of selectListUniq">
    <span class="select-options-title">{{ (select.title ? select.title : '') | translate }}</span>

    <li class="select-options-list"
        *ngFor="let item of select.items"
        [class.selected]="item.selected"
        [class.hidden]="!item.selected"
        (click)="onOptionUniq(item)"
    >
      <div class="select-options-list-icon">
        <div class="select-options-list-icon_img"
             *ngIf="item.image && item.itemNumberMasked"
        >
          <img class="select-options-list-icon_img__image" [src]="item.image" alt="image">

          <div class="select-options-list-icon_img-icon_star"
               *ngIf="item.itemStatus !== cardStatus.UNKNOWN && item.isMainItem"
          >
            <i class="icon icon-star"></i>
          </div>

          <div class="select-options-list-icon__blocked" *ngIf="!cardStatusNotBlocked(item.komplatStatus)">
            <i class="icon icon-lock"></i>
          </div>

          <div class="select-options-list-icon_img-icon_unknown"
               *ngIf="item.itemStatus === cardStatus.UNKNOWN || (item.amount && item.amount < 0) ||
                        checkExpireDate(item.expireDate!)"
          >
            <i class="icon icon-round-attention"></i>
          </div>

          <div class="select-options-list-icon_img__number">{{ getNumberMask(item.itemNumberMasked) }}</div>
        </div>
        <div class="select-options-list-icon_img__icon" *ngIf="item && item.icon">
          <i [class]="item.icon"></i>
        </div>
      </div>

      <div class="select-options-list-text">
        <span class="select-options-list-text_item">{{ item?.text }}</span>

        <div *ngIf="item.currency && item.amount && item.text; else noAmount" class="select-options-list-text_currency">
          <div *ngIf="item.displayBalance || item.amount !== 0; else hideBalance">
            <div *ngIf="item.currency && item.amount" class="select-options-list-text_currency__container">
                <span class="select-options-list-text_currency__amount">
                  {{ transformValue(item.amount, 'left') }}
                </span>
              <span class="select-options-list-text_currency__decimal">
                  ,{{ transformValue(item.amount, 'decimal') }}
                </span>
            </div>
            <span class="select-options-list-text_currency__equal">{{ item.currency }}</span>
          </div>

          <ng-template #hideBalance>
            <div class="select-options-list-text_currency__dots"></div>
            <div class="select-options-list-text_currency__dots"></div>
            <div class="select-options-list-text_currency__dots"></div>
            <div class="select-options-list-text_currency__dots"></div>
            <div class="select-options-list-text_currency__dots"></div>
            <div class="select-options-list-text_currency__dots"></div>
          </ng-template>
        </div>

        <ng-template #noAmount>
          <div class="select-options-list-text_currency">
            <div *ngIf="item.displayBalance; else hideBalance">
              <span class="select-options-list-text_currency__amount">0</span>
              <span class="select-options-list-text_currency__decimal">,00</span>
              <span class="select-options-list-text_currency__equal">{{ item.currency }}</span>
            </div>

            <ng-template #hideBalance>
              <div class="select-options-list-text_currency__dots"></div>
              <div class="select-options-list-text_currency__dots"></div>
              <div class="select-options-list-text_currency__dots"></div>
              <div class="select-options-list-text_currency__dots"></div>
              <div class="select-options-list-text_currency__dots"></div>
              <div class="select-options-list-text_currency__dots"></div>
            </ng-template>
          </div>
        </ng-template>
      </div>

      <div class="select-options-list__check" [class.multiselect]="multiSelect">
        <i class="icon icon-check-bold"></i>
      </div>
    </li>
  </div>
</ul>
