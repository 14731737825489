import { FormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { msiDateFormat } from '../config/date.config';
import { MsiDictNames, MsiQuestFieldType } from '../enums/msi.enum';
import { MsiQuestion, SoatoNode, SoatoNodeLike } from '../interfaces/msi.interface';


export function parseValidators(question: MsiQuestion): ValidatorFn[] {
  const validators: ValidatorFn[] = [];

  question.mandatory && validators.push(Validators.required);
  question.regExp && validators.push(Validators.pattern(question.regExp));

  if (question.id === 'SUBJECT_ADDRESS_POSTAL_CODE') {
    validators.push(Validators.minLength(6), Validators.maxLength(6));
  }

  return validators;
}


export function parseValue(value: string, type: MsiQuestFieldType | MsiDictNames): string | Moment {
  if (type === MsiQuestFieldType.Date) {
    return moment(value, msiDateFormat);
  }
  return value;
}


export function searchSoatoNode(node: SoatoNodeLike, code: string): SoatoNodeLike | null {
  if (node.code == code) {
    return node;
  } else if (node.soatoNodes != null) {
    let i;
    let result = null;
    for (i = 0; result == null && i < node.soatoNodes.length; i++) {
      result = searchSoatoNode(node.soatoNodes[i], code);
    }
    return result;
  }
  return null;
}


export function getSoatoName(node: SoatoNode): string {
  // обл
  if (node.regionName && !node.localityName && !node.districtName && !node.unitAryArea) {
    return `${ node.regionName } ${ node.localityKind }.`;
  }

  // р-н
  if (node.regionName && node.districtName && !node.localityName && !node.unitAryArea) {
    return `${ node.districtName } ${ node.localityKind }.`;
  }

  // г
  if (node.localityName && !node.soatoNodes) {
    return `${ node.localityKind }. ${ node.localityName }`;
  }

  // гп / сс
  if (node.unitAryArea) {
    return `${ node.unitAryArea } ${ node.localityKind }.`;
  }

  return 'n/a';
}


export function mapMomentFormFields(formValue: any): any {
  for (const key in formValue) {
    if (formValue[key] instanceof moment) {
      formValue[key] = moment(formValue[key]).format('DD.MM.YYYY');
    }
  }
  return formValue;
}


export function passEqual(group: FormGroup): { [key: string]: boolean } | null {
  if (group) {
    const controlList = [];

    for (const control in group.controls) {
      controlList.push(group.controls[control]);
    }

    if (controlList[0].value === controlList[1].value) {
      return null;
    }
  }

  return {
    'passEqual': true
  };
}


export const setValueToString = {
  DynamicValue: (id: string | number): string => `Group with id: ${ id } not found`
}
