import { GetCardBalanceDtoRs, ProductCustomizeDto } from './product-liability.interface';
import { CardSmsStatus } from '../enums/card.enum';
import { Currency } from '../enums/currency.enum';
import { ConfirmAction } from './auth.interface';
import { ProductsEnum } from '../enums/products.enum';
import LimitRule = ProductsEnum.LimitRule;
import { FormControl } from '@angular/forms';

export interface CardType {
  code: number;
  name: string;
  imageUri: string;
  paySysImageUri: string;
  textColor: string;
  paySystemName: string;
  premiumType: number;
  isPremium: boolean;
  category: string;
  urlAdvantage: string;
}


export interface ConfirmableActionDto {
  confirmationData?: string;
}

export interface CardActionDto {
  cardHash: string;
}

export interface CardActionConfirmableDto extends CardActionDto, ConfirmableActionDto {
}


export interface CardActionConfirmDto extends CardActionDto, ConfirmAction {
}


export interface CardBlockDto extends CardActionDto, ConfirmAction {
  block: boolean;
}

export interface CardManageSmsRequestDto extends CardActionDto, ConfirmAction {
  enableSmsService: boolean,
  notifyProductCode: string,
}


export interface CardLimitDto extends CardActionDto, ConfirmAction {
  limitUnits: LimitUnit[]
}


export interface LimitUnit {
  limitRule: ProductsEnum.LimitRule,
  amount?: string | null,
  value?: boolean | string,
  countries?: LimitUnitCountry[]
}


export interface LimitUnitCountry {
  value: boolean,
  isoCountryCode: string
}
export interface CardSmsStatusRs {
  smsServiceStatus: CardSmsStatus;
  notifyProductCode: string;
}


export interface LimitIdStringRule {
  id: LimitRule,
  value: string,
}

export interface LimitIdRule {
  id: LimitRule,
  value: true,
  isoCountryCodes: string[]
}

export interface LimitWithdrawForm {
  dayAmount: FormControl<string>;
  dayOperationNumber: FormControl<string>;
  monthAmount: FormControl<string>;
  monthOperationCount: FormControl<string>;
  foreignDayAmount: FormControl<string>;
  foreignDayOperationNumber: FormControl<string>;
  foreignMonthAmount: FormControl<string>;
  foreignMonthOperationCount: FormControl<string>;
}


export interface TransferForm {
  amount: FormControl<string>;
  interestRate: FormControl<string>;
  transferAmount: FormControl<string>;
}


export interface SearchForm {
  search: FormControl<string>;
}


export interface GetLimitsRs {
  permissionForTransfers: LimitIdRule,
  permissionForIntOperations: LimitIdRule,
  operationInCountryLimits: LimitIdRule[],
  cashLimits: LimitIdStringRule[],
  internetLimits: LimitIdStringRule[],
  purchLimits: LimitIdStringRule[],
}


export interface CardSmsCostRs {
  cost: string;
  phoneNumber: string;
}


export interface CardPinGenerationCostRs {
  commission: number;
  convertedAmount: number,
  currency: Currency
}


export interface CardActivateRequestDto extends CardActionDto, ConfirmAction {
  last4CardDigits: string;
}


export interface ProductMofification {
  [key: string]: any;
  cardHash?: string;
  accountHash?: string;
}


export interface SelectCustom {
  title?: string;
  sortIndex?: number;
  items: SelectCustomOptions[];
}


export interface SelectCustomOptions {
  id: string | null;
  text?: string;
  image?: string;
  icon?: string;
  iconClass?: string;
  amount?: number;
  isCard?: boolean;
  currency?: string;
  itemStatus?: string;
  isMainItem?: boolean;
  expireDate?: number;
  sortIndex?: number;
  contractType: number;
  selected?: boolean;
  komplatStatus?: string;
  contractNumber?: string;
  cardNumberHash?: string;
  paySystemName?: string;
  displayBalance?: boolean;
  displayOnMain?: boolean;
  cardNumberMasked?: string;
  itemNumberMasked?: string;
  contractNumberCard?: string;
  contractNumberHash?: string;
  cardAvailableActions?: string[];
}


export interface ProductNestingCards {
  card: Card;
  contractNumber: string;
}


export interface ProductMainCards {
  card: Card[];
  contractNumber: string;
}


export interface Status {
  clazz: string;
  code: string;
  text: string;
  production: string;
}


export interface AvailableAction {
  name: string;
  value: number;
}


export interface CardActionDto {
  cardHash: string;
}


export interface ProductRouteProps {
  isAdditional: boolean;
  isMainPayProduct: boolean;
  productName: string;
  cardStatus: string;
  availableActionsList: string[];
  expireDate: string | number;
  cardHash?: string;
  contractNumber?: string;
  trackUrl?: string;
  trackNumber?: string;
}


export interface MappingProductData {
  account: Account[] | MappingCards[] | OnlineApplication[];
  title: string;
  visible?: boolean;
  sortIndex?: number;
}


export interface MappingCards {
  cards: Card[];
  contractNumber: string;
}


export enum CardKomplatStatus {
  FULL = 'FULL',
  TOUNBLOCK = 'TOUNBLOCK',
  INFO = 'INFO',
}


export interface Card {
  cardNumberMasked: string;
  cardHash: string;
  cardType: CardType;
  cardStatus: string;
  komplatStatus: string;
  expireDate: number;
  owner: string;
  isOverdraft: boolean;
  isSmsNotification: boolean;
  canActivateSmsNotification: boolean;
  enabled3DSecure: boolean;
  isVirtual: boolean;
  displayOnMain: boolean;
  displayBalance: boolean;
  sortIndex: number;
  creditLimit: number;
  balance: number;
  isMainPayProduct: boolean;
  accountId: string;
  currency: string;
  status: Status;
  stateSignature: string;
  isAdditional: boolean;
  cardDepartmentName: string;
  cardDepartmentAddress: string;
  cardId: number;
  overdraftLimit: number;
  cardNumberHash: string;
  contractNumber?: string;
  availableActions: AvailableAction[];
  cardAvailableActions: string[];
  isCorpoCard: boolean;
  paySystemName: string;
  productCode: number;
  name: string;
  imageUrls?: {
    onMainImageUrl: string;
    productDetailsImageUrl: string;
  };
  trackUrl?:	string;
  trackNumber?: string;
}


export interface Account {
  currency: string;
  openDate: string | number;
  endDate: number;
  accountId: string;
  accountNumber: string;
  accruedInterest: number;
  productCode: number;
  productName: string;
  balanceAmount: number;
  contractId: string;
  interestRate: number;
  accountStatus: string;
  contractNumber: string;
  contractNumberHash: string;
  cards: Card[];
  displayOnMain: boolean;
  displayBalance: boolean;
  isMainPayProduct: boolean;
  overdraftAccountNumber: string;
  ibanNum: string;
  imageUrl: string;
  sortIndex: number;
  percentRate: number;
  contractType: number;
  overdraftLimit: number;
  amountInitial: number;
  isOverdraft: boolean;
  stateSignature: string;
  availableActions: string[];
}


export interface ProductData {
  hiddenCard: Card[][];
  hiddenDeposits: Account[];
  hiddenAccount: Account[];
  hiddenCredit: Account[];
}


export interface Products {
  [key: string]: Account[] | OnlineApplication[] | GetCardBalanceDtoRs | ProductCustomizeDto | ProductRouteProps | string | undefined;
  depositAccount?: Account[] | undefined;
  currentAccount?: Account[] | undefined;
  creditAccount?: Account[] | undefined;
  overdraftAccount?: Account[] | undefined;
  onlineApplications?: OnlineApplication[] | undefined;
  cardAccount?: Account[] | undefined;
  corporateCardAccount?: Account[] | undefined;
  additionalCardAccount?: Account[] | undefined;
  cardBalance?: GetCardBalanceDtoRs | undefined;
  productCustomize?: Account[] | undefined;
  productDisplayOnMain?: ProductCustomizeDto | undefined;
  productCustomizeName?: ProductCustomizeDto | undefined;
  productCustomizeMain?: ProductCustomizeDto | undefined;
  productCustomizeSortIdx?: string | undefined;
  cardDetail?: ProductRouteProps | undefined;
}


export interface ResolveVisaAliasRequestDto {
  alias: string;
}


export interface DeleteVisaAliasRequestDto extends ConfirmAction {
  alias: string;
  guid: string;
}


export interface CreateVisaAliasRequestDto extends ConfirmAction {
  alias: string;
  cardHash: string;
  cardType: string;
}


export interface UpdateVisaAliasRequest extends ConfirmAction {
  alias: string;
  cardHash: string;
  cardHashNew: string;
  cardType: string;
}


export interface CreateVisaAliasResponseDto {
  guid: string;
}


export interface ResolveVisaAliasResponseDto {
  recipientPrimaryAccountNumber: string;
  issuerName: string;
  recipientName: string;
}


export interface DescriptionOnline {
  titleRu: string;
  titleBy: string;
  titleEn: string;
  name: string;
  nameBy: string;
  nameEn: string;
  description: string;
  descriptionBy: string;
  descriptionEn: string;
  url: string;
  imageUrl: string;
  imageUrlWeb: string;
  imageUrlPreview: string;
  commonCreditConditionUrl: string;
}


export interface OnlineApplication {
  code: string;
  status: string;
  type: string;
  statusName: string;
  createdAt: string;
  decisionAt: string;
  interestRateValue: number;
  length: number;
  principal: number;
  currency: string;
  descriptionOnline: DescriptionOnline;
  accountContractId: string;
  withdrawalMethod?: ProductsEnum.WithdrawalMethodEnum;
  contractNumber: string;
  applicationNumber: number;
  sentDate: Date;
  partnerPhoneNumber: string;
  storeLogoUrl: string;
  loanProduct: string;
  totalAmount: number;
  initialFeeAmount: number;
  clientInterestRate: number;
  duration: number;
  productCode: string;
  selPlaceId: string;
  pointId: string;
  createdDate: number;
  url: string;
}


export interface LoanApplicationsRs {
  onlineApplications: OnlineApplication[];
  creditConfirmType: ProductsEnum.CreditConfirmType;
  creditApplicationSdboItems: OnlineApplication[];
}
