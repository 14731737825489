import { OperationsHistory } from '../../../interfaces/operations-history.interface';


export namespace OperationsActions {


  export class OperationHistory {
    public static readonly type = '[Operation] OperationHistory';

    constructor(public readonly requestData: OperationsHistory.OperationHistoryRequestDto) {}
  }
}
