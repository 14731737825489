import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs';
import { DepositInterface } from '../../../interfaces/deposit.interface';
import { DepositService } from '../services/deposit.service';
import { DepositActions } from './deposit.actions';


interface DepositStateModel {
  depositInfo: DepositInterface.DepositInfoResponseDto | DepositInterface.DepositTopUpResponseDto;
  getSourcingInfo: DepositInterface.DepositSourcingInfoResponseDto;
  sourcingInfo: { status: string };
}


@State<DepositStateModel>({
  name: 'deposit'
})
@Injectable()
export class DepositState {

  constructor(private readonly depositService: DepositService) {
  }


  @Action(DepositActions.DepositCloseInfo)
  public getDepositCloseInfo(ctx: StateContext<DepositStateModel>, { requestData }: DepositActions.DepositCloseInfo) {
    return this.depositService.getDepositCloseInfo(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositInfo: response });
        })
      );
  }


  @Action(DepositActions.DepositTopUpInfo)
  public getDepositTopUpInfo(ctx: StateContext<DepositStateModel>, { requestData }: DepositActions.DepositTopUpInfo) {
    return this.depositService.getDepositTopUpInfo(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositInfo: response });
        })
      );
  }


  @Action(DepositActions.DepositWithdrawalInfo)
  public getDepositWithdrawalInfo(ctx: StateContext<DepositStateModel>, { requestData }: DepositActions.DepositWithdrawalInfo) {
    return this.depositService.getDepositWithdrawalInfo(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositInfo: response });
        })
      );
  }


  @Action(DepositActions.DepositSourcingInfo)
  public depositSourcingInfo(ctx: StateContext<DepositStateModel>, { requestData }: DepositActions.DepositSourcingInfo) {
    return this.depositService.depositSourcingInfo(requestData)
      .pipe(
        map(sourcingInfo => {
          ctx.patchState({ sourcingInfo });
        })
      );
  }


  @Action(DepositActions.GetDepositSourcingInfo)
  public getDepositSourcingInfo(ctx: StateContext<DepositStateModel>) {
    return this.depositService.getDepositSourcingInfo()
      .pipe(
        map(getSourcingInfo => {
          ctx.patchState({ getSourcingInfo })
        })
      );
  }
}
