export enum SettingsTabs {
  MAIN_SCREEN = 'MAIN_SCREEN',
  PROTECTED = 'SAFETY',
  LANGUAGE = 'LANGUAGE',
  TRANSFER_BY_PHONE_NUMBER = 'TRANSFER_BY_PHONE_NUMBER'
}


export enum LanguageKey {
  EN = 'en',
  RU = 'ru',
  BE = 'be'
}


export enum LanguageText {
  BE = 'Беларусская',
  RU = 'Русский',
  EN = 'English'
}


export enum LanguageIcons {
  BE = 'assets/icons/BG_BY.png',
  RU = 'assets/icons/BG_RU.png',
  EN = 'assets/icons/BG_US.png'
}


export enum BlockCustomizeTypes {
  EXCHANGE_RATES = 'EXCHANGE_RATES',
  TOTAL_AMOUNTS = 'TOTAL_AMOUNTS',
  CARDS = 'CARDS',
  DEPOSITS = 'DEPOSITS',
  ACCOUNTS = 'ACCOUNTS',
  CREDITS = 'CREDITS',
  LOAN_APPLICATIONS = 'LOAN_APPLICATIONS'
}


export enum MainScreenSettingList {
  EXCHANGE_RATES = 'EXCHANGE_RATES',
  TOTAL_AMOUNTS = 'TOTAL_AMOUNTS',
  CARDS = 'CARDS',
  DEPOSITS = 'DEPOSITS',
  ACCOUNTS = 'ACCOUNTS',
  CREDITS = 'CREDITS',
  OVERDRAFT = "OVERDRAFT",
  LOAN_APPLICATIONS = 'LOAN_APPLICATIONS',
}


export enum DeviceIcons {
  PC = 'icon-desctop',
  MOBILE = 'icon-mobile'
}


export enum DeviceList {
  IPHONE = 'IPHONE 14',
  DESKTOP = 'desktop'
}


export enum DevicePeriod {
  NOW = 'NOW',
  TODAY = 'TODAY'
}


export enum DeviceIPs {
  NOW_IP = '178.124.156.214',
  TODAY_IP = '192.168.22.52'
}


export enum SettingActivated {
  MAKE_MAIN = 'MAKE_MAIN',
  HIDE_BALANCE = 'HIDE_BALANCE',
  UNMAKE_MAIN = 'UNMAKE_MAIN',
  TRANSFER_BY_PHONE = 'TRANSFER_BY_PHONE',
  CVVCVC = 'CVVCVC',
  CARD_UNBLOCK = 'CARD_UNBLOCK',
  CARD_BLOCK = 'CARD_BLOCK'
}


export enum FileFormat {
  PNG = 'PNG',
  JPG = 'JPG',
  JPEG = 'JPEG',
  SVG = 'SVG'
}


export enum UserBlockedButtonText {
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED'
}


export enum UserBlockedPageText {
  BLOCKED_TEXT = 'ACCESS_BLOCKED',
  UNBLOCKED_TEXT = 'CLICK_FOR_UNBLOCK'
}


export enum LoanApplicationStatus {
  IN_UNDERWRITING = 'IN_UNDERWRITING',
  UNKNOWN_STATUS = 'UNKNOWN_STATUS',
  PENDING_CONDITION = 'PENDING_CONDITION',
  PENDING_SIGN_AGREEMENT = 'PENDING_SIGN_AGREEMENT',
  PENDING_SIGN_CONTRACT = 'PENDING_SIGN_CONTRACT',
  SEND = 'SEND',
  REVIEWED = 'REVIEWED',
  NEW = 'NEW',
  PENDING_SIGN_APPLICATION_FORM = 'PENDING_SIGN_APPLICATION_FORM',
  PENDING_SIGN_CONSENT = 'PENDING_SIGN_CONSENT',
}


export enum LoanApplicationCodeStatus {
  DECLINE = 'DECLINE',
  VOID = 'VOID',
  PENDING_CONDITION = 'PENDING_CONDITION',
  PENDING_SIGN_AGREEMENT = 'PENDING_SIGN_AGREEMENT',
  PENDING_SIGN_CONTRACT = 'PENDING_SIGN_CONTRACT',
  SEND = 'SEND',
  REVIEWED = 'REVIEWED',
  NEW = 'NEW',
  PENDING_SIGN_APPLICATION_FORM = 'PENDING_SIGN_APPLICATION_FORM',
  PENDING_SIGN_CONSENT = 'PENDING_SIGN_CONSENT',
  UNKNOWN_STATUS = 'UNKNOWN_STATUS',
  IN_UNDERWRITING = 'IN_UNDERWRITING'
}
