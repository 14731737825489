import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';


@Directive({
  selector: '[maxLengthInput]',
  standalone: true
})
export class MaxLengthDirective {
  constructor(private el: ElementRef, private ngControl: NgControl) {}


  @HostListener('input', ['$event']) onInput(event: Event) {
    const input = this.el.nativeElement as HTMLInputElement;
    if (input && input.value && input.value.length > 18 && this.ngControl && this.ngControl.control) {
      const newValue = input.value.substring(0, 18);
      this.ngControl.control.setValue(newValue);
    }
  }
}
