import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, map, Observable, of } from 'rxjs';
import { AppRoutes } from '../config/routes.config';
import { FinanceActions } from '../models/finance/state/finance.actions';
import { PaymentActions } from '../models/payment/state/payment.actions';
import { ProductsActions } from '../models/products/state/products.actions';
import { ServicesActions } from '../models/services/state/services.actions';
import { MainResolver } from '../resolvers/main.resolver';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly resolver: MainResolver,
    private readonly store: Store,
    private readonly router: Router) {
  }


  public canActivate(): Observable<boolean> {
    return this.resolver.resolve()
      .pipe(
        map((res: boolean) => res),
        catchError(() => {
          this.router.navigate([ AppRoutes.Auth ]);
          this.store.dispatch([
            new ProductsActions.ClearProducts,
            new ServicesActions.ClearServices,
            new FinanceActions.ClearTotalAmount,
            new PaymentActions.ClearPaymentSources
          ]);

          return of(false);
        })
      );
  }

}
