import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CardStatus } from '../../../../../enums/card.enum';
import { ValidationErrorMessageText } from '../../../../../enums/validation.enum';
import { CardKomplatStatus, SelectCustom, SelectCustomOptions } from '../../../../../interfaces/products.interface';
import { ProductService } from '../../../../../services/product.service';
import { DateFormat } from '../../../../../utils/date.utils';
import { NumberUtils } from '../../../../../utils/number.utils';
import { ProductsUtils } from '../../../../../utils/product.utils';


@Component({
  selector: 'app-select-with-icon',
  templateUrl: './select-with-icon.component.html',
  styleUrls: [ './select-with-icon.component.scss' ]
})
export class SelectWithIconComponent implements OnInit, OnChanges, AfterViewInit {

  public isOpen: boolean = false;

  public cardStatus = CardStatus;

  public errorMessage = ValidationErrorMessageText;

  public maxAllowedLength: number = 55;

  public optionImage = '';

  public selectProduct = '';

  public otherCard = '';

  @Input()
  public selectedOption: SelectCustomOptions | undefined = undefined;

  @Input()
  public forceEnableDropdown: boolean = false;

  @Input()
  public disabled!: boolean;

  @Input()
  public isEmitFirstItem!: boolean;

  @Input()
  public isShowButton!: boolean;

  @Input()
  public isShowCustomText!: boolean;

  @Input()
  public customListItemText!: string;

  @Input()
  public routerLink!: string;

  @Input()
  public width: string = '648px';

  @Input()
  public height: string = 'auto';

  @Input()
  public label!: string;

  @Input()
  public invalid: boolean = false;

  @Input()
  public options!: SelectCustom[];

  @Input()
  public isClearNeed!: boolean;

  @Input()
  public plusButtonName: string = 'OTHER_CARD';

  @Output()
  public emitButtonListIcon: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public changeSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public selected = new EventEmitter<SelectCustomOptions>();


  constructor(
    private readonly elementRef: ElementRef,
    private readonly router: Router,
    private readonly productService: ProductService,
    private translateService: TranslateService
  ) {}


  @HostListener('document:click', [ '$event' ])
  public onClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const arrowIconClicked = targetElement.classList.contains('arrow-icon');
    if (!clickedInside && !arrowIconClicked) {
      this.isOpen = false;
      // this.invalid = !this.isOpen && !this.selectedOption;
    }
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['isClearNeed'] && changes['isClearNeed'].currentValue) {
      this.resetSelectField();
    }
  }


  public ngOnInit(): void {
    if (this.options) this.initSelectedData();

    this.translateService.get('OPTION_IMAGE', {})
      .subscribe((translation: string) => {
        this.optionImage = translation;
      });

    this.translateService.get('SELECT_PRODUCT', {})
      .subscribe((translation: string) => {
        this.selectProduct = translation;
      });

    this.translateService.get(this.plusButtonName, {})
      .subscribe((translation: string) => {
        this.otherCard = translation;
      });
  }


  public ngAfterViewInit(): void {
    this.resetSelectField();
  }


  public checkExpireDate(expireDate: number): boolean {
    return DateFormat.checkExpireDate(expireDate);
  }


  public transformValue(amount: number, type: string): string {
    return NumberUtils.transformAmount(amount, type);
  }


  public getNumberMask(mask: string): string {
    return mask.slice(-4);
  }


  public toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    //this.invalid = !this.isOpen && !this.selectedOption;
  }


  public selectOption(option: SelectCustomOptions): void {
    this.selectedOption = option;
    this.changeSelected.emit(true);
    this.selected.emit(option);
    this.isOpen = false;
    this.invalid = false;
  }


  public navigateToPage(): void {
    if (this.routerLink) {
      this.router.navigate([ this.routerLink ]);
    } else {
      this.emitButtonListIcon.emit(true);
    }
    this.isOpen = false;
  }


  public cardStatusNotBlocked(card: string | undefined): boolean {
    if (card) {
      return ProductsUtils.cardStatus(card as string) !== CardKomplatStatus.TOUNBLOCK;
    } else {
      return true;
    }
  }


  private initSelectedData(): void {
    this.options.map((item: SelectCustom) => {
      const title: string = item.title ? item.title : '';
      return {
        ...item,
        items: item.items.sort((a, b): number => {
          if (a.isMainItem === b.isMainItem) {
            return 0
          } else if (a.isMainItem) {
            return -1
          } else {
            return 1;
          }
        }),
        title: this.translateService.instant(title)
      };
    });

    this.changeSelected.emit(false);

    let accumulatedLength = 0;
    const foundItem = this.options.find((option) => option.items.length > 0);
    const mainItem = (foundItem as SelectCustom)?.items?.find(el => el.isMainItem === true);

    for (const option of this.options) {
      if (option.items) accumulatedLength += option.items.length;
    }

    if (accumulatedLength === 1 && foundItem && !mainItem) {
      this.disabled = !this.forceEnableDropdown;
      this.selectedOption = foundItem.items[0];
      this.selected.emit(this.selectedOption);
    }

    if (accumulatedLength === 1 && mainItem) {
      this.disabled = !this.forceEnableDropdown;
      this.selectedOption = mainItem;
      this.selected.emit(this.selectedOption);
    }

    if (this.isEmitFirstItem && this.options[0].items.length >= 1) {
      this.selectedOption = mainItem ? mainItem : this.options[0].items[0];
      this.selected.emit(this.selectedOption);
    }
  }


  private resetSelectField(): void {
    if (this.isClearNeed) this.selectedOption = undefined;
  }
}
