import { environment } from '../../environments/environment';


const AUTH_URL = environment.api.auth;
const CORE_URL = environment.api.core;
const PAYMENT_URL = environment.api.payment;
const OPEN_API_URL = environment.api.openApi;

export const ApiConfig = {
  REGISTRATION_GET_PARAMS: `${ AUTH_URL }/registration/params?clientKind=WEB`,
  REGISTRATION_IDENTITY: `${ AUTH_URL }/registration/identify`,
  REGISTRATION_CONSENT: `${ AUTH_URL }/registration/consent`,
  REGISTRATION_CODE: `${ AUTH_URL }/registration/code`,
  REGISTRATION_CONFIRM: `${ AUTH_URL }/registration/confirm`,
  REGISTRATION_CHECK_LOGIN: `${ AUTH_URL }/registration/check-login`,

  AUTH_LOGIN: `${ AUTH_URL }/authentication/login`,
  AUTH_LOGOUT: `${ AUTH_URL }/authentication/logout`,

  USER_GET_CLIENT: `${ AUTH_URL }/user/get-client`,
  GET_CONFIRMATIONS: `${ AUTH_URL }/confirmation/get-confirmations`,
  GET_CONFIRMATION_KEY: `${ AUTH_URL }/confirmation/get-public-signature`,
  USER_UPDATE_EMAIL: `${ AUTH_URL }/user/update-client`,
  USER_UPDATE_ERIP_NUMBER: `${ AUTH_URL }/user/update-user-profile`,
  USER_BLOCKING_PLATFORM: `${ AUTH_URL }/user/blocking-platform`,

  CONSENT_ALL: `${ OPEN_API_URL }/consent/all`,
  CONSENT_INFO: `${ OPEN_API_URL }/consent/info`,
  CONSENT_DELETE: `${ OPEN_API_URL }/consent/delete`,
  CONSENT_ALL_AND_API_KEY: `${ OPEN_API_URL }/consent/all-and-api-key`,
  CONSENT_LONG_TERM_AGREEMENT: `${ OPEN_API_URL }/consent/long-term-agreement`,
  CONSENT_USER_ACCOUNTS: `${ OPEN_API_URL }/consent/user-accounts`,
  CONSENT_EXTERNAL_REPRESENTATION: `${ OPEN_API_URL }/consent/external-representation`,
  AUTHORIZE_CONSENT_OWN_APPLICATION: `${ OPEN_API_URL }/consent/authorise-consent-own-application`,

  TEMPORAL_CONFIRM: `${ AUTH_URL }/authentication/temporal/confirm`,
  TEMPORAL_CONSENT: `${ AUTH_URL }/authentication/temporal/consent`,
  TEMPORAL_CODE: `${ AUTH_URL }/authentication/temporal/code`,

  SMS_SEND_CODE: `${ AUTH_URL }/sms/send-code`,

  MSI_GET_REDIRECT_URL: `${ AUTH_URL }/msi/oauth/redirect-url`,
  MSI_LOGIN: `${ AUTH_URL }/msi/oauth/login`,
  MSI_CODE: `${ AUTH_URL }/msi/oauth/code`,
  MSI_CONFIRM: `${ AUTH_URL }/msi/oauth/confirm`,
  MSI_GET_FORM: `${ AUTH_URL }/msi/oauth/get-form`,
  MSI_POST_FORM: `${ AUTH_URL }/msi/oauth/post-form`,
  USER_DEVICES: `${ AUTH_URL }/user/devices`,
  USER_DEVICE_REMOVE: `${ AUTH_URL }/user/device/remove`,

  NSI_GET_DICTIONARY: `${ CORE_URL }/nsi/dictionary/get`,
  NSI_GET_INFORMATION: `${ CORE_URL }/nsi/information/get`,

  INFO_COUNTIRES: `${ CORE_URL }/info/countries`,
  INFO_BANK_DIVISION: `${ CORE_URL }/info/bank-divisions`,
  INFO_GET_BRANCHES: `${ CORE_URL }/info/get-branches`,
  INFO_GET_ATMS: `${ CORE_URL }/info/get-atms`,
  INFO_GET_ATM_DETAIL: `${ CORE_URL }/info/get-atms-detail`,

  FINANCE_TOTAL_AMOUNT: `${ CORE_URL }/finance/total-amounts`,
  PRODUCT_BALANCE_AMOUNT: `${ CORE_URL }/finance/product/balance/amount`,

  RECEIPT_GET_STATEMENT: `${ CORE_URL }/receipt/get-statement`,
  RECEIPT_GET_PAYMENT_CHECK: `${ CORE_URL }/receipt/get-payment-check`,
  RECEIPT_GET_DEPOSIT_PAYMENTS_REPORT: `${ CORE_URL }/receipt/get-deposit-payments-report`,

  CURRENT_PAYMENT_BY_CREDIT: `${ CORE_URL }/credit/get-amount-current-payment-by-credit`,

  PRODUCTS_GET_ALL: `${ CORE_URL }/product/get-products`,
  PRODUCTS_UPDATE_VISIBLE_BALANCE: `${ CORE_URL }/product/customize/visible-balance`,
  PRODUCT_CUSTOMIZE_SET_NAME: `${ CORE_URL }/product/customize/set-name`,
  PRODUCT_CUSTOMIZE_MAKE_MAIN: `${ CORE_URL }/product/customize/make-main`,
  PRODUCT_CUSTOMIZE_DISPLAY_ON_MAIN: `${ CORE_URL }/product/customize/display-on-main`,
  PRODUCT_CUSTOMIZE_DISPLAY_ON_MAIN_BULK: `${ CORE_URL }/product/customize/display-on-main/bulk`,
  PRODUCT_CUSTOMIZE_SET_SORT_IDX: `${ CORE_URL }/product/customize/set-sort-indexes`,

  CURRENT_ACCOUNT_PRODUCTS: `${ CORE_URL }/current/account/products`,

  PRODUCT_CARD_BALANCE: `${ CORE_URL }/card/get-balance`,
  CARD_ACTIVATE: `${ CORE_URL }/card/activate`,
  CARD_NUMBER_MASKED: `${ CORE_URL }/card/get-card-number`,
  CARD_PIN_GENERATION_COST: `${ CORE_URL }/card/pin-generation-cost`,
  CARD_PIN_GENERATE: `${ CORE_URL }/card/generate-pin`,
  SEND_CARD_CVV: `${ CORE_URL }/card/send-card-cvv`,
  CARD_SET_LIMITS: `${ CORE_URL }/card/set-limits`,
  CARD_GET_LIMITS: `${ CORE_URL }/card/get-limits`,
  CARD_BLOCK: `${ CORE_URL }/card/block-card`,
  CARD_UNP_CHECK: `${ CORE_URL }/card/unp-check`,
  CARD_SMS_SERVICE_STATUS: `${ CORE_URL }/sms/service/status`,
  CARD_SMS_SERVICE_MANAGE: `${ CORE_URL }/sms/service/manage`,
  CARD_SMS_SERVICE_COST: `${ CORE_URL }/sms/service/cost`,

  DEPOSIT_PRODUCTS: `${ CORE_URL }/deposit/products`,
  DEPOSIT_CLOSE_INFO: `${ CORE_URL }/deposit/close/info`,
  DEPOSIT_TOP_UP_INFO: `${ CORE_URL }/deposit/top-up/info`,
  DEPOSIT_WITHDRAWAL_INFO: `${ CORE_URL }/deposit/withdrawal/interest/info`,
  DEPOSIT_OPEN_INFO: `${ CORE_URL }/deposit/open/info`,
  DEPOSIT_WITHDRAWAL_ACCOUNT: `${ CORE_URL }/deposit/withdrawal/interest/actual/contract`,
  DEPOSIT_WITHDRAWAL_TARGET: `${ CORE_URL }/deposit/withdrawal/interest/change/target`,

  EXCHANGE_RATES_ALL: `${ CORE_URL }/exchange-rates/all`,
  EXCHANGE_RATES_BY_DIVISION: `${ CORE_URL }/exchange-rates/by-division/`,
  EXCHANGE_RATES_PAIR: `${ CORE_URL }/exchange-rates/pair`,
  BLOCK_CUSTOMIZE_BY_USER: `${ CORE_URL }/block/customize/by/user`,
  BLOCK_CUSTOMIZE_BULK: `${ CORE_URL }/block/customize/bulk-upsert`,
  VISA_ALIAS_RESOLVE: `${ CORE_URL }/visa/alias/resolve`,
  VISA_ALIAS_CREATE: `${ CORE_URL }/visa/alias/create`,
  VISA_ALIAS_UPDATE: `${ CORE_URL }/visa/alias/update`,
  VASA_ALIAS_DELETE: `${ CORE_URL }/visa/alias/delete`,

  OPERATION_HISTORY: `${ CORE_URL }/operation/history/get-operations-history`,
  CARD_OPEN: `${ CORE_URL }/card/open`,
  ORDER_CALL: `${ CORE_URL }/email/order-call`,
  CALCULATE_INCOME_FORECAST: `${ CORE_URL }/deposit/calculate-income-forecast/bulk`,
  DEPOSIT_SOURCING_INFO: `${ CORE_URL }/deposit/sourcing/info`,

  ENCRYPTION_GET_PUBLIC_KEY: `${ PAYMENT_URL }/encryption/get-public-key`,
  BEPAID_TRANSFER_TYPE: `${ PAYMENT_URL }/p2p/bepaid/transfer-type`,
  BEPAID_GET_RESTRICTION: `${ PAYMENT_URL }/p2p/bepaid/get-restriction`,
  BEPAID_DO_TRANSACTION: `${ PAYMENT_URL }/p2p/bepaid/do-transaction`,
  BEPAID_GET_RECEIPT: `${ PAYMENT_URL }/p2p/bepaid/get-receipt`,
  BEPAID_GET_TRANSACTION_STATUS: `${ PAYMENT_URL }/p2p/bepaid/get-transaction-status`,

  ERIP_PAY_GPL: `${ PAYMENT_URL }/payment/erip/pay-gpl`,
  ERIP_PAY_GPLS: `${ PAYMENT_URL }/payment/erip/pay-gplf`,
  ERIP_PAY_ROC: `${ PAYMENT_URL }/payment/erip/pay-roc`,
  BY_REQUISITES_GPL: `${ PAYMENT_URL }/payment/by-requisites/transfer/gpl`, ///payment/by-requisites/transfer/roc
  BY_REQUISITES_ROC: `${ PAYMENT_URL }/payment/by-requisites/transfer/roc`, ///payment/by-requisites/transfer/roc
  CONNECT_IPS: `${ PAYMENT_URL }/payment/instant/connect-ips`,
  BETWEEN_ACCOUNTS_LIMITS: `${ PAYMENT_URL }/payment/account/between-accounts/limits`,
  TRANSFER_CLIENT_LIMITS: `${ PAYMENT_URL }/payment/transfer/client/limits`,
  BETWEEN_ACCOUNTS_TRANSFER: `${ PAYMENT_URL }/payment/account/transfer/between-accounts`,
  AVAILABLE_PAYMENT_ACTIONS: `${ PAYMENT_URL }/payment/user/info/available-payment-actions`,
  TOP_UP_TRANSFER_GPL: `${ PAYMENT_URL }/payment/top-up/transfer/gpl`,
  TOP_UP_TRANSFER_ROC: `${ PAYMENT_URL }/payment/top-up/transfer/roc`,


  PAYMENT_CARD_NUMBER_CURRENCY: `${ PAYMENT_URL }/payment/by-card/get-card-currency`,
  PAYMENT_CARD_NUMBER_TRANSFER: `${ PAYMENT_URL }/payment/by-card/transfer`,
  PAYMENT_TRANSFER_RECIPIENT: `${ PAYMENT_URL }/payment/transfer/get-recipient-info`,
  PAYMENT_TRANSFER_CARD_OR_PHONE: `${ PAYMENT_URL }/payment/transfer/by-card-or-phone-number`,

  DEPOSIT_TRANSFER_CLOSING_TO_CARD: `${ PAYMENT_URL }/payment/deposit/transfer/close/to-card`,
  DEPOSIT_TRANSFER_CLOSING_TO_ACCOUNT: `${ PAYMENT_URL }/payment/deposit/transfer/close/to-account`,
  DEPOSIT_TRANSFER_TOP_UP_TO_CARD: `${ PAYMENT_URL }/payment/deposit/transfer/from-card`,
  DEPOSIT_TRANSFER_TOP_UP_TO_ACCOUNT: `${ PAYMENT_URL }/payment/deposit/transfer/from-current-account`,
  DEPOSIT_TRANSFER_WITHDRAWAL_TO_CARD: `${ PAYMENT_URL }/payment/deposit/transfer/withdrawal/interest/to-card`,
  DEPOSIT_TRANSFER_WITHDRAWAL_TO_ACCOUNT: `${ PAYMENT_URL }/payment/deposit/transfer/withdrawal/interest/to-current-account`,
  DEPOSIT_TRANSFER_OPENING: `${ PAYMENT_URL }/payment/deposit/transfer/open`,

  LOAN_PAYMENT_INFO: `${ PAYMENT_URL }/payment/credit/get-credit-info`,
  LOAN_PAYMENT: `${ PAYMENT_URL }/payment/credit/pay-credit`,
  OPEN_CARD_GPL: `${ PAYMENT_URL }/payment/product/gpl`,
  OPEN_CARD_ROC: `${ PAYMENT_URL }/payment/card/open`,
  OPEN_ACCOUNT_ROC: `${ PAYMENT_URL }/payment/account/open`,
  CLOSE_ACCOUNT_ROC: `${ PAYMENT_URL }/payment/account/close`,
  PAYMENT_TEMPLATE: `${ PAYMENT_URL }/payment/template/templates`,
  SAVE_TEMPLATE: `${ PAYMENT_URL }/payment/template/save`,
  UPDATE_TEMPLATE: `${ PAYMENT_URL }/payment/template/bulk-update`,
  DELETE_TEMPLATE: `${ PAYMENT_URL }/payment/template/delete`,
  GET_OPERATION_HISTORY: `${ PAYMENT_URL }/payment/erip/operation/get-operation-history`,
  AUTO_PAYMENT_UPDATE: `${ PAYMENT_URL }/payment/auto-payment/update`,
  AUTO_PAYMENT_CREATE: `${ PAYMENT_URL }/payment/auto-payment/save`,
  AUTO_PAYMENT_DELETE: `${ PAYMENT_URL }/payment/auto-payment/delete`,

  GET_L0AN_PRODUCTS: `${ CORE_URL }/online/credit/get-application-products`,
  GET_L0AN_APPLICATIONS: `${ CORE_URL }/online/credit/get-client-applications`,
  GET_L0AN_STATUS: `${ CORE_URL }/online/credit/get-application-status`,
  GET_APPLICATION_CODE_STATUS: `${CORE_URL}/online/credit/get-application-code-status`,
  GET_PAYMENT_SCHEDULE: `${CORE_URL}/online/credit/get-payment-schedule`,
  FIX_OFFER: `${CORE_URL}/online/credit/fix-offer`,
  GET_OFFERS: `${CORE_URL}/online/credit/get-offers`,
  GET_L0AN_FORM: `${ CORE_URL }/online/credit/get-form`,
  GET_L0AN_VIEW_DOCUMENT: `${ CORE_URL }/online/credit/view-document`,
  GET_L0AN_FILL_FORM: `${ CORE_URL }/online/credit/fill-form`,
  GET_L0AN_VOID: `${ CORE_URL }/online/credit/void-application`,
  CHECK_SIGN_DOCUMENT: `${CORE_URL}/online/credit/check-sign-document`,
  SIGN_FINAL_DOCUMENTS: `${CORE_URL}/online/credit/sign-final-documents`,
  GET_APPLICATION_BY_NUMBER: `${CORE_URL}/partner/credit/application/by`,
  REVIEW_APPLICATION: `${CORE_URL}/partner/credit/application/review`,
  VOID_APPLICATION: `${CORE_URL}/partner/credit/application/void`,

  GET_STORIES: `${ AUTH_URL }/story/stories`,
  STORY_VIEWED: `${ AUTH_URL }/story/viewed`,
};
