import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DepositInterface } from '../../../interfaces/deposit.interface';
import { DepositResource } from '../resources/deposit.resource';



@Injectable({
  providedIn: 'root'
})
export class DepositService {


  constructor(
    private readonly resource: DepositResource) {
  }


  public getDepositCloseInfo(requestData: DepositInterface.DepositInfoRequestDto): Observable<DepositInterface.DepositInfoResponseDto> {
    return this.resource.getDepositCloseInfo(requestData);
  }


  public getDepositTopUpInfo(requestData: DepositInterface.DepositInfoRequestDto): Observable<DepositInterface.DepositTopUpResponseDto> {
    return this.resource.getDepositTopUpInfo(requestData);
  }


  public getDepositWithdrawalInfo(requestData: DepositInterface.DepositInfoRequestDto): Observable<DepositInterface.DepositTopUpResponseDto> {
    return this.resource.getDepositWithdrawalInfo(requestData);
  }


  public depositSourcingInfo(requestData: DepositInterface.DepositSourcingInfoRequestDto): Observable<{ status: string }> {
    return this.resource.depositSourcingInfo(requestData);
  }


  public getDepositSourcingInfo(): Observable<DepositInterface.DepositSourcingInfoResponseDto> {
    return this.resource.getDepositSourcingInfo();
  }
}
