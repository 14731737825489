import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlValue } from '../../../../../interfaces/control.interface';
import { handleBackspace } from '../../../../../utils/uniq.units';
import { InputComponent } from '../../impl/input.component';


interface IInputType {
  inputType: 'button' | 'checkbox' | 'color' | 'date' | 'datetime-local' | 'email' | 'file' | 'hidden' |
    'image' | 'month' | 'number' | 'password' | 'radio' | 'range' | 'reset' | 'search' | 'submit' | 'textarea' |
    'tel' | 'text' | 'time' | 'url' | 'week'
}

@Component({
  selector: 'app-text-input',
  templateUrl: './input-text.component.html',
  styleUrls: [ './input-text.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputTextComponent
    }
  ]
})
export class InputTextComponent extends InputComponent implements OnInit {

  public isFocus!: boolean;

  @Input()
  public isPhoneMask!: boolean;

  @Input()
  public isAmountInput!: boolean;

  @Input()
  public override id!: string | number;

  @Input()
  public override disabled!: boolean;

  @Input()
  public withClear!: boolean;

  @Input()
  public type!: IInputType | any;

  @Input()
  public label!: string | null;

  @Input()
  public display: 'inline' | 'block' = 'block';

  @Input()
  public width!: string;

  @Input()
  public mask?: string;

  @Input()
  public placeholder: string = '';

  @Input()
  public invalid!: boolean;

  @Input()
  public dropMode!: boolean;

  @Input()
  public tooltipInfo?: string;

  @Input()
  public hideDelay = 0;

  @Input()
  public invisible: boolean = false;

  @Input()
  public selectOpened = false;

  @Input()
  public dropDirection: 'left' | 'right' | 'up' | 'down' = 'right';

  @Input()
  public readOnly!: boolean;

  @Input()
  public maxLength!: number | any;

  @Input()
  public minLength!: number | any;

  @Input()
  public isSelect: boolean = false;

  @Input()
  public formControlCustom: FormControl<string> | undefined | AbstractControl<string>;

  @Input()
  public skipClickEvent = false;

  @Input()
  public inlineControl: boolean = false;

  public formControl!: FormControl<string>;

  @Output()
  public focused: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public keyUp: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output()
  public clear = new EventEmitter();

  @ViewChild('inputElement') inputElement!: ElementRef;

  public showClear!: boolean;



  constructor(private eRef: ElementRef) {
    super();
  }


  override get value(): FormControlValue {
    return this._value;
  }


  @Input()
  override set value(val: FormControlValue) {
    this._value = val;
    this.showClear = !!val;

    this.onChange(this._value);
  }


  public ngOnInit(): void {
    if (!this.formControlCustom) {
      this.formControl = new FormControl<string>('', {nonNullable: true});
    } else {
      this.formControl = this.formControlCustom as FormControl;
      this.value = this.formControl.value;
    }
  }


  @HostListener('document:click', [ '$event' ])
  public onClick(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isFocus = false;
    }
  }


  public onInputFocus(): void {
    this.isFocus = true;
    this.focused.emit(true);
  }


  public onBlurEvent(): void {
    const elementValue = this.inputElement.nativeElement.value
    if (this.value !== elementValue) {
      this.value = this.inputElement.nativeElement.value
      this.formControl.setValue(this.inputElement.nativeElement.value)
    }
    this.focused.emit(false);
  }


  public onClear(): void {
    if (this.isPhoneMask) {
      this.value = this.value ? this.value.substring(0, 1) : null;
    } else {
      this.value = null;
    }
    this.clear.emit();
  }


  public onControlClick(event: Event): void {
    if (this.skipClickEvent) return;

    const inputElement = document.getElementById(this.id.toString()) as HTMLInputElement;

    if (inputElement) inputElement.focus();

    this.isFocus = true;
    if (!this.isSelect) event.stopPropagation();
  }


  public onKeyUp(event: KeyboardEvent): void {
    if (this.mask === 'M0/00') {
      const inputElement: HTMLInputElement = event.target as HTMLInputElement;
      const value: string = inputElement.value;
      const cursorPosition: number = inputElement.selectionStart as number;

      if (event.key === 'Backspace' && cursorPosition > 0) {
        const newValue: string = handleBackspace(value, cursorPosition);

        if (newValue !== value) {
          this.updateInputValue(inputElement, newValue, cursorPosition - 1);
          event.preventDefault();
        }
      }
    }

    this.keyUp.emit(event);
  }


  private updateInputValue(inputElement: HTMLInputElement, value: string, cursorPosition: number): void {
    setTimeout((): void => {
      inputElement.value = value;
      inputElement.setSelectionRange(cursorPosition, cursorPosition);
    });
  }
}
