import { Component, Input } from '@angular/core';
import { ButtonTypes } from '../../../../../config/shared.config';


@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: [ './button.component.scss' ]
})
export class ButtonComponent {

  @Input()
  public type = ButtonTypes;

  @Input()
  public disabled!: boolean;

  @Input()
  public icon!: string;

  @Input()
  public columnDirection = false;

}
