import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map, tap } from 'rxjs';
import { Credentials } from '../../../interfaces/auth.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { deviceInfo, platformInfo, systemInfo } from '../../../utils/system.utils';
import { AuthService } from '../services/auth.service';
import { AuthActions } from './auth.actions';


interface AuthStateModel {
  registrationToken: string;
  codeHeader: string;
  credentials: Credentials;
  sessionToken: string;
  temporalUser: string;
  newLogin: string;
  newPwd: string;
  checkLogin: HttpError;
}


@State<AuthStateModel>({
  name: 'auth'
})
@Injectable()
export class AuthState {

  @Selector()
  public static selectCodeHeader(state: AuthStateModel): string {
    return state.codeHeader;
  }


  @Selector()
  public static selectSessionToken(state: AuthStateModel): string {
    return state.sessionToken;
  }


  @Selector()
  public static selectTemporalUser(state: AuthStateModel): string {
    return state.temporalUser;
  }


  @Selector()
  public static selectCredentials(state: AuthStateModel): Credentials {
    return state.credentials;
  }


  @Selector()
  public static selectNewLogin(state: AuthStateModel) {
    return { login: state.newLogin, password: state.newPwd };
  }


  @Selector()
  public static selectRegistrationToken(state: AuthStateModel): string {
    return state.registrationToken;
  }


  constructor(
    private readonly authService: AuthService) {
  }


  @Action(AuthActions.Identify)
  public identify(ctx: StateContext<AuthStateModel>, { payload }: AuthActions.Identify) {
    return this.authService.identify({
      ...systemInfo(),
      ...payload
    }).pipe(
      tap(({ registrationToken }) => ctx.patchState({ registrationToken }))
    );
  }


  @Action(AuthActions.Consent)
  public consent(ctx: StateContext<AuthStateModel>, { confirmedConsents }: AuthActions.Consent) {
    const { registrationToken } = ctx.getState();

    return this.authService.consent({
      registrationToken,
      confirmedConsents
    });
  }


  @Action(AuthActions.Code)
  public code(ctx: StateContext<AuthStateModel>) {
    const { registrationToken } = ctx.getState();

    return this.authService.code({ registrationToken }).pipe(
      tap(({ header }) => ctx.patchState({ codeHeader: header }))
    );
  }


  @Action(AuthActions.SetCredentials)
  public setCredentials(ctx: StateContext<AuthStateModel>, { credentials }: AuthActions.SetCredentials) {
    ctx.patchState({ credentials });
  }


  @Action(AuthActions.Confirm)
  public confirm(ctx: StateContext<AuthStateModel>, { confirmationCode }: AuthActions.Confirm) {
    const { registrationToken, credentials } = ctx.getState();

    return this.authService.confirm({
      registrationToken,
      confirmationCode,
      deviceModel: deviceInfo(),
      ...systemInfo(),
      ...credentials
    }).pipe(
      tap(() => ctx.patchState({
        credentials: undefined,
        registrationToken: undefined,
        codeHeader: undefined
      }))
    );
  }


  @Action(AuthActions.NoTemporal)
  public noTemporal(ctx: StateContext<AuthStateModel>, _: AuthActions.NoTemporal) {
    ctx.patchState({ temporalUser: undefined });
  }


  @Action(AuthActions.Login)
  public login(ctx: StateContext<AuthStateModel>, { credentials }: AuthActions.Login) {
    return this.authService.login({
      ...platformInfo(),
      ...systemInfo(),
      ...credentials
    }).pipe(
      tap(({ sessionToken, temporalUser }) => {
        ctx.patchState({ sessionToken, temporalUser, credentials: credentials });
      })
    );
  }


  @Action(AuthActions.Logout)
  public logout(ctx: StateContext<AuthStateModel>) {
    return this.authService.logout().pipe(
      tap(() => ctx.patchState({
        sessionToken: undefined,
        credentials: undefined
      }))
    );
  }


  @Action(AuthActions.TemporalConsent)
  public temporalConsent(ctx: StateContext<AuthStateModel>, { consentRequest }: AuthActions.TemporalConsent) {
    return this.authService.temporalConsent({
      ...consentRequest
    }).pipe(
      tap(({ token }) => ctx.patchState({ sessionToken: token }))
    );
  }


  @Action(AuthActions.TemporalCode)
  public temporalCode(ctx: StateContext<AuthStateModel>, { temporalCode }: AuthActions.TemporalCode) {
    return this.authService.temporalCode(temporalCode).pipe(
      tap(({ header }) => ctx.patchState({ codeHeader: header }))
    );
  }


  @Action(AuthActions.TemporalConfirm)
  public temporalConfirm(ctx: StateContext<AuthStateModel>, { confirmRequest }: AuthActions.TemporalConfirm) {
    return this.authService.temporalConfirm(confirmRequest);
  }


  @Action(AuthActions.SetNewLogin)
  public setNewLogin(ctx: StateContext<AuthStateModel>, { newLogin, newPwd }: AuthActions.SetNewLogin) {
    return ctx.patchState({ newLogin, newPwd });
  }


  @Action(AuthActions.SetAuth)
  public setAuth(ctx: StateContext<AuthStateModel>, { sessionToken }: AuthActions.SetAuth): void {
    ctx.patchState({ sessionToken });
  }


  @Action(AuthActions.ClearSession)
  public clearSession(ctx: StateContext<AuthStateModel>): void {
    ctx.patchState({ sessionToken: undefined, credentials: undefined });
  }


  @Action(AuthActions.CheckLogin)
  public registrationCheckLogin(ctx: StateContext<AuthStateModel>, { requestData }: AuthActions.CheckLogin) {
    return this.authService.registrationCheckLogin(requestData)
      .pipe(
        map((checkLogin: HttpError): void => {
          ctx.patchState({
            checkLogin
          })
        })
      )
  }
}
