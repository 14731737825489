// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  logs: {
    ngxs: true
  },
  appID: '1.22',
  clientKind: 'WEB',
  api: {
    auth: '/auth/services/v3',
    core: '/core/services/v3',
    payment: '/payment/services/v3',
    openApi: '/open-api/services/v3'
  },
  apiUrl: 'http://localhost:4224',
  apiProdUrl: 'https://new.iparitet.by',
  apiTestUrl: 'https://test2.iparitet.by',
  mock: {
    products: true,
    payment: true,
    confirmations: true
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
