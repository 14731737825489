import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { finalize, interval, Subject, Subscription, takeUntil } from 'rxjs';
import { AppRoutes } from '../config/routes.config';
import { SnackbarType } from '../enums/snackbar.enum';
import { AuthActions } from '../models/auth/state/auth.actions';
import { FinanceActions } from '../models/finance/state/finance.actions';
import { PaymentActions } from '../models/payment/state/payment.actions';
import { ProductsActions } from '../models/products/state/products.actions';
import { ServicesActions } from '../models/services/state/services.actions';
import { UserActions } from '../models/user/state/user.actions';
import { CommonService } from './common.service';


@Injectable({
  providedIn: 'root'
})
export class SessionService {

  private fullSessionInterval = 30 * 60 * 1000;

  private intervalSubscription$: Subscription | null = null;

  private destroy$: Subject<void> = new Subject();



  constructor(
    private readonly translateService: TranslateService,
    private readonly commonService: CommonService,
    private readonly store: Store,
    private readonly router: Router) {
  }


  public createSessionTimer(): void {
    this.unsubscribe();
    this.intervalSubscription$ = interval(this.fullSessionInterval).pipe(
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.logoutUser();
      this.destroySubscription();
    });
  }


  private destroySubscription(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  private unsubscribe(): void {
    if (this.intervalSubscription$) {
      this.intervalSubscription$.unsubscribe();
      this.intervalSubscription$ = null;
    }
  }


  private logoutUser(): void {
    const errorMessage = this.translateService.instant('AUTH.INACTIVE_USER');

    this.store.dispatch(new AuthActions.Logout())
      .pipe(finalize(() => this.commonService.showSnackbar(errorMessage, SnackbarType.Danger)))
      .subscribe(
        (): void => {
          this.router.navigate([ AppRoutes.Auth ]);
          this.store.dispatch([
            new ProductsActions.ClearProducts,
            new ServicesActions.ClearServices,
            new FinanceActions.ClearTotalAmount,
            new PaymentActions.ClearPaymentSources,
            new UserActions.ClearStories
          ]);
        }
      );
  }
}
