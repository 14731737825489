<div class="app-control"
     [class.disabled]="disabled"
     [style.width]="width"
     [class.inline-control]="inlineControl"
>
    <label
            *ngIf="!invisible && label"
            class="app-control-label" [class.error]="invalid" [for]="id">
        {{ label }}
    </label>

    <div
            class="app-control-field"
            [class.error]="invalid"
            [class.select-opened]="selectOpened"
            (click)="onClickControl($event)"
    >
        <div class="app-control-field__prefix">
            <ng-content select="[prefix]"></ng-content>
        </div>

        <textarea class="app-control-field__textarea"
                  [class.invisible]="invisible"
                  #textAreaElement
                  [id]="id"
                  [placeholder]="placeholder | translate"
                  [formControl]="formControl"
                  [(ngModel)]="value"
                  [readOnly]="readOnly"
                  (keyup)="onKeyUp($event)"
                  (focus)="onInputFocus()"
                  (blur)="onBlur()"
        ></textarea>

        <div class="app-control-field__suffix" *ngIf="!withClear && !dropMode">
            <ng-content select="[suffix]"></ng-content>
        </div>

        <div class="app-control-field__suffix" *ngIf="withClear && showClear && !dropMode && isFocus">
            <i class="icon icon-round-close" (click)="onClear()"></i>
        </div>

        <div class="app-control-field__suffix app-control-field__suffix_drop" *ngIf="dropMode">
            <i class="icon icon-arrow-drop-{{ dropDirection }}"></i>
        </div>
    </div>
</div>
