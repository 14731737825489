import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';
import moment from 'moment/moment';


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  public override format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return moment(date).format('DD.MM.YYYY'); // Формат для input
    } else if (displayFormat === 'monthYearLabel') {
      return moment(date).format('MMMM YYYY'); // Формат для отображения месяца и года
    } else {
      return super.format(date, displayFormat); // Для остальных форматов
    }
  }

  public override parse(value: any): Date | null {
    if (typeof value === 'string') {
      const parsedDate = moment(value, 'DD.MM.YYYY', true);
      return parsedDate.isValid() ? parsedDate.toDate() : null;
    }
    return value;
  }
}
