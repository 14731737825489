import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output, SimpleChanges,
  ViewChild
} from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MatCalendar,
  MatRangeDateSelectionModel
} from '@angular/material/datepicker';
import { TranslateService } from '@ngx-translate/core';
import { msiDateFormat, selectionRange } from '../../../../../config/date.config';
import { DateEnum } from '../../../../../enums/date.enum';
import { ValidationErrorMessageText } from '../../../../../enums/validation.enum';
import { OperationsHistory } from '../../../../../interfaces/operations-history.interface';
import { SharedInterface } from '../../../../../interfaces/shared.interface';
import { DateFormat } from '../../../../../utils/date.utils';


@Component({
  selector: 'app-date-range',
  templateUrl: './date-range.component.html',
  styleUrls: [ './date-range.component.scss' ]
})
export class DateRangeComponent implements OnInit, OnChanges {

  @ViewChild('calendar') calendar: MatCalendar<Date> | undefined;

  public selectedDateRange: DateRange<Date> | undefined;

  public dateFormat: string = msiDateFormat;

  public datePresetLabel = DateEnum.DateRange;

  public errorMessage = ValidationErrorMessageText;

  public maxDate: Date;

  public range!: any[];

  public isOpen: boolean = false;

  public isCalendarVisible: boolean = false;

  @Input()
  public invalid!: boolean;

  @Input()
  public isOnlyCalendar: boolean = false; // флаг для использования календаря без включения валидации для инпута

  @Input()
  public label!: string;

  @Input()
  public initRange!: OperationsHistory.InitDateRange;

  @Output()
  public emitSelectedDateRange: EventEmitter<{ from: Date, till: Date }> = new EventEmitter<{ from: Date, till: Date }>();

  @Input()
  public placeholder: string = 'SELECT_DATE';

  @Input()
  public set clearSelectDateRange(value: boolean) {
    if (value) this.selectedDateRange = undefined;
  }

  private dirty = false;


  constructor(private readonly selectionModel: MatRangeDateSelectionModel<Date>,
              private readonly selectionStrategy: DefaultMatCalendarRangeStrategy<Date>,
              private readonly translateService: TranslateService,
              private readonly dateAdapter: DateAdapter<Date>,
              private readonly elementRef: ElementRef) {
    this.maxDate = this.dateAdapter.today();
  }


  @HostListener('document:click', [ '$event' ])
  public onClick(event: MouseEvent): void {
    const targetElement = event.target as HTMLElement;
    const clickedInside = this.elementRef.nativeElement.contains(targetElement);
    const arrowIconClicked = targetElement.classList.contains('arrow-icon');
    if (!clickedInside && !arrowIconClicked) {
      this.isOpen = false;
      this.isCalendarVisible = false;
      this.invalid = this.dirty && !this.selectedDateRange?.start && !this.selectedDateRange?.end && !this.isOnlyCalendar;
    }
  }


  public ngOnInit(): void {
    this.initDataRange();
    this.initSelectedRange();
  }


  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['initRange'] && changes['initRange'].currentValue) {
      this.initRange = changes['initRange'].currentValue;
      this.initSelectedRange();
    }
  }


  public toggleDropdown(): void {
    this.isOpen = !this.isOpen;
    this.dirty = true;
    this.invalid = !this.isOpen && !this.selectedDateRange?.start && !this.selectedDateRange?.end && !this.isOnlyCalendar;
  }


  public clearTerms(): void {
    this.isCalendarVisible = false;

    this.initDataRange();
    this.initSelectedRange();

    this.emitSelectedDateRange.emit({
      from: this.selectedDateRange?.start!,
      till: this.selectedDateRange?.end!
    });
  } // обновление интервалов дат до первоначально установленных


  public rangeChanged(selectedDate: any): void {

    const selection = this.selectionModel.selection,
      newSelection = this.selectionStrategy.selectionFinished(
        selectedDate,
        selection
      );

    this.selectionModel.updateSelection(newSelection, this);
    this.selectedDateRange = new DateRange<Date>(
      newSelection.start,
      newSelection.end
    );

    this.emitSelectedDateRange.emit(
      { from: newSelection.start!, till: newSelection.end! }
    );

    this.isCalendarVisible = !newSelection.end;
    this.invalid = false;
  }


  public selectPreset(presetDateRange: any, presetLabel: string): void {
    const otherPeriod = this.translateService.instant(this.datePresetLabel.OTHER_PERIOD);

    if (presetLabel !== otherPeriod) {
      this.selectedDateRange = new DateRange<Date>(
        presetDateRange.start,
        presetDateRange.end
      );
      this.emitSelectedDateRange.emit({
        from: presetDateRange.start,
        till: presetDateRange.end
      });
    } else {
      this.openCalendar();
    }
    this.isOpen = false;
    this.invalid = false;
  }

  public openCalendar(): void {
    this.isCalendarVisible = !this.isCalendarVisible;
  }


  public compareDateRangeObj(selectedDateRange: DateRange<Date> | undefined, presetDateRange: any): boolean {
    if (selectedDateRange && presetDateRange) {
      return selectedDateRange.start === presetDateRange.start
        && selectedDateRange.end === presetDateRange.end;
    } else {
      return false;
    }
  }


  public changeDateRange(data: SharedInterface.DateRange): void {
    const start: Date = DateFormat.transformStringToDate(data.start as string);
    const end: Date = DateFormat.transformStringToDate(data.end as string);

    this.selectedDateRange = new DateRange<Date>(start, end);
  } // изменение интервалов дат, вызов метода осуществляется извне, от родителя к предку по средствам "ViewChild"


  private initDataRange(): void {
    this.range = selectionRange.map((item: any) => {
      return {
        ...item,
        label: this.translateService.instant(item.label)
      }
    });
  }


  private initSelectedRange(): void {
    if (this.initRange) {
      this.selectedDateRange = new DateRange<Date>(
        (this.initRange as any).start,
        (this.initRange as any).end
      );
    }
  } // установка переданной даты initRange в календарь и инпут для отображения интервалов


  protected readonly ValidationErrorMessageText = ValidationErrorMessageText;
}
