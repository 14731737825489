import { FinanceInterface } from '../../../interfaces/finance.interface';


export namespace FinanceActions {

  export class GetTotalAmounts {
    public static readonly type = '[Finance] GetTotalAmounts';
  }


  export class SetTotalAmounts {
    public static readonly type = '[Finance] SetTotalAmounts';


    constructor(public readonly payload: FinanceInterface.Finance) {}
  }


  export class ClearTotalAmount {
    public static readonly type = '[Finance] ClearTotalAmount';
  }


  export class GetProductBalanceAmount {
    public static readonly type = '[Finance] GetProductBalanceAmount';


    constructor(public readonly requestData: FinanceInterface.BalanceAmountRequestDto) {}
  }
}
