import { Lang } from '../../../enums/lang.enum';
import { Theme } from '../../../enums/theme.enum';


export namespace SettingsActions {

  export class ChangeTheme {
    static readonly type = '[Settings] ChangeTheme';


    constructor(
      public readonly theme: Theme) {
    }
  }


  export class ChangeLang {
    static readonly type = '[Settings] ChangeLang';


    constructor(
      public readonly lang: Lang) {
    }
  }
}
