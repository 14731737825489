import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { OperationsHistory } from '../../../interfaces/operations-history.interface';


@Injectable({
  providedIn: 'root'
})
export class OperationsResource {


  constructor(private readonly http: HttpClient) {}


  public getOperationHistory(requestData: OperationsHistory.OperationHistoryRequestDto): Observable<OperationsHistory.OperationHistoryResponseDto> {
    return this.http.post<OperationsHistory.OperationHistoryResponseDto>(ApiConfig.OPERATION_HISTORY, requestData);
  }
}
