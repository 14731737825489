import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { catchError, concatMap, finalize, map, Observable, switchMap, take, tap, throwError } from 'rxjs';
import { CatalogInterface } from '../interfaces/catalog.interface';
import { DepositInterface } from '../interfaces/deposit.interface';
import { HttpError } from '../interfaces/http.interface';
import { PaymentInterface } from '../interfaces/payment.interface';
import {
  DisplayOnMainPayload,
  GetCardBalanceDtoRq,
  GetCardBalanceDtoRs,
  MaskedCardNumberResponse,
  ProductCustomizeDto,
  ProductRequestDto,
  SetSortIndexRequestDto
} from '../interfaces/product-liability.interface';
import {
  Account,
  CardActionConfirmDto,
  CardActionDto,
  CardBlockDto,
  CardLimitDto,
  CardPinGenerationCostRs,
  CardSmsStatusRs,
  ProductRouteProps,
  ResolveVisaAliasResponseDto,
  SelectCustomOptions
} from '../interfaces/products.interface';
import { BlockCustomizeUnits } from '../interfaces/settings.interface';
import { DepositActions } from '../models/deposit/state/deposit.actions';
import { PaymentActions } from '../models/payment/state/payment.actions';
import { PaymentState } from '../models/payment/state/payment.state';
import { PreloaderActions } from '../models/preloader/state/preloader.actions';
import { ProductsActions } from '../models/products/state/products.actions';
import { ProductsState } from '../models/products/state/products.state';
import { ServicesActions } from '../models/services/state/services.actions';
import { ServicesState } from '../models/services/state/services.state';
import { UserActions } from '../models/user/state/user.actions';
import { UserState } from '../models/user/state/user.state';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  public currencyList!: string[];

  public isLoading: boolean = false;

  public amountRangeList!: CatalogInterface.AmountRange[];

  public catalogDepositItem!: CatalogInterface.CatalogItems;

  public catalogLoansItem!: CatalogInterface.CatalogLoans;

  public depositTermList!: CatalogInterface.DepositTermList[];

  public catalogList!: CatalogInterface.CatalogItems[];

  public resolveVisaAlias!: ResolveVisaAliasResponseDto;

  public depositInfoResp!: DepositInterface.DepositInfoResponseDto | DepositInterface.DepositTopUpResponseDto;

  @Select(ProductsState.getCardDetail)
  public productCardDetail$!: Observable<ProductRouteProps>;


  constructor(private readonly store: Store) {
  }


  public get loading(): boolean {
    return this.isLoading;
  }


  public set loading(loading: boolean) {
    this.isLoading = loading;
  }


  public get resolveVisa(): ResolveVisaAliasResponseDto {
    return this.resolveVisaAlias;
  }


  public set resolveVisa(resolveData: ResolveVisaAliasResponseDto) {
    this.resolveVisaAlias = resolveData;
  }


  public get currencies(): string[] {
    return this.currencyList;
  }


  public set currencies(currencyList: string[]) {
    this.currencyList = currencyList;
  }


  public get amountRange(): CatalogInterface.AmountRange[] {
    return this.amountRangeList;
  }


  public set amountRange(amountRange: CatalogInterface.AmountRange[]) {
    this.amountRangeList = amountRange;
  }


  public get productTerm(): CatalogInterface.DepositTermList[] {
    return this.depositTermList;
  }


  public set productTerm(productTerm: CatalogInterface.DepositTermList[]) {
    this.depositTermList = productTerm;
  }


  public get catalogs(): CatalogInterface.CatalogItems[] {
    return this.catalogList;
  }


  public set catalogs(catalog: CatalogInterface.CatalogItems[]) {
    this.catalogList = catalog;
  }


  public get catalog(): CatalogInterface.CatalogItems {
    return this.catalogDepositItem;
  }


  public set catalog(catalog: CatalogInterface.CatalogItems) {
    this.catalogDepositItem = catalog;
  }


  public get loan(): CatalogInterface.CatalogLoans {
    return this.catalogLoansItem;
  }


  public set loan(loanItem: CatalogInterface.CatalogLoans) {
    this.catalogLoansItem = loanItem;
  }


  public get depositInfo(): DepositInterface.DepositInfoResponseDto | DepositInterface.DepositTopUpResponseDto {
    return this.depositInfoResp;
  }


  public set depositInfo(deposit: DepositInterface.DepositInfoResponseDto | DepositInterface.DepositTopUpResponseDto) {
    this.depositInfoResp = deposit;
  }


  public preloaderStart() {
    return this.store.dispatch(new PreloaderActions.Start());
  }


  public preloaderStop() {
    return this.store.dispatch(new PreloaderActions.Stop());
  }


  public destroyCardActionRs() {
    return this.store.dispatch(new ProductsActions.DestroyCardActionRs());
  }


  public updateCardBalance(requestData: ProductRequestDto): Observable<Account[]> {
    return this.store.dispatch(new ProductsActions.UpdateVisibleBalance(requestData));
  }


  public makeMainProduct(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    return this.store.dispatch(new ProductsActions.MakeMainProduct(requestData));
  }


  public setSortIndexes(requestData: SetSortIndexRequestDto): Observable<string> {
    return this.store.dispatch(new ProductsActions.SetSortIndexes(requestData));
  }


  public makeDisplayOnMainProduct(requestData: ProductRequestDto): Observable<ProductCustomizeDto> {
    return this.store.dispatch(new ProductsActions.MakeDisplayOnMain(requestData));
  }


  public customizeDisplayOnMain(requestData: DisplayOnMainPayload) {
    return this.store.dispatch(new ProductsActions.CustomizeDisplayOnMain(requestData));
  }


  public getBalance(selectSource: SelectCustomOptions) {
    if (selectSource.isCard) {
      return this.store.dispatch(new ProductsActions.GetCardBalance({ cardHash: String(selectSource.id) }));
    } else {
      return this.store.dispatch(new ProductsActions.GetAccountBalance({ contractNumberHash: String(selectSource.contractNumberHash) }));
    }
  }


  public getCurrentBalance(selectSource: SelectCustomOptions): number {
    if (selectSource.isCard) {
      const account = this.store.selectSnapshot(ProductsState.selectCard)(String(selectSource.contractNumber));
      const card = account.cards.find(card => card.cardHash === selectSource.id);
      return card?.balance as number;
    } else {
      const account = this.store.selectSnapshot(ProductsState.selectAccount)(String(selectSource.contractNumber));
      return account.balanceAmount;
    }
  }


  public getCardBalance(requestData: GetCardBalanceDtoRq): Observable<GetCardBalanceDtoRs> {
    return this.store.dispatch(new ProductsActions.GetCardBalance(requestData));
  }


  public dispatchUpdateData(customizeList: BlockCustomizeUnits[]): Observable<void> {
    return this.store.dispatch(new ServicesActions.CreateOrUpdateCustomize(
      { blockCustomizeUnits: customizeList }
    ));
  }


  public setProductCardDetail(data: ProductRouteProps): void {
    this.store.dispatch(new ProductsActions.SetCardDetail(data));
  }


  public getDepositCloseInfo(contractNumberHash: string): Observable<DepositInterface.DepositInfoResponseDto> {
    return this.store.dispatch(new DepositActions.DepositCloseInfo({ contractNumberHash }));
  }


  public getDepositTopUpInfo(contractNumberHash: string): Observable<DepositInterface.DepositTopUpResponseDto> {
    return this.store.dispatch(new DepositActions.DepositTopUpInfo({ contractNumberHash }));
  }


  public getDepositWithdrawalInfo(contractNumberHash: string): Observable<DepositInterface.DepositTopUpResponseDto> {
    return this.store.dispatch(new DepositActions.DepositWithdrawalInfo({ contractNumberHash }));
  }


  public cardNumberMasked(requestData: CardActionDto): Observable<MaskedCardNumberResponse> {
    return this.store.dispatch(new ProductsActions.CardNumberMasked(requestData));
  }


  public getProduct(productCode: string): Observable<Account> {
    return this.store.select(ProductsState.selectCard).pipe(
      map(filterFn => filterFn(productCode as string))
    );
  }


  public getOverdraft(productCode: string): Observable<Account> {
    return this.store.select(ProductsState.selectOverdraft).pipe(
      map(filterFn => filterFn(productCode as string))
    );
  }


  public getProductItem(contractNumber: string): Observable<any> {
    return this.store.select(ProductsState.selectProducts).pipe(
      map(products => {
        let foundAccount = null;
        for (const accountType in products) {
          foundAccount = (products as any)[accountType].find((account: any) => account.contractNumber === contractNumber);
          if (foundAccount) {
            break;
          }
        }
        return foundAccount;
      })
    );
  }


  public patchProduct(cardId: number, patch: any): Observable<ProductCustomizeDto> {
    return this.store.dispatch(new ProductsActions.PatchProduct(cardId, patch));
  }


  public sendCardStatus(cardHash: string): Observable<CardSmsStatusRs | undefined | HttpError> {
    return this.store.dispatch(new ProductsActions.SendCardSmsStatus({ cardHash }))
      .pipe(concatMap(() => this.store.select(ProductsState.getCardSmsStatusRs)));
  }


  public initDepositInfo(isDepositClose: boolean, isDepositWithdrawal: boolean, product: Account): Observable<any> {
    let depositInfoRequest: Observable<any>;

    if (isDepositClose) {
      depositInfoRequest = this.getDepositCloseInfo(product.contractNumberHash);
    } else if (isDepositWithdrawal) {
      depositInfoRequest = this.getDepositWithdrawalInfo(product.contractNumberHash);
    } else {
      depositInfoRequest = this.getDepositTopUpInfo(product.contractNumberHash);
    }

    this.preloaderStart();

    return depositInfoRequest
      .pipe(
        finalize(() => this.preloaderStop()),
        catchError((error: any) => {
          this.preloaderStop();
          return throwError(error);
        })
      );
  }


  public sendCardSmsManage(cardHash: string, enableSmsService: boolean, notifyProductCode: string, confirmationData?: string) {
    return this.store.dispatch(new UserActions.GetConfirmationsKey()).pipe(
      switchMap(() => this.store.select(UserState.selectConfirmationsKey)),
      switchMap((confirmationKey) => this.store.dispatch(new ProductsActions.SendCardSmsManage({
        cardHash,
        enableSmsService,
        notifyProductCode,
        confirmationData,
        confirmationKey
      }))),
      concatMap(() => this.store.select(ProductsState.getActionRs))
    );
  }


  public getCardSmsCost(cardHash: string) {
    return this.store.dispatch(new ProductsActions.SendCardSmsCost({ cardHash }))
      .pipe(concatMap(() => this.store.select(ProductsState.getCardSmsCostRs)));
  }


  public sendCardPinGenerationCost(cardHash: string): Observable<CardPinGenerationCostRs | undefined | HttpError> {
    return this.store.dispatch(new ProductsActions.SendCardPinGenerationCost({ cardHash }))
      .pipe(concatMap(() => this.store.select(ProductsState.getCardPinGenerationRs)));
  }


  public sendCardPinGenerate(requestData: CardActionConfirmDto): Observable<CardPinGenerationCostRs | undefined | HttpError> {
    return this.store.dispatch(new UserActions.GetConfirmationsKey()).pipe(
      switchMap(() => this.store.select(UserState.selectConfirmationsKey)),
      switchMap((confirmationKey) => this.store.dispatch(new ProductsActions.SendCardPinGenerate({
        ...requestData,
        confirmationKey
      }))),
      concatMap(() => this.store.select(ProductsState.getActionRs))
    );
  }


  public sendCardBlock(requestData: CardBlockDto): Observable<undefined | HttpError> {
    return this.store.dispatch(new ServicesActions.SendBlockCard(requestData))
      .pipe(concatMap(() => this.store.select(ServicesState.selectActionResponse)));
  }


  public setCardLimit(requestData: CardLimitDto): Observable<CardPinGenerationCostRs | undefined | HttpError> {
    return this.store.dispatch(new ServicesActions.SendCardLimit(requestData))
      .pipe(concatMap(() => this.store.select(ServicesState.selectActionResponse)));
  }


  public receiveCardLimits(requestData: CardActionConfirmDto) {
    return this.store.dispatch(new ServicesActions.ReceiveCardLimits(requestData))
      .pipe(concatMap(() => this.store.select(ServicesState.selectLimits)));
  }


  public loanProducts() {
    return this.store.dispatch(new PaymentActions.GetLoanProducts())
      .pipe(concatMap(() => this.store.select(PaymentState.selectLoanProductsList)));
  }


  public loanStatus() {
    return this.store.dispatch(new PaymentActions.GetLoanStatus())
      .pipe(concatMap(() => this.store.select(PaymentState.selectLoanProducts)));
  }


  public loanForm(requestData: PaymentInterface.LoanForm) {
    return this.store.dispatch(new PaymentActions.GetLoanForm(requestData))
      .pipe(concatMap(() => this.store.select(PaymentState.selectLoanForm)));
  }


  public loanDocument(requestData: PaymentInterface.LoanDocument) {
    return this.store.dispatch(new PaymentActions.GetLoanViewDocument(requestData))
      .pipe(concatMap(() => this.store.select(PaymentState.selectLoanDocument)));
  }


  public recordLoanConsent(
    requestData: PaymentInterface.LoanDocument,
    checkedAgreement: boolean,
    actionNext: boolean
  ): Observable<PaymentInterface.LoanDocumentRs> {
    let requestConsentData: PaymentInterface.LoanConsent;

    this.store.dispatch(new PreloaderActions.Start());

    return this.store.dispatch(new PaymentActions.GetLoanViewDocument(requestData))
      .pipe(
        take(1),
        finalize(() => this.store.dispatch(new PreloaderActions.Stop())),
        concatMap(() => {
          return this.store.select(PaymentState.selectLoanDocument)
            .pipe(
              take(1),
              tap((result:  PaymentInterface.LoanDocumentRs): void => {
                if (result) {
                  requestConsentData = {
                    document: result.document,
                    fileName: result.filename,
                    checked: checkedAgreement,
                    actionNext
                  };

                  this.store.dispatch(new PaymentActions.GetConsentRecording(requestConsentData));
                }
              })
            )
        })
      );
  }


  public clearRecordingLoanConsent(): void {
    const requestConsentData: PaymentInterface.LoanConsent = {
      document: undefined,
      fileName: undefined,
      checked: false,
      actionNext: true
    };

    this.store.dispatch(new PaymentActions.GetConsentRecording(requestConsentData))
      .pipe(take(1));
  }


  public loanIssue(requestData: PaymentInterface.LoanFilledForm) {
    return this.store.dispatch(new PaymentActions.GetLoanIssue(requestData))
      .pipe(concatMap(() => this.store.select(PaymentState.selectLoanDocument)));
  }


  public loanVoid(requestData: PaymentInterface.LoanVoid) {
    return this.store.dispatch(new PaymentActions.LoanVoid(requestData))
      .pipe(concatMap(() => this.store.select(PaymentState.selectLoanIssueResponse)));
  }
}
