import * as moment from 'moment/moment';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { finalize, Observable, Subject } from 'rxjs';
import { AppRoutes } from '../config/routes.config';
import { SnackbarType } from '../enums/snackbar.enum';
import { AuthActions } from '../models/auth/state/auth.actions';
import { FinanceActions } from '../models/finance/state/finance.actions';
import { PaymentActions } from '../models/payment/state/payment.actions';
import { ProductsActions } from '../models/products/state/products.actions';
import { ServicesActions } from '../models/services/state/services.actions';
import { UserActions } from '../models/user/state/user.actions';
import { CommonService } from './common.service';


const DEFAULT_TIME_TOKEN = 'timer';


@Injectable({
  providedIn: 'root'
})
export class TimerService {

  private token = DEFAULT_TIME_TOKEN;
  private timeout!: number | unknown;
  private sessionTimer: any;
  private isRunning = false;

  private readonly timer = new Subject<string | null>();


  constructor(
    private readonly store: Store,
    private readonly commonService: CommonService,
    private readonly translateService: TranslateService,
    private readonly router: Router) { }


  public start(duration: number): void {
    const timeLast = localStorage.getItem(this.token);

    if (timeLast) {
      const timeStart = new Date(Number(timeLast));
      const timeOverhead = moment().diff(timeStart, 'seconds');

      if (timeOverhead <= duration - 1) {
        this.runTimer(timeStart, timeOverhead, duration);
      } else {
        this.timer.next(null);
      }
    } else {
      localStorage.setItem(this.token, String(Date.now()));

      const timeLast = localStorage.getItem(this.token);
      const timeStart = new Date(Number(timeLast));
      const timeOverhead = moment().diff(timeStart, 'seconds');

      return this.runTimer(timeStart, timeOverhead, duration);
    }
  }


  public stop(): void {
    localStorage.removeItem(this.token);
    clearInterval(this.timeout as number);
  }


  public on(): Observable<string | null> {
    return this.timer.asObservable();
  }


  public isCurrentlyRunning(): boolean {
    return this.isRunning;
  }


  private runTimer(timeStart: Date, timeOverhead: number, duration: number) {
    timeOverhead = moment().diff(timeStart, 'seconds');

    this.isRunning = true;
    let timer: number = duration - timeOverhead;
    let formattedTime: string = '';

    this.timeout = setInterval(() => {
      const seconds = Math.floor(timer / 1000);
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;

      formattedTime = `${ minutes.toString() }:${ remainingSeconds.toString().padStart(2, '0') }`;

      if (timer <= 0) {
        this.timer.next(null);
        clearInterval(timer);
        this.isRunning = false;
      } else {
        timer -= 1000;
        this.timer.next(formattedTime);
      }
    }, 1000);
  }


  public startTimer(): void {
    this.sessionTimer = setTimeout((): void => {
      this.logoutUser();
    }, 30 * 60 * 1000); // 30 минут в миллисекундах
  }


  public resetTimer(): void {
    clearTimeout(this.sessionTimer); // сброс таймера
  }


  private logoutUser(): void {
    const errorMessage = this.translateService.instant('AUTH.INACTIVE_USER');

    this.store.dispatch(new AuthActions.Logout())
      .pipe(finalize(() => this.commonService.showSnackbar(errorMessage, SnackbarType.Danger)))
      .subscribe(
        (): void => {
          this.router.navigate([ AppRoutes.Auth ]);
          this.store.dispatch([
            new ProductsActions.ClearProducts,
            new ServicesActions.ClearServices,
            new FinanceActions.ClearTotalAmount,
            new PaymentActions.ClearPaymentSources,
            new UserActions.ClearStories
          ]);
        }
      );
  }
}
