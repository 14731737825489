<div
  class="app-button"
  matRipple
  [class.disabled]="disabled"
  [class.date-size]="isCustomize"
  [ngClass]="type"
  [class.column-direction]="columnDirection"
>
  <div *ngIf="icon"
       class="app-button__icon"
       [ngClass]="{ 'inherit' : !default, 'default' : default }"
  >
    <i [ngClass]="icon"></i>
  </div>
  <div class="app-button__title">
    <ng-content></ng-content>
  </div>
</div>
