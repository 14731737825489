import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CatalogInterface } from '../../../interfaces/catalog.interface';
import { CatalogResource } from '../resources/catalog.resource';
import { HttpHeaders } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class CatalogService {


  constructor(private readonly resource: CatalogResource) {}


  public getDepositProducts(): Observable<CatalogInterface.CoreProductCommonResponseDto> {
    return this.resource.getDepositProducts();
  }


  public getCurrentAccountProducts(): Observable<CatalogInterface.CoreProductCommonResponseDto> {
    return this.resource.getCurrentAccountProducts();
  }


  public depositOpenInfo(requestData: CatalogInterface.DepositOpenRequestDto): Observable<CatalogInterface.DepositOpenResponseDto> {
    return this.resource.depositOpenInfo(requestData);
  }


  public depositCalculateIncomeForecast(requestData: CatalogInterface.CalculateIncomeForecastRequestDto): Observable<CatalogInterface.CalculateIncomeForecastResponseDto> {
    return this.resource.depositCalculateIncomeForecast(requestData);
  }


  public cardOpen(data: string): Observable<CatalogInterface.OpenBpkResponseDto> {
    return this.resource.cardOpen(data);
  }


  public openCardGpl(requestData: CatalogInterface.OpenCardGplResponse): Observable<CatalogInterface.OpenCardGpl> {
    return this.resource.openCardGpl(requestData);
  }


  public openCardRoc(requestData: CatalogInterface.OpenProductRunOperation, headers: HttpHeaders): Observable<CatalogInterface.CheckBaseResponseDto> {
    return this.resource.openCardRoc(requestData, headers);
  }


  public openAccountRoc(requestData: CatalogInterface.OpenProductRunOperation, headers: HttpHeaders): Observable<CatalogInterface.CheckBaseResponseDto> {
    return this.resource.openAccountRoc(requestData, headers);
  }


  public closeAccountRoc(requestData: CatalogInterface.OpenProductRunOperation): Observable<CatalogInterface.CheckBaseResponseDto> {
    return this.resource.closeAccountRoc(requestData);
  }
}
