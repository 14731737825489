<div
  class="app-button"
  matRipple
  [class.disabled]="disabled"
  [ngClass]="type"
  [class.column-direction]="columnDirection"
>
  <div class="app-button__icon" *ngIf="icon">
    <i [ngClass]="icon"></i>
  </div>
  <div class="app-button__title">
    <ng-content></ng-content>
  </div>
</div>
