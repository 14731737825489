import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { Lang } from '../../../enums/lang.enum';
import { StoryResponse } from '../../../interfaces/banner.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { ApiResponse } from '../../../interfaces/response.interface';
import {
  ConfirmationKey,
  ConfirmationRs,
  Profile,
  RemoveDeviceRequestDto,
  UpdateClientRequestDto,
  UserBlockingPlatformRequest,
  UserDevicesResponseDto,
  UserProfilePhotoDto
} from '../../../interfaces/user.interface';


@Injectable({
  providedIn: 'root'
})
export class UserResource {

  constructor(
    private readonly http: HttpClient) {
  }


  public getClient() {
    return this.http.get<Profile>(ApiConfig.USER_GET_CLIENT);
  }


  public getConfirmations() {
    // if (environment.mock.confirmations) {
    //   return this.http.get<ConfirmationRs>('/assets/mock/confirmations.json');
    // }

    return this.http.get<ConfirmationRs>(ApiConfig.GET_CONFIRMATIONS);
  }


  public getConfirmationsKey() {
    return this.http.get<ConfirmationKey>(ApiConfig.GET_CONFIRMATION_KEY);
  }


  public updatePhoto(photo: UserProfilePhotoDto) {
    return this.http.post<Profile>(ApiConfig.USER_UPDATE_ERIP_NUMBER, photo);
  }


  public userBlockingPlatform(requestData: UserBlockingPlatformRequest, headers: HttpHeaders) {
    return this.http.post<Profile>(ApiConfig.USER_BLOCKING_PLATFORM, requestData, { headers });
  }


  public updateEmail(requestData: UpdateClientRequestDto) {
    return this.http.post<Profile>(ApiConfig.USER_UPDATE_EMAIL, requestData);
  }


  public updateEripNumber(eripId: string) {
    return this.http.post<Profile>(ApiConfig.USER_UPDATE_ERIP_NUMBER, { eripId });
  }


  public setLanguage(language: Lang): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(ApiConfig.USER_UPDATE_ERIP_NUMBER, { userLocale: language });
  }


  public getUserDevices(): Observable<UserDevicesResponseDto> {
    return this.http.get<UserDevicesResponseDto>(ApiConfig.USER_DEVICES);
  }


  public removeDevices(requestBody: RemoveDeviceRequestDto, headers: HttpHeaders): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.USER_DEVICE_REMOVE, requestBody, { headers });
  }


  public getStories(): Observable<StoryResponse> {
    return this.http.get<StoryResponse>(ApiConfig.GET_STORIES);
  }


  public completeOnboarding(): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(ApiConfig.USER_UPDATE_ERIP_NUMBER, { onboardingCompleted: true });
  }


  public storyViewed(mediaContentId: string): Observable<ApiResponse> {
    return this.http.post<ApiResponse>(ApiConfig.STORY_VIEWED, { mediaContentId });
  }
}
