import { IEripBodyTwoRequest } from 'src/app/interfaces/erip-navigation.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';


export namespace EripActions {
  export class GetEripPayments {
    public static readonly type = '[Erip] GetEripPayments';


    constructor(
      public readonly body: IEripBodyTwoRequest | any
    ) {}
  }


  export class GetEripPaymentsGplf {
    public static readonly type = '[Erip] GetEripPaymentsGplf';


    constructor(public readonly body: PaymentInterface.GetPayListFilterRequestDto) {}
  }


  export class GetEripPaymentsRoc {
    public static readonly type = '[Erip] GetEripPaymentsRoc';


    constructor(
      public readonly body: IEripBodyTwoRequest | any
    ) {}
  }


  export class GetByRequisitesGpl {
    public static readonly type = '[Erip] GetByRequisitesGpl';


    constructor(
      public readonly body: IEripBodyTwoRequest | any
    ) {}
  }


  export class GetByRequisitesRoc {
    public static readonly type = '[Erip] GetByRequisitesRoc';


    constructor(
      public readonly body: IEripBodyTwoRequest | any
    ) {}
  }
}
