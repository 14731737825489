<div class="app-snackbar">
  <div class="app-snackbar-message {{ type }}">
    <div class="app-snackbar-message__text">
      {{ text }}
    </div>

    <div class="app-snackbar-message__close" (click)="onClose()">
      <i class="icon icon-cross"></i>
    </div>
  </div>
</div>
