import { Lang } from '../../../enums/lang.enum';
import {
  RemoveDeviceRequestDto,
  UserBlockingPlatformRequest,
  UserProfilePhotoDto
} from '../../../interfaces/user.interface';


export namespace UserActions {
  export class GetClient {
    public static readonly type = '[User] GetClient';
  }


  export class UpdatePhoto {
    public static readonly type = '[User] UpdatePhoto';


    constructor(
      public readonly requestData: UserProfilePhotoDto) {
    }
  }


  export class UserBlockingPlatform {
    public static readonly type = '[User] UserBlockingPlatform';


    constructor(public readonly requestData: UserBlockingPlatformRequest) {}
  }


  export class UpdateEmail {
    public static readonly type = '[User] UpdateEmail';


    constructor(
      public readonly requestData: { email: string }) {
    }
  }


  export class UpdateEripNumber {
    public static readonly type = '[User] UpdateEripNumber';


    constructor(
      public readonly value: string) {
    }
  }


  export class GetConfirmations {
    public static readonly type = '[User] GetConfirmations';
  }


  export class GetConfirmationsKey {
    public static readonly type = '[User] GetConfirmationsKey';
  }


  export class SetLanguage {
    public static readonly type = '[User] SetLanguage';


    constructor(
      public readonly language: Lang) {
    }
  }


  export class GetUserDevices {
    public static readonly type = '[User] GetUserDevices';
  }


  export class RemoveUserDevice {
    public static readonly type = '[User] RemoveUserDevice';


    constructor(
      public readonly requestData: RemoveDeviceRequestDto) {
    }
  }


  export class ClearStories {
    public static readonly type = '[User] ClearStories';
  }


  export class GetStories {
    public static readonly type = '[User] GetStories';
  }


  export class CompleteOnboarding {
    public static readonly type = '[User] CompleteOnboarding';
  }


  export class StoryViewed {
    public static readonly type = '[User] StoryViewed';


    constructor(
      public readonly mediaContentId: string) {
    }
  }


  export class SetIsStoriesReceived {
    public static readonly type = '[User] SetIsStoriesReceived';
  }
}
