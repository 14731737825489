import {
  Credentials,
  IdentifyCardPayload,
  IdentifyPersonalPayload, RegistrationCheckLogin,
  TemporalCodeRq, TemporalConfirmRequest,
  TemporalConsentRequest
} from '../../../interfaces/auth.interface';


export namespace AuthActions {
  export class Identify {
    public static readonly type = '[Auth] Identify';


    constructor(
      public readonly payload: IdentifyPersonalPayload | IdentifyCardPayload) {
    }
  }


  export class Consent {
    public static readonly type = '[Auth] Consent';


    constructor(
      public readonly confirmedConsents: string[]) {
    }
  }


  export class Code {
    public static readonly type = '[Auth] Code';
  }


  export class Confirm {
    public static readonly type = '[Auth] Confirm';


    constructor(
      public readonly confirmationCode: string) {
    }
  }


  export class SetCredentials {
    public static readonly type = '[Auth] SetCredentials';


    constructor(
      public readonly credentials: Credentials) {
    }
  }


  export class ClearCredentials {
    public static readonly type = '[Auth] ClearCredentials';
  }


  export class Login {
    public static readonly type = '[Auth] Login';


    constructor(
      public readonly credentials: Credentials) {
    }
  }


  export class NoTemporal {
    public static readonly type = '[Auth] NoTemporal';
  }


  export class Logout {
    public static readonly type = '[Auth] Logout';
  }


  export class SetAuth {
    public static readonly type = '[Auth] SetAuth';


    constructor(
      public readonly sessionToken?: string) {
    }
  }


  export class TemporalCode {
    public static readonly type = '[Auth] TemporalCode';


    constructor(
      public readonly temporalCode: TemporalCodeRq) {
    }
  }


  export class TemporalConsent {
    public static readonly type = '[Auth] TemporalConsent';


    constructor(
      public readonly consentRequest: TemporalConsentRequest) {
    }
  }


  export class TemporalConfirm {
    public static readonly type = '[Auth] TemporalConfirm';


    constructor(
      public readonly confirmRequest: TemporalConfirmRequest) {
    }
  }


  export class SetNewLogin {
    public static readonly type = '[Auth] SetNewLogin';


    constructor(
      public readonly newLogin: string,
      public readonly newPwd: string) {
    }
  }


  export class ClearSession {
    public static readonly type = '[Auth] ClearSession';
  }


  export class CheckLogin {
    public static readonly type = '[Auth] RegistrationCheckLogin';


    constructor(public readonly requestData: RegistrationCheckLogin) {}
  }
}
