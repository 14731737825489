import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CardStatus } from '../../../../../enums/card.enum';
import { FormControlValue } from '../../../../../interfaces/control.interface';
import { SelectCustom } from '../../../../../interfaces/products.interface';
import { NumberUtils } from '../../../../../utils/number.utils';
import { StringUtils } from '../../../../../utils/string.utils';
import { WidgetUtils } from '../../../../../utils/widget.utils';
import { MultiSelectIconComponent } from '../../../multi-select-icon/multi-select-icon.component';
import { OptionComponent } from '../option/option.component';


@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: [ './select.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectComponent
    }
  ]
})
export class SelectComponent implements OnInit, OnChanges, ControlValueAccessor, AfterContentInit {

  public cardStatus = CardStatus;

  @ContentChildren(OptionComponent)
  public options!: QueryList<OptionComponent>;

  @ViewChild('appSelect')
  public appSelect!: ElementRef;

  @Input()
  public htmlLabel: boolean = false;

  @ViewChild('textInput')
  public textInput!: ElementRef;

  @ViewChild('droppedDownList')
  public droppedDownList!: ElementRef;

  @ViewChild('multiselectText')
  public multiselectText!: ElementRef;

  @ViewChild(MultiSelectIconComponent)
  public childComponent!: MultiSelectIconComponent;

  @Input()
  public isGetLabel!: boolean;
  @Input()
  public selected: [ string, string ][] | undefined;
  @Input()
  public readOnly: boolean = false;
  @Input()
  public id!: string | number;
  @Input()
  public label!: string;
  @Input()
  public selectedLabel: string = 'SELECT_COUNTRY';
  @Input()
  public placeholder: string = '';
  @Input()
  public disabled!: boolean;
  @Input()
  public multiSelect: boolean = false;
  @Input()
  public selectedDefaultItem!: string;
  @Input()
  public unselectedAll: boolean = false;
  @Input()
  public emptyDefaultSelectItem: boolean = false; // сделал флаг, для отмены множественного выделения в мультиселекте элементов
  @Input()
  public formControlCustom!: FormControl<string> | AbstractControl<string>;
  @Input()
  public selectAll = false;
  @Input()
  public invalid!: boolean;
  @Input()
  public isDropDownUniq!: boolean;
  @Input()
  public selectListUniq!: SelectCustom[];
  @Input()
  public inlineControl: boolean = false;
  @Input()
  public isNeedBreakWord: boolean = false; // флаг используется для замены input на textarea, чтобы был перенос слов на новую строку

  @Output()
  public emitSelectedValues: EventEmitter<OptionComponent[]> = new EventEmitter<OptionComponent[]>();

  @Output()
  public selectedOption = new EventEmitter<OptionComponent>();

  @Output()
  public emitSelectList: EventEmitter<string[]> = new EventEmitter<string[]>();

  public selectedValues: OptionComponent[] = [];
  public deselectedValues: OptionComponent[] = [];

  public selectedItemsCount: number = 0;

  public selectListHidden = true;
  public allSelectedId: string = WidgetUtils.ALL_SELECTED_ID;

  public regex: RegExp = /<div class="select-supertext">(.*?)<\//;


  constructor(
    private readonly elementRef: ElementRef
  ) {
    this.id = StringUtils.generateString(8);
  }


  @HostListener('document:click', [ '$event' ])
  public onClick(event: MouseEvent): void {
    const targetElement: HTMLElement = event.target as HTMLElement;
    const dropIconClick: boolean = targetElement.tagName === 'I' && targetElement.classList.contains('icon-arrow-drop-up');
    const multiSelectList = this.multiSelect && this.droppedDownList.nativeElement.contains(targetElement);
    const clickedInside = (this.elementRef.nativeElement.contains(targetElement) && this.textInput.nativeElement.contains(targetElement));

    setTimeout((): void => {
      if (!clickedInside && !multiSelectList) {
        this.hideSelectList();
      } else {
        this.checkDropIconClick(dropIconClick);
      }
    }, 1);
  }


  private _value!: FormControlValue;


  get value() {
    return this._value;
  }


  @Input()
  set value(val: FormControlValue) {
    this._value = val;
    this.onChange(this._value);
  }


  public onChange = (_: FormControlValue) => {};


  public onTouched = () => {};


  public ngOnInit(): void {
    if (this.multiSelect && this.selected && this.selected.length > 0) {
      this.prefillOptions(this.selected);
    } else if (this.formControlCustom && this.formControlCustom.value) {
      setTimeout(() => {
        const value1 = this.formControlCustom.value;
        if (value1) this.preselect(value1);
      }, 1);
    }
  }


  public ngOnChanges(changes: SimpleChanges): void {
    this.options?.changes.subscribe((changesopt: SimpleChanges) => {
      if (this.multiSelect && changesopt && changes['selected'] && changes['selected'].currentValue && changes['selected'].currentValue.length > 0) {
        this.prefillOptions(changes['selected'].currentValue);
      }
    });

    if (this.unselectedAll) {
      this.unselectAll();
      this.selectedValues = [];
    }
  }


  public ngAfterContentInit(): void {
    if (!this.value && this.options.length && !this.options.some(i => i.selected) && !this.selectedDefaultItem && !this.emptyDefaultSelectItem) {
      this.options.first.selected = true;
    }

    if (this.selectedDefaultItem && !this.emptyDefaultSelectItem) {
      const option = this.options.find(i => i.value === this.selectedDefaultItem);

      option ? option.selected = true : this.options.first.selected = true;
    }

    if (this.value && this.options.length && !this.emptyDefaultSelectItem) {
      const option = this.options.find(i => i.value === this.value);

      option ? option.selected = true : null;
    }

    if (this.selectAll) {
      this.options.forEach(option => option.selected = true);
      this.selectedValues = [...this.options];
    }
  }


  public closeSelectList(): void {
    this.selectListHidden = true;
    this.emitSelectedValues.emit(this.selectedValues.concat(this.deselectedValues));
  }


  public transformValue(amount: number, type: string): string {
    return NumberUtils.transformAmount(amount, type);
  }


  public registerOnChange(fn: (value: FormControlValue) => object): void {
    this.onChange = fn;
  }


  public registerOnTouched(fn: () => object): void {
    this.onTouched = fn;
  }


  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }


  public writeValue(value: FormControlValue): void {
    this.value = value;
  }


  public onOption(option: OptionComponent): void {
    if (!this.multiSelect) {
      this.unselectAll();
      option.selected = true;
      this.value = this.isGetLabel ? option.label : option.value;
      this.selectedOption.emit(option);
    } else {
      if (option.value === this.allSelectedId) {
        this.reflectSelectAllValueToAllOptions(option);
      } else this.reverseValue(option);
    }
  }


  public emitCountUniq(count: number): void {
    this.selectedItemsCount = count;
  }


  public emitSelectListUniq(selectList: string[]): void {
    this.emitSelectList.emit(selectList);
  }


  public clearUniqCounter(event: Event): void {
    this.selectedItemsCount = 0;
    this.childComponent.clearAll();

    event.stopPropagation();
  }


  private hideSelectList(): void {
    if (!this.selectListHidden) {
      this.selectListHidden = true;
      this.emitSelectedValues.emit(this.selectedValues.concat(this.deselectedValues));
    }
  }


  private checkDropIconClick(dropIconClick: boolean): void {
    if (dropIconClick) {
      this.selectListHidden = !this.selectListHidden;
    } else if (this.disabled) {
      this.selectListHidden = true;
      this.emitSelectedValues.emit(this.selectedValues.concat(this.deselectedValues));
    } else {
      this.selectListHidden = false;
    }

    if (this.selectListHidden && !this.disabled) {
      this.emitSelectedValues.emit(this.selectedValues.concat(this.deselectedValues));
    }
  }


  private reverseValue(option: OptionComponent) {
    if (option.selected) {
      const splice = this.selectedValues.splice(this.selectedValues.indexOf(option), 1);
      this.deselectedValues.push(option);
      option.selected = false;
      this.setValueNull();
      const number = this.selectedValues.indexOf(option);
      number >= 0 ? this.selectedValues.splice(number, 1) : 0;
      this.setAllSelectedFalse();
    } else {
      this.selectedValues.push(option);
      option.selected = true;
      const number = this.deselectedValues.indexOf(option);
      number >= 0 ? this.deselectedValues.splice(number, 1) : 0;
      this.value = option.label!;
      if (this.selectedValues.length === this.options.length - 1) {
        this.setAllSelectedTrue();
      }
    }
  }


  private reflectSelectAllValueToAllOptions(option: OptionComponent) {
    const allValue = !option.selected;
    this.options.forEach((opt) => {
      opt.selected = allValue;
    });
    this.selectedValues = [];
    this.deselectedValues = [];
    if (allValue) {
      this.selectedValues.push(...this.options);
    } else {
      this.deselectedValues.push(...this.options);
    }
  }


  public onKeyUp($event: KeyboardEvent | undefined): void {
    const searchString: string = ($event?.target as HTMLInputElement).value.toLowerCase();

    this.options.forEach((option: OptionComponent, index: number): void => {
      const labelValue: string = this.getExtractText(option.label!).toLowerCase();
      const optionValue = option.value.toLowerCase();

      const labelMatches: boolean = labelValue.includes(searchString);
      const valueMatches = optionValue.includes(searchString);

      if (this.multiSelect) {
        const searchValue: string = this.getExtractText(option.label!).toLowerCase();

        option.hidden = !searchValue.includes(searchString.toLowerCase());
      } else {
        option.hidden = !(labelMatches || valueMatches);
      }
    });
    this.selectListHidden = false;
  }


  public onClickMultiSelect($event: MouseEvent) {
    if (this.multiSelect) {
      const querySelector = this.appSelect.nativeElement.querySelector('.app-control-field__input');
      this.appSelect.nativeElement.querySelector('.multiselect-text').style.display = 'none';
      querySelector.classList.remove('invisible');
      querySelector.focus();
    }
  }


  public onClearSelectedValues(event: Event): void {
    event.stopPropagation();

    this.reflectSelectAllValueToAllOptions({selected: true, value: this.allSelectedId, hidden: false});
    this.emitSelectedValues.emit(this.selectedValues.concat(this.deselectedValues));
  }


  public onFocused($event: boolean) {
    if (!$event && this.multiSelect) {
      this.appSelect.nativeElement.querySelector('.multiselect-text').style.display = 'flex';
      this.appSelect.nativeElement.querySelector('.app-control-field__input').classList.add('invisible');
    }
  }


  private preselect(value: string) {
    if (this.options) {
      const find = this.options.find((option) => option.value === value);
      if (find) {
        this.onOption(find);
      }
    }
  }


  private prefillOptions(selectedList: [ string, string ][]) {
    let allSelected = false;
    if (this.options && selectedList.length === this.options.length - 1) {
      allSelected = true;
    }
    if (allSelected && !this.selectedDefaultItem) {
      this.selectAllElements();
    } else {
      this.selectAllByCode(selectedList);
    }
  }


  private setAllSelectedTrue() {
    const find = this.options.find((opt) => opt.value === this.allSelectedId);
    if (find) {
      find.selected = true;
      this.selectedValues.push(find);
      const number = this.deselectedValues.indexOf(find);
      number >= 0 ? this.deselectedValues.splice(number, 1) : 0;
    }
  }


  private setAllSelectedFalse() {
    const find = this.options.find((opt) => opt.value === this.allSelectedId);
    if (find) {
      find.selected = false;
      this.deselectedValues.push(find);
      const number = this.selectedValues.indexOf(find);
      number >= 0 ? this.selectedValues.splice(number, 1) : 0;
    }
  }


  private selectAllElements() {
    this.options.forEach((opt) => {
      opt.selected = true;
      this.selectedValues.push(opt);
    });
  }


  private selectAllByCode(selectedList: [ string, string ][]) {
    if (!this.selectedDefaultItem && this.options) {
      selectedList.forEach(([ isoCode, _ ]) => {
        const found = this.options.find((option) => option.value === isoCode);
        const alreadyPresent = this.selectedValues.find((option) => option.value === isoCode)
        if (found && !alreadyPresent) {
          found.selected = true;
          this.selectedValues.push(found);
        }
      });
      this.options.forEach((option) => {
        if (this.selectedValues.find((selected) => selected.value === option.value))
          option.selected = true;
      })
    }
  }


  private setValueNull() {
    this.value = null;
    this.options.forEach((option) => {
      option.hidden = false;
    });
  }


  private unselectAll(): void {
    this.options.forEach(i => i.selected = false);

    if (this.selectListUniq && this.selectListUniq.length) {
      this.selectedItemsCount = 0;

      this.selectListUniq.forEach((el: any): void => {
        el.items.forEach((item: any): void => {
          item.selected = false;
        });
      });
    }
  }


  private getExtractText(inputValue: string): string {
    const match = inputValue.match(this.regex);

    if (match) {
      return match[1];
    } else {
      return '';
    }
  }
}
