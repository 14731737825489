import { ConfirmAction } from '../../../interfaces/auth.interface';
import { ConsentPayload } from '../../../interfaces/consent.interface';


export namespace ConsentActions {
  export class GetAllConsents {
    public static readonly type = '[Consent] GetAllConsents';
  }


  export class GetConsentInfo {
    public static readonly type = '[Consent] GetConsentInfo';


    constructor(
      public readonly payload: ConsentPayload) {
    }
  }


  export class DeleteConsent {
    public static readonly type = '[Consent] DeleteConsent';


    constructor(
      public readonly payload: ConsentPayload) {
    }
  }


  export class GetAllConsentsAndApiKey {
    public static readonly type = '[Consent] GetAllConsentsAndApiKey';
  }


  export class IssueLongTermConsent {
    public static readonly type = '[Consent] IssueLongTermConsent';


    constructor(
      public readonly payload: ConfirmAction) {
    }
  }


  export class RevokeLongTermConsent {
    public static readonly type = '[Consent] RevokeLongTermConsent';


    constructor(
      public readonly payload: ConfirmAction) {
    }
  }
}
