import { ApplicationRef, ComponentFactoryResolver, ComponentRef, Injectable, Injector } from '@angular/core';
import { PreloaderComponent } from '../../../views/lib/preloader/preloader.component';


@Injectable({
  providedIn: 'root'
})
export class PreloaderService {

  private componentRef!: ComponentRef<PreloaderComponent> | null;


  constructor(
    private readonly injector: Injector,
    private readonly applicationRef: ApplicationRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver) {
  }


  public start(): void {
    if (!this.componentRef) {
      const cfr = this.componentFactoryResolver.resolveComponentFactory(PreloaderComponent);
      this.componentRef = cfr.create(this.injector);
      this.applicationRef.attachView(this.componentRef.hostView);

      document.body.appendChild(this.componentRef.location.nativeElement);
    }
  }


  public stop(): void {
    if (this.componentRef) {
      this.applicationRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
