import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { FinanceResource } from '../resources/finance.resource';
import { FinanceInterface } from '../../../interfaces/finance.interface';


@Injectable({
  providedIn: 'root'
})
export class FinanceService {

  constructor(private readonly resource: FinanceResource) {
  }


  public getTotalAmounts(): Observable<FinanceInterface.TotalAmountsResponseDto> {
    return this.resource.getTotalAmounts();
  }


  public getProductBalanceAmount(requestData: FinanceInterface.BalanceAmountRequestDto): Observable<FinanceInterface.BalanceAmountResponseDto> {
    return this.resource.getProductBalanceAmount(requestData);
  }
}
