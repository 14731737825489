import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ReceiptInterface } from '../../../interfaces/receipt.interface';
import { ReceiptResource } from '../resources/receipt.resource';


@Injectable({
  providedIn: 'root'
})
export class ReceiptService {


  constructor(private readonly resource: ReceiptResource) {}


  public getReceiptStatement(requestData: ReceiptInterface.StatementRequestDto): Observable<ReceiptInterface.ReceiptResponseDto> {
    return this.resource.getReceiptStatement(requestData);
  }


  public detReceiptPaymentCheck(requestData: ReceiptInterface.CheckRequestDto): Observable<ReceiptInterface.ReceiptResponseDto> {
    return this.resource.detReceiptPaymentCheck(requestData);
  }


  public getDepositPaymentsReport(requestData: ReceiptInterface.DepositPaymentDto): Observable<ReceiptInterface.ReceiptResponseDto> {
    return this.resource.getDepositPaymentsReport(requestData);
  }
}
