import { CatalogInterface } from '../../../interfaces/catalog.interface';


export namespace CatalogActions {

  export class DepositProducts {
    public static readonly type = '[Catalog] DepositProducts';
  }


  export class CurrentAccountProducts {
    public static readonly type = '[Catalog] CurrentAccountProducts';
  }


  export class DepositOpenInfo {
    public static readonly type = '[Catalog] DepositOpenInfo';


    constructor(public readonly requestData: CatalogInterface.DepositOpenRequestDto) {}
  }


  export class DepositCalculateIncomeForecast {
    public static readonly type = '[Catalog] DepositCalculateIncomeForecast';


    constructor(public readonly requestData: CatalogInterface.CalculateIncomeForecastRequestDto) {}
  }


  export class OpenCard {
    public static readonly type = '[Catalog] OpenCard';


    constructor(public readonly requestData: string) {}
  }


  export class OpenCardGpl {
    public static readonly type = '[Catalog] OpenCardGpl';


    constructor(public readonly requestData: CatalogInterface.OpenCardGplResponse) {}
  }


  export class OpenCardRoc {
    public static readonly type = '[Catalog] OpenCardRoc';


    constructor(public readonly requestData: CatalogInterface.OpenProductRunOperation) {}
  }


  export class OpenAccountRoc {
    public static readonly type = '[Catalog] OpenAccountRoc';


    constructor(public readonly requestData: CatalogInterface.OpenProductRunOperation) {}
  }


  export class CloseAccountRoc {
    public static readonly type = '[Catalog] CloseAccountRoc';


    constructor(public readonly requestData: CatalogInterface.OpenProductRunOperation) {}
  }


  export class OpenCardAddressCountry {
    public static readonly type = '[Catalog] OpenCardAddressCountry';


    constructor(public readonly payload: string[]) {}
  }
}
