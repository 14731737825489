export namespace PreloaderActions {

  export class Start {
    public static readonly type = '[Preloader] Start';
  }


  export class Stop {
    public static readonly type = '[Preloader] Stop';
  }


  export class SetPriority {
    public static readonly type = '[Preloader] SetPriority';


    constructor(
      public readonly priority: boolean
    ) {}
  }
}

