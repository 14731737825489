import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { JSEncrypt } from 'jsencrypt';
import { filter, map, Observable, tap } from 'rxjs';
import { Currency } from '../../../enums/currency.enum';
import { ProductsEnum } from '../../../enums/products.enum';
import { ConfirmAction } from '../../../interfaces/auth.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import { CustomizeUnit, MaskedCardNumberResponse, SortIndexes } from '../../../interfaces/product-liability.interface';
import {
  Account, Card,
  CardPinGenerationCostRs,
  CardSmsCostRs,
  CardSmsStatusRs,
  CreateVisaAliasResponseDto, LoanApplicationsRs, OnlineApplication,
  ProductMainCards,
  ProductNestingCards,
  Products,
  ResolveVisaAliasResponseDto,
  SelectCustom, SelectCustomOptions
} from '../../../interfaces/products.interface';
import { ConfirmationKey } from '../../../interfaces/user.interface';
import { SessionStorageService } from '../../../services/session-storage.service';
import { ConfirmationUtils } from '../../../utils/confirmation.utils';
import { CustomizeUtils } from '../../../utils/customize.utils';
import { ProductActionsUtils } from '../../../utils/product-actions.utils';
import { ProductsService } from '../services/products.service';
import { ProductsActions } from './products.actions';


interface ProductsStateModel {
  products: Products;
  cardSmsStatusRs?: CardSmsStatusRs;
  cardSmsCostRs?: CardSmsCostRs;
  cardPinGenerationCostRs?: CardPinGenerationCostRs;
  visaAliasResolve?: ResolveVisaAliasResponseDto;
  visaAliasCreate?: CreateVisaAliasResponseDto;
  actionRs?: HttpError;
  cardNumberMasked?: MaskedCardNumberResponse;
  depositPayment?: PaymentInterface.TransferDepositResponseDto; // TODO: отрефакторить, удалить из продуктов, перенести в payment
  sbMessage?: string;
  depositWithdrawalRs?: PaymentInterface.DepositWithdrawalResponseDto;
  loanConditionsConfirmType?: ProductsEnum.CreditConfirmType;
}


@State<ProductsStateModel>({
  name: 'products'
})
@Injectable()
export class ProductsState {

  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly productsService: ProductsService) {
  }


  @Selector()
  public static selectSBMessage(state: ProductsStateModel) {
    return state.sbMessage;
  }


  @Selector()
  public static selectLoanConditionsConfirmType(state: ProductsStateModel) {
    return state.loanConditionsConfirmType;
  }


  @Selector()
  public static selectDWResponse(state: ProductsStateModel) {
    return state.depositWithdrawalRs;
  }


  @Selector()
  public static selectProducts(state: ProductsStateModel) {
    return state.products;
  }


  @Selector()
  public static selectCardBalance(state: ProductsStateModel) {
    return state.products.cardBalance;
  }


  @Selector()
  public static selectTotalFunds(state: ProductsStateModel): (currency: Currency) => number {
    return (currency: Currency): number => {
      const acceptedAccounts: { [key: string]: Account[] } = {};
      for (const key in state.products) {
        if (state.products[key] && key !== 'creditAccount') {
          acceptedAccounts[key] = state.products[key] as Account[];
        }
      }

      return ([] as Account[])
        .concat(...Object.values(acceptedAccounts))
        .filter((i: Account): boolean => i.currency === currency)
        .map((i: Account) => i.balanceAmount)
        .filter((i: number) => !!i)
        .reduce((partialSum: number, a: number) => partialSum + a, 0);
    };
  }


  @Selector()
  public static selectHiddenOnMain(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.sortCards((cardAccounts as Account[]), false, false);
  }


  @Selector()
  public static selectHiddenWithoutSortIndex(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.sortCardWithoutSortIdx(cardAccounts as Account[], false);
  }


  @Selector()
  public static selectHiddenNesting(state: ProductsStateModel): ProductNestingCards[] {
    return (state.products.cardAccount as Account[])
      .flatMap((account: Account) => {
        return account.cards.map((card: Card) => ({ card, contractNumber: account.contractNumber }));
      })
      .filter(account => !account.card.displayOnMain && account.card.isAdditional);
  }


  @Selector()
  public static selectDisplayOnMain(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.sortCards((cardAccounts as Account[]), true, false);
  }


  @Selector()
  public static selectTransfer(state: ProductsStateModel) {
    return state.depositPayment;
  }


  @Selector()
  public static selectOnMainPayCards(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.sortCards((cardAccounts as Account[]), true, true);
  }


  @Selector()
  public static selectHiddenOnMainPayCards(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.sortCards((cardAccounts as Account[]), false, true);
  }


  @Selector()
  public static selectMainProductCard(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.getMainProductCard((cardAccounts as Account[]), true);
  }


  @Selector()
  public static selectHiddenMainProductCard(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.getMainProductCard((cardAccounts as Account[]), false);
  }


  @Selector()
  public static selectMainCardsWithoutSortIndex(state: ProductsStateModel): ProductMainCards[] {
    const cardAccounts = state.products.cardAccount;

    return CustomizeUtils.sortCardWithoutSortIdx((cardAccounts as Account[]), true);
  }


  @Selector()
  public static selectNestingDisplayOnMain(state: ProductsStateModel): ProductNestingCards[] {
    return (state.products.cardAccount as Account[])
      .flatMap((account: Account) => {
        return account.cards.map((card: Card) => ({ card, contractNumber: account.contractNumber }));
      })
      .filter(account => account.card.isAdditional);
  }


  @Selector()
  public static selectDeposits(state: ProductsStateModel): Account[] {
    return (state.products.depositAccount as Account[])
      .filter((account: Account) => account.displayOnMain && account.sortIndex !== undefined)
      .sort((accA: Account, accB: Account) => accA.sortIndex - accB.sortIndex);
  }


  @Selector()
  public static selectDepositsNoSortIndex(state: ProductsStateModel): Account[] {
    const depositAccount = state.products.depositAccount;

    return CustomizeUtils.sortByOpenDate((depositAccount as Account[]), true);
  }


  @Selector()
  public static selectHiddenDeposits(state: ProductsStateModel): Account[] {
    return (state.products.depositAccount as Account[])
      .filter((account: Account) => !account.displayOnMain && account.sortIndex !== undefined)
      .sort((accA: Account, accB: Account) => accA.sortIndex - accB.sortIndex);
  }


  @Selector()
  public static selectHiddenDepositsNoSortIndex(state: ProductsStateModel): Account[] {
    const depositAccount = state.products.depositAccount;

    return CustomizeUtils.sortByOpenDate((depositAccount as Account[]), false);
  }


  @Selector()
  public static selectActionRs(state: ProductsStateModel) {
    return state.actionRs;
  }


  @Selector()
  public static selectProductList(state: ProductsStateModel): SelectCustom[] {
    const productTitles = ProductsEnum.ProductTitles;

    const {
      cardAccount,
      creditAccount,
      currentAccount,
      depositAccount,
      overdraftAccount
    } = state.products;

    const mapCards: SelectCustomOptions[] =
      cardAccount?.length ? CustomizeUtils.remapCards(CustomizeUtils.sortingCardProduct(cardAccount!)) : [];
    const mapDeposit: SelectCustomOptions[] =
      depositAccount?.length ? CustomizeUtils.remapProducts(CustomizeUtils.sortingProducts(depositAccount!), ProductsEnum.ProductIcons.DEPOSIT) : [];
    const mapAccount: SelectCustomOptions[] =
      currentAccount?.length ? CustomizeUtils.remapProducts(CustomizeUtils.sortingProducts(currentAccount!), ProductsEnum.ProductIcons.ACCOUNT) : [];
    const mapCredit: SelectCustomOptions[] =
      creditAccount?.length ? CustomizeUtils.remapProducts(CustomizeUtils.sortingProducts(creditAccount!), ProductsEnum.ProductIcons.CREDIT) : [];
    const mapOverdraft: SelectCustomOptions[] =
      overdraftAccount?.length ? CustomizeUtils.remapProducts(CustomizeUtils.sortingProducts(overdraftAccount!), ProductsEnum.ProductIcons.CREDIT) : [];

    return [
      { title: productTitles.CARDS, items: mapCards ? mapCards : [] },
      { title: productTitles.DEPOSITS, items: mapDeposit ? mapDeposit : [] },
      { title: productTitles.ACCOUNTS, items: mapAccount ? mapAccount : [] },
      { title: productTitles.CREDITS, items: mapCredit ? mapCredit : [] },
      { title: productTitles.OVERDRAFT, items: mapOverdraft ? mapOverdraft : [] }
    ];
  }


  @Selector()
  public static selectCard(state: ProductsStateModel): (productCode: string) => Account {
    const { cardAccount } = state.products;

    return (productCode: string): Account => {
      return (cardAccount as Account[]).find((i: Account): boolean => i.contractNumber === productCode)!;
    };
  }


  @Selector()
  public static selectOverdraft(state: ProductsStateModel): (productCode: string) => Account {
    const { overdraftAccount } = state.products;

    return (productCode: string): Account => {
      return (overdraftAccount as Account[]).find((i: Account): boolean => {
        if (i.contractNumber === productCode) {
          return i.contractNumber === productCode;
        } else {
          return i.overdraftAccountNumber === productCode;
        }
      })!;
    };
  }


  @Selector()
  public static selectDeposit(state: ProductsStateModel): (productCode: string) => Account {
    const { depositAccount } = state.products;

    return (productCode: string): Account => {
      return (depositAccount as Account[]).find((i: Account): boolean => i.contractNumber === productCode)!;
    };
  }


  @Selector()
  public static selectAccount(state: ProductsStateModel): (productCode: string) => Account {
    const { currentAccount } = state.products;

    return (productCode: string): Account => {
      return (currentAccount as Account[]).find((i: Account): boolean => i.contractNumber === productCode)!;
    };
  }


  @Selector()
  public static selectCredit(state: ProductsStateModel): (productCode: string) => Account {
    const { creditAccount } = state.products;

    return (productCode: string): Account => {
      return (creditAccount as Account[]).find((i: Account): boolean => i.contractNumber === productCode)!;
    };
  }


  @Selector()
  public static selectCreditOverdraft(state: ProductsStateModel): (productCode: string) => Account {
    const { creditAccount, overdraftAccount } = state.products;
    const mergeProducts: Account[] = [
      ...(creditAccount || []),
      ...(overdraftAccount || [])
    ];

    return (productCode: string): Account => {
      return mergeProducts.find((i: Account): boolean => i.contractNumber === productCode)!;
    };
  }


  @Selector()
  public static selectAccountsAll(state: ProductsStateModel): Account[] {
    return (state.products.currentAccount as Account[]);
  }


  @Selector()
  public static selectAccounts(state: ProductsStateModel): Account[] {
    return (state.products.currentAccount as Account[])
      .filter(account => account.displayOnMain && account.sortIndex !== undefined)
      .sort((accA, accB) => accA.sortIndex - accB.sortIndex);
  }


  @Selector()
  public static selectAccountNoSortIndex(state: ProductsStateModel): Account[] {
    const currentAccount = state.products.currentAccount;

    return CustomizeUtils.sortByOpenDate((currentAccount as Account[]), true);
  }


  @Selector()
  public static selectHiddenAccounts(state: ProductsStateModel): Account[] {
    return (state.products.currentAccount as Account[])
      .filter(account => !account.displayOnMain && account.sortIndex !== undefined)
      .sort((accA, accB) => accA.sortIndex - accB.sortIndex);
  }


  @Selector()
  public static selectHiddenAccountNoSortIndex(state: ProductsStateModel): Account[] {
    const currentAccount = state.products.currentAccount;

    return CustomizeUtils.sortByOpenDate((currentAccount as Account[]), false);
  }


  @Selector()
  public static selectCredits(state: ProductsStateModel): Account[] {
    return (state.products.creditAccount as Account[])
      .filter(account => account.displayOnMain && account.sortIndex !== undefined)
      .sort((accA, accB) => accA.sortIndex - accB.sortIndex);
  }


  @Selector()
  public static selectCreditNoSortIndex(state: ProductsStateModel): Account[] {
    const currentAccount = state.products.creditAccount;

    return CustomizeUtils.sortByOpenDate((currentAccount as Account[]), true);
  }


  @Selector()
  public static selectHiddenCredits(state: ProductsStateModel): Account[] {
    return (state.products.creditAccount as Account[])
      .filter(account => !account.displayOnMain && account.sortIndex !== undefined)
      .sort((accA, accB) => accA.sortIndex - accB.sortIndex);
  }


  @Selector()
  public static selectHiddenCreditsNoSortIndex(state: ProductsStateModel): Account[] {
    const currentAccount = state.products.creditAccount;

    return CustomizeUtils.sortByOpenDate((currentAccount as Account[]), false);
  }


  @Selector()
  public static getCardDetail(state: ProductsStateModel) {
    return state.products.cardDetail;
  }


  @Selector()
  public static getCardDetailHash(state: ProductsStateModel) {
    return state.products.cardDetail?.cardHash;
  }


  @Selector()
  public static selectProductCards(state: ProductsStateModel) {
    const productTitles = ProductsEnum.ProductTitles;

    const cardAccount: Account[] = state.products.cardAccount || [];
    const creditAccount: Account[] = state.products.creditAccount || [];
    const currentAccount: Account[] = state.products.currentAccount || [];
    const depositAccount: Account[] = state.products.depositAccount || [];
    const overdraftAccount: Account[] = state.products.overdraftAccount || [];
    const loanApplicationsAccount: OnlineApplication[] =
      state?.products?.onlineApplications?.filter((item: OnlineApplication): boolean => item.status !== 'VOID') || [];

    const mergeList: Account[] = CustomizeUtils.shuffle([ ...overdraftAccount as Account[], ...creditAccount as Account[] ]);

    const mapCardData: Account[] = CustomizeUtils.sortingCards(cardAccount);
    const mapCreditData: Account[] = CustomizeUtils.sortingCreditProducts(mergeList);
    const mapAccountData: Account[] = CustomizeUtils.sortingOtherProducts(currentAccount);
    const mapDepositData: Account[] = CustomizeUtils.sortingOtherProducts(depositAccount);

    return [
      { account: mapCardData, title: productTitles.CARDS },
      { account: mapCreditData, title: productTitles.CREDITS },
      { account: mapAccountData, title: productTitles.ACCOUNTS },
      { account: mapDepositData, title: productTitles.DEPOSITS },
      { account: [], title: productTitles.TOTAL_AMOUNTS },
      { account: loanApplicationsAccount, title: productTitles.LOAN_APPLICATIONS }
    ];
  }


  @Selector()
  public static getCardSmsStatusRs(state: ProductsStateModel) {
    return state.cardSmsStatusRs;
  }


  @Selector()
  public static getCardPinGenerationRs(state: ProductsStateModel) {
    return state.cardPinGenerationCostRs;
  }


  @Selector()
  public static getActionRs(state: ProductsStateModel) {
    return state.actionRs;
  }


  @Selector()
  public static getCardSmsCostRs(state: ProductsStateModel) {
    return state.cardSmsCostRs;
  }


  @Selector()
  public static selectVisaAliasCreate(state: ProductsStateModel) {
    return state.visaAliasCreate;
  }


  @Selector()
  public static selectVisaAliasUpdate(state: ProductsStateModel) {
    return state.visaAliasCreate;
  }


  @Selector()
  public static selectVisaAliasDelete(state: ProductsStateModel) {
    return state.visaAliasCreate;
  }


  @Selector()
  public static selectAccountByContractNumber(state: ProductsStateModel): (contractNumber: string) => Account | undefined {
    return (contractNumber: string): Account | undefined => {
      if (!state.products) return undefined;
      const { depositAccount, cardAccount, creditAccount, currentAccount } = state.products;

      const deposit = depositAccount?.find((acc: Account): boolean => acc.contractNumber === contractNumber);
      const card = cardAccount?.find((acc: Account): boolean => acc.contractNumber === contractNumber);
      const credit = creditAccount?.find((acc: Account): boolean => acc.contractNumber === contractNumber);
      const account = currentAccount?.find((acc: Account): boolean => acc.contractNumber === contractNumber);

      return deposit || card || credit || account;
    };
  }


  @Action(ProductsActions.SetCardDetail)
  public setCardDetail(ctx: StateContext<ProductsStateModel>, action: ProductsActions.SetCardDetail): void {
    const state: Products = ctx.getState().products;
    ctx.setState({
      products: {
        ...state,
        cardDetail: {
          isAdditional: action.payload.isAdditional,
          productName: action.payload.productName,
          isMainPayProduct: action.payload.isMainPayProduct,
          cardStatus: action.payload.cardStatus,
          availableActionsList: action.payload.availableActionsList,
          expireDate: action.payload.expireDate,
          cardHash: action.payload.cardHash,
          contractNumber: action.payload.contractNumber,
          trackUrl: action.payload.trackUrl,
          trackNumber: action.payload.trackNumber
        }
      }
    });
  }


  @Action(ProductsActions.SetCardProducts)
  public setCardProducts(ctx: StateContext<ProductsStateModel>, action: ProductsActions.SetCardProducts) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      products: {
        ...state.products,
        cardAccount: action.requestData
      }
    });
  }


  @Action(ProductsActions.GetProducts)
  public getProducts(ctx: StateContext<ProductsStateModel>) {
    return this.productsService.getProducts().pipe(
      map(products => ctx.patchState(
        {
          products: {
            ...products,
            cardAccount: updateCardAccounts(products.cardAccount),
            creditAccount: products.creditAccount || [],
            currentAccount: products.currentAccount || [],
            depositAccount: products.depositAccount || []
          }
        }
      ))
    );
  }


  @Action(ProductsActions.RestoreLoanApplications)
  public restoreLoanApplications(ctx: StateContext<ProductsStateModel>, { loanApplicationsRs }: ProductsActions.RestoreLoanApplications) {
    const products = ctx.getState().products;
    ctx.patchState(
      {
        loanConditionsConfirmType: loanApplicationsRs.creditConfirmType,
        products: {
          ...products,
          onlineApplications: loanApplicationsRs?.onlineApplications
        }
      }
    );
  }


  @Action(ProductsActions.GetLoanApplications)
  public getLoanApplications(ctx: StateContext<ProductsStateModel>): Observable<void> {
    const products: Products = ctx.getState().products;
    return this.productsService.getLoanApplications()
      .pipe(
        map((loanApplications: LoanApplicationsRs): void => {
          this.sessionStorageService.setValue('getclientapplications', JSON.stringify(loanApplications));
          const applications: OnlineApplication[] = loanApplications ? [
              ...(loanApplications.onlineApplications || []),
              ...(loanApplications.creditApplicationSdboItems || [])
            ]
            : [];
          ctx.patchState(
            {
              loanConditionsConfirmType: loanApplications.creditConfirmType,
              products: {
                ...products,
                onlineApplications: applications
              }
            }
          );
        })
      );
  }


  @Action(ProductsActions.SwapLoanApplications)
  public swapLoanApplications(ctx: StateContext<ProductsStateModel>, { data }: ProductsActions.SwapLoanApplications): void {
    const products: Products = ctx.getState().products;

    ctx.patchState(
      {
        products: {
          ...products,
          onlineApplications: data
        }
      }
    );
  }


  @Action(ProductsActions.ClearProducts)
  public clearProducts(ctx: StateContext<ProductsStateModel>): void {
    ctx.patchState({
      products: undefined
    });
  }


  @Action(ProductsActions.GetCardBalance)
  public getCardBalance(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.GetCardBalance) {
    const { products } = ctx.getState();
    return this.productsService.getCardBalance({ cardHash: requestData.cardHash }).pipe(
      map(response => {
        ctx.patchState({
          products: {
            ...products,
            cardBalance: response,
            cardAccount: updateCardBalance(products.cardAccount, requestData.cardHash, response.balance),
            additionalCardAccount: updateCardBalance(products.additionalCardAccount, requestData.cardHash, response.balance),
            corporateCardAccount: updateCardBalance(products.corporateCardAccount, requestData.cardHash, response.balance)
          }
        });
      })
    );
  }


  @Action(ProductsActions.GetAccountBalance)
  public getAccountBalance(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.GetAccountBalance) {
    const { products } = ctx.getState();
    return this.productsService.getAccountBalance(requestData).pipe(
      map(response => {
        ctx.patchState({
          products: {
            ...products,
            currentAccount: updateAccountBalance(products.currentAccount, requestData.contractNumberHash, response.balanceAmount),
            depositAccount: updateAccountBalance(products.depositAccount, requestData.contractNumberHash, response.balanceAmount)
          }
        });
      })
    );
  }


  @Action(ProductsActions.UpdateVisibleBalance)
  public updateVisibleBalance(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.UpdateVisibleBalance) {
    const { products } = ctx.getState();
    const updatedData = ProductActionsUtils.updateProductData(products, requestData);

    const productMapping = {
      [ProductsEnum.ProductTitles.CARDS]: { field: ProductsEnum.ProductActionKeys.CARD_ACCOUNT },
      [ProductsEnum.ProductTitles.CREDITS]: { field: ProductsEnum.ProductActionKeys.CREDIT_ACCOUNT },
      [ProductsEnum.ProductTitles.OVERDRAFT]: { field: ProductsEnum.ProductActionKeys.OVERDRAFT_ACCOUNT },
      [ProductsEnum.ProductTitles.ACCOUNTS]: { field: ProductsEnum.ProductActionKeys.CURRENT_ACCOUNT },
      [ProductsEnum.ProductTitles.DEPOSITS]: { field: ProductsEnum.ProductActionKeys.DEPOSIT_ACCOUNT }
    };

    // @ts-ignore
    const { field } = productMapping[requestData.product];

    return this.productsService.updateVisibleBalance(requestData).pipe(
      map(() => {
        const updatedProducts = {
          ...products,
          [field]: updatedData,
          productCustomize: updatedData
        };
        ctx.setState({
          products: updatedProducts
        });
        return updatedProducts;
      })
    );
  }


  @Action(ProductsActions.UpdateProductName)
  public updateProductName(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.UpdateProductName) {
    const { products } = ctx.getState();

    if (
      requestData.product === ProductsEnum.ProductTitles.CREDITS && products.overdraftAccount
      && products.overdraftAccount.find(overdraft => overdraft.contractNumber === requestData.productId)
    ) requestData.product = ProductsEnum.ProductTitles.OVERDRAFT;

    const updatedData = ProductActionsUtils.updateProductData(products, requestData);

    const productMapping = {
      [ProductsEnum.ProductTitles.CARDS]: { field: ProductsEnum.ProductActionKeys.CARD_ACCOUNT },
      [ProductsEnum.ProductTitles.CREDITS]: { field: ProductsEnum.ProductActionKeys.CREDIT_ACCOUNT },
      [ProductsEnum.ProductTitles.ACCOUNTS]: { field: ProductsEnum.ProductActionKeys.CURRENT_ACCOUNT },
      [ProductsEnum.ProductTitles.DEPOSITS]: { field: ProductsEnum.ProductActionKeys.DEPOSIT_ACCOUNT },
      [ProductsEnum.ProductTitles.OVERDRAFT]: { field: ProductsEnum.ProductActionKeys.OVERDRAFT_ACCOUNT }
    };

    // @ts-ignore
    const { field } = productMapping[requestData.product];

    return this.productsService.updateProductName(
      {
        productId: requestData.productId,
        name: requestData.name
      }
    ).pipe(
      map(() => {
        const updatedProducts = {
          ...products,
          [field]: updatedData,
          productCustomize: updatedData
        };
        ctx.setState({
          products: updatedProducts
        });
        return updatedProducts;
      })
    );
  }


  @Action(ProductsActions.MakeMainProduct)
  public makeMainProduct(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.MakeMainProduct) {
    const { products } = ctx.getState();
    const updatedData = ProductActionsUtils.updateProductData(products, requestData);

    const otherMapping = {
      [ProductsEnum.ProductTitles.ACCOUNTS]: { otherField: ProductsEnum.ProductActionKeys.CARD_ACCOUNT },
      [ProductsEnum.ProductTitles.CARDS]: { otherField: ProductsEnum.ProductActionKeys.CURRENT_ACCOUNT }
    };

    const { otherField } =
      requestData.product ?
        otherMapping[requestData.product as keyof typeof otherMapping]
        : { otherField: ProductsEnum.ProductActionKeys.CARD_ACCOUNT };

    const otherUpdatedData =
      ProductActionsUtils.updateProductData(products,
        {
          ...requestData,
          product: ProductsEnum.ProductTitles.CARDS === requestData.product ?
            ProductsEnum.ProductTitles.ACCOUNTS : ProductsEnum.ProductTitles.CARDS
        }
      );

    const productMapping = {
      [ProductsEnum.ProductTitles.CARDS]: { field: ProductsEnum.ProductActionKeys.CARD_ACCOUNT },
      [ProductsEnum.ProductTitles.CREDITS]: { field: ProductsEnum.ProductActionKeys.CREDIT_ACCOUNT },
      [ProductsEnum.ProductTitles.ACCOUNTS]: { field: ProductsEnum.ProductActionKeys.CURRENT_ACCOUNT },
      [ProductsEnum.ProductTitles.DEPOSITS]: { field: ProductsEnum.ProductActionKeys.DEPOSIT_ACCOUNT }
    };

    // @ts-ignore
    const { field } = productMapping[requestData.product];

    return this.productsService.makeMainProduct({
      productId: requestData.productId,
      action: requestData.action,
      defaultAction: requestData.defaultAction
    }).pipe(
      map(() => {
        const updatedProducts = {
          ...products,
          [field]: updatedData,
          [otherField]: otherUpdatedData,
          productCustomize: updatedData
        };
        ctx.setState({
          products: updatedProducts
        });
        return updatedProducts;
      })
    );
  }


  @Action(ProductsActions.SetSortIndexes)
  public sortProductIdx(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.SetSortIndexes) {
    const { products } = ctx.getState();
    return this.productsService.setSortIndexes(requestData).pipe(
      filter(response => response.status === 'OK'),
      tap(() => {
        ctx.patchState({
          products: {
            cardAccount: updateSortIndexes(products.cardAccount, requestData.sortIndexes, true),
            creditAccount: updateSortIndexes(products.creditAccount, requestData.sortIndexes),
            currentAccount: updateSortIndexes(products.currentAccount, requestData.sortIndexes),
            depositAccount: updateSortIndexes(products.depositAccount, requestData.sortIndexes),
            overdraftAccount: updateSortIndexes(products.overdraftAccount, requestData.sortIndexes),
            onlineApplications: products.onlineApplications
          }
        });
      })
    );
  }


  @Action(ProductsActions.CustomizeDisplayOnMain)
  public customizeDisplayOnMain(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.CustomizeDisplayOnMain): Observable<ProductsStateModel> {
    const { products } = ctx.getState();
    return this.productsService.customizeDisplayOnMain(requestData).pipe(
      filter(response => response.status === 'OK'),
      map(() => {
        return ctx.patchState({
          products: {
            cardAccount: updateDisplayOnMain(products.cardAccount, requestData.productCustomizeUnits, true),
            creditAccount: updateDisplayOnMain(products.creditAccount, requestData.productCustomizeUnits),
            currentAccount: updateDisplayOnMain(products.currentAccount, requestData.productCustomizeUnits),
            depositAccount: updateDisplayOnMain(products.depositAccount, requestData.productCustomizeUnits),
            overdraftAccount: updateDisplayOnMain(products.overdraftAccount, requestData.productCustomizeUnits)
          }
        });
      })
    );
  }


  @Action(ProductsActions.MakeDisplayOnMain)
  public makeDisplayOnMain(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.MakeDisplayOnMain) {
    const { products } = ctx.getState();
    const updatedData = ProductActionsUtils.updateProductData(products, requestData);

    const productMapping = {
      [ProductsEnum.ProductTitles.CARDS]: { field: ProductsEnum.ProductActionKeys.CARD_ACCOUNT },
      [ProductsEnum.ProductTitles.CREDITS]: { field: ProductsEnum.ProductActionKeys.CREDIT_ACCOUNT },
      [ProductsEnum.ProductTitles.ACCOUNTS]: { field: ProductsEnum.ProductActionKeys.CURRENT_ACCOUNT },
      [ProductsEnum.ProductTitles.DEPOSITS]: { field: ProductsEnum.ProductActionKeys.DEPOSIT_ACCOUNT }
    };

    // @ts-ignore
    const { field } = productMapping[requestData.product];

    return this.productsService.makeDisplayOnMain(requestData).pipe(
      map(() => {
        const updatedProducts = {
          ...products,
          [field]: updatedData,
          productDisplayOnMain: updatedData
        };

        ctx.setState({
          products: updatedProducts
        });
        return updatedProducts;
      })
    );
  }


  @Action(ProductsActions.SendBlockCard)
  public sendCardBlock(ctx: StateContext<ProductsStateModel>, {
    request: {
      requestData,
      modifications
    }
  }: ProductsActions.SendBlockCard) {
    const { products } = ctx.getState();
    return this.productsService.blockCard(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(tap((actionRs) => {
        return ctx.patchState({
          products: {
            ...products,
            cardAccount: ProductActionsUtils.updateCard(products, requestData.cardHash, modifications)
          },
          actionRs
        });
      }));
  }


  @Action(ProductsActions.PaymentCardTransferTransfer)
  public paymentCardNumberTransfer(ctx: StateContext<ProductsStateModel>, {
    request: {
      requestData,
      modifications
    }
  }: ProductsActions.PaymentCardTransferTransfer) {
    const { products } = ctx.getState();
    return this.productsService.paymentCardNumberTransfer(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(response => {
          ctx.patchState({
            depositPayment: response,
            products: ProductActionsUtils.updateProductWithModifications(products, modifications)
          });
        })
      );
  }


  @Action(ProductsActions.PaymentCardPhoneTransfer)
  public paymentCardPhoneTransfer(ctx: StateContext<ProductsStateModel>, {
    request: {
      requestData,
      modifications
    }
  }: ProductsActions.PaymentCardPhoneTransfer) {
    const { products } = ctx.getState();
    return this.productsService.paymentCardPhoneTransfer(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(response => {
          ctx.patchState({
            depositPayment: response,
            products: ProductActionsUtils.updateProductWithModifications(products, modifications)
          });
        })
      );
  }


  @Action(ProductsActions.SendCardActivate)
  public sendCardActivate(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.SendCardActivate) {
    return this.productsService.cardActivate(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData)).pipe(
      map((actionRs) => {
        ctx.patchState({ actionRs });
      })
    );
  }


  @Action(ProductsActions.SendCardPinGenerationCost)
  public sendCardPinGenerationCost(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.SendCardPinGenerationCost) {
    return this.productsService.cardPinGenerationCost(requestData).pipe(
      map((cardPinGenerationCostRs) => {
        ctx.patchState({ cardPinGenerationCostRs });
      })
    );
  }


  @Action(ProductsActions.SendCardPinGenerate)
  public sendCardPinGenerate(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.SendCardPinGenerate) {
    return this.productsService.cardPinGenerate(requestData).pipe(
      map((cardPinGenerateRs) => {
        ctx.patchState({ actionRs: cardPinGenerateRs });
      })
    );
  }


  @Action(ProductsActions.SendCardSmsStatus)
  public sendCardSmsStatus(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.SendCardSmsStatus) {
    return this.productsService.cardSmsStatus(requestData).pipe(
      map((cardSmsStatusRs) => {
        ctx.patchState({ cardSmsStatusRs });
      })
    );
  }


  @Action(ProductsActions.SendCardSmsManage)
  public sendCardSmsManage(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.SendCardSmsManage) {
    return this.productsService.cardSmsManage(requestData, this.addHeaderConfirmationData(requestData)).pipe(
      map((actionRs: HttpError) => {
        ctx.patchState({ actionRs });
      })
    );
  }


  @Action(ProductsActions.DestroyCardSmsStatusRs)
  public destroyCardSmsStatus(ctx: StateContext<ProductsStateModel>, _: ProductsActions.DestroyCardSmsStatusRs) {
    ctx.patchState({ cardSmsStatusRs: undefined });
  }


  @Action(ProductsActions.DestroyCardActionRs)
  public destroyCardActionRs(ctx: StateContext<ProductsStateModel>, _: ProductsActions.DestroyCardActionRs) {
    ctx.patchState({ actionRs: undefined });
  }


  @Action(ProductsActions.SendCardSmsCost)
  public sendCardSmsCost(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.SendCardSmsCost) {
    return this.productsService.cardSmsCost(requestData).pipe(
      map((cardSmsCostRs: CardSmsCostRs) => {
        ctx.patchState({ cardSmsCostRs });
      })
    );
  }


  @Action(ProductsActions.CardNumberMasked)
  public getCardNumberMasked(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.CardNumberMasked) {
    return this.productsService.cardNumberMasked(requestData).pipe(
      map((cardNumberMasked: MaskedCardNumberResponse) => {
        ctx.patchState({ cardNumberMasked });
      })
    );
  }


  @Action(ProductsActions.VisaAliasResolve)
  public visaAliasResolve(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.VisaAliasResolve) {
    return this.productsService.visaAliasResolve(requestData).pipe(
      map((visaAliasResolve: ResolveVisaAliasResponseDto): void => {
        ctx.patchState({ visaAliasResolve });
      })
    );
  }


  @Action(ProductsActions.VisaAliasCreate)
  public visaAliasCreate(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.VisaAliasCreate) {
    return this.productsService.visaAliasCreate(requestData, this.addHeaderConfirmationData(requestData))
      .pipe(
        map(visaAliasCreate => {
          ctx.patchState({ visaAliasCreate });
        })
      );
  }


  @Action(ProductsActions.VasaAliasUpdate)
  public visaAliasUpdate(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.VasaAliasUpdate) {
    return this.productsService.visaAliasUpdate(requestData, this.addHeaderConfirmationData(requestData))
      .pipe(
        map(visaAliasCreate => {
          ctx.patchState({ visaAliasCreate });
        })
      );
  }


  @Action(ProductsActions.VisaAliasDelete)
  public visaAliasDelete(ctx: StateContext<ProductsStateModel>, { requestData }: ProductsActions.VisaAliasDelete) {
    return this.productsService.visaAliasDelete(requestData, this.addHeaderConfirmationData(requestData))
      .pipe(
        map(visaAliasCreate => {
          ctx.patchState({ visaAliasCreate });
        })
      );
  }


  @Action(ProductsActions.SBMessage)
  public sbMessage(ctx: StateContext<ProductsStateModel>, { message }: ProductsActions.SBMessage) {
    return ctx.patchState({ sbMessage: message });
  }


  @Action(ProductsActions.GetWithdrawalAccount)
  public getWithdrawalAccount(ctx: StateContext<ProductsStateModel>, { contractNumber }: ProductsActions.GetWithdrawalAccount) {
    return this.productsService.depositWithdrawalAccount({ contractNumberHash: contractNumber })
      .pipe(
        map(response => {
          ctx.patchState({ depositWithdrawalRs: response });
        })
      );
  }


  @Action(ProductsActions.SetWithdrawalAccount)
  public setWithdrawalAccount(ctx: StateContext<ProductsStateModel>, {
    contractNumber,
    targetContractNumber
  }: ProductsActions.SetWithdrawalAccount) {
    return this.productsService.depositWithdrawalTarget({
      contractNumberHash: contractNumber,
      targetContractNumberHash: targetContractNumber
    })
      .pipe(
        map(response => {
          ctx.patchState({ depositWithdrawalRs: response });
        })
      );
  }


  @Action(ProductsActions.PatchProduct)
  public patchProduct(ctx: StateContext<ProductsStateModel>, requestData: ProductsActions.PatchProduct) {
    const st = ctx.getState();
    const products = st.products;
    const cardAccounts = products.cardAccount;
    const mapCards = () => {
      if (requestData.cardId && cardAccounts && cardAccounts.length > 0) {
        return cardAccounts.map((cardAccount) => {
          return {
            ...cardAccount,
            cards: cardAccount.cards.map((card) => {
              if (card.cardId === requestData.cardId) {
                return {
                  ...card,
                  ...requestData.patch
                };
              }
            })
          };
        });
      } else {
        return cardAccounts;
      }
    };
    return ctx.patchState({
      products: {
        ...products,
        cardAccount: mapCards()
      }
    });
  }


  private addHeaderConfirmationData(requestData: ConfirmAction) {
    const headers = new HttpHeaders();
    const confirmationKey: ConfirmationKey | undefined = requestData.confirmationKey;
    const confirmationKey1 = requestData.confirmationData ? 'SMS '.concat(requestData.confirmationData) : undefined;
    if (confirmationKey && confirmationKey1) {
      const publicSignature = confirmationKey.publicSignature;
      const jsEncrypt = new JSEncrypt({ default_key_size: '2048' });
      jsEncrypt.setPublicKey(publicSignature);
      const encrypted = jsEncrypt.encrypt(confirmationKey1);
      delete requestData.confirmationData;
      delete requestData.confirmationKey;
      return headers.append('X-Data-Exchange', encrypted as string);
    } else {
      delete requestData.confirmationData;
      delete requestData.confirmationKey;
      return headers;
    }
  }
}


const updateDisplayOnMain = (products: Account[] | undefined, customizeUnits: CustomizeUnit[], isCards = false): Account[] => {
  if (!products || !products.length) return [];

  return products.map(product => {
    const customizeUnit = customizeUnits.find(item => item.productId === product.contractNumber);
    if (customizeUnit) {
      return { ...product, displayOnMain: customizeUnit.displayOnMain };
    }

    if (isCards) {
      const customizeUnit = customizeUnits.find(item => item.productId === product.cards[0].cardHash);

      if (!customizeUnit) return product;

      return {
        ...product,
        cards: product.cards.map(card => ({
          ...card,
          displayOnMain: customizeUnit.displayOnMain
        }))
      };
    }

    return product;
  });
};


const updateSortIndexes = (products: Account[] | undefined, sortIndexes: SortIndexes[], isCards = false): Account[] => {
  if (!products || !products.length) return [];

  return products.map(product => {
    const sortItem = sortIndexes.find(item => item.productId === product.contractNumber);
    if (sortItem) {
      return { ...product, sortIndex: sortItem.sortIndex };
    }

    if (isCards) {
      const sortItem = sortIndexes.find(item => item.productId === product.cards[0].cardHash);

      if (!sortItem) return product;

      return {
        ...product,
        sortIndex: sortItem.sortIndex,
        cards: product.cards.map(card => ({
          ...card,
          sortIndex: sortItem.sortIndex
        }))
      };
    }

    return product;
  });
};


const updateCardAccounts = (cardAccounts: Account[] | undefined): Account[] => {
  if (!cardAccounts || !cardAccounts.length) return [];

  return cardAccounts.map(account => ({
    ...account,
    sortIndex: account.cards[0].sortIndex
  }));
};


const updateCardBalance = (accounts: Account[] | undefined, cardHash: string, newBalance: number): Account[] => {
  if (!accounts || !accounts.length) return [];

  return accounts.map(account => {
    return {
      ...account,
      cards: account.cards.map(card => {
        if (card.cardHash === cardHash) {
          return { ...card, balance: newBalance };
        }
        return card;
      })
    };
  });
};


const updateAccountBalance = (accounts: Account[] | undefined, contractNumberHash: string, newBalance: number): Account[] => {
  if (!accounts || !accounts.length) return [];

  return accounts.map(account => {
    if (account.contractNumberHash === contractNumberHash) {
      return { ...account, balanceAmount: newBalance };
    }
    return account;
  });
};
