import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { AnimationOptions } from 'ngx-lottie';
import { AnimationItem } from 'ngx-lottie/lib/symbols';
import { preloaderConfig } from '../../../config/preloader.config';
import { PreloaderState } from '../../../models/preloader/state/preloader.state';


@Component({
  templateUrl: './preloader.component.html',
  styleUrls: [ './preloader.component.scss' ]
})
export class PreloaderComponent {

  public config = preloaderConfig();

  public showPreloaderPriority!: boolean;

  public options: AnimationOptions = {
    path: 'assets/preloader.json'
  };


  constructor(
    private readonly store: Store) {
    this.showPreloaderPriority = this.store.selectSnapshot(PreloaderState.selectShowPreloaderPriority);
  }


  public onAnimate(animation: AnimationItem): void {
    console.log(animation);
  }

}
