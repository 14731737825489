<div class="app-control" data-size="1">
  <div class="select-container" [ngClass]="{ 'open': isOpen && !invalid, 'open-red': invalid }">

    <span class="select-container_label" [ngClass]="{ 'error' : invalid, 'black' : !invalid }">
        {{ label }}
    </span>

    <div class="selected-option"
         [ngClass]="{ 'gray-border' : !invalid, 'red-border' : invalid }"
         (click)="disabled ? {} : toggleDropdown()"
    >

      <div class="selected-option_pic" *ngIf="selectedOption && selectedOption.image || selectedOption?.icon; else noSelectedItem">
        <div class="selected-option_pic__img"
             *ngIf="selectedOption && selectedOption.image && selectedOption.itemNumberMasked"
        >
          <img class="selected-option_pic__img-image" [src]="selectedOption.image" alt={{optionImage}}>

          <div class="selected-option_pic__img-icon_star"
               *ngIf="selectedOption.itemStatus !== cardStatus.UNKNOWN && selectedOption.isMainItem"
          >
            <i class="icon icon-star"></i>
          </div>

          <div class="options-icon__blocked" *ngIf="!cardStatusNotBlocked(selectedOption.komplatStatus)">
            <i class="icon icon-lock"></i>
          </div>

          <div class="selected-option_pic__img-icon_unknown"
               *ngIf="selectedOption.itemStatus === cardStatus.UNKNOWN || (selectedOption.amount && selectedOption.amount < 0) ||
                        checkExpireDate(selectedOption.expireDate!)"
          >
            <i class="icon icon-round-attention"></i>
          </div>

          <div class="selected-option_pic__img-number">{{ getNumberMask(selectedOption.itemNumberMasked) }}</div>
        </div>

          <div class="selected-option_pic__icon" *ngIf="selectedOption && selectedOption.icon">
            <i [class]="selectedOption.icon"></i>

          <div class="selected-option_pic__icon-icon_star" *ngIf="selectedOption.isMainItem">
            <i class="icon icon-star"></i>
          </div>
        </div>
      </div>

      <ng-template #noSelectedItem>
        <div class="selected-option_pic__icon-disable">
          <i class="icon icon-coinsBundle2"></i>
        </div>
      </ng-template>

      <div class="selected-text" [style.height]="height">
          <span class="selected-text_item"
                *ngIf="selectedOption && selectedOption.text && selectedOption.text.length <= maxAllowedLength; else noDataToShow"
          >
            {{ selectedOption.text }}
          </span>

        <ng-template #noDataToShow>
          <div class="selected-text_item-disable">
            <span class="selected-text_item-disable_text"> {{ this.selectProduct }}</span>
          </div>
        </ng-template>

        <div *ngIf="selectedOption && selectedOption.amount && selectedOption.currency; else noAmount"
             class="selected-text_currency"
        >
          <!--            <div *ngIf="selectedOption.displayBalance; else hideBalance" class="amount-block">-->
          <div class="amount-block">
              <span class="selected-text_currency__amount">
                {{ transformValue(selectedOption.amount, 'left') }}
              </span>
            <span class="selected-text_currency__decimal">
                ,{{ transformValue(selectedOption.amount, 'decimal') }}
              </span>
            <span class="selected-text_currency__equal">{{ selectedOption.currency }}</span>
          </div>

          <!--            <ng-template #hideBalance>-->
          <!--              <div class="selected-text_currency__dots"></div>-->
          <!--              <div class="selected-text_currency__dots"></div>-->
          <!--              <div class="selected-text_currency__dots"></div>-->
          <!--              <div class="selected-text_currency__dots"></div>-->
          <!--              <div class="selected-text_currency__dots"></div>-->
          <!--              <div class="selected-text_currency__dots"></div>-->
          <!--            </ng-template>-->
        </div>

        <ng-template #noAmount>
          <ng-container *ngIf="selectedOption && selectedOption.currency">
            <div class="options-text_currency">
              <!--                <div *ngIf="selectedOption.displayBalance; else hideBalance">-->
              <div>
                <span class="options-text_currency__amount">0</span>
                <span class="options-text_currency__decimal">,00</span>
                <span class="options-text_currency__equal">{{ selectedOption.currency }}</span>
              </div>

              <!--                <ng-template #hideBalance>-->
              <!--                  <div class="options-text_currency__dots"></div>-->
              <!--                  <div class="options-text_currency__dots"></div>-->
              <!--                  <div class="options-text_currency__dots"></div>-->
              <!--                  <div class="options-text_currency__dots"></div>-->
              <!--                  <div class="options-text_currency__dots"></div>-->
              <!--                  <div class="options-text_currency__dots"></div>-->
              <!--                </ng-template>-->
            </div>
          </ng-container>
        </ng-template>
      </div>

      <span
        *ngIf="!disabled"
        class="arrow-icon">
          <i [ngClass]="{ 'icon icon-arrow-drop-down' : !isOpen, 'icon icon-arrow-drop-up' : isOpen }"></i>
        </span>
      <span
        *ngIf="disabled"
        class="arrow-icon-invisible">
          <i [ngClass]="{ 'icon icon-arrow-drop-down' : !isOpen, 'icon icon-arrow-drop-up' : isOpen }"></i>
        </span>
    </div>

    <div class="options-container">
      <ul class="options" [style.max-height]="width" *ngIf="isOpen">
        <ng-container *ngFor="let select of options">
        <span class="options-title" *ngIf="select.items.length">
          {{ (select.title ? select.title : '') | translate }}
        </span>

          <li *ngFor="let item of select.items" (click)="selectOption(item)">
            <div class="options-icon">
              <div class="options-icon_img"
                   *ngIf="item.image && item.itemNumberMasked"
              >
                <img class="options-icon_img__image" [src]="item.image" alt={{this.optionImage}}>

                <div class="options-icon_img-icon_star"
                     *ngIf="item.itemStatus !== cardStatus.UNKNOWN && item.isMainItem"
                >
                  <i class="icon icon-star"></i>
                </div>

                <div class="options-icon__blocked" *ngIf="!cardStatusNotBlocked(item.komplatStatus)">
                  <i class="icon icon-lock"></i>
                </div>

                <div class="options-icon_img-icon_unknown"
                     *ngIf="item.itemStatus === cardStatus.UNKNOWN || (item.amount && item.amount < 0) ||
                        checkExpireDate(item.expireDate!)"
                >
                  <i class="icon icon-round-attention"></i>
                </div>

              <div class="options-icon_img__number">{{ getNumberMask(item.itemNumberMasked) }}</div>
            </div>
            <div [class]="'options-icon_img__icon '.concat(item.iconClass ? item.iconClass : '') " *ngIf="item && item.icon">
              <i [class]="item.icon"></i>

                <div class="options-icon_img__icon-icon_star" *ngIf="item.isMainItem">
                  <i class="icon icon-star"></i>
                </div>
              </div>
            </div>

            <div class="options-text">
              <span class="options-text_item">{{ item?.text }}</span>

              <div *ngIf="item.currency && item.amount && item.text; else noAmount" class="options-text_currency">
                <!--              <div *ngIf="item.displayBalance; else hideBalance">-->
                <div>
                  <div *ngIf="item.currency && item.amount" class="options-text_currency__container">
                <span class="options-text_currency__amount">
                  {{ transformValue(item.amount, 'left') }}
                </span>
                    <span class="options-text_currency__decimal">
                  ,{{ transformValue(item.amount, 'decimal') }}
                </span>
                  </div>
                  <span class="options-text_currency__equal">{{ item.currency }}</span>
                </div>

                <!--              <ng-template #hideBalance>-->
                <!--                <div class="options-text_currency__dots"></div>-->
                <!--                <div class="options-text_currency__dots"></div>-->
                <!--                <div class="options-text_currency__dots"></div>-->
                <!--                <div class="options-text_currency__dots"></div>-->
                <!--                <div class="options-text_currency__dots"></div>-->
                <!--                <div class="options-text_currency__dots"></div>-->
                <!--              </ng-template>-->
              </div>

              <ng-template #noAmount>
                <div class="options-text_currency">
                  <!--                <div *ngIf="item.displayBalance; else hideBalance">-->
                  <div>
                    <span class="options-text_currency__amount">0</span>
                    <span class="options-text_currency__decimal">,00</span>
                    <span class="options-text_currency__equal">{{ item.currency }}</span>
                  </div>

                  <!--                <ng-template #hideBalance>-->
                  <!--                  <div class="options-text_currency__dots"></div>-->
                  <!--                  <div class="options-text_currency__dots"></div>-->
                  <!--                  <div class="options-text_currency__dots"></div>-->
                  <!--                  <div class="options-text_currency__dots"></div>-->
                  <!--                  <div class="options-text_currency__dots"></div>-->
                  <!--                  <div class="options-text_currency__dots"></div>-->
                  <!--                </ng-template>-->
                </div>
              </ng-template>
            </div>

            <i *ngIf="selectedOption === item" class="icon icon-Check-21"></i>
          </li>
          <li *ngIf="isShowButton" (click)="navigateToPage()">
            <div class="options-icon">
              <div class="options-icon_block">
                <div class="options-icon_block__item">
                  <i class="icon icon-plus"></i>
                </div>
                <span class="options-icon_block__text">{{this.otherCard}}</span>
              </div>
            </div>
          </li>
          <li *ngIf="isShowCustomText && !isShowButton">
            <span class="options-subtitle">{{ customListItemText | translate }}</span>
          </li>
        </ng-container>
      </ul>
    </div>
  </div>

  <div class="error" *ngIf="invalid">{{ errorMessage.MANDATORY_FIELD | translate }}</div>
</div>



