export enum CardType {
  DEFAULT = 0,
  MIR = 2,
  VISA = 4,
  MASTER = 5,
  MAESTRO = 6,
  BEL = 9,
}


export enum CardTypeRequisite {
  DEBUT_CARD = 'DEBIT_CARD',
  VIRTUAL_CARD = 'VIRTUAL'
}


export enum CurrencyList {
  BYN = 'BYN',
  USD = 'USD',
  EUR = 'EUR',
  RUB = 'RUB'
}


export enum CardNumberType {
  DEFAULT = '0',
  MIR = '2',
  VISA = '4',
  MASTER = '5',
  MAESTRO = '6',
  BEL = '9',
}


export enum CardBackClasses {
  BEL_CARD_CLASS = 'product-card-head_card_bg-1',
  VISA_CARD_CLASS = 'product-card-head_card_bg-2',
  MASTER_CARD_CLASS = 'product-card-head_card_bg-3',
  MIR_CARD_CLASS = 'product-card-head_card_bg-4',
  DEFAULT_CARD_CLASS = 'product-card-head_card_bg-5',
  ADDED_CARD_CLASS = 'product-card-head_card_bg-6'
}


export enum ResponsibilityType {
  MAIN_CARD = 'MAIN_CARD',
  MAIN_ACCOUNT = 'MAIN_ACCOUNT',
  OVERDRAFT_CARD = 'OVERDRAFT'
}


export enum CardStatus {
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}


export enum CardSmsStatus {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED'
}


export enum CardAvailableActions {
  REQUISITES = 'REQUISITES',
  RATES = 'RATES',
  DOCUMENTS = 'DOCUMENTS',
  STATEMENT = 'STATEMENT',
  OPERATIONS_HISTORY = 'OPERATIONS_HISTORY',
  REISSUE_CARDS = 'REISSUE_CARDS',
  UNBLOCK_CARD = 'UNBLOCK_CARD',
  BLOCK_CARD = 'BLOCK_CARD',
  CARD_ACTIVATION = 'CARD_ACTIVATION',
  DISPLAY_ON_MAIN = 'DISPLAY_ON_MAIN',
  RENAME_CARD = 'RENAME_CARD',
  TOP_UP_CARD_BALANCE = 'TOP_UP_CARD_BALANCE',
  PAY_AND_TRANSFER = 'PAY_AND_TRANSFER',
  MAIN_SOURCE = 'MAIN_SOURCE',
  HIDE_BALANCE = 'HIDE_BALANCE',
  SMS_NOTIFICATIONS = 'SMS_NOTIFICATIONS',
  SENDING_CVV_CVC = 'SENDING_CVV_CVC',
  GETTING_PIN = 'GETTING_PIN',
  SETTING_LIMITS = 'SETTING_LIMITS',
  TRANSFERS_BY_PHONE_NUMBER = 'TRANSFERS_BY_PHONE_NUMBER'
}


export enum CardOverlayStatusText {
  BLOCKED_CARD = 'CARD_BLOCKED',
  EXPIRED_CARD = 'CARD_EXPIRED',
  MAKING_CARD = 'CARD_PRODUCTION',
  POSTAL_CARD = 'CARD_SENT_MAIL',
  READY_FOR_DELIVERY = 'READY_FOR_DELIVERY'
}


export enum CurrentPaymentByCard {
  CURRENT_PAYMENT = 'CURRENT_PAYMENT',
  LATE_PAYMENT = 'EXPIRED'
}


export enum SettingsCardText {
  MAIN_CARD = 'MAIN_CARD',
  DISPLAY_ON_MAIN = 'SHOW_ON_MAIN_PAGE',
  HIDE_BALANCE = 'HIDE_BALANCE',
  SMS_NOTIFICATION = 'SMS_NOTIFICATION',
  EXCHANGE_BY_PHONE = 'TRANSFER_BY_PHONE_NUMBER',
  CHANGE_PIN = 'CHANGE_PIN_CODE',
  BLOCKED = 'BLOCKED'
}


export enum CardStateSignature {
  DOSENDLETTER = 'DOSENDLETTER',
  BRANCHSEND = 'BRANCHSEND',
  BETRAY = 'BETRAY'
}
