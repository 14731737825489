import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OperationsHistory } from '../../../interfaces/operations-history.interface';
import { OperationsResource } from '../resources/operations.resource';


@Injectable({
  providedIn: 'root'
})
export class OperationsService {


  constructor(private readonly resource: OperationsResource) {}


  public getOperationHistory(requestData: OperationsHistory.OperationHistoryRequestDto): Observable<OperationsHistory.OperationHistoryResponseDto> {
    return this.resource.getOperationHistory(requestData);
  }
}
