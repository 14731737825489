<div class="app-control">
  <div class="app-control-field">
    <input class="app-control-field__input"
           id="inputFilter"
           type="text"
           [placeholder]="placeholder"
           [(ngModel)]="searchTerm"
           (keyup)="onKeyUp($event)"
           (input)="onSearch(false)"
           (focus)="onInputFocus()">

    <div class="app-control-field__suffix" *ngIf="searchTerm.length">
      <i class="icon icon-round-close" (click)="onClear()"></i>
    </div>
  </div>
<!--  <ul class="app-control_list"-->
<!--      [style.max-height]="height"-->
<!--      *ngIf="showDropdown && searchResults.length && !isError"-->
<!--  >-->
<!--    <div class="app-control_list__title">-->
<!--      {{ labelList }}-->
<!--    </div>-->
<!--    <li *ngFor="let result of searchResults"-->
<!--        (click)="selectResult(result)"-->
<!--    >-->
<!--      <div class="app-control_list__item">-->
<!--        <div class="app-control_list__item_icon">-->
<!--          <i class="icon" [class]="result.iconClass"></i>-->
<!--        </div>-->
<!--        <span class="app-control_list__item_text">-->
<!--          {{ result.name }}-->
<!--        </span>-->
<!--      </div>-->
<!--      <div class="app-control_list__item_underline"></div>-->
<!--    </li>-->
<!--  </ul>--><!--TODO: Временно оставляю, пока не согласуют BA требования-->
  <div class="app-control_empty" *ngIf="isError">
    <span class="app-control_empty__text">{{ emptyText }}</span>
  </div>
</div>
