import { NativeDateAdapter } from '@angular/material/core';
import { Injectable } from '@angular/core';


@Injectable()
export class CustomDateAdapter extends NativeDateAdapter {
  public override format(date: Date, displayFormat: any): string {
    if (displayFormat === 'input') {
      return this.format(date, 'DD.MM.YYYY');
    } else {
      return super.format(date, 'DD.MM.YYYY');
    }
  }
}
