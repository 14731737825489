import { Component, EventEmitter, Input } from '@angular/core';
import { FormControlValue } from '../../../../../interfaces/control.interface';


@Component({
  selector: 'app-radio-button',
  templateUrl: './radio-button.component.html',
  styleUrls: [ './radio-button.component.scss' ]
})
export class RadioButtonComponent {

  @Input()
  public value!: FormControlValue;

  @Input()
  public checked!: boolean;

  @Input()
  public disabled!: boolean;

  public checkEvent = new EventEmitter<RadioButtonComponent>();


  constructor() {}


  public onCheck(): void {
    this.checkEvent.emit(this);
  }
}
