import { Currency } from '../enums/currency.enum';
import { SharedEnum } from '../enums/shared.enum';
import { ShortCutModel } from '../interfaces/control.interface';


export const ButtonTypes: SharedEnum.ButtonTypes.PRIMARY |
  SharedEnum.ButtonTypes.PRIMARY_CUSTOM |
  SharedEnum.ButtonTypes.PRIMARY_RED |
  SharedEnum.ButtonTypes.SECONDARY |
  SharedEnum.ButtonTypes.SECONDARY_RED |
  SharedEnum.ButtonTypes.BLUR |
  SharedEnum.ButtonTypes.GRAY |
  SharedEnum.ButtonTypes.DEFAULT = SharedEnum.ButtonTypes.DEFAULT;


export const ShortCutsForeign: ShortCutModel[] = [
  {
    title: SharedEnum.ShortCutText.TWENTY,
    value: SharedEnum.ShortCutValues.TWENTY,
    currency: ''
  },
  {
    title: SharedEnum.ShortCutText.FIFTY,
    value: SharedEnum.ShortCutValues.FIFTY,
    currency: ''
  },
  {
    title: SharedEnum.ShortCutText.HUNDRED,
    value: SharedEnum.ShortCutValues.HUNDRED,
    currency: ''
  }
];


export const ShortCutInRub: ShortCutModel[] = [
  {
    title: SharedEnum.ShortCutText.FIVE_HUNDRED,
    value: SharedEnum.ShortCutValues.FIVE_HUNDRED,
    currency: Currency.RUB
  },
  {
    title: SharedEnum.ShortCutText.THOUSAND,
    value: SharedEnum.ShortCutValues.THOUSAND,
    currency: Currency.RUB
  },
  {
    title: SharedEnum.ShortCutText.FIVE_THOUSAND,
    value: SharedEnum.ShortCutValues.FIVE_THOUSAND,
    currency: Currency.RUB
  }
];
