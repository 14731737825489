<div class="app-control" [class.disabled]="disabled">
  <label class="app-control-label" [class.error]="invalid" [for]="id" *ngIf="label">{{ label }}</label>

  <div class="app-control-field" [class.error]="invalid" (click)="onControlClick()">
    <div class="app-control-field__prefix">
      <img [src]="value | cardPreviewSrc" alt="card">
    </div>

    <input class="app-control-field__input"
           [id]="id"
           [placeholder]="placeholder"
           mask="0000 0000 0000 0000"
           [(ngModel)]="value"
           (focus)="onInputFocus()"
           (blur)="onBlurEvent()"
    >

    <div class="app-control-field__suffix" *ngIf="showClear && withClear">
      <i class="icon icon-round-close" (click)="onClear()"></i>
    </div>

    <div class="app-control-field__suffix" *ngIf="!withClear">
      <ng-content select="[suffix]"></ng-content>
    </div>
  </div>
</div>
