import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngxs/store';
import { AppRoutes } from '../config/routes.config';
import { AuthState } from '../models/auth/state/auth.state';
import { DeeplinkService } from '../services/deeplink.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private readonly deeplinkService: DeeplinkService,
    private readonly store: Store,
    private readonly router: Router) {
  }


  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (!this.store.selectSnapshot(AuthState.selectSessionToken)) {
      this.deeplinkService.checkAndSaveDeeplink(state.url);

      this.router.navigate([ AppRoutes.Auth ]);

      return false;
    }

    return true;
    // TODO: удалить после тестирования
    // return this.resolver.resolve()
    //   .pipe(
    //     map((res: boolean) => res),
    //     catchError(() => {
    //       console.log('3333333333333333333333333333333333333333')
    //       this.router.navigate([ AppRoutes.Auth ]);
    //       this.store.dispatch([
    //         new ProductsActions.ClearProducts,
    //         new ServicesActions.ClearServices,
    //         new FinanceActions.ClearTotalAmount,
    //         new PaymentActions.ClearPaymentSources
    //       ]);
    //
    //       return of(false);
    //     })
    //   );
  }

}
