import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IEripBodyTwoRequest } from 'src/app/interfaces/erip-navigation.interface';
import { ApiConfig } from '../../../config/api.config';
import { EripInterface } from '../../../interfaces/erip.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';


@Injectable({
  providedIn: 'root'
})
export class EripResource {

  constructor(
    private readonly http: HttpClient) {
  }


  public getEripPayments(body: IEripBodyTwoRequest): Observable<EripInterface.EripResponse> {
    return this.http.post<EripInterface.EripResponse>(ApiConfig.ERIP_PAY_GPL, body);
  }


  public getEripPaymentsGmpl(body: PaymentInterface.GetPayListFilterRequestDto): Observable<PaymentInterface.GetPayListFilterResponseDto> {
    return this.http.post<PaymentInterface.GetPayListFilterResponseDto>(ApiConfig.ERIP_PAY_GPLS, body);
  }


  public getEripPaymentsRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.http.post<PaymentInterface.TransferResponseDto>(ApiConfig.ERIP_PAY_ROC, body, { headers });
  }


  public byRequisitesGpl(body: IEripBodyTwoRequest): Observable<EripInterface.EripResponse> {
    return this.http.post<EripInterface.EripResponse>(ApiConfig.BY_REQUISITES_GPL, body);
  }


  public byRequisitesRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.http.post<PaymentInterface.TransferResponseDto>(ApiConfig.BY_REQUISITES_ROC, body, { headers });
  }
}
