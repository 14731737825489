import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { ThemeConfig } from '../../../config/theme.config';
import { Lang } from '../../../enums/lang.enum';
import { SettingsState } from '../store/settings.state';


@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  private renderer!: Renderer2;


  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly store: Store,
    private readonly translateService: TranslateService) {

    this.renderer = rendererFactory.createRenderer(null, null);

    this.translateService.addLangs([ Lang.EN, Lang.RU, Lang.BE ]);
    this.translateService.setDefaultLang(Lang.RU);
  }


  public startLangListener(): void {
    this.store.select(SettingsState.selectLang).subscribe(
      lang => this.translateService.use(lang)
    );
  }


  public startThemeListener(): void {
    this.store.select(SettingsState.selectTheme).subscribe(theme => {
      this.clearTheme();

      this.renderer.addClass(document.body, theme);
    });
  }


  private clearTheme(): void {
    this.renderer.removeClass(document.body, ThemeConfig.styleClass.light);
    this.renderer.removeClass(document.body, ThemeConfig.styleClass.dark);
  }

}
