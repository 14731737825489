export namespace PaymentsEnum {


  export enum PaymentCustomText {
    TRANSFER_INTERNAL_CARD = 'TRANSFER_OTHER_COUNTRY_ONLY_PARITET_CARDS'
  }


  export enum PaymentLinks {
    UPLOAD_FILE = 'https://www.paritetbank.by/upload/P2P_iparitet.pdf'
  }


  export enum AccountType {
    MS = 'MS',
    ACCOUNT = 'ACCOUNT'
  }


  export enum PaymentPeriodUnit {
    MNTH = 'MONTH',
    DAYS = 'DAYS',
    QRTR = 'QRTR',
    YEAR = 'YEAR'
  }

  export enum RedirectStatus {
    SUCCESS = 'successful',
    INCOMPLETE = 'incomplete',
    FAILED = 'failed'
  }


  export enum PaymentDepositUnits {
    PAY_DATE = 'PAY_DATE',
    PAY_DATE_TIMESTAMP = 'PAY_DATE_TIMESTAMP',
    KIOSK_RECEIPT = 'KIOSK_RECEIPT',
    PAY_ALL = 'PAY_ALL',
    PAY_COMMISSION = 'PAY_COMMISSION',
    SUMMA = 'SUMMA',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    LOAN_NAME ='LOAN_NAME',
    LOAN_AMOUNT = 'LOAN_AMOUNT',
    LOAN_AMOUNT_CHECK = 'LOAN_AMOUNT_CHECK',
    MAX_PAY = 'MAX_PAY',
    LOAN_DURATION = 'LOAN_DURATION',
    LOAN_PERCENT = 'LOAN_PERCENT',
    TOP_UP_TYPE = 'TOP_UP_TYPE',
    STATUS = 'STATUS',
    DATE_TIME = 'DATE_TIME'
  }


  export enum PaymentUnits {
    PAY_DATE = 'PAY_DATE',
    PAY_DATE_TIMESTAMP = 'PAY_DATE_TIMESTAMP',
    KIOSK_RECEIPT = 'KIOSK_RECEIPT',
    PAY_ALL = 'PAY_ALL',
    PAY_COMMISSION = 'PAY_COMMISSION',
    SUMMA = 'SUMMA',
    FIRST_NAME = 'FIRST_NAME',
    LAST_NAME = 'LAST_NAME',
    LOAN_NAME ='LOAN_NAME',
    LOAN_AMOUNT = 'LOAN_AMOUNT',
    LOAN_AMOUNT_CHECK = 'LOAN_AMOUNT_CHECK',
    MAX_PAY = 'MAX_PAY',
    LOAN_DURATION = 'LOAN_DURATION',
    LOAN_PERCENT = 'LOAN_PERCENT',
    TOP_UP_TYPE = 'TOP_UP_TYPE',
    STATUS = 'STATUS',
    DATE_TIME = 'DATE_TIME'
  }


  export enum TypeCodes {
    DEPOSIT_OR_TRANSFER = 'DEPOSIT_OR_TRANSFER',
  }


  export enum PayCodes {
    PROCESSING_DEPOSIT = 'PROCESSING_DEPOSIT'
  }


  export enum TransferTypes {
    LOCAL_TRANSFER = 'LOCAL_TRANSFER',
    TRANSFER_TO_ANOTHER_COUNTRY = 'TRANSFER_TO_ANOTHER_COUNTRY',
    TRANSFER_BETWEEN_BANK_CLIENTS_EXCEPTION = 'TRANSFER_BETWEEN_BANK_CLIENTS_EXCEPTION',
    VISA_ALIAS = 'VISA_ALIAS',
    P2P = 'P2P'
  }

  export enum PaymentTabTitle {
    LOAN_PAYMENT_AND_REPAYMENT = 'PAYMENT_BY_CREDIT',
    LOAN_PAYMENT = 'PAY_BY_CREDIT',
    LOAN_REPAYMENT = 'FULL_LOAN_REPAYMENT',
    PERSONAL_LOAN_TAB = 'MY_LOAN',
    FOREIGN_LOAN_TAB = 'OTHER_LOAN'
  }


  export enum TypeOfOperation {
    SELECT_ALL = 'SELECT_ALL',
    WRITE_OFF = 'WRITE_OFF',
    TOP_UP = 'TOP_UP'
  }


  export enum PayCodeEnum {
    AIGENIS = 'AIGENIS',
    ACCOUNT_REPLENISHMENT_PARITETBROKER = 'ACCOUNT_REPLENISHMENT_PARITETBROKER'
  }


  export enum PayCodeOperation {
    PAY_PIN_SERVICE = 'PAY_PIN_SERVICE',
    P2P = 'P2P',
    A2P = 'A2P',
    P2A = 'P2A',
    A2A = 'A2A',
    P2P_CLIENT = 'P2P_CLIENT',
    A2P_CLIENT = 'A2P_CLIENT',
    P2A_CLIENT = 'P2A_CLIENT',
    A2A_CLIENT = 'A2A_CLIENT',
    PROCESSING_BPK = 'PROCESSING_BPK',
    PROCESSING_VIRTUAL_BPK = 'PROCESSING_VIRTUAL_BPK',
    PROCESSING_ADDITIONAL_BPK = 'PROCESSING_ADDITIONAL_BPK',
    REISSUE_INVALID_BPK = 'REISSUE_INVALID_BPK',
    REISSUE_EXPIRED_BPK = 'REISSUE_EXPIRED_BPK',
    PROCESSING_DEPOSIT = 'PROCESSING_DEPOSIT',
    TOP_UP_DEPOSIT = 'TOP_UP_DEPOSIT',
    WITHDRAWING_INTEREST_FROM_DEPOSIT_TO_CARD = 'WITHDRAWING_INTEREST_FROM_DEPOSIT_TO_CARD',
    WITHDRAWING_INTEREST_FROM_DEPOSIT_TO_ACCOUNT = 'WITHDRAWING_INTEREST_FROM_DEPOSIT_TO_ACCOUNT',
    CLOSING_DEPOSIT_ON_CARD = 'CLOSING_DEPOSIT_ON_CARD',
    CLOSING_DEPOSIT_ON_ACCOUNT = 'CLOSING_DEPOSIT_ON_ACCOUNT',
    PARTIAL_LOAN_REPAYMENT = 'PARTIAL_LOAN_REPAYMENT',
    FULL_LOAN_REPAYMENT = 'FULL_LOAN_REPAYMENT',
    BY_REQUISITES_TO_ANOTHER_BANK = 'BY_REQUISITES_TO_ANOTHER_BANK',
    BY_REQUISITES_TO_PARITET = 'BY_REQUISITES_TO_PARITET',
    AIGENIS = 'AIGENIS',
    SCMAP = 'SCMAP',
    BEPAID = 'BEPAID',
    VISA_ALIAS = 'VISA_ALIAS',
    ACCOUNT_REPLENISHMENT_PARITETBROKER = 'ACCOUNT_REPLENISHMENT_PARITETBROKER',
    ERIP = 'ERIP',
    CLOSE_ACCOUNT_WITH_ZERO_BALANCE = 'CLOSE_ACCOUNT_WITH_ZERO_BALANCE',
    CLOSE_ACCOUNT_TO_ACCOUNT = 'CLOSE_ACCOUNT_TO_ACCOUNT',
    CLOSE_ACCOUNT_TO_CARD = 'CLOSE_ACCOUNT_TO_CARD',
    BY_PHONE_NUMBER = 'BY_PHONE_NUMBER',
    BY_PAN_PARITET = 'BY_PAN_PARITET',
    OPEN_ACCOUNT = 'OPEN_ACCOUNT',
    BPC = 'BPC'
  }


  export enum OperationStatus {
    IN_PROCESSING = 'в обработке',
    COMPLETED = 'исполнен'
  }


  export enum PeriodTypeValue {
    ONCE = 'ONCE',
    DAILY = 'DAILY',
    WEEKLY = 'WEEKLY',
    MONTHLY = 'MONTHLY'
  }


  export enum PeriodTypeLabel {
    ONCE = 'Однократно',
    DAILY = 'Ежедневно',
    WEEKLY = 'Еженедельно',
    MONTHLY = 'Ежемесячно'
  }


  export enum ValidityLabel {
    INDEFINITE = 'Бессрочно',
    UNTIL_DATE = 'До определенной даты'
  }


  export enum AmountTypeValue {
    BASIC_AMOUNT = 'BASIC_AMOUNT',
    REQUIRED_AMOUNT = 'REQUIRED_AMOUNT'
  }


  export enum AmountTypeLabel {
    BASIC_AMOUNT = 'Фиксированная',
    REQUIRED_AMOUNT = 'Начисленная'
  }


  export enum PluralizeKeys {
    SINGLE_PAYMENT = 'SINGLE_PAYMENT',
    MULTIPLE_PAYMENTS = 'MULTIPLE_PAYMENTS',
    UNIQ_PAYMENTS = 'UNIQ_PAYMENTS'
  }
}
