export function checkObjsInsideArray(list: any[]): boolean {
  return list.every(item => item.items.length === 0);
}


export function checkListEmpty(list: any): boolean {
  for (let i: number = 0; i < list.length; i++) {
    if (list[i].items.length > 0) {
      return false;
    }
  }

  return true;
}


export function handleBackspace(value: string, cursorPosition: number): string {
  let beforeSlash: string = '';
  let afterSlash: string = '';
  let newPosition: number = cursorPosition;

  if (value[cursorPosition - 1] === '/') {
    newPosition--;
  }

  beforeSlash = value.slice(0, newPosition);
  afterSlash = value.slice(cursorPosition);

  if (beforeSlash.length > 0 && beforeSlash[beforeSlash.length - 1] === '/') {
    beforeSlash = beforeSlash.slice(0, -1);

    beforeSlash = beforePartCommon(afterSlash, beforeSlash).before;
    afterSlash = beforePartCommon(afterSlash, beforeSlash).after;
  } else if (beforeSlash.length < 2 && afterSlash.length > 0) {
    beforeSlash = beforePartial(afterSlash, beforeSlash).before;
    afterSlash = beforePartial(afterSlash, beforeSlash).after;
  } else if (afterSlash.length === 1) {
    afterSlash = '/' + afterSlash;
  }

  return beforeSlash + afterSlash;
}

function beforePartCommon(after: string, before: string): any {
  if (after.length > 0 && after.charAt(0) !== '/') {
    const digitToMove: string = after.charAt(0);
    // Перемещаем цифру перед слешем для случая, когда удаляется символ

    before = before.slice(0, before.lastIndexOf('/') - 1) + digitToMove + before.slice(before.lastIndexOf('/') - 1);
    after = after.slice(1);

    return { before, after };
  }
}

function beforePartial(after: string, before: string): any {
  const firstSymbolFromAfter: string = after.charAt(1);
  const afterSlashCopy: string = after;

  after = after.substr(2);

  if (firstSymbolFromAfter !== '/' && after.length !== 0) {
    before += firstSymbolFromAfter + '/';
  }

  if (after.length === 0) {
    after = afterSlashCopy;
  }

  return { before, after };
}
