import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AmountByCreditRequestDto, AmountCurrentPaymentByCreditRespDto } from '../../../interfaces/credit.interface';
import { CreditResource } from '../resources/credit.resource';


@Injectable({
  providedIn: 'root'
})
export class CreditService {


  constructor(
    private readonly resource: CreditResource) {
  }


  public getAmountCurrentPaymentByCredit(requestData: AmountByCreditRequestDto): Observable<AmountCurrentPaymentByCreditRespDto> {
    return this.resource.getAmountCurrentPaymentByCredit(requestData);
  }
}
