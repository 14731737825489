import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiKeyInfo, Consent, ConsentInfo, ConsentPayload } from '../../../interfaces/consent.interface';
import { ApiResponse } from '../../../interfaces/response.interface';
import { ConsentResource } from '../resources/consent.resource';


@Injectable({
  providedIn: 'root'
})
export class ConsentService {
  constructor(
    private readonly resource: ConsentResource) {
  }


  public getAllConsents(): Observable<Consent[]> {
    return this.resource.getAllConsents();
  }


  public getConsentInfo(payload: ConsentPayload): Observable<ConsentInfo> {
    return this.resource.getConsentInfo(payload);
  }


  public deleteConsent(payload: ConsentPayload) {
    return this.resource.deleteConsent(payload);
  }


  public getAllConsentsAndApiKey(): Observable<ApiKeyInfo> {
    return this.resource.getAllConsentsAndApiKey();
  }


  public issueLongTermConsent(headers: HttpHeaders): Observable<ApiKeyInfo> {
    return this.resource.issueLongTermConsent(headers);
  }


  public revokeLongTermConsent(headers: HttpHeaders): Observable<ApiResponse> {
    return this.resource.revokeLongTermConsent(headers);
  }
}
