import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs';
import { CatalogInterface } from '../../../interfaces/catalog.interface';
import { CatalogService } from '../services/catalog.service';
import { CatalogActions } from './catalog.actions';
import { ConfirmationUtils } from '../../../utils/confirmation.utils';


interface CatalogStateModel {
  depositProducts?: CatalogInterface.CoreProductCommonResponseDto;
  accountProducts?: CatalogInterface.CoreProductCommonResponseDto;
  depositOpenInfo?: CatalogInterface.DepositOpenResponseDto;
  openCardInfo?: CatalogInterface.OpenBpkResponseDto;
  openCardGpl?: CatalogInterface.OpenCardGpl;
  openCardRoc?: CatalogInterface.CheckBaseResponseDto;
  openAccountRoc?: CatalogInterface.CheckBaseResponseDto;
  depositCalcIncomeForecast?: CatalogInterface.CalculateIncomeForecastResponseDto;
  openCardAddress: string[];
}


@State<CatalogStateModel>({
  name: 'catalog'
})
@Injectable()
export class CatalogState {



  @Selector()
  public static selectCloseAccountRs(state: CatalogStateModel) {
    return state.openAccountRoc;
  }


  @Selector()
  public static selectOpenCardRoc(state: CatalogStateModel) {
    return state.openCardRoc;
  }


  @Selector()
  public static selectDepositOpenInfo(state: CatalogStateModel) {
    return state.depositOpenInfo as CatalogInterface.DepositOpenResponseDto;
  }


  @Selector()
  public static selectCardOpenAddressCountries(state: CatalogStateModel): string[] {
    return state.openCardAddress;
  }


  constructor(private readonly catalogService: CatalogService) {}


  @Action(CatalogActions.DepositProducts)
  public getDepositProducts(ctx: StateContext<CatalogStateModel>) {
    return this.catalogService.getDepositProducts()
      .pipe(
        map(depositProducts => {
          ctx.patchState({ depositProducts });
        })
      )
  }


  @Action(CatalogActions.DepositCalculateIncomeForecast)
  public getDepositCalcForecast(ctx: StateContext<CatalogStateModel>, { requestData }: CatalogActions.DepositCalculateIncomeForecast) {
    return this.catalogService.depositCalculateIncomeForecast(requestData)
      .pipe(
        map(depositCalcIncomeForecast => {
          ctx.patchState({ depositCalcIncomeForecast });
        })
      )
  }


  @Action(CatalogActions.CurrentAccountProducts)
  public getCurrentAccountProducts(ctx: StateContext<CatalogStateModel>) {
    return this.catalogService.getCurrentAccountProducts()
      .pipe(
        map(accountProducts => {
          ctx.patchState({ accountProducts });
        })
      )
  }


  @Action(CatalogActions.DepositOpenInfo)
  public depositOpenInfo(ctx: StateContext<CatalogStateModel>, { requestData }: CatalogActions.DepositOpenInfo) {
    return this.catalogService.depositOpenInfo(requestData)
      .pipe(
        map(depositOpenInfo => {
          ctx.patchState({ depositOpenInfo })
        })
      )
  }


  @Action(CatalogActions.OpenCard)
  public openCard(ctx: StateContext<CatalogStateModel>, { requestData }: CatalogActions.OpenCard) {
    return this.catalogService.cardOpen(requestData)
      .pipe(
        map(openCardInfo => {
          ctx.patchState({ openCardInfo })
        })
      )
  }


  @Action(CatalogActions.OpenCardGpl)
  public openCardGpl(ctx: StateContext<CatalogStateModel>, { requestData }: CatalogActions.OpenCardGpl) {
    return this.catalogService.openCardGpl(requestData)
      .pipe(
        map(openCardGpl => {
          ctx.patchState({ openCardGpl })
        })
      )
  }


  @Action(CatalogActions.OpenCardRoc)
  public openCardRoc(ctx: StateContext<CatalogStateModel>, { requestData }: CatalogActions.OpenCardRoc) {
    return this.catalogService.openCardRoc(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(openCardRoc => {
          ctx.patchState({ openCardRoc })
        })
      )
  }


  @Action(CatalogActions.OpenAccountRoc)
  public openAccountRoc(ctx: StateContext<CatalogStateModel>, { requestData }: CatalogActions.OpenAccountRoc) {
    return this.catalogService.openAccountRoc(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(openAccountRoc => {
          ctx.patchState({ openAccountRoc })
        })
      )
  }


  @Action(CatalogActions.CloseAccountRoc)
  public closeAccountRoc(ctx: StateContext<CatalogStateModel>, { requestData }: CatalogActions.CloseAccountRoc) {
    return this.catalogService.closeAccountRoc(requestData)
      .pipe(
        map(openAccountRoc => {
          ctx.patchState({ openAccountRoc })
        })
      )
  }


  @Action(CatalogActions.OpenCardAddressCountry)
  public setOpenCardAddressCountry(ctx: StateContext<CatalogStateModel>, action: CatalogActions.OpenCardAddressCountry) {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      openCardAddress: action.payload
    })
  }
}
