<div class="error-popup">
    <div class="error-popup-content">
        <div class="error-popup-header">
            <div class="error-popup-header_title">
                <div class="error-popup-header_title__icon" [style.background]="bgColor">
                    <i [class]="icon"></i>
                </div>
            </div>

            <span class="error-popup-header_text">{{ title | translate }}</span>
        </div>

        <span class="error-popup-text">{{ text | translate }}</span>

        <div class="error-popup-button" (click)="submitPopup()">
            <app-button [type]="buttonType">{{ buttonText | translate }}</app-button>
        </div>
    </div>
</div>
