<div class="app-control"
     [class.inline-control]="inlineControl"
     [class.disabled]="disabled"
     [class.invalid]="invalid && (amountControl.dirty || amountControl.touched)"
>
  <label class="app-control-label" [class.error]="invalid" [for]="id" *ngIf="label">{{ label }}</label>

  <div class="app-control-field"
       [class.error]="invalid"
       [class.app-height]="addClass"
       [class.disabled]="disabled"
       [class.centered]="centered"
       (click)="onControlClick($event)"
  >
    <input class="app-control-field__input"
           *ngIf="!isAmountInput else inputAmount"
           [id]="id"
           [placeholder]="placeholder"
           [formControl]="amountControl"
           [mask]="mask"
           [maxlength]="maxLength"
           [readOnly]="readOnly"
           (focus)="onInputFocus($event)"
           (blur)="onAddZeroToRightSide()"
    >

    <ng-template #inputAmount>
      <input class="app-control-field__input"
             appValidAmount
             [id]="id"
             [placeholder]="placeholder"
             [formControl]="amountControl"
             [mask]="mask"
             [maxlength]="maxLength"
             [readOnly]="readOnly"
             (focus)="onInputFocus($event)"
             (blur)="onBlur();onAddZeroToRightSide()"
      >
    </ng-template>

    <div class="app-control-field__actions desktop" *ngIf="isAmountList && !disabled">
      <div class="amount-list">
        <div class="amount-list-item" *ngFor="let item of addList" (click)="onAdd(item)">
          <div class="amount-list-item__value">+{{ item }}</div>
          <div class="amount-list-item__currency">{{ currency }}</div>
        </div>
      </div>
    </div>

    <div class="app-control-field__info"
         [class.disabled-info]="disabled"
         *ngIf="showInfoIcon"
         (click)="showInfo()">
      <i class="icon icon-round-info"></i>
    </div>

    <div class="app-control-field__info"
         *ngIf="tooltipInfo"
         [tooltip]="showTooltip()"
         [class.disabled-info]="disabled"
         (click)="showInfo()">
      <i class="icon icon-round-info"></i>
    </div>

    <div class="app-control-field__info"
         *ngIf="isClickOnRepeat && disabled"
         [style.transform]="'rotate(90deg)'"
    >
      <i class="icon icon-ArrowUpFramed32"></i>
    </div>
  </div>

  <div
    *ngIf="subText"
    [innerHTML]="subText"
  >
  </div>

  <div *ngIf="invalid; else noValidation" [class.centered]="centered">
    <div class="app-error">
      {{ getError(amountControl) }}
    </div>
  </div>
  <ng-template #noValidation></ng-template>

  <div class="amount-buttons mobile"
       *ngIf="isAmountList && !disabled"
  >
    <div class="amount-buttons__item" *ngFor="let item of addList" (click)="onAdd(item)">
      <div class="amount-buttons__item-value">+{{ item }}</div>
      <div class="amount-buttons__item-currency">{{ currency }}</div>
    </div>
  </div>
</div>
