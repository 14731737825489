import { Component, ContentChildren, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ErrorComponent } from '../../../../shared/components/error/error.component';


@Component({
  selector: 'app-form-group',
  templateUrl: './form-group.component.html',
  styleUrls: [ './form-group.component.scss' ]
})
export class FormGroupComponent implements OnInit {

  @Input()
  public control!: FormControl<string>;

  @ContentChildren(ErrorComponent)
  public errors!: QueryList<ErrorComponent>;


  constructor() {
    console.log(this.errors);
  }


  public ngOnInit(): void {
    console.log(this.errors);
  }
}
