import { FormControl, FormGroup } from '@angular/forms';
import { Currency } from '../enums/currency.enum';
import { PaymentsEnum } from '../enums/payments.enum';
import { ConfirmAction } from './auth.interface';
import { IEripLookup, IEripNavigationItem as EripNavItem } from './erip-navigation.interface';
import { PairExchangeRateResponseDto } from './exchange-rates-list-dto.interface';
import { Account, SelectCustom, SelectCustomOptions } from './products.interface';
import { TransferItem } from './segment.interface';


export namespace PaymentInterface {


  export interface PaySumma {
    paySumma: string | null;
  }


  export enum PayCodeEnum {
    P2P = 'P2P',
    P2A = 'P2A',
    A2P = 'A2P',
    A2A = 'A2A',
    P2P_CLIENT = 'P2P_CLIENT',
    A2P_CLIENT = 'A2P_CLIENT',
    P2A_CLIENT = 'P2A_CLIENT',
    A2A_CLIENT = 'A2A_CLIENT',
    BY_REQUISITES_TO_PARITET = 'BY_REQUISITES_TO_PARITET'
  }


  export interface BetweenAccountsLimitsDto {
    payCode?: {
      payCodeEnum: PayCodeEnum
    };
  }


  export interface PaySumma {
    paySumma: string | null;
    currency?: string;
  }


  export enum LoanPaymentType {
    PAYMENT = 'PAYMENT',
    FULL_PAYMENT = 'FULL_PAYMENT'
  }


  export interface LoanPaymentInfoDto extends ConfirmAction {
    creditPaymentType?: LoanPaymentType;  // PAYMENT(частичное погашение) или FULL_PAYMENT(полное погашение).
    creditContractNumberHash?: string; // - хэш кредитного договора клиента.
    creditContractNumber?: string; // - номер кредитного договора. Заполняется при погашении кредита другого клиента
    exchangeRate?: string; // - курс ВВО (только для совершения платежей в валюте)
    writeOffAmount?: string; // - Эквивалент суммы списания (только для совершения платежей в валюте)
    amount?: string;
    paymentSource: {
      paymentType: AccountType; // - MS или ACCOUNT
      productHash: string | null | undefined; // - хэш источника платежа
    };
  }


  export interface BetweenAccountsTransfer extends ConfirmAction {
    sourceProduct: string,
    targetProduct: string,
    writeOffAmount: string,
    exchangeRate: string,
    paySumma: PaySumma[],
    payCode: {
      payCodeEnum: PayCodeEnum
    }
  }


  export interface IEripAttributeRecord {
    change: any;
    code: string;
    codeOut: any;
    edit: string;
    format: any;
    formula: any;
    hint: any;
    inputFormat: any;
    mandatory: string;
    mask: any;
    max: any;
    maxLength: string;
    min: any;
    minLength: string;
    name: string;
    print: any;
    pwd: any;
    search: any;
    type: string;
    value: string;
    view: string;
    warning: string;
  }


  interface IEripGroupRecord {
    code: string;
    name: string;
  }


  export interface MinMaxLimit {
    min: number;
    max: number;
  }


  export interface BetweenAccountsLimitsRs {
    min: number;
    max: number;
    lengthLimits?: MinMaxLimit;
    amountLimits?: MinMaxLimit;
  }


  export interface BetweenAccountsRs {
    errorCode: string,
    errorText: string,
    abonent: string,
    payRecord: IEripNavigationItem[]
  }


  export interface IEripNavigationItem {
    attrRecord: IEripAttributeRecord[];
    budget: string;
    claimId: string;
    code: string;
    codeType: string;
    commission: string;
    currency: string;
    diType: string;
    direction: any;
    edit: string;
    extraId: any;
    fine: any;
    formula: any;
    getPayListType: string;
    groupRecord: IEripGroupRecord;
    isCalculate: any;
    isCommission: any;
    isFine: any;
    lookups: any;
    max: any;
    min: any;
    name: string;
    nameType: string;
    nextCode: any;
    nominal: any;
    payCommission: string;
    picture: any;
    searchAttrs: any;
    stornoMode: string;
    summa: string;
    view: string;
    title: string;
    route: string;
    iconClass?: string;
    desc?: string;
    disabled?: boolean;
  }


  export interface IEripBodyTwoRequest {
    diType: string;
    payCode: {
      payCode: string;
    };
  }


  export interface IEripBodyOneRequest {
    diType: string;
    payCode: {
      payCode: string;
    };
    attrRecord: IEripAttributeItem[];
  }


  export interface IEripAttributeItem {
    change: any;
    code: string;
    codeOut: any;
    edit: string;
    format: any;
    formula: any;
    hint: string;
    inputFormat: any;
    mandatory: any;
    mask: any;
    max: any;
    maxLength: string;
    min: any;
    minLength: string;
    name: string;
    print: any;
    pwd: any;
    search: string;
    type: string;
    value: any;
    view: string;
    warning: string;
  }


  export interface AttrRecordList {
    change?: any;
    code?: string;
    codeOut?: any;
    edit?: string;
    format?: any;
    formula?: any;
    hint?: string;
    inputFormat?: any;
    mandatory?: any;
    mask?: any;
    max?: any;
    maxLength?: string;
    min?: any;
    minLength?: string;
    name?: string;
    print?: any;
    pwd?: any;
    search?: string;
    type?: string;
    value?: any;
    view?: string;
    warning?: string;
  }


  export enum KomplatType {
    S = 'S' // строка
  }


  export interface ValidationAttributes {
    code: string,
    codeOut: string,
    name: string,
    type: KomplatType,
    minLength: string,
    maxLength: string,
    min: string,
    max: string,
    mandatory: string,
    edit: string,
    print: string,
    value: string,
    mask: string,
    search: string,
    formula: string,
    view: string,
    hint: string,
    warning: string,
    format: string,
    inputFormat: string,
    change: string,
    pwd: string,
    lookup: string
  }


  export interface PaymentDetails {
    details: string,
    amount: string
  }


  export interface CreditInfoResponse {
    errorCode: string;
    errorText: string;
    contractNumber: string;
    fullName: string;
    mainDebtAmount: string;
    currentPaymentAmount: string;
    minSummaValue: string;
    maxSummaValue: string;
    currencyCode: string;
    validationAttributes: ValidationAttributes[];
    paymentDetails: PaymentDetails[];
  }


  export interface TransferDepositRequestDto {
    sourceProduct: string;
    targetProduct: string;
    paySumma: PaySumma[];
    writeOffAmount?: string | null;
    exchangeRate?: string | null;
  }


  export interface TransferBePaidSources {
    targetProduct?: string;
    targetPhone?: string;
    targetCurrency?: string;
    targetHolderFio?: string;
    targetIssueName?: string;
  }


  export interface TransferCardNumberCurrencyDto {
    pan?: string;
    expiry?: string;
    sourceCurrency?: string,
    phoneNumber?: string;
  }


  export enum AccountType {
    MS = 'MS',
    ACCOUNT = 'ACCOUNT',
  }


  export interface TransferCardNumberTransferDto extends ConfirmAction {
    sourceProduct: string,
    accountType: AccountType,
    writeOffAmount: string,
    exchangeRate: string,
    payCode: {
      payCodeEnum: PayCodeEnum
    },
    paySumma: [
      {
        paySumma: string;
      }
    ],
    targetCard?: {
      pan: string,
      expiry: string,
      currency: Currency,
      contractNumber: string,
      productCode: string
    }
  }


  export interface TransferByCardCurrencyRs {
    targetCurrency: Currency,
    currency: Currency,
    contractNumber: string,
    productCode: string,
    maskedCard: string,
    maskedFio: string,
    maskedAccount: string,
  }


  export interface TransferDepositResponseDto {
    checkHeaderToString: string;
    checkFooterToString: string;
    sourceProduct: string;
    targetProduct: string;
    paymentId: string;
    units: TransferUnits[];
    balance?: number;
  }


  export interface TransferResponseDto {
    checkHeaderToString: string;
    checkFooterToString: string;
    sourceProduct: string;
    targetProduct: string;
    paymentId: string;
    units: TransferUnits[];
  }


  export interface InitializeProductAble {
    productExtract: SelectCustom[];
    absenceOfProducts?: string;
    selectOptions: SelectCustom[];
    selectSource?: SelectCustomOptions;

    emitSelected(event: SelectCustomOptions): void;
  }


  export interface BetweenAccountsLimitsAble {
    transferLimits: BetweenAccountsLimitsRs;
    form: FormGroup;
    minLimit: number;
    maxLimit?: number;
  }


  export interface InitializeProductTargetAble {
    productExtract: SelectCustom[];
    absenceOfProducts?: string;
    selectOptionsTarget: SelectCustom[];
    selectTarget?: SelectCustomOptions;

    emitSelectedTarget(event: SelectCustomOptions): void;
  }


  export interface ExchangeRateAble {
    exchangeFormulaString?: string;
    showRate?: number;
    unitDtos?: PairExchangeRateResponseDto;
    calcRate?: string;
    amountValue?: string;
  }


  export interface PayFormAble {
    form: FormGroup;
  }


  export interface DepositWithdrawalRequestDto {
    contractNumberHash: string;
    targetContractNumberHash?: string;
  }


  export interface DepositWithdrawalResponseDto {
    minAmount: number,
    maxAmount: number,
    period: string,
    contractNumbers: string[]
    contractNumber: string,
    actualContractNumber: string;
    acceptableContractNumbers: string[]
  }


  export interface CommonPage {
    pageTitle: string;
    absenceOfProducts: string;
  }


  export interface LoanSchedule {
    amount: string;
    mainDebt: string;
    percent: string;
    date: string;
  }


  export interface CalculatorPage {
    amountTitle: string;
    amountCurrency: string;
    amountStepAdd: number;
    amountStepSubtract: number;
    minSum: number;
    maxSum: number;
    onUpdateAmount: ($event: FormControl) => void;
  }


  export interface EripPaymentAble {
    eripPayments: [ EripNavItem[] ];
    eripAttributes?: IEripAttributeItem[];
    form: FormGroup;
    attrLookups?: IEripLookup[];
    step: number;
    hintTexts: string[];
  }


  export interface TransferUnits {
    key: string;
    value: string;
  }


  export interface PaymentBalance {
    uniqProductKey: boolean;
    sourceItem: SelectCustomOptions | Account,
    targetItem: SelectCustomOptions | Account,
    source: number,
    target: number,
    depositData: TransferDepositResponseDto
  }


  export interface AmountAccordionContent {
    title: string;
    text: string;
  }


  export interface TitleContent {
    icon: string;
    titleIcon: string;
    type: string;
    color: string;
  }


  export interface AmountContent {
    type: string;
    amount: string;
    currency: string;
  }


  export interface ToggleContent {
    paymentId: string;
  }


  export interface TransferContent {
    transferData: TransferItem[];
  }


  export interface CheckData {
    amountAccordionContent?: AmountAccordionContent[];
    titleContent?: TitleContent;
    amountContent?: AmountContent;
    toggleContent?: ToggleContent;
    transferContent?: TransferContent;
  }


  export interface TransferCardNumberForm {
    amount: FormControl<string>;
    pan: FormControl<string>;
    expiry: FormControl<string>;
  }


  export interface AmountForm {
    amount: FormControl<string>;
  }


  export interface LoanIssueForm {
    amount: FormControl<string>;
    range: FormControl<string>;
  }


  export interface LoanIssueValues {
    amount: string;
    range: string;
  }


  export interface LoanApplicationForm {
    income: FormControl<string>;
    incomeType: FormControl<string>;
    fieldOfActivity: FormControl<string>;
    agree: FormControl<boolean>;
  }


  export interface BranchesForm {
    text: FormControl<string>;
    workTime: FormControl<string>;
    services: FormControl<string>;
  }


  export interface TransferPhoneNumberForm {
    phoneNumber: FormControl<string>;
    amount: FormControl<string>;
    recipient: FormControl<string>;
    recipientAccountNumber: FormControl<string>;
    purpose: FormControl<string>;
  }


  export interface EncryptedRequestDto {
    encryptedData: string;
    loggedUser?: boolean;
  }


  export interface GetTransactionStatusDto {
    trackingId: string;
    loggedUser?: boolean;
  }


  export interface ReceiptResponseDto {
    receipt: string;
    pdfReceipt: string[];
  }


  export interface TransferTypeResponseDto {
    transferType: string;
    issuerName: string;
    recipientName: string;
  }


  export interface RestrictionsResponseDto {
    status: string;
    message: string;
    commission: RestrictionCommission;
  }


  export interface RestrictionCommission {
    minimum: number;
    percent: number;
    bankFee: number;
    currency: string;
  }


  export interface GetPublicSignatureResponseDto {
    publicSignature: string;
  }


  export interface PaymentCardDto {
    number: string;
    verificationValue: string;
    expMonth: string;
    expYear: string;
  }


  export interface RecipientCardDto {
    number: string;
    holder?: string;
  }


  export interface EncryptedJSON {
    test?: boolean;
    amount?: number;
    currency?: string;
    duplicateCheck?: boolean;
    description?: string;
    trackingId?: string;
    returnUrl?: string;
    payerCard?: PaymentCardDto;
    recipientCard?: RecipientCardDto;
    payerCardNumber?: string;
    recipientCardNumber?: string;
    recipientPhoneNumber?: string;
    visaAlias?: boolean;
  }


  export interface ClientInfo {
    id: string;
    name: string;
    unp: string;
    idType: string;
    bic: string;
    account: string;
  }


  export interface TypePan {
    typePan: string;
    accountType: string;
  }


  export interface PanModel {
    pan: string;
    expiry: string;
    cardTrack2: string;
    cardPinBlock: string;
    extraRs: string;
    authSource: string;
  }


  export interface AttrRecordModel {
    attrRecord: IEripAttributeItem[];
  }


  export interface GetPayListFilterRequestDto {
    sourceProduct?: string;
    targetProduct?: string;
    accountType?: string;
    writeOffAmount?: string;
    exchangeRate?: string;
    diType?: string;
    diTypeCode?: string;
    pan?: PanModel;
    typePan?: TypePan;
    clientInfo?: ClientInfo;
    clientKind?: string;
  }


  export interface AttrRecord {
    attrRecord?: IEripAttributeItem[];
  }


  export interface PayCodeModel {
    payCode?: string;
    type?: string;
    payCodeEnum?: string;
  }


  export interface AbonentModel {
    ls: string;
    els: string;
    fio: string;
    address: string;
  }


  export interface PaySummaModel {
    paySumma: string,
    currency: string
  }


  export interface GetPayListRequestDto extends ConfirmAction {
    sourceProduct?: string;
    targetProduct?: string;
    accountType?: string;
    writeOffAmount?: string;
    exchangeRate?: string;
    diType?: string;
    diTypeCode?: string;
    pan?: PanModel;
    typePan?: TypePan;
    clientInfo?: ClientInfo;
    clientKind?: string;
    paymentPurpose?: string;
    period?: string;
    depositProductCode?: string;
    depositCurrency?: string;
    filter?: AttrRecord;
    payCode?: PayCodeModel;
    extraId?: string;
    ls?: string;
    scanerLs?: string;
    abonent?: AbonentModel;
    paySumma?: PaySummaModel[];
    countAttr?: string;
    attrRecord?: IEripAttributeItem[];
  }


  export interface KioskNominal {
    nominal?: string;
    nominalCount?: string;
  }


  export interface PayRecord {
    code?: string;
    nextCode?: string;
    diType?: string;
    name?: string;
    claimId?: string;
    extraId?: string;
    direction?: string;
    codeType?: string;
    nameType?: string;
    stornoMode?: string;
    codeOutEnum?: string;
    isFine?: string;
    isCalculate?: string;
    isCommission?: string;
    getPayListType?: string;
    commission?: string;
    picture?: string;
    summa?: string;
    min?: string;
    max?: string;
    nominal?: string;
    edit?: string;
    view?: string;
    currency?: string;
    fine?: string;
    budget?: string;
    payCommission?: string;
    formula?: string;
    searchAttrs?: SearchAttrs;
    groupRecord?: GroupRecord;
    attrLookups?: AttrLookUps;
    attrRecord?: AttrRecord[];
  }


  export interface SearchAttrs {
    code?: string;
    name?: string;
    type?: string;
    minLength?: string;
    maxLength?: string;
    min?: string;
    max?: string;
    hint?: string;
    format?: string;
  }


  export interface GroupRecord {
    code?: string;
    name?: string;
  }


  export interface AttrLookUps {
    attrLookup: AttrLookup[];
  }


  export interface Items {
    item?: string;
    name?: string;
  }


  export interface AttrLookup {
    name?: string;
    items?: Items[];
  }


  export interface Units {
    key: string;
    value: string;
  }


  export interface RunOperationRequestDto extends ConfirmAction {
    sourceProduct?: string;
    targetProduct?: string;
    accountType?: string;
    writeOffAmount?: string;
    exchangeRate?: string;
    diType?: string;
    diTypeCode?: string;
    pan?: PanModel;
    typePan?: TypePan;
    clientInfo?: ClientInfo;
    clientKind?: string;
    paymentPurpose?: string;
    signCode?: string;
    payDate?: string;
    kioskReceipt?: string;
    kioskError?: string;
    kioskNominal?: KioskNominal[];
    payAccept?: string;
    payRecordCount?: string;
    payRecord?: PayRecord;
  }


  export interface CheckBaseResponseDto {
    checkHeaderToString: string;
    checkFooterToString: string;
    sourceProduct: string;
    targetProduct: string;
    paymentId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    units: Units;
  }


  export interface SearchAttrModel {
    code: string;
    name: string;
    type: string;
    minLength: string;
    maxLength: string;
    min: string;
    max: string;
    hint: string;
    format: string;
  }


  export interface GroupRecordModel {
    code: string;
    name: string;
  }


  export interface ItemsLookUpModel {
    item: string;
    name: string;
  }


  export interface LookUpModel {
    name: string;
    items: ItemsLookUpModel[];
  }


  export interface AttrLookupModel {
    attrLookup: LookUpModel[];
  }


  export interface PayRecordModel {
    code: string;
    nextCode: string;
    diType: string;
    name: string;
    claimId: string;
    extraId: string;
    direction: string;
    codeType: string;
    nameType: string;
    stornoMode: string;
    isFine: string;
    isCalculate: string;
    isCommission: string;
    getPayListType: string;
    commission: string;
    picture: string;
    summa: string;
    min: string;
    max: string;
    nominal: string;
    edit: string;
    view: string;
    currency: string;
    fine: string;
    budget: string;
    payCommission: string;
    formula: string;
    searchAttrs: SearchAttrModel[];
    groupRecord: GroupRecordModel;
    attrLookups: AttrLookupModel;
    attrRecord: AttrRecordList[];
  }


  export interface AbonentModel {
    ls: string;
    els: string;
    fio: string;
    address: string;
  }


  export interface GetPayListResponseDto {
    errorCode: string;
    errorText: string;
    abonent: AbonentModel[];
    payRecord: PayRecordModel[];
  }


  export interface GetPayListFilterResponseDto {
    errorCode: string;
    errorText: string;
    filter: AttrRecordModel;
  }


  export interface TransferReceiptDto {
    id: string;
    trackingId: string;
    uid: string;
    payerCardLastFourNumbers: string;
    recipientCardLastFourNumbers: string;
    paidAt: string;
    amount: number;
    currency: string;
    bankFee: number;
    status: string;
    rrn: string;
    authCode: string;
    redirectUrl: string;
  }


  export interface AvailableActionsResponseDto {
    payCodeEnum: string[];
  }


  export interface GetLimitsRequestDto {
    diTypeCode: string;
    payCodeEnum: string;
  }


  export interface Limits {
    min: string;
    max: string;
  }


  export interface TransferLimitsResponseDto {
    amountLimits: Limits;
    lengthLimits: Limits;
  }


  export interface AutoPaymentDto {
    id: string;
    name: string;
    payCodeEnum: string;
    paymentId: string;
    phoneNumber: string;
    cardPan: string;
    expiry: string;
    summa: string;
    currency: string;
    holderFio: string;
    date: number;
    autoPay: boolean;
    templateSortIndex: number;
    autoPaySortIndex: number;
    autoPayId: string;
    payPeriodType: string;
    dateStart: number;
    dateEnd: number;
    claimSummPriority: string;
    isLocked: boolean;
  }


  export interface PaymentTemplateDto {
    id: string;
    name: string;
    payCodeEnum: string;
    paymentId: string;
    phoneNumber: string;
    cardPan: string;
    expiry: string;
    summa: string;
    currency: string;
    holderFio: string;
    date: number;
    autoPay: boolean;
    templateSortIndex: number;
    autoPaySortIndex: number;
  }


  export interface ListPaymentTemplateRespDto {
    autoPaymentDtos: PaymentTemplateDto[];
    paymentTemplateDtos: AutoPaymentDto[];
  }


  export interface CreatePaymentTemplateRequestDto {
    id?: string;
    name?: string;
    payCodeEnum?: string;
    paymentId?: string;
    phoneNumber?: string;
    cardPan?: string;
    holderFio?: string;
    expiry?: string;
    summa?: string;
    currency?: string;
    date?: number | string;
    autoPay?: boolean;
    claimSummPriority?: string;
    payPeriodType?: string;
    dateStartTimestamp?: number;
    dateEndTimestamp?: number;
    templateSortIndex?: number;
    autoPaySortIndex?: number;
  }


  export interface PaymentTemplateDtos {
    id?: string;
    name?: string;
    payCodeEnum?: string;
    paymentId?: string;
    phoneNumber?: string;
    cardPan?: string;
    summa?: string;
    currency?: string;
    date?: number;
    autoPay?: boolean;
    templateSortIndex?: number;
    autoPaySortIndex?: boolean;
  }


  export interface RemapCheckHistory {
    summa: any;
    currency: string;
    date: number;
    paymentId: string;
    payCodeEnum?: string;
    name?: string;
  }


  export interface UpdatePaymentTemplateDto {
    paymentTemplateDtos: PaymentTemplateDtos[];
  }


  export interface SourceOperationHistory {
    sourceID?: string;
    type?: string;
  }


  export interface AttrRecordOperationHistory {
    code?: string;
    codeOut?: string;
    name?: string;
    type?: string;
    minLength?: string;
    maxLength?: string;
    min?: string;
    max?: string;
    mandatory?: string;
    edit?: string;
    print?: string;
    value?: string;
    mask?: string;
    search?: string;
    formula?: string;
    view?: string;
    hint?: string;
    warning?: string;
    format?: string;
    inputFormat?: string;
    change?: string;
    pwd?: string;
    lookup?: string;
  }


  export interface FilterAttrRecordOperationHistory {
    attrRecord?: AttrRecordOperationHistory[];
  }


  export interface GetOperationHistoryRequestDto {
    paymentId?: string;
    clientKind?: string;
    sourceId?: SourceOperationHistory;
    filter?: FilterAttrRecordOperationHistory;
  }


  export interface LoanFormRs {
    applicationCode: string;
    fields: LoanFormRsFields[];
  }


  export interface LoanDocumentRs {
    filename: string;
    document: string;
  }


  export interface LoanCheckInfo {
    loanName: string;
    loanAmount: string;
    loanMaxPayment: string;
    loanDuration: string;
    loanPercent: string;
  }


  export interface LoanApplicationFill {
    [key: string]: string | boolean;
  }


  export interface LoanConditionsData {
    withdrawalMethod?: WithdrawalMethod;
    contractNumber: string;
  }


  export interface LoanFormRsFieldsEnumValues {
    value: string;
    description: string;
  }


  export interface LoanFormRsFields {
    path: string;
    name: string;
    type: LoanItemType;
    value?: string;
    enumValues: LoanFormRsFieldsEnumValues[];
    requiredIf: LoanFormRsRequiredIf;
    required: boolean;
    hint: string;
    order: string;
    regex: string;
    control?: FormControl<string>;
    selectedValue?: string;
    validate?: boolean;
  }


  export enum LoanFormConditionOperator {
    LOGICAL_OR = '||',
    EQUALS = '==',
  }


  export interface LoanFormRsRequiredIf {
    conditions: LoanFormCondition[];
    logicalRule: LoanFormConditionOperator;
  }


  export interface LoanFormCondition {
    enumType: string;
    comparisonRule: LoanFormConditionOperator;
    value: string;
  }


  export enum LoanItemType {
    ENUM = 'ENUM',
    BigDecimal = 'BigDecimal',
  }


  export interface LoanDocument {
    documentType: string;
    applicationCode?: string;
  }


  export interface LoanConsent {
    checked: boolean;
    document: any; // файл в формате base64
    fileName: string | undefined;
    actionNext: boolean;
  }


  export interface LoanPathValue {
    path: string;
    value: string;
  }


  export interface LoanVoid {
    applicationCode: string;
  }


  export interface CheckSignDocumentRq {
    applicationCode: string;
  }


  export interface CheckSignDocumentRs {

  }


  export interface SignFinalDocumentsRq {
    applicationCode: string;
    confirmationData?: SignFinalDocumentsConfirmationData;
  }


  export interface SignFinalDocumentsConfirmationData {
    smsCode?: string;
    eventId?: string;
    code?: string;
  }


  export interface SignFinalDocumentsRs {

  }


  export interface LoanFilledForm extends ConfirmAction {
    applicationCode: string;
    consentsAccepted: boolean;
    formData: LoanPathValue[];
  }


  export interface LoanForm {
    productCode: string;
    duration: number;
    currency: Currency;
    principal: number;
    type?: string;
    selPlaceId?: string;
    pointId?: string | number;
  }


  export interface AttrRecordRespOperationHistory {
    code: string;
    codeOut: string;
    name: string;
    type: string;
    minLength: string;
    maxLength: string;
    min: string;
    max: string;
    mandatory: string;
    edit: string;
    print: string;
    value: string;
    mask: string;
    search: string;
    formula: string;
    view: string;
    hint: string;
    warning: string;
    format: string;
    inputFormat: string;
    change: string;
    pwd: string;
    lookup: string;
  }


  export interface GetOperationHistoryResponseDto {
    paymentId: string;
    diType: string;
    payCode: string;
    payName: string;
    paymentDate: number;
    amount: number;
    currency: string;
    operationLocation: string;
    rrn: string;
    authCode: string;
    commission: number;
    commissionCurrency: string;
    operationStatus: string;
    attrRecords: AttrRecordRespOperationHistory[];
  }


  export interface LoanProduct {
    code: string;
    title: string;
    minAmount: number;
    maxAmount: number;
    minTerm: number;
    maxTerm: number;
    interestRateExpression: string;
    interestRateValue: number;
    productType: string;
    paymentType: string;
    currency: string;
    descriptionOnline: LoanProductDescriptionOnline;
    payCard: boolean;
    productCode: string;
    length: number;
    principal: number;
    withdrawalMethod: WithdrawalMethod;
    offers: Offers[];
  }


  export interface LoanProductDescriptionOnline {
    titleRu: string;
    titleBy: string;
    titleEn: string;
    name: string;
    nameBy: string;
    nameEn: string;
    description: string;
    descriptionBy: string;
    descriptionEn: string;
    url: string;
    imageUrl: string;
    imageUrlPreview: string;
    imageUrlWeb: string;
    commonCreditConditionUrl: string;
  }


  export interface WithdrawalMethod {
    cash: boolean;
    card: boolean;
    account: boolean;
    partnerAccount: boolean;
  }


  export interface Offers {
    id: string;
    maxTerm: number;
    maxAmount: number;
    minTerm: number;
    minAmount: number;
    title: string;
    confirmIncomeRequired: boolean;
    procuringRequired: boolean;
    offerKey: string;
    maxMonthlyPayment: number;
  }


  export interface PreCondition {
    productCode: string;
    length: number;
    principal: number;
  }


  export interface Offer {
    products: LoanProduct[];
    preCondition: PreCondition;
    contractNumbers: string[];
  }


  export interface PaymentScheduleRq {
    applicationCode: string,
    offerId: string,
    securedOfferKey: string,
    principal: number,
    length: number,
    currency: string
  }


  export interface GetPaymentScheduleResponse {
    date: string;
    dateTimestamp: number;
    principal: number;
    interest: number;
    remainingPrincipal: number;
  }


  export interface PaymentSchedule {
    getPaymentScheduleResponses: GetPaymentScheduleResponse[];
  }


  export interface FixOfferRq {
    selectedCode?: string;
    applicationCode: string,
    offerId: string,
    securedOfferKey: string,
    principal: number,
    length: number,
    currency: string,
    contractNumber?: string
  }


  export interface FixOfferRs {

  }


  export interface ApplicationCodeRq {
    applicationCode: string;
  }


  export interface CreateAutoPaymentRequestDto {
    name?: string;
    payCodeEnum?: string;
    paymentId?: string;
    phoneNumber?: string;
    cardPan?: string;
    expiry?: string;
    summa?: string;
    currency?: string;
    holderFio?: string;
    date?: number;
    autoPay?: boolean;
    templateSortIndex?: number;
    autoPaySortIndex?: number;
    claimSummPriority?: string;
    payPeriodType?: string;
    dateStartTimestamp?: number;
    dateEndTimestamp?: number;
  }


  export interface UpdateAutoPaymentRequestDto {
    autoPayId?: string;
    name?: string;
    claimSummPriority?: string;
    isLocked?: boolean;
    summa?: number;
    authorizationParams?: AuthorizationParamsAutoPay;
    payPeriod?: PayPeriodAutoPay;
    attrRecords?: AttrRecordAutoPay[];
    sourceProduct?: string;
  }


  export interface UpdateAutoPayPan {
    pan?: string;
    expiry?: string;
    cardTrack2?: string;
    cardPinBlock?: string;
    extraRs?: string;
    authSource?: string;
  }


  export interface AuthorizationParamsAutoPay {
    pan?: UpdateAutoPayPan,
    typePAN?: string;
  }


  export interface PayPeriodAutoPay {
    payPeriodType?: string;
    dateStart?: string;
    dateEnd?: string;
    dateStartTimestamp?: number;
    dateEndTimestamp?: number;
  }


  export interface AttrRecordAutoPay {
    code?: string;
    codeOut?: string;
    name?: string;
    type?: string;
    minLength?: string;
    maxLength?: string;
    min?: string;
    max?: string;
    mandatory?: string;
    edit?: string;
    print?: string;
    value?: string;
    mask?: string;
    search?: string;
    formula?: string;
    view?: string;
    hint?: string;
    warning?: string;
    format?: string;
    inputFormat?: string;
    change?: string;
    pwd?: string;
    lookup?: string;
  }


  export interface ApplicationResponse {
    applicationNumber: number;
    clientFullName: string;
    clientPhoneNumber: string;
    creditAmount: number;
    initialFeeAmount: number;
    deliveryPrice: number;
    goodsAmount: number;
    totalAmount: number;
    loanProduct: string;
    status: string;
    comment: string;
    requestValidityPeriod: number;
    createdBy: string;
    updatedBy: string;
    createdDate: number;
    updatedDate: number;
    sentDate: number;
    expiredDate: number;
    goods: Good[];
    clientInterestRate: number;
    partnerName: string;
    partnerPhoneNumber: string;
    currency: Currency;
    loanPeriod: number;
    loanPeriodUnit: keyof typeof PaymentsEnum.PaymentPeriodUnit;
    monthLoanPeriod: string;
  }


  export interface Good {
    id: string;
    goodsName: string;
    description: string;
    imageUrl: string;
    goodsUrl: string;
    amountPerItem: number;
    quantity: number;
    createdDate: number;
    updatedDate: number;
  }


  export interface ReviewApplicationRq {
    applicationNumber: number | string;
  }


  export interface VoidApplicationRq {
    applicationNumber: number;
  }


  export interface VoidApplicationRs {
    status: string;
  }
}
