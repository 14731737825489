import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { SettingsService } from './models/settings/services/settings.service';
import { VERSION } from '../environments/version';


@Component({
  selector: 'app-root',
  standalone: true,
  imports: [ CommonModule, RouterOutlet ],
  template: '<router-outlet></router-outlet>'
})
export class AppComponent implements OnInit {

  constructor(
    private readonly settingsService: SettingsService,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2) {

    this.settingsService.startThemeListener();
    this.settingsService.startLangListener();
  }


  public ngOnInit(): void {
    const srcScript = this.renderer2.createElement('script');
    srcScript.type = 'text/javascript';
    // удалить для прода
    srcScript.text = `
      (function() {
        window['l'+'ive'+'Tex'] = true,
        window['live'+'Tex'+'ID'] = 56,
        window['li'+'veTe'+'x_obje'+'ct'] = true;
        (function() {
        var t = document['c'+'reat'+'eEl'+'eme'+'nt']('script');
        t.type ='text/javascript';
        t.async = true;
        t.src = '/'+'/widgets-loader.chat.paritetbank.by/js/'+'client.js';
        var c = document['get'+'Elemen'+'tsByTa'+'gNa'+'me']('script')[0];
        if ( c ) c['paren'+'tN'+'ode']['in'+'sert'+'Before'](t, c);
        else document['d'+'ocumen'+'tElem'+'en'+'t']['fir'+'st'+'C'+'hild']['ap'+'pe'+'n'+'dC'+'h'+'i'+'l'+'d'](t);
        })();
      }());
    `;

    //заменить для прода
    // srcScript.text = `
    //   (function() {
    //     window['l'+'ive'+'Tex'] = true,
    //     window['live'+'Tex'+'ID'] = 56,
    //     window['li'+'veTe'+'x_obje'+'ct'] = true;
    //     (function() {
    //     var t = document['c'+'reat'+'eEl'+'eme'+'nt']('script');
    //     t.type ='text/javascript';
    //     t.async = true;
    //     t.src = '/'+'/widgets-loader.chat.paritetbank.by/js/'+'client.js';
    //     var c = document['get'+'Elemen'+'tsByTa'+'gNa'+'me']('script')[0];
    //     if ( c ) c['paren'+'tN'+'ode']['in'+'sert'+'Before'](t, c);
    //     else document['d'+'ocumen'+'tElem'+'en'+'t']['fir'+'st'+'C'+'hild']['ap'+'pe'+'n'+'dC'+'h'+'i'+'l'+'d'](t);
    //     })();
    //   }());
    // `;
    this.renderer2.appendChild(this.document.body, srcScript);
    console.log(`Application version is: version (from package.json)=${VERSION.version}, git-tag=${VERSION.tag}, git-hash=${VERSION.hash}`);
  }
}
