export enum ValidatorToken {
  Email = 'email',
  Pattern = 'pattern',
  Required = 'required',
  MinLength = 'minlength',
  MaxLength = 'maxlength',
  Limit = 'limit',
  MinLimit = 'minLimit',
  Max = 'max',
  Min = 'min',
  MaxLimit = 'maxLimit',
  IncorrectValue = 'incorrectValue',
  PasswordMismatch = 'passwordMismatch',
  NotOwnBankCardNumber = 'notOwnBankCardNumber',
  CustomFormat = 'customFormat',
  CustomCardFormat = 'customCardFormat'
}

export enum ListenerKeys {
  BACKSPACE = 'Backspace',
  DELETE = 'Delete'
}

export enum ValidationErrorMessageText {
  MANDATORY_FIELD = 'REQUIRED_FIELD',
  NOT_PERMIT_AGREEMENT = 'DONT_AGREE_OFFER',
  ENTER_CORRECT_DATA = 'DONT_CORRECT_VALUE',
  ENTER_CORRECT_FORMAT_DATA = 'SELECT_NUMBER_IN_FORMAT',
  ENTER_PARITET_CARD_NUMBER = 'ENTER_PARITETBANK_CARD_NUMBER',
  MAXIMUM_AMOUNT = 'MAX_SUM',
  MINIMUM_AMOUNT = 'MIN_SUM',
  MAX_LOAN_TERM = 'MAX_LOAN_TERM',
  MIN_LOAN_TERM = 'MIN_LOAN_TERM',
  INCORRECT_INPUT = 'ENTER_CORRECT_DATA',
  MAX_LENGTH = 'MAX_INPUT_SYMBOL_LENGTH',
  MIN_LENGTH = 'MIN_INPUT_SYMBOL_LENGTH',
  INCORRECT_DATA = 'INCORRECT_DATA'
}
