import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiConfig } from '../../../config/api.config';
import {
  CodeRequest,
  CodeResponse,
  ConfirmRequest,
  ConfirmResponse,
  ConsentRequest,
  IdentifyCardRequest,
  IdentifyPersonalRequest,
  IdentifyResponse,
  LoginConsentResponse,
  LoginRequest,
  LoginResponse, RegistrationCheckLogin,
  TemporalCodeRq,
  TemporalConfirmRequest,
  TemporalConsentRequest
} from '../../../interfaces/auth.interface';
import { Observable } from 'rxjs';
import { HttpError } from '../../../interfaces/http.interface';


@Injectable({
  providedIn: 'root'
})
export class AuthResource {

  constructor(
    private readonly http: HttpClient) {
  }


  public identify(payload: IdentifyPersonalRequest | IdentifyCardRequest) {
    return this.http.post<IdentifyResponse>(ApiConfig.REGISTRATION_IDENTITY, payload);
  }


  public consent(payload: ConsentRequest) {
    return this.http.post<unknown>(ApiConfig.REGISTRATION_CONSENT, payload);
  }


  public code(payload: CodeRequest) {
    return this.http.post<CodeResponse>(ApiConfig.REGISTRATION_CODE, payload);
  }


  public confirm(payload: ConfirmRequest) {
    return this.http.post<ConfirmResponse>(ApiConfig.REGISTRATION_CONFIRM, payload);
  }


  public login(payload: LoginRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(ApiConfig.AUTH_LOGIN, payload);
  }


  public logout() {
    return this.http.get<any>(ApiConfig.AUTH_LOGOUT);
  }


  public temporalConfirm(payload: TemporalConfirmRequest): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(ApiConfig.TEMPORAL_CONFIRM, payload);
  }


  public temporalConsent(payload: TemporalConsentRequest): Observable<LoginConsentResponse> {
    return this.http.post<LoginConsentResponse>(ApiConfig.TEMPORAL_CONSENT, payload);
  }


  public temporalCode(payload: TemporalCodeRq): Observable<CodeResponse> {
    return this.http.post<CodeResponse>(ApiConfig.TEMPORAL_CODE, payload);
  }


  public registrationCheckLogin(payload: RegistrationCheckLogin): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.REGISTRATION_CHECK_LOGIN, payload);
  }
}
