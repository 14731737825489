import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs';
import { IEripNavigationItem } from '../../../interfaces/erip-navigation.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import { ConfirmationUtils } from '../../../utils/confirmation.utils';
import { EripService } from '../services/erip.service';
import { EripActions } from './erip.actions';


interface EripStateModel {
  eripPayments: IEripNavigationItem[];
  eripPaymentsGplf: PaymentInterface.GetPayListFilterResponseDto;
  transferResponse: PaymentInterface.TransferResponseDto;
}


@State<EripStateModel>({
  name: 'erip'
})

@Injectable()
export class EripState {

  constructor(private readonly eripService: EripService) {
  }


  @Selector()
  public static selectEripPayments(state: EripStateModel) {
    return state.eripPayments || [];
  }


  @Selector()
  public static selectTransferResponse(state: EripStateModel) {
    return state.transferResponse;
  }


  @Selector()
  public static selectAdvancedEripPayment(state: EripStateModel) {
    const { attrRecord } = state.eripPaymentsGplf.filter;

    return attrRecord;
  }


  @Action(EripActions.GetEripPayments)
  public getEripPayments(ctx: StateContext<EripStateModel>, { body }: EripActions.GetEripPayments) {
    return this.eripService.getEripPayments(body).pipe(
      map(({ payRecord }) => {
        ctx.patchState({
          eripPayments: payRecord
        });
      })
    );
  }


  @Action(EripActions.GetEripPaymentsGplf)
  public getEripPaymentsGmpl(ctx: StateContext<EripStateModel>, { body }: EripActions.GetEripPaymentsGplf) {
    return this.eripService.getEripPaymentsGmpl(body).pipe(
      map(payments => {
        ctx.patchState({
          eripPaymentsGplf: payments
        });
      })
    );
  }


  @Action(EripActions.GetEripPaymentsRoc)
  public getEripPaymentsRoc(ctx: StateContext<EripStateModel>, { body }: EripActions.GetEripPaymentsRoc) {
    return this.eripService.getEripPaymentsRoc(body, ConfirmationUtils.addHeaderConfirmationData(body)).pipe(
      map(payments => {
        ctx.patchState({
          transferResponse: payments
        });
      })
    );
  }


  @Action(EripActions.GetByRequisitesGpl)
  public byRequisitesGpl(ctx: StateContext<EripStateModel>, { body }: EripActions.GetByRequisitesGpl) {
    return this.eripService.byRequisitesGpl(body).pipe(
      map(({ payRecord }) => {
        ctx.patchState({
          eripPayments: payRecord
        });
      })
    );
  }


  @Action(EripActions.GetByRequisitesRoc)
  public byRequisitesRoc(ctx: StateContext<EripStateModel>, { body }: EripActions.GetByRequisitesRoc) {
    return this.eripService.byRequisitesRoc(body, ConfirmationUtils.addHeaderConfirmationData(body)).pipe(
      map(payments => {
        ctx.patchState({
          transferResponse: payments
        });
      })
    );
  }
}
