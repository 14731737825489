import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { AmountByCreditRequestDto, AmountCurrentPaymentByCreditRespDto } from '../../../interfaces/credit.interface';


@Injectable({
  providedIn: 'root'
})
export class CreditResource {


  constructor(
    private readonly http: HttpClient) {
  }


  public getAmountCurrentPaymentByCredit(requestData: AmountByCreditRequestDto): Observable<AmountCurrentPaymentByCreditRespDto> {
    return this.http.post<AmountCurrentPaymentByCreditRespDto>(ApiConfig.CURRENT_PAYMENT_BY_CREDIT, requestData);
  }
}
