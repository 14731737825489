import { DepositInterface } from '../../../interfaces/deposit.interface';


export namespace DepositActions {
  export class DepositCloseInfo {
    public static readonly type = '[Deposit] DepositCloseInfo';


    constructor(public readonly requestData: DepositInterface.DepositInfoRequestDto) {}
  }


  export class DepositTopUpInfo {
    public static readonly type = '[Deposit] DepositTopUpInfo';


    constructor(public readonly requestData: DepositInterface.DepositInfoRequestDto) {}
  }


  export class DepositWithdrawalInfo {
    public static readonly type = '[Deposit] DepositWithdrawalInfo';


    constructor(public readonly requestData: DepositInterface.DepositInfoRequestDto) {}
  }


  export class DepositSourcingInfo {
    public static readonly type = '[Deposit] DepositSourcingInfo';


    constructor(public readonly requestData: DepositInterface.DepositSourcingInfoRequestDto) {}
  }


  export class GetDepositSourcingInfo {
    public static readonly type = '[Deposit] GetDepositSourcingInfo';
  }
}
