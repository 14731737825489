import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  public setValue(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public getValue(key: string): any {
    const value: string | null = sessionStorage.getItem(key);
    return value && !(value === 'undefined') ? JSON.parse(value) : null;
  }

  public removeValue(key: string): void {
    sessionStorage.removeItem(key);
  }
}
