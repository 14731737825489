import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlValue } from '../../../../../interfaces/control.interface';
import { InputComponent } from '../../impl/input.component';


@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaComponent
    }
  ]
})
export class TextareaComponent extends InputComponent implements OnInit {

  public isFocus!: boolean;

  public showClear!: boolean;

  public formControl!: FormControl<string>;

  @Input()
  public override id!: string | number;

  @Input()
  public override disabled!: boolean;

  @Input()
  public dropMode!: boolean;

  @Input()
  public dropDirection: 'left' | 'right' | 'up' | 'down' = 'right';

  @Input()
  public readOnly!: boolean;

  @Input()
  public invisible: boolean = false;

  @Input()
  public skipClickEvent: boolean = false;

  @Input()
  public isSelect: boolean = false;

  @Input()
  public tooltipInfo?: string;

  @Input()
  public width!: string;

  @Input()
  public withClear!: boolean;

  @Input()
  public selectOpened: boolean = false;

  @Input()
  public label!: string | null;

  @Input()
  public placeholder: string = '';

  @Input()
  public inlineControl: boolean = false;

  @Input()
  public invalid!: boolean;

  @Input()
  public formControlCustom: FormControl<string> | undefined | AbstractControl<string>;

  @Output()
  public focused: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  public keyUp: EventEmitter<KeyboardEvent> = new EventEmitter<KeyboardEvent>();

  @Output()
  public clear = new EventEmitter();

  @ViewChild('textAreaElement') textAreaElement!: ElementRef;


  override get value(): FormControlValue {
    return this._value;
  }


  @Input()
  override set value(val: FormControlValue) {
    this._value = val;
    this.showClear = !!val;

    this.onChange(this._value);
  }


  constructor(private eRef: ElementRef) {
    super();
  }


  public ngOnInit(): void {
    this.initControl();
  }


  @HostListener('document:click', [ '$event' ])
  public onClick(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isFocus = false;
    }
  }


  public onInputFocus(): void {
    this.isFocus = true;
    this.focused.emit(true);
  }


  public onBlur(): void {
    const elementValue = this.textAreaElement.nativeElement.value;

    if (this.value !== elementValue) {
      this.value = this.textAreaElement.nativeElement.value;
      this.formControl.setValue(this.textAreaElement.nativeElement.value);
    }

    this.focused.emit(false);
  }


  public onClear(): void {
    this.value = null;

    this.clear.emit();
  }


  public showTooltip(): string {
    return this.tooltipInfo!;
  }

  public onClickControl(event: Event): void {
    if (this.skipClickEvent) return;

    const inputElement: HTMLInputElement = document.getElementById(this.id.toString()) as HTMLInputElement;

    this.isFocus = true;

    if (inputElement) inputElement.focus();

    if (!this.isSelect) event.stopPropagation();
  }


  public onKeyUp(event: KeyboardEvent): void {
    this.keyUp.emit(event);
  }


  private updateValue(inputElement: HTMLInputElement, value: string, cursorPosition: number): void {
    setTimeout((): void => {
      inputElement.value = value;
      inputElement.setSelectionRange(cursorPosition, cursorPosition);
    });
  }


  private initControl(): void {
    if (!this.formControlCustom) {
      this.formControl = new FormControl<string>('', { nonNullable: true });
    } else {
      this.formControl = this.formControlCustom as FormControl;
      this.value = this.formControl.value;
    }
  }
}
