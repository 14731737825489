import { ProductsEnum } from '../enums/products.enum';
import {
  GetCardBalanceDtoRq,
  GetCardBalanceDtoRs,
  ProductRequestDto,
  SetSortIndexRequestDto,
  SortIndexes
} from '../interfaces/product-liability.interface';
import { Account, Card, ProductMofification, Products } from '../interfaces/products.interface';
import { ProductCustomizeNameDto } from '../interfaces/settings.interface';
import { TransferType } from '../interfaces/transfer.interface';
import { CustomizeUtils } from './customize.utils';


export namespace ProductActionsUtils {

  export function updateProductData(
    products: Products,
    productType: ProductRequestDto | ProductCustomizeNameDto | GetCardBalanceDtoRq,
    cardBalance?: GetCardBalanceDtoRs
  ) {
    const productTypes = ProductsEnum.ProductTitles;
    const account = getActualProduct(products, productType);
    let mapProduct: any;

    const field = getProductObjField(productType);

    if (productType.product === productTypes.CARDS) {
      mapProduct = mapProductCardData(account, productType, cardBalance!, field);
    } else {
      mapProduct = mapProductAccount(account, productType, field);
    }

    return mapProduct;
  }


  export function prePareSortIdxData(product: Products, productType: ProductRequestDto): SetSortIndexRequestDto {
    const actualProduct = getActualProduct(product, productType);
    const sortIndexes: SortIndexes[] = [];

    if (productType.product === ProductsEnum.ProductTitles.CARDS) {
      actualProduct.product.forEach((account: Account) => {
        account.cards.forEach((card: Card) => {
          sortIndexes.push({
            productId: card.cardHash,
            sortIndex: card.sortIndex
          });
        });
      });
    } else {
      actualProduct.product.forEach((account: Account) => {
        sortIndexes.push({
          productId: account.contractNumber,
          sortIndex: account.sortIndex
        });
      });
    }

    return { sortIndexes };
  }

  export function updateProductWithModifications(
    products: Products,
    modifications: ProductMofification,
  ) {
    if (modifications.cardHash) {
      return {
        ...products,
        cardAccount: updateCard(products, modifications.cardHash, modifications)
      }
    } else if (modifications.accountHash) {
      return  {
        ...products,
        currentAccount: updateAccount(products, modifications.accountHash, modifications)
      }
    } else return products;
  }


  export function updateCard(
    products: Products,
    cardHash: string,
    modifications: ProductMofification
  ) {
    const cardAccount = products.cardAccount;
    delete modifications.cardHash;
    delete modifications.accountHash;
    if (products.cardAccount)
      return [...products.cardAccount.map((account) => {
        return {
          ...account,
          cards: account.cards.map((card) => {
            if (cardHash === card.cardHash) {
              return {
                ...card,
                ...modifications
              }
            } else  return card;
          })
        };
      })];
    else return cardAccount;
  }

  export function updateAccount(
    products: Products,
    accountHash: string,
    modifications: ProductMofification
  ) {
    const currentAccount = products.currentAccount;
    delete modifications.cardHash;
    delete modifications.accountHash;
    if (products.currentAccount)
      return [...products.currentAccount.map((account) => {
        if (accountHash === account.contractNumberHash) {
          return {
            ...account,
            ...modifications
          };
        } else {
          return account;
        }
      })];
    else return currentAccount;
  }



  export function getProductObjField(productType: ProductRequestDto | ProductCustomizeNameDto | GetCardBalanceDtoRq) {
    const productFields = {
      [ProductsEnum.AccountKeys.DISPLAY_BALANCE]: { field: ProductsEnum.AccountKeys.DISPLAY_BALANCE },
      [ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT]: { field: ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT },
      [ProductsEnum.AccountKeys.IS_UNMAIN_PAY_PRODUCT]: { field: ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT },
      [ProductsEnum.AccountKeys.CHANGE_NAME]: { field: ProductsEnum.AccountKeys.CHANGE_NAME },
      [ProductsEnum.AccountKeys.CHANGE_PRODUCT_NAME]: { field: ProductsEnum.AccountKeys.CHANGE_PRODUCT_NAME },
      [ProductsEnum.AccountKeys.DISPLAY_ON_MAIN]: { field: ProductsEnum.AccountKeys.DISPLAY_ON_MAIN },
      [ProductsEnum.AccountKeys.BALANCE]: { field: ProductsEnum.AccountKeys.BALANCE }
    };

    // @ts-ignore
    const { field } = productFields[productType.action];

    return field;
  }


  export function mapProductCardData(
    account: { product: Account[], key: string },
    productType: ProductRequestDto | ProductCustomizeNameDto | GetCardBalanceDtoRq,
    cardBalance: GetCardBalanceDtoRs,
    field: string
  ): Account[] {
    return CustomizeUtils.sortingCards(account.product.map((account: Account) => {
      return {
        ...account,
        cards: account.cards.map((card: Card) => {
          if (
            card.cardHash === (productType as ProductRequestDto | ProductCustomizeNameDto).productId ||
            card.cardHash === (productType as GetCardBalanceDtoRq).cardHash
          ) {
            return {
              ...card,
              [field]:
                productType.action === ProductsEnum.AccountKeys.CHANGE_NAME ||
                productType.action === ProductsEnum.AccountKeys.CHANGE_PRODUCT_NAME
                  ? (productType as ProductCustomizeNameDto).name :
                  productType.action === ProductsEnum.AccountKeys.BALANCE
                    ? cardBalance?.balance :
                    productType.action === ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT
                      ? !(productType as ProductRequestDto).defaultAction :
                      productType.action === ProductsEnum.AccountKeys.IS_UNMAIN_PAY_PRODUCT
                        ? false
                        : (productType as ProductRequestDto).visible!,
              sortIndex: card.sortIndex
            };
          } else {
            return productType.action === ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT ?
              { ...card, isMainPayProduct: false } : { ...card };
          }
        })
      };
    }));
  }


  export function mapProductAccount(
    account: { product: Account[], key: string },
    productType: ProductRequestDto | ProductCustomizeNameDto | GetCardBalanceDtoRq,
    field: string
  ): Account[] {
    return CustomizeUtils.sortingOtherProducts(account.product.map((account: Account) => {
      if (
        account.contractNumber === (productType as ProductRequestDto | ProductCustomizeNameDto).productId ||
        account.contractNumber === (productType as GetCardBalanceDtoRq).cardHash
      ) {
        return {
          ...account,
          [field]:
            productType.action === ProductsEnum.AccountKeys.CHANGE_NAME
            || productType.action === ProductsEnum.AccountKeys.CHANGE_PRODUCT_NAME
              ? (productType as ProductCustomizeNameDto).name :
              productType.action === ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT
                ? !(productType as ProductRequestDto).defaultAction :
                productType.action === ProductsEnum.AccountKeys.IS_UNMAIN_PAY_PRODUCT
                  ? false
                  : (productType as ProductRequestDto).visible!,
          sortIndex: account.sortIndex
        };
      } else {
        return productType.action === ProductsEnum.AccountKeys.IS_MAIN_PAY_PRODUCT ?
          { ...account, isMainPayProduct: false } : { ...account };
      }
    }) as Account[]);
  }


  export function getActualProduct(
    products: Products,
    productType: ProductRequestDto | ProductCustomizeNameDto | GetCardBalanceDtoRq
  ) {
    let product: Account[];
    let productKey: string;
    const productTypes = ProductsEnum.ProductTitles;

    switch (productType.product) {
      case productTypes.CARDS:
        product = products.cardAccount!;
        productKey = ProductsEnum.ProductActionKeys.CARD_ACCOUNT;
        break;
      case productTypes.DEPOSITS:
        product = products.depositAccount!;
        productKey = ProductsEnum.ProductActionKeys.DEPOSIT_ACCOUNT;
        break;
      case productTypes.ACCOUNTS:
        product = products.currentAccount!;
        productKey = ProductsEnum.ProductActionKeys.CURRENT_ACCOUNT;
        break;
      case productTypes.CREDITS:
        product = products.creditAccount!;
        productKey = ProductsEnum.ProductActionKeys.CREDIT_ACCOUNT;
        break;
      case productTypes.OVERDRAFT:
        product = products.overdraftAccount!;
        productKey = ProductsEnum.ProductActionKeys.OVERDRAFT_ACCOUNT;
        break;
    }

    return { product: product!, key: productKey! };
  }


  export function loanTransferType(transferType: string | null) {
    if (transferType)
      switch (transferType) {
        case TransferType.LoanRePayment:
          return TransferType.LoanRePayment
        case TransferType.LoanPayment:
          return TransferType.LoanPayment
      }
    return TransferType.LoanMenu
  }
}
