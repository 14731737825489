import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import {
  CurrencyRateUtilDto,
  ExchangeRatesListRequestDto, ExchangeRatesPairRequestDto, PairExchangeRateResponseDto
} from '../../../interfaces/exchange-rates-list-dto.interface';
import { BlockCustomizeUnits } from '../../../interfaces/settings.interface';
import {
  CardActionConfirmDto,
  CardActionDto,
  CardBlockDto,
  CardLimitDto,
  GetLimitsRs
} from '../../../interfaces/products.interface';
import { HttpError } from '../../../interfaces/http.interface';
import {
  BankDivisionsDto,
  BankExchangeRateDto,
  GetBranchesRs,
  InfoCountriesRs, OrderCallRequestDto
} from '../../../interfaces/questionnaire.interface';
import { DivisionType } from '../../../enums/questionnaire.enum';


@Injectable({
  providedIn: 'root'
})
export class ServiceResource {


  constructor(
    private readonly http: HttpClient) {
  }


  public getExchangeRatesAll(requestData: ExchangeRatesListRequestDto): Observable<CurrencyRateUtilDto> {
    return this.http.post<CurrencyRateUtilDto>(ApiConfig.EXCHANGE_RATES_ALL, requestData);
  }


  public getExchangeRatesPair(requestData: ExchangeRatesPairRequestDto): Observable<PairExchangeRateResponseDto> {
    return this.http.post<PairExchangeRateResponseDto>(ApiConfig.EXCHANGE_RATES_PAIR, requestData);
  }


  public getCustomizeByUser(): Observable<{ blockCustomizeUnits: BlockCustomizeUnits[] }> {
    return this.http.get<{ blockCustomizeUnits: BlockCustomizeUnits[] }>(ApiConfig.BLOCK_CUSTOMIZE_BY_USER);
  }


  public createOrUpdateCustomize(requestData: { blockCustomizeUnits: BlockCustomizeUnits[] }): Observable<string> {
    return this.http.post<string>(ApiConfig.BLOCK_CUSTOMIZE_BULK, requestData);
  }


  public sendCardCvv(requestData: CardActionDto, headers: HttpHeaders): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.SEND_CARD_CVV, requestData, {headers});
  }


  public sendCardLimits(requestData: CardLimitDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.CARD_SET_LIMITS, requestData);
  }


  public receiveCardLimits(requestData: CardActionConfirmDto): Observable<GetLimitsRs> {
    return this.http.post<GetLimitsRs>(ApiConfig.CARD_GET_LIMITS, requestData);
  }


  public blockCard(requestData: CardBlockDto, headers: HttpHeaders): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.CARD_BLOCK, requestData, {headers: headers});
  }


  public sendSmsConfirmation(): Observable<HttpError> {
    return this.http.get<HttpError>(ApiConfig.SMS_SEND_CODE);
  }

  public sendInfoCountries(): Observable<InfoCountriesRs> {
    return this.http.get<InfoCountriesRs>(ApiConfig.INFO_COUNTIRES);
  }


  public getBankDivisions(divisionTypes?: DivisionType[]): Observable<BankDivisionsDto> {
    let params: HttpParams = new HttpParams().append('types', 'ATM');
    if (divisionTypes) {
      for (const divisionType of divisionTypes) {
        params = params.append('types', divisionType)
      }
    } else {
      params = params.set('types', 'EXCHANGEPOINT');
      params = params.append('types', 'OFFICE');
    }

    return this.http.get<BankDivisionsDto>(ApiConfig.INFO_BANK_DIVISION, { params });
  }


  public getBranches(): Observable<GetBranchesRs> {
    return this.http.get<GetBranchesRs>(ApiConfig.INFO_GET_BRANCHES);
  }


  public getATMS(): Observable<GetBranchesRs> {
    return this.http.get<GetBranchesRs>(ApiConfig.INFO_GET_ATMS);
  }



  public getATMDetail(id: string): Observable<GetBranchesRs> {
    return this.http.get<GetBranchesRs>(ApiConfig.INFO_GET_ATM_DETAIL.concat("/" + id));
  }


  public getBankDivisionById(id: string): Observable<BankExchangeRateDto> {
    return this.http.get<BankExchangeRateDto>(ApiConfig.EXCHANGE_RATES_BY_DIVISION + `${ id }`);
  }


  public getOrderCall(requestData: OrderCallRequestDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.ORDER_CALL, requestData);
  }
}
