import { Pipe, PipeTransform } from '@angular/core';
import { CardConfig } from '../../../../../config/card.config';
import { FormControlValue } from '../../../../../interfaces/control.interface';


@Pipe({
  name: 'cardPreviewSrc'
})
export class InputCardPipe implements PipeTransform {

  public transform(value: FormControlValue): string {
    const firstNumber = +(value as string)?.charAt(0);
    const iconItem = CardConfig.defaultIcons.find(
      i => i.type === firstNumber
    );
    return iconItem?.src || CardConfig.defaultIcons[0].src;
  }

}
