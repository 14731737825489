import { NgForOf, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MultiSelectIconComponent } from './multi-select-icon.component';


@NgModule({
  declarations: [ MultiSelectIconComponent ],
  imports: [
    NgForOf,
    NgIf,
    TranslateModule
  ],
  exports: [ MultiSelectIconComponent ]
})
export class MultiSelectIconModule {}
