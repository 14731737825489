import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input, OnChanges,
  OnInit,
  Output, SimpleChanges
} from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { pairwise, startWith } from 'rxjs';
import { FormControlValue } from '../../../../../interfaces/control.interface';
import { addCurrency, addZeros, removeCurrency, transformValue } from '../../../../../utils/amount.utils';
import { InputComponent } from '../../impl/input.component';
import { ValidationErrorMessageText, ValidatorToken } from '../../../../../enums/validation.enum';
import { NumberUtils } from '../../../../../utils/number.utils';


@Component({
  selector: 'app-amount-input',
  templateUrl: './input-amount.component.html',
  styleUrls: [ './input-amount.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputAmountComponent
    }
  ]
})
export class InputAmountComponent extends InputComponent implements OnChanges, OnInit {

  public isFocus!: boolean;

  public maxLength!: number;

  public separatorLength: number = 1;

  public currencyLength: number = 4;

  public currentAmountValue: string = '';

  @Input()
  public centered: boolean = false;

  @Input()
  public override id!: string | number;

  @Input()
  public override disabled!: boolean;

  @Input()
  public label!: string;

  @Input()
  public subText?: string;

  @Input()
  public display: 'inline' | 'block' = 'block';

  @Input()
  public mask!: string;

  @Input()
  public currency!: string;

  @Input()
  public invalid!: boolean;

  @Input()
  public placeholder: string = '';

  @Input()
  public readOnly!: boolean;

  @Input()
  public showInfoIcon!: boolean;

  @Input()
  public tooltipInfo?: string;

  @Input()
  public isAmountList: boolean = true;

  @Input()
  public isClickOnRepeat: boolean = false; // позволяет по клику на иконку производить событие кика на disabled поле

  @Input()
  public addList!: string[];

  @Input()
  public maxLengthLeftSide: number = 9;

  @Input()
  public inlineControl: boolean = false;

  @Input()
  public addClass: boolean = false;

  @Output()
  public updateAmount = new EventEmitter<FormControl>();

  @Output()
  public updateAmountValue = new EventEmitter<string>();

  @Output()
  public updateCurrency = new EventEmitter<FormControl>();

  @Output()
  public focused: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() public amountControl!: FormControl;

  @Input() public trueAmountControl?: FormControl; // TODO: пока не удаляю, но не понимаю зачем вообще его создавали?

  @Input()
  public isAmountInput!: boolean;

  @Input()
  public leaveEmptyField: boolean = false;

  @Output()
  public emitInfo: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  override set value(val: FormControlValue) {
    this._value = val;
    this.onChange(this._value);
  }

  private maxLengthRightSide = 2;

  private pattern = /[^0-9.,]/;


  override get value(): FormControlValue {
    return this._value;
  }


  constructor(
    private eRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService) {
    super();
  }


  @HostListener('document:click', ['$event'])
  public onClick(event: Event): void {
    if (!this.eRef.nativeElement.contains(event.target)) {
      this.isFocus = false;
    }
  }


  public ngOnChanges(changes: SimpleChanges): void {
    this.maxLength = this.amountControl && !this.amountControl.value.startsWith('0') ?
      this.maxLengthLeftSide + this.maxLengthRightSide + this.separatorLength + this.currencyLength : 8;
    // TODO: после потери фокуса получаем 0,00 BYN - 8 символов, сделал для того, чтобы после валюты нельзя было ничего писать

    if (this.amountControl && this.amountControl.value && !this.isAmountInput) {
      this.onAddZeroToRightSide();
    }
  }


  public ngOnInit(): void {
    // this.amountControl.valueChanges
    //   .subscribe((newValue: string): void => {
    //     if (newValue !== this.currentAmountValue) {
    //       this.currentAmountValue = removeCurrency(newValue, this.currency);
    //       this.updateAmount.emit(this.amountControl);
    //     }
    //   });

    if (this.amountControl) {
      this.amountControl.valueChanges
        .pipe(
          startWith(this.amountControl.value),
          pairwise()
        )
        .subscribe(([prevValue, currValue]) => {
          if (prevValue !== currValue && currValue && currValue.includes(this.currency)) {
            this.amountControl.setValue(currValue);
            this.updateAmount.emit(this.amountControl);
          } else if (prevValue !== currValue) {
            this.currentAmountValue = currValue;
            this.amountControl.setValue(transformValue(currValue, this.maxLengthLeftSide, this.pattern, prevValue));
            this.updateAmount.emit(this.amountControl);
          }
        });
    }
    if (this.isAmountInput) this.onAddZeroToRightSide();
  }


  public onInputFocus($event?: FocusEvent): void {
    this.isFocus = true;
    this.onClearEmptyValue();
    this.amountControl.setValue(removeCurrency(this.amountControl.value, this.currency))
    this.focused.emit(true);
  }


  public onAdd(item: string): void {
    let mapData: string;

    this.onInputFocus(); // TODO: в мобильной версии необходимо делать фокус в инпут для корректной работы добавления суммы

    if (!this.currentAmountValue) {
      mapData = transformValue(addZeros(item), this.maxLengthLeftSide, this.pattern);
    } else {
      const currentValue = parseFloat(this.currentAmountValue.replace(",", "."));
      const newValue = currentValue + parseFloat(item);
      mapData = transformValue(newValue.toFixed(2), this.maxLengthLeftSide, this.pattern).toString();
    }
    this.updateAmountValue.emit(mapData);
    this.amountControl.setValue(mapData, { emitEvent: false });
  }



  public onControlClick(event: Event): void {
    const inputElement = document.getElementById(this.id.toString()) as HTMLInputElement;

    if (inputElement) inputElement.focus();

    this.isFocus = true;
    event.stopPropagation();
  }


  public onBlur() {
    this.focused.emit(false)
  }


  public onAddZeroToRightSide(): void {
    if (this.leaveEmptyField) {
      if (this.amountControl.value)
        this.amountControl.setValue(addCurrency(addZeros(removeCurrency(this.amountControl.value, this.currency)), this.currency));
    } else {
      this.amountControl.setValue(addCurrency(addZeros(removeCurrency(this.amountControl.value, this.currency)), this.currency));
    }
    this.updateAmount.emit(this.amountControl);
  }


  public onClearEmptyValue(): void {
    const s = removeCurrency(this.amountControl.value, this.currency).replace(',', '.');
    if (parseFloat(s) == 0 || isNaN(parseFloat(s))) {
      this.amountControl.setValue('');
    }
  }


  public showInfo(): void {
    this.emitInfo.emit(true);
  }


  public showTooltip() {
    return this.tooltipInfo!;
  }


  public getError(control: FormControl<string | boolean>): any {
    const required = this.translateService.instant(ValidationErrorMessageText.MANDATORY_FIELD);
    const maxAmount = this.translateService.instant(ValidationErrorMessageText.MAXIMUM_AMOUNT);
    const minAmount = this.translateService.instant(ValidationErrorMessageText.MINIMUM_AMOUNT);
    const maxLength = this.translateService.instant(ValidationErrorMessageText.MAX_LENGTH);

    if (control.hasError(ValidatorToken.Required)) {
      return required;
    }
    if (control.hasError(ValidatorToken.Max) && control.errors) {
      return maxAmount.concat(' ')
        .concat(NumberUtils.formatSpacedAmount(control.errors[ValidatorToken.Max].max.toString()))
        .concat(' ').concat(this.currency);
    }
    if (control.hasError(ValidatorToken.Min) && control.errors) {
      return minAmount.concat(' ')
        .concat(NumberUtils.formatSpacedAmount(control.errors[ValidatorToken.Min].min.toString()))
        .concat(' ').concat(this.currency);
    }
    if (control.hasError(ValidatorToken.MaxLength) && control.errors) {
      return maxLength.replace('[0]', (this.maxLength - this.currencyLength).toString())
    }
  }
}
