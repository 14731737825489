import { TranslateService } from '@ngx-translate/core';
import { PaymentsEnum } from '../enums/payments.enum';
import { ProductsEnum } from '../enums/products.enum';
import { SharedEnum } from '../enums/shared.enum';
import { PaymentInterface } from '../interfaces/payment.interface';
import { Card, CardKomplatStatus, SelectCustomOptions } from '../interfaces/products.interface';
import { AccordionItem, TransferItem } from '../interfaces/segment.interface';
import { DateFormat } from './date.utils';
import PaymentUnits = PaymentsEnum.PaymentUnits;


export namespace ProductsUtils {

  const showableItemsWithoutCommission: PaymentsEnum.PaymentUnits[] = [
    PaymentUnits.PAY_DATE,
    PaymentUnits.PAY_ALL,
    PaymentUnits.LOAN_NAME,
    PaymentUnits.LOAN_AMOUNT,
    PaymentUnits.LOAN_AMOUNT_CHECK,
    PaymentUnits.MAX_PAY,
    PaymentUnits.LOAN_DURATION,
    PaymentUnits.LOAN_PERCENT,
    PaymentUnits.TOP_UP_TYPE,
    PaymentUnits.STATUS,
    PaymentUnits.DATE_TIME
  ];


  const showAbleItemsWithCommission: PaymentsEnum.PaymentUnits[] = [
    PaymentUnits.PAY_DATE,
    PaymentUnits.PAY_ALL,
    PaymentUnits.LOAN_NAME,
    PaymentUnits.LOAN_AMOUNT,
    PaymentUnits.LOAN_AMOUNT_CHECK,
    PaymentUnits.MAX_PAY,
    PaymentUnits.LOAN_DURATION,
    PaymentUnits.LOAN_PERCENT,
    PaymentUnits.TOP_UP_TYPE,
    PaymentUnits.STATUS,
    PaymentUnits.DATE_TIME,
    PaymentUnits.SUMMA,
    PaymentUnits.PAY_COMMISSION
  ];


  const keyOrder: { [key: string]: number } = {
    [PaymentUnits.PAY_DATE]: 1,
    [PaymentUnits.SUMMA]: 2,
    [PaymentUnits.PAY_COMMISSION]: 3,
    [PaymentUnits.PAY_ALL]: 4
  };


  export const iconOrderActions: string[] = [
    ProductsEnum.IconProductActions.ICON_ROUND_PLUS,
    ProductsEnum.IconProductActions.ICON_EDIT,
    ProductsEnum.IconProductActions.ICON_MOON,
    ProductsEnum.IconProductActions.ICON_SUN,
    ProductsEnum.IconProductActions.ICON_STAR,
    ProductsEnum.IconProductActions.ICON_EYE_OPEN,
    ProductsEnum.IconProductActions.ICON_EYE_CLOSED
  ];


  export function cardNumberDotted(cardNumber: string): string {
    if (cardNumber.length !== 16) {
      return '';
    }

    const visiblePart: string = cardNumber.slice(0, 4);
    const maskedPart: string = cardNumber.slice(4, 16).replace(/\*/g, '•').replace(/(.{4})/g, '$1 ');

    return visiblePart + ' ' + maskedPart;
  }


  export function replaceOriginString(originalStr: string, replacePartStr: string): string {
    if (originalStr.length !== 16) {
      return '';
    }

    const visiblePart: string = originalStr.slice(0, 4);
    let maskedPart: string = originalStr.slice(4, 12).replace(/\*/g, ''); // Удаление звёздочек

    maskedPart =
      maskedPart.slice(0, 4) +
      ' ' +
      replacePartStr.slice(0, 4) +
      ' ' +
      maskedPart.slice(4, 8) +
      ' ' +
      replacePartStr.slice(4, 8);

    return visiblePart + ' ' + maskedPart + ' ' + originalStr.slice(12, 16);
  }


  export const completedOperation: AccordionItem = {
    title: 'STATUS',
    text: 'COMPLETED'
  };


  export function formatCardNumber(cardNumber: string): string | undefined {
    return cardNumber.match(/.{1,4}/g)?.join(' ');
  }


  export function prepareAccordionContent(units: PaymentInterface.TransferUnits[], currency: string, translateService?: TranslateService): AccordionItem[] {
    if (units && units.length > 0) {
      return units.map((item) => {
        const keyEnum = PaymentsEnum.PaymentUnits[item.key as keyof typeof PaymentsEnum.PaymentUnits];
        return {
          ...item,
          key: keyEnum
        };
      }).filter((item): boolean => {
        return item.key === PaymentUnits.PAY_COMMISSION && item.value && item.value === '0' ?
          showableItemsWithoutCommission.includes(item.key) :
          showAbleItemsWithCommission.includes(item.key);
      })
        .sort((a, b) => keyOrder[a.key] - keyOrder[b.key])
        .map((unit): AccordionItem => {
          if (unit.key === PaymentUnits.PAY_DATE && unit.value) {
            return {
              title: 'DATE_TIME',
              text: DateFormat.convertDate(unit.value, translateService)
            };
          } else if (unit.key === PaymentUnits.SUMMA && unit.value) {
            return {
              title: 'SUM',
              text: unit.value.concat(' ').concat(currency)
            };
          } else if (unit.key === PaymentUnits.PAY_COMMISSION && unit.value) {
            return {
              title: 'COMISSION',
              text: unit.value.concat(' ').concat(currency)
            };
          } else if (unit.key === PaymentUnits.PAY_ALL && unit.value) {
            return {
              title: 'TOTAL',
              text: unit.value.concat(' ').concat(currency)
            };
          } else if (unit.key === PaymentUnits.LOAN_NAME && unit.value) {
            return {
              title: 'LOAN_NAME',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.LOAN_AMOUNT && unit.value) {
            return {
              title: 'LOAN_SUM',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.LOAN_AMOUNT_CHECK && unit.value) {
            return {
              title: 'LOAN_SUM_CHECK',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.MAX_PAY && unit.value) {
            return {
              title: 'MAX_PAY',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.LOAN_DURATION && unit.value) {
            return {
              title: 'LOAN_DURATION',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.LOAN_PERCENT && unit.value) {
            return {
              title: 'LOAN_PERCENT',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.TOP_UP_TYPE && unit.value) {
            return {
              title: 'TOP_UP_TYPE',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.STATUS && unit.value) {
            return {
              title: 'STATUS',
              text: unit.value
            };
          } else if (unit.key === PaymentUnits.DATE_TIME && unit.value) {
            return {
              title: 'DATE_TIME',
              text: unit.value
            };
          } else {
            return {
              title: unit.key,
              text: unit.value
            };
          }
        })
        .filter(item => item.text !== undefined)
    } else return [];
  }


  export function prepareTransferContent(
    sourceItem: SelectCustomOptions,
    targetAmount: string,
    targetNumber?: string
  ): TransferItem[] {
    const arr: TransferItem[] = [ {
      title: sourceItem.text ?
        sourceItem.text && sourceItem.isCard ?
          `${ (sourceItem).text } • ${ (sourceItem.cardNumberMasked as string).slice(-4) }` :
          (sourceItem).text : '(sourceItem).productName',
      sourceSender: (sourceItem.amount as number).toString(),
      sourceSenderCurrency: sourceItem.currency,
      targetReceipt: targetAmount,
      targetReceiptCurrency: sourceItem.currency
    } ];

    targetNumber ? arr.push({
      title: 'MAP',
      subtitle: formatCardNumber(targetNumber)
    } as TransferItem) : 0;

    return arr;
  }


  export function prepareCloseContent(
    sourceItem: SelectCustomOptions,
    targetItem: SelectCustomOptions,
    differenceAmount: string
  ): TransferItem[] {
    const sourceAmount = (((sourceItem).amount as number) - parseFloat(differenceAmount)).toFixed(2).replace('.', ',');
    const targetAmount = (((targetItem).amount as number) + parseFloat(differenceAmount)).toFixed(2).replace('.', ',');
    return [ {
      title: sourceItem.text
        ? sourceItem.text && sourceItem.isCard
          ? `${ (sourceItem).text } ● ${ (sourceItem.cardNumberMasked as string).slice(-4) }`
          : (sourceItem).text
        : '(sourceItem).productName',
      sourceSender: sourceItem.amount?.toString(),
      sourceSenderCurrency: sourceItem.currency,
      targetReceipt: sourceAmount,
      targetReceiptCurrency: sourceItem.currency
    }, {
      title: targetItem.text ? targetItem.text && targetItem.isCard
        ? `${ (targetItem).text } ●${ (targetItem.cardNumberMasked as string).slice(-4) }`
        : (targetItem).text : '(sourceItem).productName',
      sourceSender: targetItem.amount?.toString(),
      sourceSenderCurrency: targetItem.currency,
      targetReceipt: targetAmount,
      targetReceiptCurrency: targetItem.currency
    } ];
  }


  export function prepareTransferContentBetweenProducts(
    sourceItem: SelectCustomOptions,
    targetItem: SelectCustomOptions,
    targetAmount: string,
    sourceAmount: string
  ): TransferItem[] {
    return [ {
      title: sourceItem.text
        ? sourceItem.text && sourceItem.isCard
          ? `${ (sourceItem).text } ● ${ (sourceItem.cardNumberMasked as string).slice(-4) }`
          : (sourceItem).text
        : '(sourceItem).productName',
      sourceSender: sourceItem.amount?.toString(),
      sourceSenderCurrency: sourceItem.currency,
      targetReceipt: sourceAmount,
      targetReceiptCurrency: sourceItem.currency
    }, {
      title: targetItem.text ? targetItem.text && targetItem.isCard
        ? `${ (targetItem).text } ●${ (targetItem.cardNumberMasked as string).slice(-4) }`
        : (targetItem).text : '(sourceItem).productName',
      sourceSender: targetItem.amount?.toString(),
      sourceSenderCurrency: targetItem.currency,
      targetReceipt: targetAmount,
      targetReceiptCurrency: targetItem.currency
    } ];
  }


  export function cardStatus(card: Card | string) {
    if (typeof card === 'string') {
      return (card as string) === '0' ? CardKomplatStatus.FULL :
        (card as string) === '100' || (card as string) === '106' ? CardKomplatStatus.TOUNBLOCK
          : CardKomplatStatus.INFO;
    } else {
      return (card as Card).komplatStatus === '0' ? CardKomplatStatus.FULL :
        (card as Card).komplatStatus === '100' || (card as Card).komplatStatus === '106' ? CardKomplatStatus.TOUNBLOCK
          : CardKomplatStatus.INFO;
    }
  }


  export function checkLastFourDigits(cardNumber: string, valueToCompare: string) {
    return cardNumber.slice(-4) === valueToCompare.slice(-4);
  }


  export const FutureNewAccount = (name: string) => {
    return {
      'id': '1==',
      'text': name,
      'iconClass': 'grayed-icon',
      'icon': 'icon-coinsBundle2 gray',
      'isCard': false,
      'amount': 0,
      'contractNumberHash': '1==',
      'currency': 'BYN',
      'selected': false,
      'displayBalance': true,
      'displayOnMain': true,
      'contractType': 5,
      'isMainItem': false,
      'contractNumber': SharedEnum.NewProduct.ACCOUNT_CONTRACT_NUMBER
    };
  };
}
