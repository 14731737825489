<div class="app-select"
     #appSelect
>
  <div
    #textInput
    class="app-select-input error"
  >
    <div *ngIf="!isNeedBreakWord; else textarea">
      <app-text-input
              [label]="label"
              [placeholder]="placeholder"
              [invalid]="invalid"
              [inlineControl]="inlineControl"
              [value]="value"
              [readOnly]="readOnly"
              [dropMode]="true"
              [disabled]="disabled"
              [formControlCustom]="formControlCustom"
              [invisible]="multiSelect"
              [selectOpened]="!selectListHidden"
              (keyUp)="!disabled ? onKeyUp($event) : {}"
              (focused)="!disabled ? onFocused($event) : {}"
              [dropDirection]="selectListHidden ? 'down' : 'up'"
              [isSelect]="true"
      >
      </app-text-input>
    </div>

    <ng-template #textarea>
      <app-textarea
              [label]="label"
              [placeholder]="placeholder"
              [invalid]="invalid"
              [inlineControl]="inlineControl"
              [value]="value"
              [readOnly]="readOnly"
              [dropMode]="true"
              [disabled]="disabled"
              [formControlCustom]="formControlCustom"
              [invisible]="multiSelect"
              [selectOpened]="!selectListHidden"
              (keyUp)="!disabled ? onKeyUp($event) : {}"
              (focused)="!disabled ? onFocused($event) : {}"
              [dropDirection]="selectListHidden ? 'down' : 'up'"
              [isSelect]="true"
      ></app-textarea>
    </ng-template>

    <div
      class="multiselect-text"
      #multiselectText
      (click)="!disabled ? onClickMultiSelect($event) : {}"
    >
      <div
        *ngIf="multiSelect"
        class="multiselect-label"
      >
        {{ selectedLabel | translate }}
      </div>
      <div
        *ngIf="multiSelect && selectedValues.length && selectedValues.length > 0 && !isDropDownUniq; else showUniqCount"
        class="counter"
        (click)="onClearSelectedValues($event)"
      >
        <div>
          {{  selectedValues.length === options.length ? selectedValues.length - 1 : selectedValues.length }}
        </div>
        <i class="icon icon-cross"></i>
      </div>

      <ng-template #showUniqCount>
        <div
          *ngIf="multiSelect && selectedItemsCount > 0"
          class="counter"
          (click)="clearUniqCounter($event)"
        >
          <div>
            {{ selectedItemsCount }}
          </div>
          <i class="icon icon-cross"></i>
        </div>
      </ng-template>
    </div>

  </div>

  <div
    #droppedDownList
    class="app-select-list"
    [ngStyle]="{ 'max-height': isDropDownUniq ? '712px' : '316px' }"
    [class.hidden]="selectListHidden"
  >
    <div *ngIf="!isDropDownUniq; else openUniqDropdown">
      <div class="app-select-list-item"
           *ngFor="let option of options"
           [class.hidden]="option.hidden"
           [class.selected]="option.selected"
           (click)="onOption(option)"
      >
        <div
          *ngIf="htmlLabel"
          [innerHTML]="option.label"
          class="app-select-list-item__value">
        </div>
        <div
          *ngIf="!htmlLabel"
          class="app-select-list-item__value">
          {{ option.label ?? option.value }}
        </div>

        <div class="app-select-list-item__check" [class.multiselect]="multiSelect">
          <i class="icon icon-check-bold"></i>
        </div>
      </div>
    </div>

    <ng-template #openUniqDropdown>
      <app-multi-select-option
        [multiSelect]="true"
        [selectListUniq]="selectListUniq"
        (emitCountSelect)="emitCountUniq($event)"
        (emitSelectedList)="emitSelectListUniq($event)"
      ></app-multi-select-option>
    </ng-template>
  </div>
</div>
