export enum HttpErrorCode {
  INVALID_USER_FROM_SCMAP = 'INVALID_USER_FROM_SCMAP',
  ERROR_DURING_GET_CLIENT_FROM_SCMAP = 'ERROR_DURING_GET_CLIENT_FROM_SCMAP',
  KOMPLAT_GET_PAY_LIST_EXCEPTION = 'KOMPLAT_GET_PAY_LIST_EXCEPTION',
  KOMPLAT_ERROR_STAY_CURRENT_PAGE = 'KOMPLAT_ERROR_STAY_CURRENT_PAGE',
  UNSUCCESSFUL_ATTEMPT_EXCEPTION = 'UNSUCCESSFUL_ATTEMPT_EXCEPTION',
  UNSUCCESSFUL_ATTEMPT = 'UNSUCCESSFUL_ATTEMPT',
  SC_MAP_GET_CLIENT_EXCEPTION = 'SC_MAP_GET_CLIENT_EXCEPTION',
  USER_IS_BLOCKED_IN_IB_EXCEPTION = 'USER_IS_BLOCKED_IN_IB_EXCEPTION',
  BLOCK_USER_ON_EMPTY_ATTEMPTS = 'BLOCK_USER_ON_EMPTY_ATTEMPTS',
  SC_MAP_GET_EXCHANGE_RATES_EXCEPTION = 'SC_MAP_GET_EXCHANGE_RATES_EXCEPTION',
  SC_MAP_GET_ALL_EXCHANGE_RATES_EXCEPTION = 'SC_MAP_GET_ALL_EXCHANGE_RATES_EXCEPTION',
  CURRENT_USER_PLATFORM_IS_BLOCKED = 'CURRENT_USER_PLATFORM_IS_BLOCKED',
  CLOSING_DEPOSIT_NOT_ALLOWED = 'CLOSING_DEPOSIT_NOT_ALLOWED',
  CURRENT_PLATFORM_IS_BLOCKED = 'CURRENT_PLATFORM_IS_BLOCKED',
  LOGIN_IS_NOT_UNIQUE = 'LOGIN_IS_NOT_UNIQUE',
  CONFIRMATION_CODE_NOT_VALID = 'CONFIRMATION_CODE_NOT_VALID',
  VALIDATION_EXCEPTION = 'VALIDATION_EXCEPTION'
}


export enum ControlStatus {
  INVALID = 'INVALID',
  VALID = 'VALID'
}


export enum KomplatErrorCode {
  KOMPLAT_ERROR_78299 = 'KOMPLAT_ERROR_78299'
}


export enum HttpErrorStatus {
  OK = "OK",
  NOT_FOUND = 'NOT_FOUND',
}


export enum CustomErrorMessageText {
  DEFAULT_ERROR_MESSAGE = 'ERROR_RETRY_AGAIN',
  CURRENCY_RATES_ERROR_TEXT = 'ERROR_LOAD_CURRENCIES_RATES'
}
