import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs';
import { OperationsHistory } from '../../../interfaces/operations-history.interface';
import { OperationsService } from '../services/operations.service';
import { OperationsActions } from './operations.actions';


interface OperationStateModel {
  productHistory: OperationsHistory.OperationHistoryResponseDto;
}


@State<OperationStateModel>({
  name: 'operations'
})
@Injectable()
export class OperationsState {

  constructor(private readonly operationsService: OperationsService) {}


  @Action(OperationsActions.OperationHistory)
  public getOperationHistory(ctx: StateContext<OperationStateModel>, { requestData }: OperationsActions.OperationHistory) {
    return this.operationsService.getOperationHistory(requestData)
      .pipe(
        map(productHistory => {
          ctx.patchState({ productHistory })
        })
      )
  }
}
