import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import { ParamsStateModel } from '../../../interfaces/params.interface';
import { ParamsService } from '../services/params.service';
import { ParamsActions } from './params.actions';


@State<ParamsStateModel>({
  name: 'params'
})
@Injectable()
export class ParamsState {


  @Selector()
  public static selectTermOfOfferUrl(state: ParamsStateModel) {
    return state.documentParam.offer.url;
  }


  @Selector()
  public static selectConsents(state: ParamsStateModel) {
    return state.documentParam.consents ? state.documentParam.consents : [];
  }


  @Selector()
  public static selectInfoMessage(state: ParamsStateModel) {
    return (typeRegistration: string) => {
      return state.documentParam.infoMessages.find(i => i.typeRegistration === typeRegistration);
    };
  }


  @Selector()
  public static selectAuthParam(state: ParamsStateModel) {
    return state.authParam;
  }


  @Selector()
  public static selectBankBins(state: ParamsStateModel) {
    return state.productParam.bankBins;
  }


  @Selector()
  public static selectSmsParams(state: ParamsStateModel) {
    return state.smsParam;
  }


  constructor(
    private readonly authService: ParamsService) {
  }


  @Action(ParamsActions.GetParams)
  public getParams(ctx: StateContext<ParamsStateModel>) {
    return this.authService.getParams().pipe(
      tap(params => ctx.patchState(params))
    );
  }

}
