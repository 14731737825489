import { IEripBodyTwoRequest } from '../../../interfaces/erip-navigation.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import { GetAccountBalanceDtoRq, GetCardBalanceDtoRq } from '../../../interfaces/product-liability.interface';
import { OnlineApplication } from '../../../interfaces/products.interface';


export namespace PaymentActions {
  import BetweenAccountsLimitsDto = PaymentInterface.BetweenAccountsLimitsDto;
  import BetweenAccountsTransfer = PaymentInterface.BetweenAccountsTransfer;


  export class DepositPaymentClosingToCard {
    public static readonly type = '[Payment] DepositPaymentClosingToCard';


    constructor(public readonly requestData: PaymentInterface.TransferDepositRequestDto) {}
  }


  export class PaymentCardTransferCurrency {
    public static readonly type = '[Payment] PaymentCardTransferCurrency';


    constructor(public readonly requestData: PaymentInterface.TransferCardNumberCurrencyDto) {}
  }


  export class PaymentTransferInfo {
    public static readonly type = '[Payment] PaymentTransferInfo';


    constructor(public readonly requestData: PaymentInterface.TransferCardNumberCurrencyDto) {}
  }


  export class PaymentCardTransferTransfer {
    public static readonly type = '[Payment] PaymentCardTransferTransfer';


    constructor(
      public readonly request: { requestData: PaymentInterface.TransferCardNumberTransferDto, modifications: any }) {}
  }


  export class DepositPaymentClosingToAccount {
    public static readonly type = '[Payment] DepositPaymentClosingToAccount';


    constructor(public readonly requestData: PaymentInterface.TransferDepositRequestDto) {}
  }


  export class DepositTransferOpening {
    public static readonly type = '[Payment] DepositTransferOpening';


    constructor(public readonly requestData: PaymentInterface.GetPayListRequestDto) {}
  }


  export class BetweenTransferLimits {
    public static readonly type = '[Payment] BetweenTransferLimits';


    constructor(public readonly requestData: PaymentInterface.GetLimitsRequestDto) {}
  }


  export class DepositPaymentTopUpToCard {
    public static readonly type = '[Payment] DepositPaymentTopUpToCard';


    constructor(public readonly requestData: PaymentInterface.TransferDepositRequestDto) {}
  }


  export class DepositPaymentTopUpToAccount {
    public static readonly type = '[Payment] DepositPaymentTopUpToAccount';


    constructor(public readonly requestData: PaymentInterface.TransferDepositRequestDto) {}
  }


  export class DepositPaymentWithdrawalToCard {
    public static readonly type = '[Payment] DepositPaymentWithdrawalToCard';


    constructor(public readonly requestData: PaymentInterface.TransferDepositRequestDto) {}
  }


  export class DepositPaymentWithdrawalToAccount {
    public static readonly type = '[Payment] DepositPaymentWithdrawalToAccount';


    constructor(public readonly requestData: PaymentInterface.TransferDepositRequestDto) {}
  }


  export class BetweenAccountsLimits {
    public static readonly type = '[Payment] BetweenAccountsLimits';


    constructor(public readonly requestData: BetweenAccountsLimitsDto) {}
  }


  export class TransferClientLimits {
    public static readonly type = '[Payment] TransferClientLimits';


    constructor(public readonly requestData: {}) {}
  }


  export class SendBetweenAccountsTransfer {
    public static readonly type = '[Payment] SendBetweenAccountsTransfer';


    constructor(public readonly requestData: BetweenAccountsTransfer) {}
  }


  export class SendLoanPaymentInfo {
    public static readonly type = '[Payment] SendLoanPaymentInfo';


    constructor(public readonly requestData: PaymentInterface.LoanPaymentInfoDto) {}
  }


  export class SendLoanPayment {
    public static readonly type = '[Payment] SendLoanPayment';


    constructor(public readonly requestData: PaymentInterface.LoanPaymentInfoDto) {}
  }


  export class TransferTypePayment {
    public static readonly type = '[Payment] TransferTypePayment';


    constructor(public readonly requestData: PaymentInterface.EncryptedRequestDto) {}
  }


  export class GetRestrictionPayment {
    public static readonly type = '[Payment] GetRestrictionPayment';


    constructor(public readonly requestData: PaymentInterface.EncryptedRequestDto) {}
  }


  export class DoTransactionPayment {
    public static readonly type = '[Payment] DoTransactionPayment';


    constructor(public readonly requestData: PaymentInterface.EncryptedRequestDto) {}
  }


  export class GetTransactionStatus {
    public static readonly type = '[Payment] GetTransactionStatus';


    constructor(public readonly requestData: PaymentInterface.GetTransactionStatusDto) {}
  }


  export class GetReceipt {
    public static readonly type = '[Payment] GetReceipt';


    constructor(public readonly requestData: string) {}
  }


  export class GetEncryptionPublicKey {
    public static readonly type = '[Payment] GetEncryptionPublicKey';
  }


  export class SetLoanNavigateToConditions {
    public static readonly type = '[Payment] SetLoanNavigateToConditions';


    constructor() {}
  }


  export class SetLoanRepaymentContractNumber {
    public static readonly type = '[Payment] SetLoanRepaymentContractNumber';


    constructor(public readonly contractNumber: string) {}
  }


  export class ClearLoanRepaymentContractNumber {
    public static readonly type = '[Payment] ClearLoanRepaymentContractNumber';


    constructor() {}
  }


  export class GetAvailablePaymentActions {
    public static readonly type = '[Payment] GetAvailablePaymentActions';


    constructor() {}
  }


  export class GetPaymentTopUpTransferGpl {
    public static readonly type = '[Payment] GetPaymentTopUpTransferGpl';


    constructor(public readonly requestData: PaymentInterface.GetPayListRequestDto) {}
  }


  export class GetEripPaymentsRoc {
    public static readonly type = '[Payment] GetEripPaymentsRoc';


    constructor(
      public readonly request: { requestData: IEripBodyTwoRequest | any, modifications?: any },
      public readonly isOnePay = false
    ) {}
  }


  export class GetByRequisitesRoc {
    public static readonly type = '[Payment] GetByRequisitesRoc';


    constructor(
      public readonly request: { requestData: IEripBodyTwoRequest | any, modifications?: any },
      public readonly isOnePay = false
    ) {}
  }


  export class GetCardBalance {
    public static readonly type = '[Payment] GetCardBalance';


    constructor(
      public readonly requestData: GetCardBalanceDtoRq
    ) {}
  }


  export class GetAccountBalance {
    public static readonly type = '[Payment] GetAccountBalance';


    constructor(
      public readonly requestData: GetAccountBalanceDtoRq) {}
  }


  export class ClearCheckList {
    public static readonly type = '[Payment] ClearCheckList';
  }


  export class SetCheckPayment {
    public static readonly type = '[Payment] SetCheckPayment';


    constructor(public readonly payload: any) {};
  }


  export class PaymentTopUpTransferRoc {
    public static readonly type = '[Payment] PaymentTopUpTransferRoc';


    constructor(
      public readonly requestData: PaymentInterface.RunOperationRequestDto,
      public readonly isOnePay = false
    ) {}
  }


  export class GetPaymentTemplates {
    public static readonly type = '[Payment] GetPaymentTemplates';
  }


  export class CreatePaymentTemplate {
    public static readonly type = '[Payment] CreatePaymentTemplate';


    constructor(public readonly requestData: PaymentInterface.CreatePaymentTemplateRequestDto) {}
  }


  export class DeletePaymentTemplate {
    public static readonly type = '[Payment] DeletePaymentTemplate';


    constructor(public readonly id: string) {}
  }


  export class UpdatePaymentTemplate {
    public static readonly type = '[Payment] UpdatePaymentTemplate';


    constructor(public readonly requestData: PaymentInterface.UpdatePaymentTemplateDto) {}
  }


  export class GetOperationHistory {
    public static readonly type = '[Payment] GetOperationHistory';


    constructor(public readonly requestData: PaymentInterface.GetOperationHistoryRequestDto) {}
  }


  export class PutLoanProducts {
    public static readonly type = '[Payment] PutLoanProducts';


    constructor(public readonly loanProducts: PaymentInterface.LoanProduct[]) {}
  }


  export class PutBackHandlerClearFlag {
    public static readonly type = '[Payment] PutBackHandlerClearFlag';


    constructor(public readonly flag: boolean) {}
  }


  export class PutLoanIssuePreventAlternativeClear {
    public static readonly type = '[Payment] PutLoanIssuePreventAlternativeClear';


    constructor(public readonly flag: boolean) {}
  }


  export class PutBackAlternativeHandler {
    public static readonly type = '[Payment] PutBackAlternativeHandler';


    constructor(public readonly handler: () => void) {}
  }


  export class ClearBackAlternativeHandler {
    public static readonly type = '[Payment] ClearBackAlternativeHandler';


    constructor() {}
  }


  export class PutBackHandler {
    public static readonly type = '[Payment] PutBackHandler';


    constructor(public readonly handler: () => void) {}
  }


  export class ClearBackHandler {
    public static readonly type = '[Payment] ClearBackHandler';


    constructor() {}
  }


  export class PutIssueBackHandler {
    public static readonly type = '[Payment] PutIssueBackHandler';


    constructor(public readonly handler: () => void) {}
  }


  export class ClearIssueBackHandler {
    public static readonly type = '[Payment] ClearIssueBackHandler';


    constructor() {}
  }


  export class PutConditionsBackInterruptHandler {
    public static readonly type = '[Payment] PutConditionsBackInterruptHandler';

    constructor(public readonly handler: () => void) {}
  }


  export class ClearConditionsBackInterruptHandler {
    public static readonly type = '[Payment] ClearConditionsBackInterruptHandler';

    constructor() {}
  }


  export class PutConditionsBackHandler {
    public static readonly type = '[Payment] PutConditionsBackHandler';

    constructor(public readonly handler: () => void) {}
  }


  export class ClearConditionsBackHandler {
    public static readonly type = '[Payment] ClearConditionsBackHandler';

    constructor() {}
  }


  export class GetLoanProducts {
    public static readonly type = '[Payment] GetLoanProducts';


    constructor() {}
  }


  export class SkipLoanProducts {
    public static readonly type = '[Payment] SkipLoanProducts';


    constructor() {}
  }


  export class ContinueLoanProducts {
    public static readonly type = '[Payment] ContinueLoanProducts';


    constructor() {}
  }


  export class GetLoanStatus {
    public static readonly type = '[Payment] GetLoanStatus';


    constructor() {}
  }


  export class ClearApplicationCodeStatus {
    public static readonly type = '[Payment] ClearApplicationCodeStatus';

    constructor() {}
  }


  export class SwapApplicationCodeStatus {
    public static readonly type = '[Payment] SwapApplicationCodeStatus';

    constructor(public readonly requestData: PaymentInterface.LoanProduct) {}
  }



  export class ClearLoanAlternative {
    public static readonly type = '[Payment] ClearLoanAlternative';

    constructor() {}
  }


  export class GetApplicationCodeStatus {
    public static readonly type = '[Payment] GetApplicationCodeStatus';

    constructor(public readonly requestData: PaymentInterface.ApplicationCodeRq) {}
  }


  export class GetPaymentSchedule {
    public static readonly type = '[Payment] GetPaymentSchedule';

    constructor(public readonly requestData: PaymentInterface.PaymentScheduleRq) {}
  }


  export class FixOffer {
    public static readonly type = '[Payment] FixOffer';

    constructor(public readonly requestData: PaymentInterface.FixOfferRq) {}
  }


  export class SaveUserCreditInputSchedule {
    public static readonly type = '[Payment] SaveUserCreditInputSchedule';

    constructor(public readonly requestData: PaymentInterface.LoanIssueValues) {}
  }


  export class PopUserCreditInputSchedule {
    public static readonly type = '[Payment] PopUserCreditInputSchedule';

    constructor() {}
  }


  export class RestoreSelectLoanApplication {
    public static readonly type = '[Payment] RestoreSelectLoanApplication';


    constructor(public readonly requestData: OnlineApplication) {}
  }


  export class SelectLoanApplication {
    public static readonly type = '[Payment] SelectLoanApplication';


    constructor(public readonly requestData: OnlineApplication) {}
  }


  export class GetLoanForm {
    public static readonly type = '[Payment] GetLoanForm';


    constructor(public readonly requestData: PaymentInterface.LoanForm) {}
  }


  export class GetLoanViewDocument {
    public static readonly type = '[Payment] GetLoanViewDocument';


    constructor(public readonly requestData: PaymentInterface.LoanDocument) {}
  }


  export class GetConsentRecording {
    public static readonly type = '[Payment] GetConsentReporting';

    constructor(public readonly payload: PaymentInterface.LoanConsent) {}
  }


  export class GetLoanIssue {
    public static readonly type = '[Payment] GetLoanIssue';


    constructor(public readonly requestData: PaymentInterface.LoanFilledForm) {}
  }


  export class RestoreOffers {
    public static readonly type = '[Payment] RestoreOffers';

    constructor(public readonly offer: PaymentInterface.Offer) {}
  }


  export class GetOffers {
    public static readonly type = '[Payment] GetOffers';

    constructor(public readonly requestData: PaymentInterface.ApplicationCodeRq) {}
  }


  export class LoanVoid {
    public static readonly type = '[Payment] LoanVoid';


    constructor(public readonly requestData: PaymentInterface.LoanVoid) {}
  }


  export class CheckSignDocument {
    public static readonly type = '[Payment] CheckSignDocument';

    constructor(public readonly data: PaymentInterface.CheckSignDocumentRq) {}
  }


  export class SignFinalDocuments {
    public static readonly type = '[Payment] SignFinalDocuments';

    constructor(public readonly data: PaymentInterface.SignFinalDocumentsRq) {}
  }


  export class GetApplicationByNumber {
    public static readonly type = '[Payment] GetApplicationByNumber';

    constructor(public readonly applicationNumber: number) {}
  }


  export class ReviewApplication {
    public static readonly type = '[Payment] ReviewApplication';

    constructor(public readonly requestData: PaymentInterface.ReviewApplicationRq) {}
  }


  export class VoidApplication {
    public static readonly type = '[Payment] VoidApplication';

    constructor(public readonly requestData: PaymentInterface.VoidApplicationRq) {}
  }


  export class SaveLoanVoid {

    public static readonly type = '[Payment] SaveLoanVoid';


    constructor() {}
  }


  export class ClearLoanVoid {

    public static readonly type = '[Payment] ClearLoanVoid';


    constructor() {}
  }


  export class ClearLoanApplicationFilled {
    public static readonly type = '[Payment] ClearLoanApplicationFilled';
  }


  export class SaveLoanApplicationFilled {
    public static readonly type = '[Payment] SaveLoanApplicationFilled';


    constructor(public readonly loanApplicationFill: PaymentInterface.LoanApplicationFill) {}
  }


  export class ClearLoanCheck {
    public static readonly type = '[Payment] ClearLoanCheck';


    constructor() {}
  }


  export class SaveLoanCheck {
    public static readonly type = '[Payment] SaveLoanCheck';


    constructor(public readonly loanCheckInfo: PaymentInterface.LoanCheckInfo) {}
  }


  export class ClearLoanConditionData {
    public static readonly type = '[Payment] ClearLoanConditionData';


    constructor() {}
  }


  export class SaveLoanConditionData {
    public static readonly type = '[Payment] SaveLoanConditionData';


    constructor(public readonly loanConditionsData: PaymentInterface.LoanConditionsData) {}
  }


  export class SetBePaidSources {
    public static readonly type = '[Payment] SetBePaidSources';


    constructor(public readonly payload: PaymentInterface.TransferBePaidSources) {}
  }


  export class ClearPaymentSources {
    public static readonly type = '[Payment] ClearPaymentSources';
  }


  export class UpdateAutoPayment {
    public static readonly type = '[Payment] UpdateAutoPayment';


    constructor(public readonly requestData: PaymentInterface.UpdateAutoPaymentRequestDto) {}
  }


  export class CreateAutoPayment {
    public static readonly type = '[Payment] CreateAutoPayment';


    constructor(public readonly requestData: PaymentInterface.CreatePaymentTemplateRequestDto) {}
  }


  export class DeleteAutoPayById {
    public static readonly type = '[Payment] DeleteAutoPayById';


    constructor(public readonly id: string) {}
  }
}
