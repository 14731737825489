import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { CodeRequest, CodeResponse, ConfirmResponse } from '../../../interfaces/auth.interface';
// import { RegistrationVerifyPayload } from '../../../interfaces/auth.interface';
import {
  GetNsiDataPayload,
  MsiConfirmRequest,
  MsiFormReqPayload,
  MsiFormResponse,
  MsiLoginPayload,
  MsiLoginResponse,
  MsiPostFormReqPayload,
  MsiPostFormResponse,
  MsiRedirectResponse,
  NsiDataResponse,
  NsiDictionariesResponse
} from '../../../interfaces/msi.interface';
import { SystemInfo } from '../../../interfaces/system.interface';


@Injectable({
  providedIn: 'root'
})
export class MsiResource {

  constructor(
    private readonly http: HttpClient) {
  }


  public getRedirectURL(payload: SystemInfo): Observable<MsiRedirectResponse> {
    return this.http.post<MsiRedirectResponse>(ApiConfig.MSI_GET_REDIRECT_URL, payload);
  }


  public login(payload: MsiLoginPayload): Observable<MsiLoginResponse> {
    return this.http.post<MsiLoginResponse>(ApiConfig.MSI_LOGIN, payload);
  }


  public code(payload: CodeRequest): Observable<CodeResponse> {
    return this.http.post<CodeResponse>(ApiConfig.MSI_CODE, payload);
  }


  public confirm(payload: MsiConfirmRequest): Observable<ConfirmResponse> {
    return this.http.post<ConfirmResponse>(ApiConfig.MSI_CONFIRM, payload);
  }


  public getForm(payload: MsiFormReqPayload): Observable<MsiFormResponse> {
    return this.http.post<MsiFormResponse>(ApiConfig.MSI_GET_FORM, payload);
  }


  public getDictionaries(): Observable<NsiDictionariesResponse> {
    return this.http.post<NsiDictionariesResponse>(ApiConfig.NSI_GET_DICTIONARY, { dictionaryType: 'ALL' });
  }


  public getNsiInformation(payload: GetNsiDataPayload): Observable<NsiDataResponse> {
    return this.http.post<NsiDataResponse>(ApiConfig.NSI_GET_INFORMATION, payload);
  }


  public postForm(payload: MsiPostFormReqPayload): Observable<MsiPostFormResponse> {
    return this.http.post<MsiPostFormResponse>(ApiConfig.MSI_POST_FORM, payload);
  }
}
