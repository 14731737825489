import { Component } from '@angular/core';


@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  standalone: true,
  styleUrls: [ './error.component.scss' ]
})
export class ErrorComponent {


}
