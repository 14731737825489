import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs';
import { HttpError } from '../../../interfaces/http.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import {
  GetAccountBalanceDtoRs,
  GetCardBalanceDtoRs
} from '../../../interfaces/product-liability.interface';
import { ConfirmationKey } from '../../../interfaces/user.interface';
import { ProductsService } from '../../products/services/products.service';
import { PaymentService } from '../services/payment.service';
import { PaymentActions } from './payment.actions';
import { ConfirmationUtils } from '../../../utils/confirmation.utils';
import { OnlineApplication } from '../../../interfaces/products.interface';
import { SessionStorageService } from '../../../services/session-storage.service';


interface PaymentStateModel {
  depositPayment: PaymentInterface.TransferDepositResponseDto; // TODO: отрефакторить, убрать вообще!!!!
  betweenAccountsLimits: PaymentInterface.TransferLimitsResponseDto;
  transferClientLimits: PaymentInterface.BetweenAccountsLimitsRs;
  transferResponse: PaymentInterface.TransferDepositResponseDto; // TODO: отрефакторить, оставить и заменить depositPayment!!!!
  creditInfoResponse: PaymentInterface.CreditInfoResponse;
  byCardTransferCurrency: PaymentInterface.TransferByCardCurrencyRs;
  transferTypeResponse: PaymentInterface.TransferTypeResponseDto;
  getRestriction: PaymentInterface.RestrictionsResponseDto;
  doRestriction: PaymentInterface.TransferReceiptDto;
  getTransactionStatus: PaymentInterface.GetTransactionStatusDto;
  getEncryptedPublicKey: PaymentInterface.GetPublicSignatureResponseDto;
  getAvailableActions: PaymentInterface.AvailableActionsResponseDto;
  getPaymentTopUpTransferGpl: PaymentInterface.GetPayListResponseDto;
  getPaymentTransferRoc: PaymentInterface.CheckBaseResponseDto;
  getReceipt: PaymentInterface.ReceiptResponseDto;
  loanRepaymentContractNumber: string;
  getDepositTransferOpening: PaymentInterface.CheckBaseResponseDto;
  transferLimits: PaymentInterface.TransferLimitsResponseDto,
  paymentTemplates: PaymentInterface.ListPaymentTemplateRespDto;
  createTemplate: HttpError;
  updateTemplate: HttpError;
  deleteTemplate: HttpError;
  getOperationHistory: PaymentInterface.GetOperationHistoryResponseDto;
  loanProductsList: PaymentInterface.LoanProduct[];
  loanProducts: PaymentInterface.LoanProduct[];
  loanForm: PaymentInterface.LoanFormRs;
  loanDocument: PaymentInterface.LoanDocumentRs;
  loanConsent: PaymentInterface.LoanConsent;
  loanIssueResponse: PaymentInterface.LoanDocumentRs;
  loanCheckInfo: PaymentInterface.LoanCheckInfo;
  loanConditionsData: PaymentInterface.LoanConditionsData;
  loanVoid: boolean;
  paymentSources: PaymentInterface.TransferBePaidSources | null;
  selectedLoanApplication: OnlineApplication;
  alternativeLoanProduct: PaymentInterface.LoanProduct[];
  applicationCodeStatus: OnlineApplication;
  offer: PaymentInterface.Offer;
  paymentSchedule: PaymentInterface.PaymentSchedule;
  fixOffer: PaymentInterface.FixOfferRs;
  skipLoanProducts: boolean;
  checkSignDocumentResponse: PaymentInterface.CheckSignDocumentRs;
  signFinalDocumentsResponse: PaymentInterface.SignFinalDocumentsRs;
  fixOfferRequestData: PaymentInterface.FixOfferRq,
  loanApplicationFill: PaymentInterface.LoanApplicationFill;
  checkPaymentList: any[];
  productCardBalance: GetCardBalanceDtoRs;
  productAccountBalance: GetAccountBalanceDtoRs;
  crudAutoPayment: HttpError;
  backHandler: () => void;
  backHandlerFlag: boolean;
  loanIssuePreventAlternativeClear: boolean;
  backHandlerAlternative: (() => void)[];
  backHandlerIssue: () => void;
  backHandlerConditions: () => void;
  backHandlerInterruptConditions: () => void;
  msiNavigateToConditions?: boolean;
  partnerApplicationDetail: PaymentInterface.ApplicationResponse;
  voidResponse: PaymentInterface.VoidApplicationRs;
  userCreditInput: PaymentInterface.LoanIssueValues;
}


@State<PaymentStateModel>({
  name: 'payment'
})
@Injectable()
export class PaymentState {


  @Selector()
  public static selectApplicationOffer(state: PaymentStateModel) {
    return state.offer;
  }


  @Selector()
  public static selectSkipLoanProducts(state: PaymentStateModel) {
    return state.skipLoanProducts;
  }


  @Selector()
  public static selectFixOfferRequestData(state: PaymentStateModel) {
    return state.fixOfferRequestData;
  }


  @Selector()
  public static selectPartnerApplicationDetail(state: PaymentStateModel) {
    return state.partnerApplicationDetail;
  }


  @Selector()
  public static selectSelectedLoanApplication(state: PaymentStateModel) {
    return state.selectedLoanApplication;
  }


  @Selector()
  public static selectMsiNavigateToConditions(state: PaymentStateModel) {
    return state.msiNavigateToConditions;
  }


  @Selector()
  public static selectVoidResponse(state: PaymentStateModel) {
    return state.voidResponse;
  }


  @Selector()
  public static selectLoanApplicationFill(state: PaymentStateModel) {
    return state.loanApplicationFill;
  }


  @Selector()
  public static selectSignFinalDocumentsResponse(state: PaymentStateModel) {
    return state.selectedLoanApplication;
  }


  @Selector()
  public static selectPaymentSchedule(state: PaymentStateModel) {
    return state.paymentSchedule;
  }


  @Selector()
  public static selectAlternativeLoanProduct(state: PaymentStateModel) {
    return state.alternativeLoanProduct;
  }


  @Selector()
  public static selectApplicationCodeStatus(state: PaymentStateModel) {
    return state.applicationCodeStatus;
  }


  @Selector()
  public static selectGetDepositTransferOpening(state: PaymentStateModel) {
    return state.getDepositTransferOpening;
  }


  @Selector()
  public static selectTransferResponse(state: PaymentStateModel) {
    return state.transferResponse;
  }


  @Selector()
  public static selectLoanProducts(state: PaymentStateModel) {
    return state.loanProducts;
  }


  @Selector()
  public static selectLoanProductsList(state: PaymentStateModel) {
    return state.loanProductsList;
  }


  @Selector()
  public static selectLoanForm(state: PaymentStateModel) {
    return state.loanForm;
  }


  @Selector()
  public static selectLoanDocument(state: PaymentStateModel) {
    return state.loanDocument;
  }


  @Selector()
  public static selectLoanConsent(state: PaymentStateModel) {
    return state.loanConsent;
  }


  @Selector()
  public static selectTransfer(state: PaymentStateModel) {
    return state.depositPayment;
  }


  @Selector()
  public static selectChecks(state: PaymentStateModel) {
    return state.checkPaymentList;
  }


  @Selector()
  public static selectCreditInfoResponse(state: PaymentStateModel) {
    return state.creditInfoResponse;
  }


  @Selector()
  public static selectTransferCurrency(state: PaymentStateModel) {
    return state.byCardTransferCurrency;
  }


  @Selector()
  public static selectLoanRepaymentContractNumber(state: PaymentStateModel) {
    return state.loanRepaymentContractNumber;
  }


  @Selector()
  public static selectBetweenAccountsLimits(state: PaymentStateModel) {
    return state.betweenAccountsLimits;
  }


  @Selector()
  public static selectTransferClientLimits(state: PaymentStateModel) {
    return state.transferClientLimits;
  }


  @Selector()
  public static selectBackHandler(state: PaymentStateModel) {
    return state.backHandler;
  }


  @Selector()
  public static selectBackHandlerFlag(state: PaymentStateModel) {
    return state.backHandlerFlag;
  }


  @Selector()
  public static selectLoanIssuePreventAlternativeClear(state: PaymentStateModel) {
    return state.loanIssuePreventAlternativeClear;
  }


  @Selector()
  public static selectBackHandlerAlternative(state: PaymentStateModel) {
    return state.backHandlerAlternative;
  }


  @Selector()
  public static selectUserCreditInput(state: PaymentStateModel) {
    return state.userCreditInput;
  }


  @Selector()
  public static selectBackHandlerIssue(state: PaymentStateModel) {
    return state.backHandlerIssue;
  }


  @Selector()
  public static selectBackHandlerInterruptConditions(state: PaymentStateModel) {
    return state.backHandlerInterruptConditions;
  }


  @Selector()
  public static selectBackHandlerConditions(state: PaymentStateModel) {
    return state.backHandlerConditions;
  }


  @Selector()
  public static selectAvailablePaymentActions(state: PaymentStateModel) {
    const { payCodeEnum } = state.getAvailableActions as any;
    return payCodeEnum;
  }


  @Selector()
  public static selectTemplates(state: PaymentStateModel) {
    const { paymentTemplateDtos } = state.paymentTemplates as any;

    const withSortIndex = paymentTemplateDtos.filter((item: any) => item.hasOwnProperty('templateSortIndex'));
    const withoutSortIndex = paymentTemplateDtos.filter((item: any) => !item.hasOwnProperty('templateSortIndex'));

    withSortIndex.sort((a: any, b: any) => a.templateSortIndex! - b.templateSortIndex!);

    return [...withSortIndex, ...withoutSortIndex];
  }


  @Selector()
  public static selectAutoTemplates(state: PaymentStateModel) {
    const { autoPaymentDtos } = state.paymentTemplates as any;

    const withSortIndex = autoPaymentDtos.filter((item: any) => item.hasOwnProperty('autoPaySortIndex'));
    const withoutSortIndex = autoPaymentDtos.filter((item: any) => !item.hasOwnProperty('autoPaySortIndex'));

    withSortIndex.sort((a: any, b: any) => a.autoPaySortIndex! - b.autoPaySortIndex!);

    return [...withSortIndex, ...withoutSortIndex];
  }


  @Selector()
  public static selectLoanCheckInfo(state: PaymentStateModel) {
    return state.loanCheckInfo;
  }


  @Selector()
  public static selectLoanConditionsData(state: PaymentStateModel) {
    return state.loanConditionsData;
  }


  @Selector()
  public static selectLoanIssueResponse(state: PaymentStateModel) {
    return state.loanIssueResponse;
  }

  @Selector()
  public static selectLoanVoid(state: PaymentStateModel) {
    return state.loanVoid;
  }


  @Selector()
  public static selectTopUpTransferRoc(state: PaymentStateModel) {
    return state.getPaymentTransferRoc;
  }


  @Selector()
  public static selectPaymentTopUpTransferGpl(state: PaymentStateModel) {
    return state.getPaymentTopUpTransferGpl;
  }


  constructor(
    private readonly sessionStorageService: SessionStorageService,
    private readonly paymentService: PaymentService,
    private readonly productsService: ProductsService) {
  }


  @Action(PaymentActions.ClearCheckList)
  public clearCheckList(ctx: StateContext<PaymentStateModel>): void {
    ctx.patchState({
      checkPaymentList: []
    });
  }


  @Action(PaymentActions.SetCheckPayment)
  public setCheckPayment(ctx: StateContext<PaymentStateModel>, action: PaymentActions.SetCheckPayment): void {
    const state = ctx.getState();
    const { checkPaymentList } = ctx.getState();

    ctx.setState({
      ...state,
      checkPaymentList: [...(checkPaymentList) || [], action.payload]
    });
  }


  @Action(PaymentActions.GetEripPaymentsRoc)
  public eripPaymentRoc(ctx: StateContext<PaymentStateModel>, {
    request: {
      requestData,
      modifications
    },
    isOnePay
  }: PaymentActions.GetEripPaymentsRoc) {
    const { checkPaymentList } = ctx.getState();
    const requestWithoutName = {
      ...requestData,
      confirmationData: requestData.confirmationData,
      confirmationKey: requestData.confirmationKey
    };

    delete requestWithoutName.templateName;
    delete requestWithoutName.payCodeEnum;

    const data = isOnePay ? requestWithoutName : requestData;

    return this.paymentService.getEripPaymentsRoc(data, ConfirmationUtils.addHeaderConfirmationData(data))
      .pipe(
        map(response => {
          if (isOnePay) {
            ctx.patchState({
              checkPaymentList: [
                ...(checkPaymentList) || [],
                {
                  ...response,
                  templateName: (response as any).templateName ? (response as any).templateName : (requestData as any).templateName,
                  payCodeEnum: (response as any).payCodeEnum ? (response as any).payCodeEnum : (requestData as any).payCodeEnum
                }
              ]
            });
          } else {
            ctx.patchState({
              depositPayment: response
            });
          }
        })
      );
  }


  @Action(PaymentActions.GetByRequisitesRoc)
  public byRequisitesRoc(ctx: StateContext<PaymentStateModel>, {
    request: {
      requestData,
      modifications
    },
    isOnePay
  }: PaymentActions.GetByRequisitesRoc) {
    const { checkPaymentList } = ctx.getState();
    const requestWithoutName = {
      ...requestData,
      confirmationData: requestData.confirmationData,
      confirmationKey: requestData.confirmationKey
    };

    delete requestWithoutName.templateName;
    delete requestWithoutName.payCodeEnum;

    const data = isOnePay ? requestWithoutName : requestData;

    return this.paymentService.byRequisitesRoc(data, ConfirmationUtils.addHeaderConfirmationData(data)).pipe(
      map(response => {
        if (isOnePay) {
          ctx.patchState({
            checkPaymentList: [
              ...(checkPaymentList) || [],
              {
                ...response,
                templateName: (response as any).templateName ? (response as any).templateName : (requestData as any).templateName,
                payCodeEnum: (response as any).payCodeEnum ? (response as any).payCodeEnum : (requestData as any).payCodeEnum
              }
            ]
          });
        } else {
          ctx.patchState({
            depositPayment: response
          });
        }
      })
    );
  }


  @Action(PaymentActions.GetCardBalance)
  public getCardBalance(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.GetCardBalance) {
    const { checkPaymentList } = ctx.getState();

    return this.productsService.getCardBalance({ cardHash: requestData.cardHash }).pipe(
      map(response => {
        ctx.patchState({
          checkPaymentList: [
            ...(checkPaymentList.map(el => ({
              ...el,
              balance: el.balance ? el.balance : response.balance,
              templateName: el.templateName ? el.templateName : (requestData as any).templateName,
              payCodeEnum: (el as any).payCodeEnum ? (el as any).payCodeEnum : (requestData as any).payCodeEnum
            }))) || []
          ]
        });
      })
    );
  }


  @Action(PaymentActions.GetAccountBalance)
  public getAccountBalance(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.GetAccountBalance) {
    const { checkPaymentList } = ctx.getState();
    const data: any = { ...requestData };

    delete data.templateName;
    delete data.payCodeEnum;

    return this.productsService.getAccountBalance(data).pipe(
      map(response => {
        ctx.patchState({
          checkPaymentList: [
            ...(checkPaymentList.map(el => ({
              ...el,
              balance: el.balance ? el.balance : response.balanceAmount,
              templateName: el.templateName ? el.templateName : (requestData as any).templateName,
              payCodeEnum: (el as any).payCodeEnum ? (el as any).payCodeEnum : (requestData as any).payCodeEnum
            }))) || []
          ]
        });
      })
    );
  }


  @Action(PaymentActions.DepositPaymentClosingToCard)
  public depositTransferClosingToCard(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DepositPaymentClosingToCard) {
    return this.paymentService.depositTransferClosingToCard(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositPayment: response });
        })
      );
  }


  @Action(PaymentActions.PaymentCardTransferCurrency)
  public paymentCardNumberCurrency(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.PaymentCardTransferCurrency) {
    return this.paymentService.paymentCardNumberCurrency(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ byCardTransferCurrency: response });
        })
      );
  }


  @Action(PaymentActions.PaymentTransferInfo)
  public paymentTransferInfo(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.PaymentTransferInfo) {
    return this.paymentService.paymentTransferInfo(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ byCardTransferCurrency: response });
        })
      );
  }


  @Action(PaymentActions.DepositPaymentClosingToAccount)
  public depositTransferClosingToAccount(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DepositPaymentClosingToAccount) {
    return this.paymentService.depositTransferClosingToAccount(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositPayment: response });
        })
      );
  }


  @Action(PaymentActions.DepositTransferOpening)
  public depositTransferOpening(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DepositTransferOpening) {
    return this.paymentService.depositTransferOpening(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(getDepositTransferOpening => {
          ctx.patchState({ getDepositTransferOpening })
        })
      )
  }


  @Action(PaymentActions.BetweenTransferLimits)
  public betweenTransferLimits(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.BetweenTransferLimits) {
    return this.paymentService.betweenAccountLimits(requestData)
      .pipe(
        map(transferLimits => {
          ctx.patchState({ transferLimits })
        })
      )
  }


  @Action(PaymentActions.DepositPaymentTopUpToCard)
  public depositTransferTopUpToCard(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DepositPaymentTopUpToCard) {
    return this.paymentService.depositTransferTopUpFromCard(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositPayment: response });
        })
      );
  }


  @Action(PaymentActions.DepositPaymentTopUpToAccount)
  public depositTransferTopUpToAccount(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DepositPaymentTopUpToAccount) {
    return this.paymentService.depositTransferTopUpFromAccount(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositPayment: response });
        })
      );
  }


  @Action(PaymentActions.DepositPaymentWithdrawalToCard)
  public depositTransferToCard(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DepositPaymentWithdrawalToCard) {
    return this.paymentService.depositTransferWithdrawalFromCard(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositPayment: response });
        })
      );
  }


  @Action(PaymentActions.DepositPaymentWithdrawalToAccount)
  public depositTransferToAccount(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DepositPaymentWithdrawalToAccount) {
    return this.paymentService.depositTransferWithdrawalFromAccount(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ depositPayment: response });
        })
      );
  }


  @Action(PaymentActions.BetweenAccountsLimits)
  public betweenAccountsLimits(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.BetweenAccountsLimits) {
    return this.paymentService.betweenAccountsLimits(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ betweenAccountsLimits: response });
        })
      );
  }


  @Action(PaymentActions.TransferClientLimits)
  public transferClientLimits(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.TransferClientLimits) {
    return this.paymentService.transferClientLimits(requestData)
      .pipe(
        map(transferClientLimits => {
          ctx.patchState({ transferClientLimits });
        })
      );
  }


  @Action(PaymentActions.SendBetweenAccountsTransfer)
  public betweenAccountsTransfer(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.SendBetweenAccountsTransfer) {
    return this.paymentService.betweenAccountsTransfer(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(response => {
          ctx.patchState({ transferResponse: response });
        })
      );
  }


  @Action(PaymentActions.SendLoanPaymentInfo)
  public loanPaymentInfo(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.SendLoanPaymentInfo) {
    return this.paymentService.loanPaymentInfo(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ creditInfoResponse: response });
        })
      );
  }


  @Action(PaymentActions.SendLoanPayment)
  public loanPayment(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.SendLoanPayment) {
    return this.paymentService.loanPayment(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(response => {
          ctx.patchState({ transferResponse: response });
        })
      );
  }


  @Action(PaymentActions.TransferTypePayment)
  public transferTypePayment(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.TransferTypePayment) {
    return this.paymentService.transferType(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ transferTypeResponse: response })
        })
      )
  }


  @Action(PaymentActions.GetRestrictionPayment)
  public getRestrictionPayment(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.GetRestrictionPayment) {
    return this.paymentService.getRestriction(requestData)
      .pipe(
        map(response => {
          ctx.patchState({ getRestriction: response })
        })
      )
  }


  @Action(PaymentActions.DoTransactionPayment)
  public doTransactionPayment(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.DoTransactionPayment) {
    return this.paymentService.doTransaction(requestData)
      .pipe(
        map(doRestriction => {
          ctx.patchState({ doRestriction })
        })
      )
  }


  @Action(PaymentActions.GetTransactionStatus)
  public getTransactionStatus(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.GetTransactionStatus) {
    return this.paymentService.getTransactionStatus(requestData)
      .pipe(
        map(getTransactionStatus => {
          ctx.patchState({ getTransactionStatus })
        })
      )
  }


  @Action(PaymentActions.GetReceipt)
  public getReceipt(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.GetReceipt) {
    return this.paymentService.getReceipt(requestData)
      .pipe(
        map(getReceipt => {
          ctx.patchState({ getReceipt })
        })
      )
  }


  @Action(PaymentActions.GetEncryptionPublicKey)
  public getEncryptionPublicKey(ctx: StateContext<PaymentStateModel>) {
    return this.paymentService.getEncryptionPublicKey()
      .pipe(
        map(response => {
          ctx.patchState({ getEncryptedPublicKey: response })
        })
      )
  }


  @Action(PaymentActions.SetLoanNavigateToConditions)
  public setLoanNavigateToConditions(ctx: StateContext<PaymentStateModel>, { contractNumber }: PaymentActions.SetLoanRepaymentContractNumber) {
    return ctx.patchState({msiNavigateToConditions: true})
  }


  @Action(PaymentActions.SetLoanRepaymentContractNumber)
  public setLoanRepaymentContractNumber(ctx: StateContext<PaymentStateModel>, { contractNumber }: PaymentActions.SetLoanRepaymentContractNumber) {
    return ctx.patchState({loanRepaymentContractNumber: contractNumber})
  }


  @Action(PaymentActions.ClearLoanRepaymentContractNumber)
  public clearLoanRepaymentContractNumber(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearLoanRepaymentContractNumber) {
    return ctx.patchState({loanRepaymentContractNumber: undefined})
  }


  @Action(PaymentActions.GetAvailablePaymentActions)
  public getAvailablePaymentActions(ctx: StateContext<PaymentStateModel>) {
    return this.paymentService.getAvailablePaymentActions()
      .pipe(
        map(getAvailableActions => {
          ctx.patchState({ getAvailableActions })
        })
      )
  }


  @Action(PaymentActions.GetPaymentTopUpTransferGpl)
  public getPaymentTopUpTransferGpl(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.GetPaymentTopUpTransferGpl) {
    return this.paymentService.paymentTopUpTransferGpl({ payCode: { payCodeEnum: requestData.payCode?.payCodeEnum as string } })
      .pipe(
        map(getPaymentTopUpTransferGpl => {
          ctx.patchState({ getPaymentTopUpTransferGpl })
        })
      )
  }


  @Action(PaymentActions.PaymentTopUpTransferRoc)
  public paymentTopUpTransferRoc(
    ctx: StateContext<PaymentStateModel>,
    {
      requestData,
      isOnePay
    }: PaymentActions.PaymentTopUpTransferRoc,
  ) {
    const { checkPaymentList } = ctx.getState();

    const requestWithoutName: any = {
      ...requestData,
      confirmationData: requestData.confirmationData,
      confirmationKey: requestData.confirmationKey
    };

    delete requestWithoutName.requestData;
    delete requestWithoutName.templateName;
    delete requestWithoutName.payCodeEnum;

    const data = isOnePay ? requestWithoutName : requestData;
    const confirmAction = isOnePay ?
      {
        confirmationData: (requestData as any).requestData.confirmationData,
        confirmationKey: (requestData as any).requestData.confirmationKey
      } :
      data;

    return this.paymentService.paymentTopUpTransferRoc(data, ConfirmationUtils.addHeaderConfirmationData(confirmAction))
      .pipe(
        map(getPaymentTransferRoc => {
          if (isOnePay) {
            ctx.patchState({
              checkPaymentList: [
                ...(checkPaymentList || []),
                {
                  ...getPaymentTransferRoc,
                  templateName: (getPaymentTransferRoc as any).templateName ? (getPaymentTransferRoc as any).templateName : (requestData as any).templateName,
                  payCodeEnum: (getPaymentTransferRoc as any).payCodeEnum ? (getPaymentTransferRoc as any).payCodeEnum : (requestData as any).payCodeEnum
                }
              ]
            });
          } else {
            ctx.patchState({
              getPaymentTransferRoc
            });
          }
        })
      )
  }


  @Action(PaymentActions.GetPaymentTemplates)
  public getPaymentTemplates(ctx: StateContext<PaymentStateModel>): PaymentActions.GetPaymentTemplates {
    return this.paymentService.getPaymentTemplate()
      .pipe(
        map(paymentTemplates => {
          ctx.patchState({ paymentTemplates })
        })
      )
  }


  @Action(PaymentActions.CreatePaymentTemplate)
  public createPaymentTemplates(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.CreatePaymentTemplate) {
    return this.paymentService.createPaymentTemplate(requestData)
      .pipe(
        map(createTemplate => {
          ctx.patchState({ createTemplate })
        })
      )
  }


  @Action(PaymentActions.UpdatePaymentTemplate)
  public updatePaymentTemplates(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.UpdatePaymentTemplate) {
    return this.paymentService.updatePaymentTemplate(requestData)
      .pipe(
        map(updateTemplate => {
          ctx.patchState({ updateTemplate })
        })
      )
  }


  @Action(PaymentActions.DeletePaymentTemplate)
  public deletePaymentTemplates(ctx: StateContext<PaymentStateModel>, { id }: PaymentActions.DeletePaymentTemplate) {
    return this.paymentService.deletePaymentTemplate(id)
      .pipe(
        map(deleteTemplate => {
          ctx.patchState({ deleteTemplate })
        })
      )
  }


  @Action(PaymentActions.GetOperationHistory)
  public getOperationHistory(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.GetOperationHistory) {
    return this.paymentService.getOperationHistory(requestData)
      .pipe(
        map(getOperationHistory => {
          ctx.patchState({ getOperationHistory })
        })
      )
  }


  @Action(PaymentActions.GetLoanProducts)
  public getLoanProducts(ctx: StateContext<PaymentStateModel>, _: PaymentActions.GetLoanProducts) {
    return this.paymentService.getLoanProducts()
      .pipe(
        map(loanProductsList => {
          ctx.patchState({ loanProductsList })
        })
      )
  }


  @Action(PaymentActions.SkipLoanProducts)
  public skipLoanProducts(ctx: StateContext<PaymentStateModel>, _: PaymentActions.SkipLoanProducts) {
    ctx.patchState({ skipLoanProducts: true })
  }


  @Action(PaymentActions.ContinueLoanProducts)
  public continueLoanProducts(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ContinueLoanProducts) {
    ctx.patchState({ skipLoanProducts: false })
  }


  @Action(PaymentActions.PutLoanProducts)
  public putLoanProducts(ctx: StateContext<PaymentStateModel>, {loanProducts}: PaymentActions.PutLoanProducts) {
    ctx.patchState({ loanProducts })
  }


  @Action(PaymentActions.PutLoanIssuePreventAlternativeClear)
  public putLoanIssuePreventAlternativeClear(ctx: StateContext<PaymentStateModel>, {flag}: PaymentActions.PutBackHandlerClearFlag) {
    ctx.patchState({ loanIssuePreventAlternativeClear: flag });
  }


  @Action(PaymentActions.PutBackHandlerClearFlag)
  public putBackHandlerFlag(ctx: StateContext<PaymentStateModel>, {flag}: PaymentActions.PutBackHandlerClearFlag) {
    ctx.patchState({ backHandlerFlag: flag });
  }


  @Action(PaymentActions.PutBackAlternativeHandler)
  public putBackAlternativeHandler(ctx: StateContext<PaymentStateModel>, {handler}: PaymentActions.PutBackAlternativeHandler) {
    const storedValue = ctx.getState().backHandlerAlternative;
    const storedValueUnion = [handler].concat(storedValue)
    if (!storedValue || !storedValue.length)
      ctx.patchState({backHandlerAlternative: [handler]})
    else ctx.patchState({ backHandlerAlternative: storedValueUnion });
  }


  @Action(PaymentActions.ClearBackAlternativeHandler)
  public clearBackAlternativeHandler(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearBackAlternativeHandler) {
    const storedValue = ctx.getState().backHandlerAlternative;
    const rest = storedValue.slice(1);
    if (!storedValue || !storedValue.length)
      ctx.patchState({backHandlerAlternative: undefined})
    else {
      ctx.patchState({ backHandlerAlternative: rest });
    }
  }


  @Action(PaymentActions.PutBackHandler)
  public putBackHandler(ctx: StateContext<PaymentStateModel>, {handler}: PaymentActions.PutBackHandler) {
    ctx.patchState({ backHandler: handler })
  }


  @Action(PaymentActions.ClearBackHandler)
  public clearBackHandler(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearBackHandler) {
    ctx.patchState({ backHandler: undefined })
  }


  @Action(PaymentActions.PutIssueBackHandler)
  public putIssueBackHandler(ctx: StateContext<PaymentStateModel>, {handler}: PaymentActions.PutIssueBackHandler) {
    ctx.patchState({ backHandlerIssue: handler })
  }


  @Action(PaymentActions.ClearIssueBackHandler)
  public clearIssueBackHandler(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearIssueBackHandler) {
    ctx.patchState({ backHandlerIssue: undefined })
  }


  @Action(PaymentActions.PutConditionsBackInterruptHandler)
  public putConditionsInterruptBackHandler(ctx: StateContext<PaymentStateModel>, {handler}: PaymentActions.PutConditionsBackInterruptHandler) {
    ctx.patchState({ backHandlerInterruptConditions: handler })
  }


  @Action(PaymentActions.ClearConditionsBackInterruptHandler)
  public clearConditionsInterruptBackHandler(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearConditionsBackInterruptHandler) {
    ctx.patchState({ backHandlerInterruptConditions: undefined })
  }


  @Action(PaymentActions.PutConditionsBackHandler)
  public putConditionsBackHandler(ctx: StateContext<PaymentStateModel>, {handler}: PaymentActions.PutConditionsBackHandler) {
    ctx.patchState({ backHandlerConditions: handler })
  }


  @Action(PaymentActions.ClearConditionsBackHandler)
  public clearConditionsBackHandler(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearConditionsBackHandler) {
    ctx.patchState({ backHandlerConditions: undefined })
  }


  @Action(PaymentActions.GetLoanStatus)
  public getLoanStatus(ctx: StateContext<PaymentStateModel>, _: PaymentActions.GetLoanStatus) {
    return this.paymentService.getLoanStatus()
      .pipe(
        map(loanProducts => {
          ctx.patchState({ loanProducts })
        })
      )
  }


  @Action(PaymentActions.ClearApplicationCodeStatus)
  public clearApplicationCodeStatus(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearApplicationCodeStatus) {
    ctx.patchState({ applicationCodeStatus: undefined });
  }


  @Action(PaymentActions.GetApplicationCodeStatus)
  public getApplicationCodeStatus(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.GetApplicationCodeStatus) {
    return this.paymentService.getApplicationCodeStatus(requestData).pipe(
      map((applicationCodeStatus: OnlineApplication) => {
        ctx.patchState({ applicationCodeStatus });
      })
    );
  }


  @Action(PaymentActions.SwapApplicationCodeStatus)
  public swapApplicationCodeStatus(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.SwapApplicationCodeStatus) {
    const currentValue = ctx.getState().alternativeLoanProduct;
    const requestDataUnion = [requestData].concat(currentValue);
    if (!currentValue || currentValue.length === 0)
      ctx.patchState({ alternativeLoanProduct: [requestData] })
    else {
      ctx.patchState({ alternativeLoanProduct: requestDataUnion });
    }
  }


  @Action(PaymentActions.ClearLoanAlternative)
  public clearAlternativeLoanProduct(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearLoanAlternative) {
    const storedValue = ctx.getState().alternativeLoanProduct;
    if (!storedValue || !storedValue.length)
      ctx.patchState({alternativeLoanProduct: undefined})
    else {
      const rest = storedValue.slice(1)
      ctx.patchState({ alternativeLoanProduct: rest });
    }
  }


  @Action(PaymentActions.GetPaymentSchedule)
  public getPaymentSchedule(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.GetPaymentSchedule) {
    return this.paymentService.getPaymentSchedule(requestData).pipe(
      map((paymentSchedule: PaymentInterface.PaymentSchedule) => {
        ctx.patchState({ paymentSchedule });
      })
    );
  }


  @Action(PaymentActions.RestoreSelectLoanApplication)
  public restoreSelectLoanApplication(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.RestoreSelectLoanApplication) {
    ctx.patchState({selectedLoanApplication: requestData})
  }


  @Action(PaymentActions.SelectLoanApplication)
  public selectLoanApplication(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.SelectLoanApplication) {
    ctx.patchState({selectedLoanApplication: requestData})
  }


  @Action(PaymentActions.FixOffer)
  public fixOffer(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.FixOffer) {
    ctx.patchState({fixOfferRequestData: requestData})
    const clearRequest = {
      ...requestData,
      selectedCode: undefined
    }
    return this.paymentService.fixOffer(clearRequest).pipe(
      map((fixOffer: PaymentInterface.FixOfferRs) => {
        ctx.patchState({ fixOffer });
      })
    );
  }

  @Action(PaymentActions.GetLoanForm)
  public getLoanForm(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.GetLoanForm) {
    return this.paymentService.getLoanForm(requestData)
      .pipe(
        map(loanForm => {
          ctx.patchState({loanForm})
        })
      )
  }


  @Action(PaymentActions.GetLoanViewDocument)
  public getLoanViewDocument(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.GetLoanViewDocument) {
    return this.paymentService.getLoanViewDocument(requestData)
      .pipe(
        map(loanDocument => {
          ctx.patchState({loanDocument})
        })
      );
  }


  @Action(PaymentActions.GetConsentRecording)
  public getConsentReporting(ctx: StateContext<PaymentStateModel>, action: PaymentActions.GetConsentRecording): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      loanConsent: action.payload
    });
  }


  @Action(PaymentActions.GetLoanIssue)
  public getLoanIssue(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.GetLoanIssue) {
    return this.paymentService.getLoanIssue(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData))
      .pipe(
        map(loanIssueResponse => {
          ctx.patchState({loanIssueResponse})
        })
      )
  }


  @Action(PaymentActions.RestoreOffers)
  public restoreOffers(ctx: StateContext<PaymentStateModel>, {offer}: PaymentActions.RestoreOffers) {
    ctx.patchState({ offer });
  }


  @Action(PaymentActions.GetOffers)
  public getOffers(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.GetOffers) {
    return this.paymentService.getOffers(requestData).pipe(
      map((offer: PaymentInterface.Offer) => {
        this.sessionStorageService.setValue("getoffers", JSON.stringify(offer));
        ctx.patchState({ offer });
      })
    );
  }


  @Action(PaymentActions.SaveUserCreditInputSchedule)
  public saveUserCreditInputSchedule(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.SaveUserCreditInputSchedule) {
    ctx.patchState({ userCreditInput: requestData });
  }


  @Action(PaymentActions.PopUserCreditInputSchedule)
  public popUserCreditInputSchedule(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.GetOffers) {
    ctx.patchState({ userCreditInput: undefined });
  }


  @Action(PaymentActions.LoanVoid)
  public loanVoid(ctx: StateContext<PaymentStateModel>, {requestData}: PaymentActions.LoanVoid) {
    return this.paymentService.loanVoid(requestData)
      .pipe(
        map(loanIssueResponse => {
          ctx.patchState({loanIssueResponse})
        })
      )
  }


  @Action(PaymentActions.CheckSignDocument)
  public checkSignDocument(ctx: StateContext<PaymentStateModel>, { data }: PaymentActions.CheckSignDocument) {
    return this.paymentService.checkSignDocument(data).pipe(
      map((response: PaymentInterface.CheckSignDocumentRs) => {
        ctx.patchState({ checkSignDocumentResponse: response });
      })
    );
  }


  @Action(PaymentActions.SignFinalDocuments)
  public signFinalDocuments(ctx: StateContext<PaymentStateModel>, { data }: PaymentActions.SignFinalDocuments) {
    return this.paymentService.signFinalDocuments(data).pipe(
      map((response: PaymentInterface.SignFinalDocumentsRs) => {
        ctx.patchState({ signFinalDocumentsResponse: response });
      })
    );
  }


  @Action(PaymentActions.GetApplicationByNumber)
  public getApplicationByNumber(ctx: StateContext<PaymentStateModel>, { applicationNumber }: PaymentActions.GetApplicationByNumber) {
    return this.paymentService.getApplicationByNumber(applicationNumber).pipe(
      map((partnerApplicationDetail: PaymentInterface.ApplicationResponse) => {
        ctx.patchState({ partnerApplicationDetail });
      })
    );
  }


  @Action(PaymentActions.ReviewApplication)
  public reviewApplication(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.ReviewApplication) {
    return this.paymentService.reviewApplication(requestData).pipe(
      map(() => {
        ctx.patchState({});
      })
    );
  }


  @Action(PaymentActions.VoidApplication)
  public voidApplication(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.VoidApplication) {
    return this.paymentService.voidApplication(requestData).pipe(
      map((response: PaymentInterface.VoidApplicationRs) => {
        ctx.patchState({ voidResponse: response });
      })
    );
  }


  @Action(PaymentActions.SaveLoanVoid)
  public saveLoanVoid(ctx: StateContext<PaymentStateModel>, _: PaymentActions.SaveLoanVoid) {
    return ctx.patchState({loanVoid: true})
  }


  @Action(PaymentActions.ClearLoanVoid)
  public clearLoanVoid(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearLoanVoid) {
    return ctx.patchState({loanVoid: false})
  }


  @Action(PaymentActions.ClearLoanCheck)
  public clearLoanCheck(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearLoanCheck) {
    return ctx.patchState({loanCheckInfo: undefined})
  }


  @Action(PaymentActions.SaveLoanConditionData)
  public saveLoanConditionsData(ctx: StateContext<PaymentStateModel>, {loanConditionsData}: PaymentActions.SaveLoanConditionData) {
    return ctx.patchState({loanConditionsData})
  }


  @Action(PaymentActions.ClearLoanConditionData)
  public clearLoanConditionsData(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearLoanConditionData) {
    return ctx.patchState({loanConditionsData: undefined})
  }


  @Action(PaymentActions.SaveLoanCheck)
  public saveLoanCheck(ctx: StateContext<PaymentStateModel>, {loanCheckInfo}: PaymentActions.SaveLoanCheck) {
    return ctx.patchState({loanCheckInfo})
  }


  @Action(PaymentActions.SaveLoanApplicationFilled)
  public saveLoanApplicationFilled(ctx: StateContext<PaymentStateModel>, {loanApplicationFill}: PaymentActions.SaveLoanApplicationFilled) {
    return ctx.patchState({loanApplicationFill})
  }


  @Action(PaymentActions.ClearLoanApplicationFilled)
  public clearLoanApplicationFilled(ctx: StateContext<PaymentStateModel>, _: PaymentActions.ClearLoanApplicationFilled) {
    return ctx.patchState({loanApplicationFill: undefined})
  }


  @Action(PaymentActions.SetBePaidSources)
  public setBePaidSources(ctx: StateContext<PaymentStateModel>, action: PaymentActions.SetBePaidSources) {
    const state = ctx.getState();

    ctx.patchState({
      ...state,
      paymentSources: action.payload
    })
  }


  @Action(PaymentActions.ClearPaymentSources)
  public clearSources(ctx: StateContext<PaymentStateModel>) {
    ctx.patchState({
      paymentSources: null
    });
  }


  @Action(PaymentActions.UpdateAutoPayment)
  public updateAutoPay(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.UpdateAutoPayment) {
    return this.paymentService.updateAutoPayment(requestData).pipe(
      map((crudAutoPayment: HttpError): void => {
        ctx.patchState({ crudAutoPayment });
      })
    );
  }


  @Action(PaymentActions.CreateAutoPayment)
  public createAutoPay(ctx: StateContext<PaymentStateModel>, { requestData }: PaymentActions.CreateAutoPayment) {
    return this.paymentService.createAutoPayment(requestData).pipe(
      map((crudAutoPayment: HttpError): void => {
        ctx.patchState({ crudAutoPayment });
      })
    );
  }


  @Action(PaymentActions.DeleteAutoPayById)
  public deleteAutoPayment(ctx: StateContext<PaymentStateModel>, { id }: PaymentActions.DeleteAutoPayById) {
    return this.paymentService.deleteAutoPayment(id).pipe(
      map((crudAutoPayment: HttpError): void => {
        ctx.patchState({ crudAutoPayment });
      })
    );
  }
}
