import { AfterContentInit, Component, ContentChildren, Input, OnDestroy, QueryList } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { from, mergeMap, Subject } from 'rxjs';
import { FormControlValue } from '../../../../../interfaces/control.interface';
import { RadioButtonComponent } from '../radio-button/radio-button.component';


@Component({
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: [ './radio-group.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RadioGroupComponent
    }
  ]
})
export class RadioGroupComponent implements ControlValueAccessor, AfterContentInit, OnDestroy {

  @ContentChildren(RadioButtonComponent)
  public buttons!: QueryList<RadioButtonComponent>;


  @Input({ required: true })
  public name!: string;


  @Input()
  set value(val: FormControlValue) {
    this._value = val;
    this.onChange(this._value);
  }


  get value() {
    return this._value;
  }


  private _value!: FormControlValue;

  private onChange = (value: FormControlValue) => {};
  private onTouched = () => {};

  private destroy$ = new Subject<void>();


  constructor() {}


  public ngAfterContentInit(): void {
    const checkEvents$ = this.buttons.map(i => i.checkEvent);

    from(checkEvents$).pipe(
      mergeMap(checkEvent => checkEvent),
      takeUntilDestroyed()
    ).subscribe(button => this.onButton(button));

    if (!this.value && this.buttons.length && !this.buttons.some(i => i.checked)) {
      this.buttons.first.checked = true;
    }

    if (this.value && this.buttons.length) {
      const button = this.buttons.find(i => i.value === this.value);

      button ? button.checked = true : false;
    }
  }


  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }


  public registerOnChange(fn: (value: FormControlValue) => object): void {
    this.onChange = fn;
  }


  public registerOnTouched(fn: () => object): void {
    this.onTouched = fn;
  }


  public writeValue(value: FormControlValue): void {
    const button = this.buttons?.find(i => i.value === value);
    this.onButton(button);

    this.value = value;
  }


  public onButton(button?: RadioButtonComponent) {
    if (button) {
      this.uncheckAll();
      button.checked = true;
    }
  }


  private uncheckAll(): void {
    this.buttons.forEach(i => i.checked = false);
  }
}
