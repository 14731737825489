import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlValue } from '../../../../../interfaces/control.interface';
import { InputComponent } from '../../impl/input.component';


@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: [ './input-password.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputPasswordComponent
    }
  ]
})
export class InputPasswordComponent extends InputComponent {

  public showPass!: boolean;

  @Input()
  public override id!: string | number;

  @Input()
  public override disabled!: boolean;

  @Input()
  public invalid!: boolean;

  @Input()
  public label!: string;

  @Input()
  public display: 'inline' | 'block' = 'block';

  @Input()
  public mask!: string;

  @Input()
  public placeholder: string = '';

  @Output()
  public focused: EventEmitter<boolean> = new EventEmitter<boolean>();


  @Input()
  override set value(val: FormControlValue) {
    this._value = val;

    this.onChange(this._value);
  }


  override get value(): FormControlValue {
    return this._value;
  }


  public onInputFocus(): void {
    this.focused.emit(true);
  }


  public onBlurEvent(): void {
    this.focused.emit(false);
  }


  public onControlClick(): void {
    const inputElement = document.getElementById(this.id.toString()) as HTMLInputElement;

    if (inputElement) inputElement.focus();
  }
}
