<div class="date-range" [ngClass]="{ 'open-range': isOpen }">
  <span class="date-range_label" [ngClass]="{ 'error' : invalid, 'black' : !invalid }">{{ label }}</span>
    <div class="date-range_select" tabindex="0"
         [ngClass]="{
          'focus' : isOpen && !invalid,
          'blur': !isOpen && !invalid,
          'error-border': invalid,
          'mb-8': isOnlyCalendar
         }"
         (click)="toggleDropdown()"
    >
      <div class="date-range_select__date">
          <span *ngIf="selectedDateRange && (selectedDateRange.start || selectedDateRange.end)">
              {{ selectedDateRange.start | customDate }}-{{ selectedDateRange.end | customDate }}
          </span>
          <span class="date-range_select__date_placeholder" *ngIf="!selectedDateRange">
              {{ placeholder | translate }}
          </span>
      </div>
      <span *ngIf="!isOnlyCalendar" class="arrow-icon">
          <i [ngClass]="{ 'icon icon-arrow-drop-down' : !isOpen, 'icon icon-arrow-drop-up' : isOpen }"></i>
      </span>

      <span *ngIf="isOnlyCalendar" class="arrow-icon-calendar">
        <i class="icon icon-calendar-empty"></i>
      </span>
    </div>

    <ul class="date-range_options" *ngIf="isOpen">
        <ng-container *ngFor="let preset of range">
            <li (click)="selectPreset(preset.range, preset.label)">
              <span class="date-range_options__text">{{ preset.label | translate }}</span>
              <div *ngIf="selectedDateRange">
                <i *ngIf="compareDateRangeObj(this.selectedDateRange, preset.range)" class="icon icon-Check-21"></i>
              </div>
              <div *ngIf="preset.label === datePresetLabel.OTHER_PERIOD">
                <i class="icon icon-arrow-drop-right"></i>
              </div>
            </li>
        </ng-container>
    </ul>
    <div class="date-range_calendar" *ngIf="isCalendarVisible">
        <mat-calendar
          #calendar
          [maxDate]="maxDate"
          [(selected)]="selectedDateRange!"
          (selectedChange)="rangeChanged($event!)"
        ></mat-calendar>
    </div>
</div>
<div class="error" *ngIf="invalid">{{ errorMessage.MANDATORY_FIELD | translate }}</div>
