import { HttpRequest } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ApiConfig } from '../config/api.config';
import { Lang } from '../enums/lang.enum';
import { ProductsEnum } from '../enums/products.enum';
import { SharedEnum } from '../enums/shared.enum';
import { HttpError } from '../interfaces/http.interface';
import { ErrorPopupInterface, PopupInterface } from '../interfaces/popup.interface';
import { ErrorPopupComponent } from '../views/shared/components/popups/error-popup/error.popup.component';
import { PopupComponent } from '../views/shared/components/popups/popup/popup.component';
import { ConfirmationUtils } from './confirmation.utils';


export const errorUtils = (request: HttpRequest<any>) => {
  return {
    params: request.url.includes('params'),
    identify: request.url.includes('identify'),
    exchangeRates: request.url.includes('exchange-rates'),
    consent: request.url.includes('consent'),
    totalAmounts: request.url.includes('total-amounts'),
    paymentDeposit: request.url.includes('payment/deposit/transfer'),
    popupError: request.url.includes(ConfirmationUtils.urlForConfirmationCheck(ApiConfig.PAYMENT_CARD_NUMBER_TRANSFER))
  };
};


export function matchErrorMessageText(message: string): string {
  const errorMess = message.match(/ErrorMess=(.*?)(?=})/)?.[1] || '';

  return errorMess ? errorMess : message;
}


export function dialogErrorData(errorMessage: string, statusCode?: string, translate?: TranslateService): ErrorPopupInterface<string> {
  return {
    icon: 'icon icon-cross',
    title: translate ? translate.instant(ProductsEnum.ProductDialogTitle.ATTENTION) : ProductsEnum.ProductDialogTitle.ATTENTION,
    text: errorMessage,
    buttonText: ProductsEnum.ProductDialogButtonText.CONTINUE,
    buttonType: SharedEnum.ButtonTypes.PRIMARY,
    props: statusCode
  };
}


export function openDialogBlockPlatformConfirm(
  error: HttpError,
  dialog: MatDialog,
  language: string
): void {
  const redirectUrl: string = 'https://www.paritetbank.by/private/';
  const data: PopupInterface<any> = {
    title: ProductsEnum.ProductDialogTitle.ATTENTION,
    subTitle: error.message,
    buttonText: TextByAcceptLanguage(language),
    buttonType: SharedEnum.ButtonTypes.PRIMARY,
    isInputField: false,
    inputLabel: null,
    props: error
  };

  dialog.open(PopupComponent, { data })
    .afterClosed()
    .subscribe(data => {
      if (data === SharedEnum.PopupCloseStatus.SUBMIT) {
        window.open(error && error.redirectUrl ? error.redirectUrl : redirectUrl, '__blank');
      }
    });
}


export function showErrorConnectionDialog(dialog: MatDialog): void {
  const data: ErrorPopupInterface<string> = {
    title: 'MAIN.ERROR_CONNECTION',
    text: 'MAIN.ERROR_CONNECTION_TEXT',
    icon: 'icon icon-cross',
    buttonText: ProductsEnum.ProductDialogButtonText.CONTINUE,
    buttonType: SharedEnum.ButtonTypes.PRIMARY,
    props: undefined
  };

  dialog.open(ErrorPopupComponent, { data });
}


const TextByAcceptLanguage = (lang: string): string => {
  if (lang === Lang.RU) {
    return 'Просмотреть';
  } else if (lang === Lang.BE) {
    return 'Прагледзець';
  } else {
    return 'View';
  }
};
