export enum MsiDictNames {
  DictionarySex = 'DICTIONARY_SEX',
  DictionaryCountry = 'DICTIONARY_COUNTRY',
  DictionaryLocalityType = 'DICTIONARY_LOCALITY_TYPE',
  DictionaryStreetType = 'DICTIONARY_STREET_TYPE',
}


export enum MsiQuestFieldType {
  Text = 'TEXT',
  Boolean = 'BOOLEAN',
  Date = 'DATE',
  Pass = 'PASS',
  SOATO = 'SOATO',
}


export enum MsiExecuteQuery {
  Cancel = 'cancel',
  Error = 'error',
  Code = 'code',
  State = 'state',
}


export enum NsiNames {
  StreetType = '1',
  DocumentType = '2',
  CountryCodes = '3',
  Sex = '4',
  CodesSOATO = '5',
  LocalityTypeIdentifier = '6',
}


export enum MsiQuestion {
  CONTACT_PHONE = 'CONTACT_PHONE',
  EMAIL = 'EMAIL',
  CODEWORD = 'CODEWORD',
  SUBJECT_BIRTH_PLACE_COUNTRY = 'SUBJECT_BIRTH_PLACE_COUNTRY',
  SUBJECT_BIRTH_PLACE_LOCALITY_TYPE = 'SUBJECT_BIRTH_PLACE_LOCALITY_TYPE',
  SUBJECT_BIRTH_PLACE_LOCALITY = 'SUBJECT_BIRTH_PLACE_LOCALITY',
  SUBJECT_BIRTH_PLACE_SOATO = 'SUBJECT_BIRTH_PLACE_SOATO',
  SUBJECT_BIRTH_PLACE_DISTRICT = 'SUBJECT_BIRTH_PLACE_DISTRICT',
  SUBJECT_BIRTH_PLACE_REGION = 'SUBJECT_BIRTH_PLACE_REGION',
  SUBJECT_ADDRESS_COUNTRY = 'SUBJECT_ADDRESS_COUNTRY',
  SUBJECT_ADDRESS_LOCALITY = 'SUBJECT_ADDRESS_LOCALITY',
  SUBJECT_ADDRESS_SOATO = 'SUBJECT_ADDRESS_SOATO',
  SUBJECT_ADDRESS_STREET_TYPE = 'SUBJECT_ADDRESS_STREET_TYPE',
  SUBJECT_ADDRESS_STREET = 'SUBJECT_ADDRESS_STREET',
  SUBJECT_ADDRESS_HOUSE = 'SUBJECT_ADDRESS_HOUSE',
  SUBJECT_ADDRESS_BUILDING = 'SUBJECT_ADDRESS_BUILDING',
  SUBJECT_ADDRESS_APARTMENT = 'SUBJECT_ADDRESS_APARTMENT',
  SUBJECT_ADDRESS_POSTAL_CODE = 'SUBJECT_ADDRESS_POSTAL_CODE',
  SUBJECT_ADDRESS_REGION = 'SUBJECT_ADDRESS_REGION',
  SUBJECT_ADDRESS_DISTRICT = 'SUBJECT_ADDRESS_DISTRICT',
  SUBJECT_ADDRESS_UNITARY_AREA = 'SUBJECT_ADDRESS_UNITARY_AREA',
  SUBJECT_ADDRESS_LOCALITY_TYPE = 'SUBJECT_ADDRESS_LOCALITY_TYPE',
  CONTACT_LIVING_ADDRESS_EQ_REGISTRATION = 'CONTACT_LIVING_ADDRESS_EQ_REGISTRATION',
  CONTACT_LIVING_ADDRESS_COUNTRY = 'CONTACT_LIVING_ADDRESS_COUNTRY',
  CONTACT_LIVING_ADDRESS_REGION = 'CONTACT_LIVING_ADDRESS_REGION',
  CONTACT_LIVING_ADDRESS_DISTRICT = 'CONTACT_LIVING_ADDRESS_DISTRICT',
  CONTACT_LIVING_ADDRESS_UNITARY_AREA = 'CONTACT_LIVING_ADDRESS_UNITARY_AREA',
  CONTACT_LIVING_ADDRESS_LOCALITY_TYPE = 'CONTACT_LIVING_ADDRESS_LOCALITY_TYPE',
  CONTACT_LIVING_ADDRESS_LOCALITY = 'CONTACT_LIVING_ADDRESS_LOCALITY',
  CONTACT_LIVING_ADDRESS_SOATO = 'CONTACT_LIVING_ADDRESS_SOATO',
  CONTACT_LIVING_ADDRESS_STREET_TYPE = 'CONTACT_LIVING_ADDRESS_STREET_TYPE',
  CONTACT_LIVING_ADDRESS_STREET = 'CONTACT_LIVING_ADDRESS_STREET',
  CONTACT_LIVING_ADDRESS_HOUSE = 'CONTACT_LIVING_ADDRESS_HOUSE',
  CONTACT_LIVING_ADDRESS_BUILDING = 'CONTACT_LIVING_ADDRESS_BUILDING',
  CONTACT_LIVING_ADDRESS_APARTMENT = 'CONTACT_LIVING_ADDRESS_APARTMENT',
  CONTACT_LIVING_ADDRESS_POSTAL_CODE = 'CONTACT_LIVING_ADDRESS_POSTAL_CODE'
}


export enum MsiErrorMessage {
  MISSING_CONTACT_PHONE = 'Missing contact phone number.',
  SESSION_TOKEN_DEFINED = 'SessionToken not defined. Perhaps the call to this method was called in a pre-authorization zone.',
  MSI_NOT_DEFINED = 'RedirectUrl not defined.',
  MSI_SATE_MESSAGE = 'MsiState - Object type MsiStateModelQuestionnaire not defined.'
}
