import { ChangeDetectorRef, Component, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { PopupInterface } from '../../../../../interfaces/popup.interface';
import { ValidationConfig } from '../../../../../config/validation.config';
import { ValidationErrorMessageText, ValidatorToken } from '../../../../../enums/validation.enum';
import { ButtonTypes } from '../../../../../config/shared.config';
import { SharedEnum } from '../../../../../enums/shared.enum';
import { TimerService } from '../../../../../services/timer.service';
import { DateFormat } from '../../../../../utils/date.utils';
import { finalize, Subject, Subscription, takeUntil, tap } from 'rxjs';
import { Store } from '@ngxs/store';
import { ServicesActions } from '../../../../../models/services/state/services.actions';
import { ProductsEnum } from '../../../../../enums/products.enum';
import { TranslateService } from "@ngx-translate/core";


interface FormValidation {
  maxLength: number;
}


interface Form {
  name: FormControl<string>;
}


@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: [ './popup.component.scss' ]
})
export class PopupComponent<T> implements OnInit, OnDestroy {

  public type = ButtonTypes;

  public popupStatus = SharedEnum.PopupCloseStatus;
  public countdown: string = '';

  public label!: string | null;

  public isInputNeed!: boolean;
  public isRequired!: boolean;
  public mask?: string;
  public checkMarkTitle?: boolean;

  public submitted!: boolean

  public form!: FormGroup<Form>;
  public formValidation!: FormValidation;
  public disableButton!: boolean;
  public displayButtonText!: string;
  public maxLength!: number;
  private destroy$ = new Subject<void>();

  private timerSubscription!: Subscription;
  private repeatSms!: string;
  private repeatSmsWait!: string;


  constructor(public dialogRef: MatDialogRef<PopupComponent<T>>,
              private readonly translateService: TranslateService,
              private readonly store: Store,
              public cdr: ChangeDetectorRef,
              private readonly timer: TimerService,
              @Inject(MAT_DIALOG_DATA) public data: PopupInterface<T>,
              private readonly fb: FormBuilder) {
    this.repeatSmsWait = this.translateService.instant(ProductsEnum.SMSText.REPEAT_SMS_WAIT);
    this.repeatSms = this.translateService.instant(ProductsEnum.SMSText.REPEAT_SMS);
    this.type = data.buttonType as typeof ButtonTypes;
    this.label = data.inputLabel;
    this.isInputNeed = data.isInputField;
    this.isRequired = data.isRequiredCtrl as boolean;
    this.mask = data.mask;
    this.checkMarkTitle = data.checkMarkTitle;

    if (data.timeStart) {
      this.countdown = ' '.concat(DateFormat.millisecondsToMinutesSeconds(data.timeStart));
      this.timerSubscription = this.timer.on().pipe(takeUntil(this.destroy$), finalize(() => this.timer.stop()))
        .subscribe(countdown => {
          if (countdown) {
            this.countdown = ' ' + countdown;
          } else {
            this.timer.stop();
            this.timerSubscription.unsubscribe();
            this.disableButton = false;
          }
        });
    }
    if (data.timeStart) {
      this.timer.start(data.timeStart);
    }
  }


  public computeButtonText(): string {
    if (this.data.timeStart) {
      if (this.form.controls.name.value && this.form.valid) {
        this.disableButton = false;
        this.displayButtonText = this.data.buttonText;

        this.isRequired ? this.addControlNameValidation() : {};
      } else if (!this.countdown || this.countdown === ' ' || this.countdown === ' 0:01') {
        this.disableButton = false;
        this.displayButtonText = this.repeatSms;

        this.clearControlValidation();
      } else {
        this.disableButton = true;
        this.displayButtonText = this.repeatSmsWait + this.countdown;
      }
    } else {
      this.disableButton = false;
      this.displayButtonText = this.data.buttonText;

      this.isRequired ? this.addControlNameValidation() : {};
    }

    return this.displayButtonText;
  }


  public focusIn(): void {
    this.submitted = false;
  }


  public focusOut(): void {
    this.submitted = false;
  }


  public ngOnInit(): void {
    this.form = this.createForm();
    this.computeButtonText();
  }


  public ngOnDestroy(): void {
    this.timer.stop();
    this.destroy$.next();
    this.destroy$.complete();
  }


  public closePopup(): void {
    this.dialogRef.close(this.popupStatus.CLOSED);
  }


  public submitPopup(): void {
    this.dialogRef.close(this.popupStatus.SUBMIT);
  }


  public getError(control: FormControl<string>): any {
    if (control.hasError(ValidatorToken.Required)) {
      return this.translateService.instant(ValidationErrorMessageText.MANDATORY_FIELD);
    }
    if (control.hasError(ValidatorToken.MaxLength)) {
      return this.translateService.instant(ValidationErrorMessageText.ENTER_CORRECT_DATA);
    }

    return null;
  }


  public submitForm(): void {
    if (!(this.displayButtonText === this.repeatSms)) {
      this.submitted = true;
    }

    if (this.form.invalid && !(this.displayButtonText === this.repeatSms)) {
      return;
    }

    if (!this.disableButton && this.displayButtonText === this.repeatSms) {
      const subscription = this.store.dispatch(new ServicesActions.SendSmsConfirmation()).pipe(
        tap(() => this.store.dispatch(new ServicesActions.SetSmsAlreadySent()))).subscribe(() => {
        subscription.unsubscribe();
        this.restartTimer();
      });
    } else {
      const productId = this.data.props.cardHash ? this.data.props.cardHash
        : this.data.props.cards && this.data.props.cards.length > 0
        && this.data.props.cards[0].cardHash ? this.data.props.cards[0].cardHash
          : this.data.props.contractNumber
      const data = {
        productId: productId,
        name: this.form.getRawValue().name
      };

      this.dialogRef.close(data);
    }
  }


  private createForm(): FormGroup<Form> {
    this.formValidation = { maxLength: ValidationConfig.cardName.maxLength };
    let nameValue = this.data.props && Object.prototype.hasOwnProperty.call(this.data.props, 'inputValue')
      ? this.data.props.inputValue
      : this.data.props?.name ? this.data.props?.name : this.data.props?.productName;

    nameValue = this.data.maxLength && nameValue.length > this.data.maxLength ? nameValue.substring(0, this.data.maxLength) : nameValue;

    this.maxLength = this.data.maxLength ? this.data.maxLength : this.data.props?.name ? this.formValidation.maxLength : 100;

    let validators: ValidatorFn[] = [
      Validators.maxLength(this.maxLength)
    ];

    validators = this.isRequired ? [...validators, Validators.required] : validators;

    return this.fb.nonNullable.group({ name: [nameValue, validators] });
  }


  private restartTimer() {
    const defaultTime = 60000;
    this.countdown = ' '.concat(DateFormat.millisecondsToMinutesSeconds(defaultTime));
    this.timerSubscription = this.timer.on().pipe(takeUntil(this.destroy$))
      .subscribe(countdown => {
        if (countdown) {
          this.countdown = ' ' + countdown;
        } else {
          this.timer.stop();
          this.timerSubscription.unsubscribe();
          this.disableButton = false;
        }
      });
    this.timer.start(defaultTime);
  }


  private clearControlValidation(): void {
    this.form.controls.name.clearValidators();
    this.form.controls.name.updateValueAndValidity();
  }


  private addControlNameValidation(): void {
    this.form.controls.name.addValidators(Validators.required);
    this.form.controls.name.updateValueAndValidity();
  }
}
