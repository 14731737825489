import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { IEripBodyTwoRequest } from '../../../interfaces/erip-navigation.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { PaymentInterface } from '../../../interfaces/payment.interface';
import BetweenAccountsLimitsDto = PaymentInterface.BetweenAccountsLimitsDto;
import BetweenAccountsTransfer = PaymentInterface.BetweenAccountsTransfer;
import { OnlineApplication } from '../../../interfaces/products.interface';


@Injectable({
  providedIn: 'root'
})
export class PaymentResource {


  constructor(private readonly http: HttpClient) {
  }


  public getEripPaymentsRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.http.post<PaymentInterface.TransferResponseDto>(ApiConfig.ERIP_PAY_ROC, body, { headers });
  }


  public byRequisitesRoc(body: IEripBodyTwoRequest, headers: HttpHeaders): Observable<PaymentInterface.TransferResponseDto> {
    return this.http.post<PaymentInterface.TransferResponseDto>(ApiConfig.BY_REQUISITES_ROC, body, { headers });
  }


  public depositTransferClosingToCard(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.DEPOSIT_TRANSFER_CLOSING_TO_CARD, requestData);
  }


  public paymentCardNumberCurrency(requestData: PaymentInterface.TransferCardNumberCurrencyDto): Observable<PaymentInterface.TransferByCardCurrencyRs> {
    return this.http.post<PaymentInterface.TransferByCardCurrencyRs>(ApiConfig.PAYMENT_CARD_NUMBER_CURRENCY, requestData);
  }


  public paymentTransferInfo(requestData: PaymentInterface.TransferCardNumberCurrencyDto): Observable<PaymentInterface.TransferByCardCurrencyRs> {
    return this.http.post<PaymentInterface.TransferByCardCurrencyRs>(ApiConfig.PAYMENT_TRANSFER_RECIPIENT, requestData);
  }


  public paymentCardNumberTransfer(requestData: PaymentInterface.TransferCardNumberTransferDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.PAYMENT_CARD_NUMBER_TRANSFER, requestData, {headers});
  }


  public depositTransferClosingToAccount(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.DEPOSIT_TRANSFER_CLOSING_TO_ACCOUNT, requestData);
  }


  public depositTransferTopUpFromCard(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.DEPOSIT_TRANSFER_TOP_UP_TO_CARD, requestData);
  }


  public depositTransferTopUpFromAccount(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.DEPOSIT_TRANSFER_TOP_UP_TO_ACCOUNT, requestData);
  }


  public depositTransferWithdrawalFromCard(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.DEPOSIT_TRANSFER_WITHDRAWAL_TO_CARD, requestData);
  }


  public depositTransferWithdrawalFromAccount(requestData: PaymentInterface.TransferDepositRequestDto): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.DEPOSIT_TRANSFER_WITHDRAWAL_TO_ACCOUNT, requestData);
  }


  public depositTransferOpening(requestData: PaymentInterface.GetPayListRequestDto, headers: HttpHeaders): Observable<PaymentInterface.CheckBaseResponseDto> {
    return this.http.post<PaymentInterface.CheckBaseResponseDto>(ApiConfig.DEPOSIT_TRANSFER_OPENING, requestData, {headers});
  }


  public betweenAccountLimits(requestData: PaymentInterface.GetLimitsRequestDto): Observable<PaymentInterface.TransferLimitsResponseDto> {
    return this.http.post<PaymentInterface.TransferLimitsResponseDto>(ApiConfig.BETWEEN_ACCOUNTS_LIMITS, requestData);
  }


  public betweenAccountsLimits(requestData: BetweenAccountsLimitsDto): Observable<PaymentInterface.TransferLimitsResponseDto> {
    return this.http.post<PaymentInterface.TransferLimitsResponseDto>(ApiConfig.BETWEEN_ACCOUNTS_LIMITS, requestData);
  }


  public transferClientLimits(requestData = {}): Observable<PaymentInterface.BetweenAccountsLimitsRs> {
    return this.http.post<PaymentInterface.BetweenAccountsLimitsRs>(ApiConfig.TRANSFER_CLIENT_LIMITS, requestData);
  }


  public betweenAccountsTransfer(requestData: BetweenAccountsTransfer, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.BETWEEN_ACCOUNTS_TRANSFER, requestData, {headers});
  }


  public loanPaymentInfo(requestData: PaymentInterface.LoanPaymentInfoDto): Observable<PaymentInterface.CreditInfoResponse> {
    return this.http.post<PaymentInterface.CreditInfoResponse>(ApiConfig.LOAN_PAYMENT_INFO, requestData);
  }


  public loanPayment(requestData: PaymentInterface.LoanPaymentInfoDto, headers: HttpHeaders): Observable<PaymentInterface.TransferDepositResponseDto> {
    return this.http.post<PaymentInterface.TransferDepositResponseDto>(ApiConfig.LOAN_PAYMENT, requestData, {headers});
  }


  public transferType(requestData: PaymentInterface.EncryptedRequestDto): Observable<PaymentInterface.TransferTypeResponseDto> {
    return this.http.post<PaymentInterface.TransferTypeResponseDto>(ApiConfig.BEPAID_TRANSFER_TYPE, { encryptedData: requestData.encryptedData });
  }


  public getRestriction(requestData: PaymentInterface.EncryptedRequestDto): Observable<PaymentInterface.RestrictionsResponseDto> {
    return this.http.post<PaymentInterface.RestrictionsResponseDto>(ApiConfig.BEPAID_GET_RESTRICTION, { encryptedData: requestData.encryptedData })
  }


  public doTransaction(requestData: PaymentInterface.EncryptedRequestDto): Observable<PaymentInterface.TransferReceiptDto> {
    return this.http.post<PaymentInterface.TransferReceiptDto>(ApiConfig.BEPAID_DO_TRANSACTION, { encryptedData: requestData.encryptedData });
  }


  public getTransactionStatus(requestData: PaymentInterface.GetTransactionStatusDto): Observable<PaymentInterface.TransferReceiptDto> {
    return this.http.post<PaymentInterface.TransferReceiptDto>(ApiConfig.BEPAID_GET_TRANSACTION_STATUS, { trackingId: requestData.trackingId });
  }


  public getReceipt(requestData: string): Observable<PaymentInterface.ReceiptResponseDto> {
    const params: HttpParams = new HttpParams()
      .set('trackingId', requestData);

    return this.http.get<PaymentInterface.ReceiptResponseDto>(ApiConfig.BEPAID_GET_RECEIPT, { params });
  }


  public getEncryptionPublicKey(): Observable<PaymentInterface.GetPublicSignatureResponseDto> {
    return this.http.get<PaymentInterface.GetPublicSignatureResponseDto>(ApiConfig.ENCRYPTION_GET_PUBLIC_KEY);
  }


  public getAvailablePaymentActions(): Observable<PaymentInterface.AvailableActionsResponseDto> {
    return this.http.get<PaymentInterface.AvailableActionsResponseDto>(ApiConfig.AVAILABLE_PAYMENT_ACTIONS);
  }


  public paymentTopUpTransferGpl(requestData: PaymentInterface.GetPayListRequestDto): Observable<PaymentInterface.GetPayListResponseDto> {
    return this.http.post<PaymentInterface.GetPayListResponseDto>(ApiConfig.TOP_UP_TRANSFER_GPL, requestData);
  }


  public paymentTopUpTransferRoc(requestData: PaymentInterface.RunOperationRequestDto, headers: HttpHeaders): Observable<PaymentInterface.CheckBaseResponseDto> {
    return this.http.post<PaymentInterface.CheckBaseResponseDto>(ApiConfig.TOP_UP_TRANSFER_ROC, requestData, { headers });
  }


  public getPaymentTemplate(): Observable<PaymentInterface.ListPaymentTemplateRespDto> {
    return this.http.get<PaymentInterface.ListPaymentTemplateRespDto>(ApiConfig.PAYMENT_TEMPLATE);
  }


  public createPaymentTemplate(requestData: PaymentInterface.CreatePaymentTemplateRequestDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.SAVE_TEMPLATE, requestData);
  }


  public updatePaymentTemplate(requestData: PaymentInterface.UpdatePaymentTemplateDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.UPDATE_TEMPLATE, requestData)
  }


  public deletePaymentTemplate(id: string): Observable<HttpError> {
    return this.http.get<HttpError>(ApiConfig.DELETE_TEMPLATE.concat("/" + id));
  }


  public updateAutoPayment(requestData: PaymentInterface.UpdateAutoPaymentRequestDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.AUTO_PAYMENT_UPDATE, requestData);
  }


  public createAutoPayment(requestData: PaymentInterface.CreatePaymentTemplateRequestDto): Observable<HttpError> {
    return this.http.post<HttpError>(ApiConfig.AUTO_PAYMENT_CREATE, requestData);
  }


  public deleteAutoPayment(id: string): Observable<HttpError> {
    return this.http.get<HttpError>(ApiConfig.AUTO_PAYMENT_DELETE + '/' + `${ id }`);
  }


  public getOperationHistory(requestData: PaymentInterface.GetOperationHistoryRequestDto): Observable<PaymentInterface.GetOperationHistoryResponseDto> {
    return this.http.post<PaymentInterface.GetOperationHistoryResponseDto>(ApiConfig.GET_OPERATION_HISTORY, requestData);
  }


  public getLoanProducts(): Observable<PaymentInterface.LoanProduct[]> {
    return this.http.post<PaymentInterface.LoanProduct[]>(ApiConfig.GET_L0AN_PRODUCTS, {});
  }


  public getLoanStatus(): Observable<PaymentInterface.LoanProduct[]> {
    return this.http.post<PaymentInterface.LoanProduct[]>(ApiConfig.GET_L0AN_STATUS, {});
  }


  public getApplicationCodeStatus(requestData: PaymentInterface.ApplicationCodeRq): Observable<OnlineApplication> {
    return this.http.post<OnlineApplication>(ApiConfig.GET_APPLICATION_CODE_STATUS, requestData);
  }


  public getPaymentSchedule(requestData: PaymentInterface.PaymentScheduleRq): Observable<PaymentInterface.PaymentSchedule> {
    return this.http.post<PaymentInterface.PaymentSchedule>(ApiConfig.GET_PAYMENT_SCHEDULE, requestData);
  }


  public fixOffer(requestData: PaymentInterface.FixOfferRq): Observable<PaymentInterface.FixOfferRs> {
    return this.http.post(ApiConfig.FIX_OFFER, requestData);
  }


  public getOffers(requestData: PaymentInterface.ApplicationCodeRq): Observable<PaymentInterface.Offer> {
    return this.http.post<PaymentInterface.Offer>(ApiConfig.GET_OFFERS, requestData);
  }


  public getLoanForm(requestData: PaymentInterface.LoanForm): Observable<PaymentInterface.LoanFormRs> {
    return this.http.post<PaymentInterface.LoanFormRs>(ApiConfig.GET_L0AN_FORM, requestData);
  }


  public getLoanViewDocument(requestData: PaymentInterface.LoanDocument): Observable<PaymentInterface.LoanDocumentRs> {
    return this.http.post<PaymentInterface.LoanDocumentRs>(ApiConfig.GET_L0AN_VIEW_DOCUMENT, requestData);
  }


  public getLoanIssue(requestData: PaymentInterface.LoanFilledForm, headers: HttpHeaders): Observable<PaymentInterface.LoanDocumentRs> {
    return this.http.post<PaymentInterface.LoanDocumentRs>(ApiConfig.GET_L0AN_FILL_FORM, requestData, {headers});
  }


  public loanVoid(requestData: PaymentInterface.LoanVoid): Observable<PaymentInterface.LoanDocumentRs> {
    return this.http.post<PaymentInterface.LoanDocumentRs>(ApiConfig.GET_L0AN_VOID, requestData);
  }


  public checkSignDocument(data: PaymentInterface.CheckSignDocumentRq): Observable<PaymentInterface.CheckSignDocumentRs> {
    return this.http.post<PaymentInterface.CheckSignDocumentRs>(ApiConfig.CHECK_SIGN_DOCUMENT, data);
  }


  public signFinalDocuments(data: PaymentInterface.SignFinalDocumentsRq): Observable<PaymentInterface.SignFinalDocumentsRs> {
    return this.http.post<PaymentInterface.SignFinalDocumentsRs>(ApiConfig.SIGN_FINAL_DOCUMENTS, data);
  }


  public getApplicationByNumber(applicationNumber: number): Observable<PaymentInterface.ApplicationResponse> {
    return this.http.get<PaymentInterface.ApplicationResponse>(`${ApiConfig.GET_APPLICATION_BY_NUMBER}/${applicationNumber}`);
  }


  public reviewApplication(requestData: PaymentInterface.ReviewApplicationRq): Observable<any> {
    return this.http.post<any>(ApiConfig.REVIEW_APPLICATION, requestData);
  }


  public voidApplication(requestData: PaymentInterface.VoidApplicationRq): Observable<PaymentInterface.VoidApplicationRs> {
    return this.http.post<PaymentInterface.VoidApplicationRs>(ApiConfig.VOID_APPLICATION, requestData);
  }
}
