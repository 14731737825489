import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { OperationsHistory } from '../../../interfaces/operations-history.interface';
import { DateFormat } from '../../../utils/date.utils';
import { OperationsService } from '../services/operations.service';
import { OperationsActions } from './operations.actions';
import timestampToTimestampWithoutLocale = DateFormat.timestampToTimestampWithoutLocale;


interface OperationStateModel {
  operationHistory: OperationsHistory.OperationHistory[];
}


@State<OperationStateModel>({
  name: 'operations'
})
@Injectable()
export class OperationsState {

  constructor(private readonly operationsService: OperationsService) {}


  @Selector()
  public static selectOperationHistory(state: OperationStateModel): OperationsHistory.OperationHistory[] {
    return state.operationHistory || [];
  }


  @Action(OperationsActions.OperationHistory)
  public getOperationHistory(ctx: StateContext<OperationStateModel>, { requestData }: OperationsActions.OperationHistory): Observable<OperationStateModel> {
    return this.operationsService.getOperationHistory(requestData)
      .pipe(
        map(({ operationHistory }: OperationsHistory.OperationHistoryResponseDto) => {

          return ctx.patchState({
            operationHistory: operationHistory.map(operation => ({
              ...operation,
              paymentDate: timestampToTimestampWithoutLocale(operation.paymentDate)
            }))
          });
        })
      );
  }
}
