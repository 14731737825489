export enum SnackbarType {
  Success = 'success',
  Danger = 'danger',
  Default = 'default'
}


export enum SnackBarTextList {
  NO_RESIDENT_BANK = 'NO_EXIST_BANK_CLIENT',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',

  CHANGE_CARD_NAME = 'CARD_NAME_CHANGED',
  CHANGE_DEPOSIT_NAME = 'DEPOSIT_NAME_CHANGED',
  CHANGE_ACCOUNT_NAME = 'ACCOUNT_NAME_CHANGED',
  CHANGE_CREDIT_NAME = 'CREDIT_NAME_CHANGED',

  CHANGE_PROFILE_EMAIL = 'EMAIL_SUCCESS_CHANGED',
  CHANGE_PROFILE_ERIP_NUMBER = 'ERIP_NUMBER_SUCCESS_CHANGED',
  CHANGE_PROFILE_PHOTO = 'PHOTO_SUCCESS_CHANGED',
  ACCESS_MOBILE_BANK_BLOCKED = 'ACCESS_MOBILE_BANKING_BLOCKED',
  ACCESS_MOBILE_BANK_UNBLOCKED = 'ACCESS_MOBILE_BANKING_UNBLOCKED',
  SELECT_TEMPLATE_CONTINUE = 'SELECT_TEMPLATE_CONTINUE',

  CREATE_AUTO_PAY_SUCCESS_MESSAGE = 'CREATE_AUTO_PAY_SUCCESS_MESSAGE',
  CREATE_TEMPLATE_SUCCESS_MESSAGE = 'CREATE_TEMPLATE_SUCCESS_MESSAGE',
  UPDATE_AUTO_PAY_SUCCESS_MESSAGE = 'UPDATE_AUTO_PAY_SUCCESS_MESSAGE',
  UPDATE_TEMPLATE_SUCCESS_MESSAGE = 'UPDATE_TEMPLATE_SUCCESS_MESSAGE'
}
