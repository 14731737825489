import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map, Observable, of, switchMap, tap } from 'rxjs';
import {
  CurrencyRateUtilDto,
  MainRates,
  PairExchangeRateResponseDto,
  Services,
  ServicesStateModel
} from '../../../interfaces/exchange-rates-list-dto.interface';
import { HttpError } from '../../../interfaces/http.interface';
import { GetLimitsRs } from '../../../interfaces/products.interface';
import { InfoCountriesRs } from '../../../interfaces/questionnaire.interface';
import { BlockCustomizeUnits } from '../../../interfaces/settings.interface';
import { ConfirmationUtils } from '../../../utils/confirmation.utils';
import { ServiceService } from '../services/service.service';
import { ServicesActions } from './services.actions';


@State<ServicesStateModel>({
  name: 'services',
  defaults: {
    smsAlreadySent: false
  }
})
@Injectable()
export class ServicesState {


  constructor(private readonly serviceService: ServiceService) {
  }


  @Selector()
  public static selectActionResponse(state: ServicesStateModel): HttpError {
    return state.actionResponse!;
  }


  @Selector()
  public static selectLimits(state: ServicesStateModel): GetLimitsRs {
    return state.getLimits!;
  }


  @Selector()
  public static selectTotalAmount(state: ServicesStateModel) {
    const { blockCustomizeUnits } = state.services!;

    return blockCustomizeUnits as BlockCustomizeUnits[];
  }


  @Selector()
  public static selectSmsAlreadySent(state: ServicesStateModel): boolean {
    return state.smsAlreadySent;
  }


  @Selector()
  public static selectInfoCountries(state: ServicesStateModel): InfoCountriesRs | undefined {
    return state.infoCountries;
  }


  @Selector()
  public static selectPartnerATMS(state: ServicesStateModel) {
    return state.partnerATMS;
  }


  @Selector()
  public static selectATMDetail(state: ServicesStateModel) {
    return state.ATMDetails;
  }


  @Selector()
  public static selectBranches(state: ServicesStateModel) {
    return state.branches;
  }


  @Selector()
  public static selectMainRates(state: ServicesStateModel): MainRates[] {
    if (!state.services || !state.services.unitDtos) return [];

    const unitDtos = state.services?.unitDtos as CurrencyRateUtilDto;
    return unitDtos.mainRates;
  }


  @Selector()
  public static selectPairRate(state: ServicesStateModel): PairExchangeRateResponseDto {
    return state.pairRates as PairExchangeRateResponseDto;
  }


  @Selector()
  public static selectExchangeRatesPair(state: ServicesStateModel): Services {
    return state.services as Services;
  }


  @Action(ServicesActions.GetExchangeRatesAll)
  public getExchangeRatesAll(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.GetExchangeRatesAll) {
    const state = ctx.getState();
    const blockCustomizeUnits = state.services?.blockCustomizeUnits;

    return this.serviceService.getExchangeRatesAll(requestData).pipe(
      tap(result => {
        const services: Services = {
          unitDtos: result,
          blockCustomizeUnits: blockCustomizeUnits ? blockCustomizeUnits : []
        };
        ctx.patchState({ services });
      })
    );
  }


  @Action(ServicesActions.GetExchangeRatesPair)
  public getExchangeRatesPair(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.GetExchangeRatesPair) {
    const state = ctx.getState();
    const { blockCustomizeUnits } = state.services!;

    return this.serviceService.getExchangeRatesPair(requestData).pipe(
      tap(result => {
        const services: Services = {
          unitDtos: result,
          blockCustomizeUnits: blockCustomizeUnits ? blockCustomizeUnits : []
        };
        ctx.patchState({ services });
      })
    );
  }


  @Action(ServicesActions.GetExchangeRatesPairForEquivalent)
  public getExchangeRatesPairForEquivalent(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.GetExchangeRatesPair): Observable<ServicesStateModel> {
    return this.serviceService.getExchangeRatesPair(requestData).pipe(
      map(result => {
        return ctx.patchState({ pairRates: result });
      })
    );
  }


  @Action(ServicesActions.GetCustomizeByUser)
  public getCustomizeByUser(ctx: StateContext<ServicesStateModel>) {
    return this.serviceService.getCustomizeByUser().pipe(
      switchMap((data: { blockCustomizeUnits: BlockCustomizeUnits[] }) => {
        const services: Services = {
          // unitDtos,
          blockCustomizeUnits: data.blockCustomizeUnits
        };

        ctx.patchState({ services });
        return of(null);
      })
    );
  }


  @Action(ServicesActions.CreateOrUpdateCustomize)
  public createOrUpdateCustomize(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.CreateOrUpdateCustomize) {
    const state = ctx.getState();
    const { unitDtos, blockCustomizeUnits } = state.services!;

    return this.serviceService.createOrUpdateCustomize(requestData).pipe(
      map(() => {
        const services: Services = {
          blockCustomizeUnits: requestData.blockCustomizeUnits,
          unitDtos
        };

        ctx.patchState({ services });
      })
    );
  }


  @Action(ServicesActions.ClearServices)
  public clearServices(ctx: StateContext<ServicesStateModel>) {
    ctx.patchState({
      services: {
        blockCustomizeUnits: undefined
      },
      smsAlreadySent: false
    });
  }


  @Action(ServicesActions.SendCardActionCvv)
  public sendCardActionCvv(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.SendCardActionCvv) {
    return this.serviceService.sendCardCvv(requestData, ConfirmationUtils.addHeaderConfirmationData(requestData)).pipe(tap((actionResponse) => {
      return ctx.patchState({ actionResponse });
    }));
  }


  @Action(ServicesActions.SendCardLimit)
  public sendCardLimit(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.SendCardLimit) {
    return this.serviceService.sendCardLimits(requestData).pipe(tap((actionResponse) => {
      return ctx.patchState({ actionResponse });
    }));
  }


  @Action(ServicesActions.ReceiveCardLimits)
  public receiveCardLimits(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.ReceiveCardLimits) {
    return this.serviceService.receiveCardLimits(requestData).pipe(tap((getLimits) => {
      return ctx.patchState({ getLimits });
    }));
  }


  @Action(ServicesActions.SetSmsAlreadySent)
  public setSmsAlreadySentAction(ctx: StateContext<ServicesStateModel>, _: ServicesActions.SetSmsAlreadySent) {
    ctx.patchState({ smsAlreadySent: true });
  }


  @Action(ServicesActions.SendSmsConfirmation)
  public sendSmsConfirmation(ctx: StateContext<ServicesStateModel>, _: ServicesActions.SendSmsConfirmation) {
    return this.serviceService.sendSmsConfirmation().pipe(tap((actionResponse) => {
      return ctx.patchState({ actionResponse });
    }));
  }


  @Action(ServicesActions.SendInfoCountries)
  public sendInfoCountries(ctx: StateContext<ServicesStateModel>, _: ServicesActions.SendSmsConfirmation) {
    return this.serviceService.infoCountries().pipe(tap((infoCountries) => {
      return ctx.patchState({ infoCountries });
    }));
  }


  @Action(ServicesActions.GetBankDivisions)
  public getBankDivisions(ctx: StateContext<ServicesStateModel>, { divisionTypes }: ServicesActions.GetBankDivisions) {
    return this.serviceService.getBankDivisions(divisionTypes).pipe(
      map(bankDivisions => {
        return ctx.patchState({ getBankDivisions: bankDivisions });
      })
    );
  }


  @Action(ServicesActions.GetBranches)
  public getBranches(ctx: StateContext<ServicesStateModel>, _: ServicesActions.GetBranches) {
    return this.serviceService.getBranches().pipe(
      map(branches => {
        ctx.patchState({ branches });
      })
    );
  }


  @Action(ServicesActions.GetATMS)
  public getATMS(ctx: StateContext<ServicesStateModel>, _: ServicesActions.GetATMS) {
    return this.serviceService.getATMS().pipe(
      map(br => {
        // @ts-ignore
        const atms = br.atms;
        console.log('recceived', atms);
        ctx.patchState({ partnerATMS: atms });
      })
    );
  }


  @Action(ServicesActions.GetATMDetail)
  public getATMDetail(ctx: StateContext<ServicesStateModel>, { id }: ServicesActions.GetATMDetail) {
    return this.serviceService.getATMDetail(id).pipe(
      map(ATMDetails => {
        ctx.patchState({ ATMDetails });
      })
    );
  }


  @Action(ServicesActions.GetBankDivisionById)
  public getBankDivisionById(ctx: StateContext<ServicesStateModel>, { id }: ServicesActions.GetBankDivisionById) {
    return this.serviceService.getBankDivisionById(id).pipe(
      map(response => {
        return ctx.patchState({ bankDivisionById: response });
      })
    );
  }


  @Action(ServicesActions.GetCallOrder)
  public getCallOrder(ctx: StateContext<ServicesStateModel>, { requestData }: ServicesActions.GetCallOrder) {
    return this.serviceService.getOrderCall(requestData).pipe(
      map(callOrder => {
        return ctx.patchState({ callOrder });
      })
    );
  }
}
