import {
  ApplicationRef,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  ElementRef,
  EmbeddedViewRef,
  HostListener,
  Injector,
  Input
} from '@angular/core';
import { SharedEnum } from '../../enums/shared.enum';
import { TooltipComponent } from '../../views/shared/components/tooltip/tooltip.component';


@Directive({
  selector: '[tooltip]',
  standalone: true
})
export class TooltipDirective {

  @Input() tooltip = '';

  @Input() position: SharedEnum.TooltipPositions = SharedEnum.TooltipPositions.DEFAULT;

  @Input() theme: SharedEnum.TooltipTheme = SharedEnum.TooltipTheme.DEFAULT;

  @Input() showDelay = 0;

  @Input() hideDelay = 0;

  private componentRef: ComponentRef<any> | null = null;

  private showTimeout?: number;

  private hideTimeout?: number;

  private touchTimeout?: number;


  constructor(private elementRef: ElementRef, private appRef: ApplicationRef,
              private componentFactoryResolver: ComponentFactoryResolver, private injector: Injector) {
  }


  @HostListener('mouseenter')
  public onMouseEnter(): void {
    this.initializeTooltip();
  }


  @HostListener('mouseleave')
  public onMouseLeave(): void {
    this.setHideTooltipTimeout();
  }


  @HostListener('mousemove', [ '$event' ])
  public onMouseMove($event: MouseEvent): void {
    if (this.componentRef !== null && this.position === SharedEnum.TooltipPositions.DYNAMIC) {
      this.componentRef.instance.left = $event.clientX;
      this.componentRef.instance.top = $event.clientY;
      this.componentRef.instance.tooltip = this.tooltip;
    }
  }


  @HostListener('touchstart', [ '$event' ])
  public onTouchStart($event: TouchEvent): void {
    $event.preventDefault();
    window.clearTimeout(this.touchTimeout);
    this.touchTimeout = window.setTimeout(this.initializeTooltip.bind(this), 500);
  }


  @HostListener('touchend')
  public onTouchEnd(): void {
    window.clearTimeout(this.touchTimeout);
    this.setHideTooltipTimeout();
  }


  private initializeTooltip(): void {
    if (this.componentRef === null) {
      window.clearInterval(this.hideDelay);
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(TooltipComponent);
      this.componentRef = componentFactory.create(this.injector);

      this.appRef.attachView(this.componentRef.hostView);
      const [ tooltipDOMElement ] = (this.componentRef.hostView as EmbeddedViewRef<any>).rootNodes;

      this.setTooltipComponentProperties();

      document.body.appendChild(tooltipDOMElement);
      this.showTimeout = window.setTimeout(this.showTooltip.bind(this), this.showDelay);

      if (this.componentRef.instance) {
        this.componentRef.instance.tooltipContent = this.tooltip;
      }
    }
  }


  private setTooltipComponentProperties(): void {
    if (this.componentRef !== null) {
      this.componentRef.instance.tooltipContent = this.tooltip;

      this.componentRef.instance.tooltip = this.tooltip;
      this.componentRef.instance.position = this.position;
      this.componentRef.instance.theme = this.theme;

      const { left, right, top, bottom } = this.elementRef.nativeElement.getBoundingClientRect();

      switch (this.position) {
        case SharedEnum.TooltipPositions.BELOW: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        case SharedEnum.TooltipPositions.BELOW_RIGHT: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(bottom);
          break;
        }
        case SharedEnum.TooltipPositions.ABOVE: {
          this.componentRef.instance.left = Math.round((right - left) / 2 + left);
          this.componentRef.instance.top = Math.round(top) - 9;
          break;
        }
        case SharedEnum.TooltipPositions.RIGHT: {
          this.componentRef.instance.left = Math.round(right);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        case SharedEnum.TooltipPositions.LEFT: {
          this.componentRef.instance.left = Math.round(left);
          this.componentRef.instance.top = Math.round(top + (bottom - top) / 2);
          break;
        }
        default: {
          break;
        }
      }
    }
  }


  private showTooltip(): void {
    if (this.componentRef !== null) {
      this.componentRef.instance.visible = true;
    }
  }


  private setHideTooltipTimeout(): void {
    this.hideTimeout = window.setTimeout(this.destroy.bind(this), this.hideDelay);
  }


  public ngOnDestroy(): void {
    this.destroy();
  }


  private destroy(): void {
    if (this.componentRef !== null) {
      window.clearInterval(this.showTimeout);
      window.clearInterval(this.hideDelay);
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.componentRef = null;
    }
  }
}
