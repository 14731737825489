import { Currency, CurrencyCode } from '../enums/currency.enum';
import { ConfirmAction } from '../interfaces/auth.interface';
import { HttpHeaders } from '@angular/common/http';
import { ConfirmationKey } from '../interfaces/user.interface';
import { JSEncrypt } from 'jsencrypt';


export namespace ConfirmationUtils {
  export function urlForConfirmationCheck(url: string) {
    return url.substring(url.indexOf('v3') + 2)
  }


  export function urlsForConfirmationCheck(urls: string[]): string[] {
    return urls.map((url: string) => url.substring(url.indexOf('v3') + 2));
  }


  export function addHeaderConfirmationData(requestData: ConfirmAction) {
    const headers = new HttpHeaders();
    const confirmationKey: ConfirmationKey | undefined = requestData.confirmationKey;
    const confirmationKey1 = requestData.confirmationData ? 'SMS '.concat(requestData.confirmationData) : undefined;
    if (confirmationKey && confirmationKey1) {
      const publicSignature = confirmationKey.publicSignature;
      const jsEncrypt = new JSEncrypt({default_key_size: '2048'});
      jsEncrypt.setPublicKey(publicSignature);
      const encrypted = jsEncrypt.encrypt(confirmationKey1);
      delete requestData.confirmationData;
      delete requestData.confirmationKey
      return headers.append('X-Data-Exchange', encrypted as string)
    } else {
      delete requestData.confirmationData;
      delete requestData.confirmationKey
      return headers;
    }
  }


  export function encryptedJSON(publicKey: string, data: any): string {
    const jsonString: string = JSON.stringify(data);
    const jsEncrypt: JSEncrypt = new JSEncrypt({default_key_size: '2048'});

    jsEncrypt.setPublicKey(publicKey);

    return jsEncrypt.encrypt(jsonString) as string;
  }


  export function findRestrictionByCurrency(data: any, codeOrCurrency: string) {
    const currencies: string[] = [Currency.BYN, Currency.EUR, Currency.USD, Currency.RUB, Currency.CNY, Currency.AED];
    const currenciesCode: string [] = [CurrencyCode.BYN, CurrencyCode.EUR, CurrencyCode.USD, CurrencyCode.RUB, CurrencyCode.CNY, CurrencyCode.AED];
    let currency: string;

    if (currenciesCode.includes(codeOrCurrency)) {
      let index: number = currenciesCode.indexOf(codeOrCurrency);
      currency = currencies[index];
    } else {
      currency = codeOrCurrency;
    }

    for (const confirmation of data) {
      if (confirmation.restrictions) {
        for (const restriction of confirmation.restrictions) {
          if (restriction.currency === currency) {
            return restriction;
          }
        }
      }
    }

    return null;
  }
}
