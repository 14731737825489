import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { preloaderConfig } from '../../config/preloader.config';
import { PreloaderState } from '../../models/preloader/state/preloader.state';


@Component({
  templateUrl: './preloader.component.html',
  styleUrls: [ './preloader.component.scss' ]
})
export class PreloaderComponent {

  public config = preloaderConfig();
  public showPreloaderPriority!: boolean;


  constructor(
    private readonly store: Store) {

    // this.showPreloaderPriority = this.store.selectSnapshot(PreloaderState.selectShowPreloaderPriority);
  }

}
