import { ControlValueAccessor } from '@angular/forms';
import { FormControlValue } from '../../../../interfaces/control.interface';
import { StringUtils } from '../../../../utils/string.utils';


export class InputComponent implements ControlValueAccessor {

  public id!: string | number;
  public disabled!: boolean;

  protected onChange = (value: FormControlValue) => {};

  protected onTouched = () => {};


  set value(val: FormControlValue) {
    this._value = val;
    this.onChange(this._value);
  }


  get value(): FormControlValue {
    return this._value;
  }


  protected _value: FormControlValue = null;


  constructor() {
    this.id = StringUtils.generateString(8);
  }


  public registerOnChange(fn: (value: FormControlValue) => object): void {
    this.onChange = fn;
  }


  public registerOnTouched(fn: () => object): void {
    this.onTouched = fn;
  }


  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }


  public writeValue(value: FormControlValue): void {
    this.value = value;
  }
}
