import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'sortBy',
  standalone: true
})
export class SortByPipe implements PipeTransform {
  transform(array: any[], field: string): any[] {
    if (!array || !field) {
      return array;
    }
    return array.sort((a, b) => a[field] > b[field] ? 1 : -1);
  }
}
