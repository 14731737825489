import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedInterface } from '../../../../../interfaces/shared.interface';


@Component({
  selector: 'app-button-groups',
  templateUrl: './button-groups.component.html',
  styleUrls: [ './button-groups.component.scss' ]
})
export class ButtonGroupsComponent implements OnInit {

  public activeIndex: number = 0;

  @Input()
  public label!: string;

  @Input()
  public style!: any;

  @Input()
  public buttonList!: string[];

  @Input()
  public isOptions: boolean = false;

  @Input()
  public options!: { value: string, label: string, code?: string }[];

  @Output()
  public emitButtonItem: EventEmitter<string> = new EventEmitter<string>();

  @Output()
  public emitOptionItem: EventEmitter<SharedInterface.OptionItem> = new EventEmitter<SharedInterface.OptionItem>();


  public ngOnInit(): void {
    this.initMainItem();
  }


  public setActiveIndex(index: number): void {
    this.activeIndex = index;
    this.emitButtonItem.emit(this.buttonList[index]);
  }


  public setActiveOptionIndex(index: number): void {
    this.activeIndex = index;
    this.emitOptionItem.emit(this.options[index]);
  }


  private initMainItem(): void {
    if (this.buttonList && this.buttonList.length) this.emitButtonItem.emit(this.buttonList[0]);
    if (this.options && this.options.length) this.emitOptionItem.emit(this.options[0]);
  }
}
