import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CodeRequest, CodeResponse, ConfirmResponse } from '../../../interfaces/auth.interface';
import {
  GetNsiDataPayload,
  MsiConfirmRequest,
  MsiForm,
  MsiFormReqPayload,
  MsiLoginData,
  MsiLoginPayload,
  MsiPostFormReqPayload,
  MsiPostFormResponse,
  MsiRedirect,
  NsiDataResponse,
  NsiDictionariesResponse
} from '../../../interfaces/msi.interface';
import { SystemInfo } from '../../../interfaces/system.interface';
import { MsiResource } from '../resources/msi.resource';


@Injectable({
  providedIn: 'root'
})
export class MsiService {

  constructor(
    private readonly resource: MsiResource) {
  }


  public getRedirectURL(payload: SystemInfo): Observable<MsiRedirect> {
    return this.resource.getRedirectURL(payload);
  }


  public login(payload: MsiLoginPayload): Observable<MsiLoginData> {
    return this.resource.login(payload);
  }


  public confirm(payload: MsiConfirmRequest): Observable<ConfirmResponse> {
    return this.resource.confirm(payload);
  }


  public getForm(payload: MsiFormReqPayload): Observable<MsiForm> {
    return this.resource.getForm(payload);
  }


  public code(payload: CodeRequest): Observable<CodeResponse> {
    return this.resource.code(payload);
  }


  public getDictionaries(): Observable<NsiDictionariesResponse> {
    return this.resource.getDictionaries();
  }


  public getNsiInformation(payload: GetNsiDataPayload): Observable<NsiDataResponse> {
    return this.resource.getNsiInformation(payload);
  }


  public postForm(payload: MsiPostFormReqPayload): Observable<MsiPostFormResponse> {
    return this.resource.postForm(payload);
  }
}
