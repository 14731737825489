import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import {
  DefaultMatCalendarRangeStrategy,
  MatDatepickerModule,
  MatRangeDateSelectionModel,
} from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective } from 'ngx-mask';
import { DATE_FORMAT } from '../../../config/date.config';
import { SetDefaultActiveDirective } from '../../../core/directives/active-time-item.directive';
import { DateInputDirective } from '../../../core/directives/add-date-input.directive';
import { MaxLengthDirective } from '../../../core/directives/max-length-input.directive';
import { PhoneMaskDirective } from '../../../core/directives/phone-mask.directive';
import { TimeFormatDirective } from '../../../core/directives/time-format.directive';
import { CustomDatePipe } from '../../../pipes/custom-date-string.pipe';
import { SortByPipe } from '../../../pipes/sort-by-name.pipe';
import { ButtonsModule } from '../buttons/buttons.module';
import { MultiSelectIconModule } from '../multi-select-icon/multi-select-icon.module';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { DateRangeComponent } from './components/date-range/date-range.component';
import { DateComponent } from './components/date/date.component';
import { InputCardComponent } from './components/input-card/input-card.component';
import { InputCardPipe } from './components/input-card/input-card.pipe';
import { InputPasswordComponent } from './components/input-password/input-password.component';
import { InputSearchComponent } from './components/input-search/input-search.component';
import { InputTextComponent } from './components/input-text/input-text.component';
import { OptionComponent } from './components/option/option.component';
import { ProgressComponent } from './components/progress/progress.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { RadioGroupComponent } from './components/radio-group/radio-group.component';
import { SelectWithIconComponent } from './components/select-with-icon/select-with-icon.component';
import { SelectComponent } from './components/select/select.component';
import { SwitchComponent } from './components/switch/switch.component';
import { InputValidatorComponent } from './components/input-validator/input-validator.component';
import { InputAmountComponent } from './components/input-amount/input-amount.component';
import { FormGroupComponent } from './components/form-group/form-group.component';
import { TooltipDirective } from '../../../core/directives/tooltip.directive';
import { TextareaComponent } from './components/textarea/textarea.component';
import {
  TimeDatepickerComponent
} from './components/time-datepicker/time-datepicker.component';
import { ValidInputAmountDirective } from '../../../core/directives/amount.directive';


@NgModule({
  declarations: [
    CheckboxComponent,
    InputTextComponent,
    InputCardComponent,
    InputPasswordComponent,
    SwitchComponent,
    SelectComponent,
    SelectWithIconComponent,
    DateComponent,
    DateRangeComponent,
    OptionComponent,
    RadioGroupComponent,
    RadioButtonComponent,
    ProgressComponent,
    InputValidatorComponent,
    InputAmountComponent,
    InputSearchComponent,
    FormGroupComponent,
    InputCardPipe,
    TimeDatepickerComponent,
    TextareaComponent
  ],
  exports: [
    CheckboxComponent,
    InputTextComponent,
    InputCardComponent,
    InputPasswordComponent,
    SwitchComponent,
    SelectComponent,
    SelectWithIconComponent,
    DateComponent,
    TimeDatepickerComponent,
    DateRangeComponent,
    OptionComponent,
    RadioGroupComponent,
    RadioButtonComponent,
    ProgressComponent,
    InputValidatorComponent,
    InputAmountComponent,
    InputSearchComponent,
    FormGroupComponent,
    InputCardPipe,
    TextareaComponent
  ],
  imports: [
    CommonModule,
    NgxMaskDirective,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CustomDatePipe,
    MatButtonModule,
    PhoneMaskDirective,
    MaxLengthDirective,
    MaxLengthDirective,
    TooltipDirective,
    SortByPipe,
    TranslateModule,
    ButtonsModule,
    DateInputDirective,
    TimeFormatDirective,
    SetDefaultActiveDirective,
    MultiSelectIconModule,
    ValidInputAmountDirective
  ],
  providers: [
    DefaultMatCalendarRangeStrategy,
    MatRangeDateSelectionModel,
    { provide: MAT_DATE_LOCALE, useValue: 'ru-RU' },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMAT }
  ]
})
export class ControlsModule {}
