export namespace ProductsEnum {

  export enum ProductTooltipTextIcon {
    SHOW_BALANCE = 'SWIPE_BUTTONS.SHOW_BALANCE',
    HIDE_BALANCE = 'SWIPE_BUTTONS.HIDE_BALANCE',
    RENAME_CARD = 'RENAME_CARD',
    RENAME_DEPOSIT = 'RENAME_DEPOSIT',
    RENAME_ACCOUNT = 'RENAME_ACCOUNT',
    RENAME_CREDIT = 'RENAME_CREDIT',
    MAIN_CARD = 'SWIPE_BUTTONS.MAIN_CARD',
    MAIN_ACCOUNT = 'SWIPE_BUTTONS.MAIN_ACCOUNT',
    TOP_UP_CARD = 'SWIPE_BUTTONS.TOP_UP_CARD',
    HIDE_CARD = 'SWIPE_BUTTONS.HIDE_CARD',
    SHOW_CARD = 'SWIPE_BUTTONS.SHOW_CARD',
    HIDE_DEPOSIT = 'SWIPE_BUTTONS.HIDE_DEPOSIT',
    SHOW_DEPOSIT = 'SWIPE_BUTTONS.SHOW_DEPOSIT',
    HIDE_BUDGET = 'HIDE_BUDGET',
    HIDE_ACCOUNT = 'SWIPE_BUTTONS.HIDE_ACCOUNT',
    SHOW_ACCOUNT = 'SWIPE_BUTTONS.SHOW_ACCOUNT',
    HIDE_CREDIT = 'SWIPE_BUTTONS.HIDE_LOAN',
    SHOW_CREDIT = 'SWIPE_BUTTONS.SHOW_LOAN',
    MAKE_PAYMENT = 'SWIPE_BUTTONS.CURRENT_PAYMENT'
  }


  export enum ProductActionKeys {
    CARD_ACCOUNT = 'cardAccount',
    CREDIT_ACCOUNT = 'creditAccount',
    CURRENT_ACCOUNT = 'currentAccount',
    DEPOSIT_ACCOUNT = 'depositAccount',
    OVERDRAFT_ACCOUNT = 'overdraftAccount'
  }


  export enum AccountKeys {
    DISPLAY_BALANCE = 'displayBalance',
    DISPLAY_ON_MAIN = 'displayOnMain',
    IS_MAIN_PAY_PRODUCT = 'isMainPayProduct',
    IS_UNMAIN_PAY_PRODUCT = 'isUnMainPayProduct',
    IS_BALANCE_VISIBLE = 'isBalanceVisible',
    CHANGE_NAME = 'name',
    BALANCE = 'balance',
    CHANGE_PRODUCT_NAME = 'productName',
    SET_SORT_INDEX = 'setSortIndex'
  }


  export enum ProductTitles {
    CARDS = 'CARDS',
    DEPOSITS = 'DEPOSITS',
    ACCOUNTS = 'ACCOUNTS',
    CREDITS = 'CREDITS',
    OVERDRAFT = 'OVERDRAFT',
    TOTAL_AMOUNTS = 'TOTAL_AMOUNTS',
    LOAN_APPLICATIONS = 'LOAN_APPLICATIONS',
  }


  export enum PairExchangeRateTypes {
    WRITE_OFF = 'WRITE_OFF',
    TOP_UP = 'TOP_UP'
  }


  export enum SwitchMatch {
    REVOCABLE = 'REVOCABLE',
    CAN_REFILL = 'CAN_TOPUP',
    CAN_TOP = 'CAN_TOP',
    GET_PERCENT = 'GET_PERCENT',
    INTEREST_WITHDRAW = 'CAN_WITHDRAW_INTEREST'
  }


  export enum CatalogRules {
    PERMITTED = 'Разрешено',
    FORBIDDEN = 'Запрещено'
  }


  export enum CatalogTypeRules {
    WITHDRAWN = 'Отзывный',
    IRREVOCABLE = 'Безотзывный'
  }


  export enum CatalogItems {
    DEPOSIT_AMOUNT = 'SUM_DEPOSIT',
    DEPOSIT_TYPE = 'TYPE_DEPOSIT',
    DEADLINE = 'TERM'
  }


  export enum ProductType {
    CARD = 'card',
    DEPOSIT = 'deposit',
    ACCOUNT = 'account',
    CREDIT = 'credit'
  }


  export enum ProductAmountVisibility {
    HIDE_BALANCE = 'hide',
    SHOW_BALANCE = 'show',
    VISIBLE = 'visible'
  }


  export enum ResolveStatus {
    PARITET_BANK = 'ParitetBank'
  }


  export enum SettingsDetails {
    MAIN_CARD = 'MAIN_CARD',
    MAIN_ACCOUNT = 'MAIN_ACCOUNT',
    SHOW_ON_MAIN = 'SHOW_ON_MAIN_PAGE',
    HIDE_BALANCE = 'HIDE_BALANCE'
  }


  export enum ProductActionMessage {
    ENABLED_SMS_NOTIFICATIONS = 'SMS_NOTIFICATION_ACTIVATED',
    DISABLED_SMS_NOTIFICATIONS = 'SMS_NOTIFICATION_DEACTIVATED',
    GENERATE_PIN_PRICE = 'NOT_ENOUGH_MONEY',
    SUCCESS_BLOCK_CARD = 'CARD_BLOCKED',
    SUCCESS_UNBLOCK_CARD = 'CARD_UNBLOCKED'
  }

  export enum ProductDialogTitle {
    ATTENTION = 'ATTENTION',
    RENAME_CARD = 'RENAME_CARD',
    RENAME_DEPOSIT = 'RENAME_DEPOSIT',
    RENAME_ACCOUNT = 'RENAME_ACCOUNT',
    RENAME_CREDIT = 'RENAME_CREDIT',
    SEND_CVVCVC = 'SEND_CVV',
    SEND_CVVCVC_SUCCESS = 'SEND_CVVCVC_SUCCESS',
    ACTIVATE_CARD = 'CARD_ACTIVATION',
    ACTIVATED_CARD = 'CARD_ACTIVATED',
    DISABLE_SMS_NOTIFICATIONS = 'DISABLE_SMS_NOTIFICATION',
    ENABLE_SMS_NOTIFICATIONS = 'CONNECT_SMS_NOTIFICATION',
    CARD_CHANGE_PIN = 'CHANGE_PIN_CODE',
    CARD_CHANGE_PIN_SUCCESS = 'PIN_SUCCESS_UPDATE',
    CONFIRM_OPERATION = 'TRANSACTION_CONFIRMATION',
    BLOCK_CARD = 'CARD_BLOCKING',
    UNBLOCK_CARD = 'UNLOCKING_CARD',
    SUCCESSFUL_CARD_OPEN_TITLE = 'SUCCESSFUL_CARD_OPEN_TITLE',
    SUCCESSFUL_CARD_OPEN = 'SUCCESSFUL_CARD_OPEN',
    SUCCESSFUL_CARD_DELIVERY_OPEN = 'SUCCESSFUL_CARD_DELIVERY_OPEN',
    SUCCESS_CREATE_ACCOUNT = 'ACCOUNT_SUCCESSFUL_OPEN',
    SUCCESSFUL_VIRTUAL_CARD_OPEN = 'SUCCESSFUL_VIRTUAL_CARD_OPEN'
  }


  export enum ProductDialogSubtitle {
    NEW_CARD_NAME = 'ENTER_NEW_CARD_NAME',
    NEW_DEPOSIT_NAME = 'ENTER_NEW_DEPOSIT_NAME',
    NEW_ACCOUNT_NAME = 'ENTER_NEW_ACCOUNT_NAME',
    NEW_CREDIT_NAME = 'ENTER_NEW_CREDIT_NAME',
    CVV_SEND_DETAIL = 'CVV_WILL_SEND_ON_PHONE',
    CVV_SEND_DETAIL_SUCCESS = 'CVV_SENT',
    ACTIVATE_CARD_NAME = 'ENTER_LAST_FOUR_CARD_NUMBER',
    ACTIVATE_CARD_SUCCESS = 'MAKE_ANY_OPERATION_WITH_PINCODE',
    DISABLE_SMS_NOTIFICATIONS_CONFIRM = 'SURE_DISABLE_SMS_NOTIFICATION',
    CLOSE_ACCOUNT_SUBTITLE = 'CLOSE_ACCOUNT',
    ENABLE_SMS_NOTIFICATION_CONFIRM = 'ACTIVATE_SMS_NOTIFICATION_NEW_PHONENUMBER',
    CARD_CHANGE_PIN_DETAIL = 'NEW_PIN_WILL_SEND_ON_PHONENUMBER',
    CARD_CHANGE_PIN_SUCCESS_DETAIL = 'VISIT_ATM_FOR_ACTIVATE_PIN',
    CONFIRM_OPERATION_DETAIL = 'SENT_SESSION_SMS_PSW',
    CONFIRM_OPERATION_REPEAT_DETAIL = 'USE_SESSION_SMS_PSW',
    BLOCK_CARD_DETAIL = 'TRANSACTION_BY_CARD_WILL_NOT_AVAILABLE',
    UNBLOCK_CARD_DETAIL = 'CARD_TRANSACTION_WILL_AVAILABLE',
    CARD_TO_CARD_TRANSFER = 'TRANSFER_BETWEEN_CARD_IN_PART_APPLICATION'
  }


  export enum DepositStatusList {
    DEPOSIT_CLOSE = 'DEPOSIT_CLOSE',
    TOP_UP_IN_BYN = 'TOP_UP_IN_BYN',
    TOP_UP_IN_CUR = 'TOP_UP_IN_CUR'
  }


  export enum DepositOperationTitles {
    DEPOSIT_CLOSE = 'CLOSE_DEPOSIT',
    DEPOSIT_TOP_UP = 'TOP_UP_DEPOSIT',
    WITHDRAW_INTEREST = 'WITHDRAW_INTEREST',
    DEPOSIT_TOP_UP_BTN = 'REPLENISH',
    WITHDRAW_INTEREST_BTN = 'WITHDRAW_INTEREST_BTN',
    TARIFFS = 'TARIFFS',
    PAYMENT_SCHEDULE = 'SCHEDULE_PAYMENT',
    GENERAL_TERMS_SERVICE = 'GENERAL_TERMS_SERVICE',
    TERM_ATTRACTION = 'TERM_ATTRACTION',
    BANKING_TERM_CONDITION = 'BANKING_TERM_CONDITION',
    BANKING_TERM_RULES = 'BANKING_TERM_RULES'
  }


  export enum DepositLabels {
    AVAILABLE_BY_INPUT = 'AVAILABLE_BY_INPUT',
    AVAILABLE_WITHDRAW = 'AVAILABLE_WITHDRAW',
    AMOUNT_ADMISSION = 'AMOUNT_ADMISSION',
    WITHDRAW_AMOUNT = 'WITHDRAW_AMOUNT',
    TRANSFER_AMOUNT = 'TRANSFER_AMOUNT'
  }


  export enum ExchangeLabels {
    WRITE_OF_AMOUNT = 'WRITE_OF_AMOUNT',
    TOP_UP_AMOUNT = 'TOP_UP_AMOUNT',
    TRANSFER_AMOUNT = 'TRANSFER_AMOUNT',
  }


  export enum DepositOperationSubtitles {
    DEPOSIT_CLOSE_SUBTITLE = 'CLOSE_INCOME_LOST',
    ABSENCE_OF_PRODUCTS = 'HAVE_NO_PRODUCT_FOR_TRANSACTION',
    TRANSFER_ALERT = 'AGREE_WITH_COURSES'
  }


  export enum DepositOperationButtonTypes {
    DEPOSIT_CLOSE = 'DEPOSIT_CLOSE',
    CHECK_OUT_PRODUCT = 'CHECK_OUT_PRODUCT',
    TRANSFER = 'MAKE_TRANSFER'
  }


  export enum ProductText {
    ACCOUNT = 'ACCOUNT',
  }

  export enum ProductDialogButtonText {
    SAVE = 'SAVE',
    ACTIVATE = 'ACTIVATE',
    CONTINUE = 'CONTINUE',
    DISABLE = 'DISABLE',
    ENABLE = 'ENABLE',
    PAY = 'PAY',
    CONFIRM = 'CONFIRM',
    CROSS_OVER = 'GO',
    REPEAT_SMS = 'REQUEST_SMS_PSW_AGAIN',
    REPEAT_SMS_AFTER = 'REQUEST_VIA',
    CLOSE_ACCOUNT_BUTTON = 'CLOSE_ACCOUNT_BUTTON',
    CLOSE = 'CLOSE',
    READY = 'READY'
  }

  export enum ProductDialogButtonHint {
    REPEAT_SMS = 'REQUEST_SMS_PSW_AGAIN_IF_NO_HAVE',
  }


  export enum ProductDialogLabel {
    NAME = 'NAME',
    LAST_4_DIGITS = 'LAST_FOUR_NUMBER',
    SMS_PASSWORD = 'SMS_PSW'
  }


  export enum ProductIcons {
    DEPOSIT = 'icon icon-deposit-safe',
    ACCOUNT = 'icon-coinsBundle2',
    CREDIT = 'icon icon-Deposit7'
  }


  export enum SMSText {
    REPEAT_SMS = 'REQUEST_SMS_PSW_AGAIN',
    REPEAT_SMS_WAIT = 'REQUEST_VIA'
  }

  export enum LimitRule {
    LIM_CNTRY = 'LIM_CNTRY',
    LIM_NO_P2P = 'LIM_NO_P2P',
    LIM_INTERNET = 'LIM_INTERNET',
    LIM_CASH_DAY_SUM_BY = 'LIM_CASH_DAY_SUM_BY',
    LIM_CASH_DAY_COUNT_BY = 'LIM_CASH_DAY_COUNT_BY',
    LIM_CASH_MONTH_SUM_BY = 'LIM_CASH_MONTH_SUM_BY',
    LIM_CASH_MONTH_COUNT_BY = 'LIM_CASH_MONTH_COUNT_BY',
    LIM_PURCH_PTS_DAY_SUM_BY = 'LIM_PURCH_PTS_DAY_SUM_BY',
    LIM_PURCH_PTS_DAY_COUNT_BY = 'LIM_PURCH_PTS_DAY_COUNT_BY',
    LIM_PURCH_PTS_MONTH_SUM_BY = 'LIM_PURCH_PTS_MONTH_SUM_BY',
    LIM_PURCH_PTS_MONTH_COUNT_BY = 'LIM_PURCH_PTS_MONTH_COUNT_BY',
    LIM_PURCH_INT_DAY_SUM_BY = 'LIM_PURCH_INT_DAY_SUM_BY',
    LIM_PURCH_INT_DAY_COUNT_BY = 'LIM_PURCH_INT_DAY_COUNT_BY',
    LIM_PURCH_INT_MONTH_SUM_BY = 'LIM_PURCH_INT_MONTH_SUM_BY',
    LIM_PURCH_INT_MONTH_COUNT_BY = 'LIM_PURCH_INT_MONTH_COUNT_BY',
    LIM_CASH_DAY_SUM_NOT_BY = 'LIM_CASH_DAY_SUM_NOT_BY',
    LIM_CASH_DAY_COUNT_NOT_BY = 'LIM_CASH_DAY_COUNT_NOT_BY',
    LIM_CASH_MONTH_SUM_NOT_BY = 'LIM_CASH_MONTH_SUM_NOT_BY',
    LIM_CASH_MONTH_COUNT_NOT_BY = 'LIM_CASH_MONTH_COUNT_NOT_BY',
    LIM_PURCH_PTS_MON_COUNT_NOT_BY = 'LIM_PURCH_PTS_MON_COUNT_NOT_BY',
    LIM_PURCH_PTS_MONTH_SUM_NOT_BY = 'LIM_PURCH_PTS_MONTH_SUM_NOT_BY',
    LIM_PURCH_PTS_DAY_COUNT_NOT_BY = 'LIM_PURCH_PTS_DAY_COUNT_NOT_BY',
    LIM_PURCH_PTS_DAY_SUM_NOT_BY = 'LIM_PURCH_PTS_DAY_SUM_NOT_BY',
    LIM_PURCH_INT_DAY_SUM_NOT_BY = 'LIM_PURCH_INT_DAY_SUM_NOT_BY',
    LIM_PURCH_INT_DAY_COUNT_NOT_BY = 'LIM_PURCH_INT_DAY_COUNT_NOT_BY',
    LIM_PURCH_INT_MONTH_SUM_NOT_BY = 'LIM_PURCH_INT_MONTH_SUM_NOT_BY',
    LIM_PURCH_INT_MON_COUNT_NO_BY = 'LIM_PURCH_INT_MON_COUNT_NO_BY',
  }


  export enum ProductsTab {
    HISTORY = 'HISTORY'
  }


  export enum CreditConfirmType {
    MSI = 'MSI',
    SMS = 'SMS'
  }


  export enum WithdrawalMethodEnum {
    account = 'account'
  }


  export enum IconProductActions {
    ICON_ROUND_PLUS = 'icon-round-plus',
    ICON_EDIT = 'icon-edit',
    ICON_MOON = 'icon-moon',
    ICON_SUN = 'icon-sun',
    ICON_STAR = 'icon-star',
    ICON_EYE_OPEN = 'icon-eye-open',
    ICON_EYE_CLOSED = 'icon-eye-closed'
  }
}
