import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


@Component({
  selector: 'app-switch',
  templateUrl: './switch.component.html',
  styleUrls: [ './switch.component.scss' ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SwitchComponent
    }
  ]
})
export class SwitchComponent implements ControlValueAccessor {

  @Input()
  public checked!: boolean;

  @Input()
  public disabled!: boolean;

  private onChange = (value: boolean) => {};

  private onTouched = () => {};


  public registerOnChange(fn: (value: boolean) => object): void {
    this.onChange = fn;
  }


  public registerOnTouched(fn: () => object): void {
    this.onTouched = fn;
  }


  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }


  public writeValue(value: boolean): void {
    this.checked = value;
  }


  public onCheck(): void {
    this.checked = !this.checked;
  }
}
