<div class="app-control" [class.disabled]="disabled">
  <label class="app-control-label" [class.error]="invalid" [for]="id" *ngIf="label">{{ label }}</label>

  <div class="app-control-field" [class.error]="invalid" (click)="onControlClick()">
    <div class="app-control-field__icon-prefix">
      <ng-content select="[icon-prefix]"></ng-content>
    </div>

    <div class="app-control-field__prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>

    <input class="app-control-field__input"
           [id]="id"
           [type]="showPass ? 'text' : 'password'"
           [placeholder]="placeholder"
           [(ngModel)]="value"
           (focus)="onInputFocus()"
           (blur)="onBlurEvent()"
    >

    <div class="app-control-field__suffix">
      <i class="icon icon-eye-{{ showPass ? 'open' : 'closed' }}" (click)="showPass = !showPass"></i>
    </div>
  </div>
</div>
