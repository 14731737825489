import { BlockCustomizeTypes, MainScreenSettingList } from '../enums/settings.enum';
import {
  BlockCustomizeUnits,
  IntermediateState,
  ToggleGroupObj
} from '../interfaces/settings.interface';


export namespace SettingsUtils {

  export function prepareBlockCustomizeType(blockCustomizeType: string): any {
    switch (blockCustomizeType) {
      case BlockCustomizeTypes.EXCHANGE_RATES:
        return MainScreenSettingList.EXCHANGE_RATES;
      case BlockCustomizeTypes.ACCOUNTS:
        return MainScreenSettingList.ACCOUNTS;
      case BlockCustomizeTypes.CARDS:
        return MainScreenSettingList.CARDS;
      case BlockCustomizeTypes.CREDITS:
        return MainScreenSettingList.CREDITS;
      case BlockCustomizeTypes.DEPOSITS:
        return MainScreenSettingList.DEPOSITS;
      case BlockCustomizeTypes.TOTAL_AMOUNTS:
        return MainScreenSettingList.TOTAL_AMOUNTS;
      case BlockCustomizeTypes.LOAN_APPLICATIONS:
        return MainScreenSettingList.LOAN_APPLICATIONS;
    }
  }


  export function returnBlockCustomizeCode(blockCustomize: string): any {
    switch (blockCustomize) {
      case MainScreenSettingList.EXCHANGE_RATES:
        return BlockCustomizeTypes.EXCHANGE_RATES;
      case MainScreenSettingList.ACCOUNTS:
        return BlockCustomizeTypes.ACCOUNTS;
      case MainScreenSettingList.CARDS:
        return BlockCustomizeTypes.CARDS;
      case MainScreenSettingList.CREDITS:
        return BlockCustomizeTypes.CREDITS;
      case MainScreenSettingList.DEPOSITS:
        return BlockCustomizeTypes.DEPOSITS;
      case MainScreenSettingList.TOTAL_AMOUNTS:
        return BlockCustomizeTypes.TOTAL_AMOUNTS;
    }
  }


  export function sortProductTitleList(blocks: ToggleGroupObj[]): ToggleGroupObj[]  {
    return  blocks.sort((blockA, blockB) => {
      if (blockA.sortIndex !== undefined && blockB.sortIndex !== undefined) {
        return blockA.sortIndex - blockB.sortIndex;
      }
      if (blockA.sortIndex === undefined && blockB.sortIndex === undefined) {
        return 0;
      }
      if (blockA.sortIndex === undefined) {
        return 1;
      }
      return -1;
    });
  }


  export function prepareData(blockCustomizeUniq: BlockCustomizeUnits[]): IntermediateState {
    const units = blockCustomizeUniq.map(item => ({
      ...item,
      blockCustomizeType: SettingsUtils.prepareBlockCustomizeType(item.blockCustomizeType),
    }));

    const blockCustomizeUnits = units.map(item => ({
      sortIndex: item.sortIndex,
      checked: item.isVisible,
      value: item.blockCustomizeType
    }) as ToggleGroupObj);

    return {
      blockCustomizeUniq,
      blockCustomizeUnits
    }
  }
}
