import { Credentials } from '../../../interfaces/auth.interface';
import { GetNsiDataPayload, MsiStateModel, UpdateMsiFormData } from '../../../interfaces/msi.interface';


export class GetMsiRedirectUrl {
  public static readonly type = '[MSI] GetMsiRedirectUrl';
}


export class GetMsiRedirectUrlBiometric {
  public static readonly type = '[MSI] GetMsiRedirectUrlBiometric';
}


export class MsiLogin {
  public static readonly type = '[MSI] MsiLogin';
}


export class MsiCode {
  public static readonly type = '[MSI] MsiCode';
}


export class MsiConfirm {
  public static readonly type = '[MSI] MsiConfirm';


  constructor(
    public readonly confirmationCode: string) {
  }
}


export class UpdateMsiState {
  public static readonly type = '[MSI] UpdateMsiState';


  constructor(
    public readonly value: Partial<MsiStateModel>) {
  }
}


export class SetMsiCredentials {
  public static readonly type = '[MSI] SetMsiCredentials';


  constructor(
    public readonly credentials: Credentials) {
  }
}


export class GetMsiQuestForm {
  public static readonly type = '[MSI] GetMsiQuestForm';
}


export class GetDictionaries {
  public static readonly type = '[MSI] GetDictionaries';
}


export class GetNsiInformation {
  public static readonly type = '[MSI] GetNsiInformation';


  constructor(
    public readonly payload: GetNsiDataPayload) {
  }
}


export class UpdateMsiForm {
  public static readonly type = '[MSI] UpdateMsiForm';


  constructor(
    public readonly updateMsiFormData: UpdateMsiFormData) {
  }
}


export class ResetMsiState {
  public static readonly type = '[MSI] ResetMsiState';
}


export class MsiPostForm {
  public static readonly type = '[MSI] MsiPostForm';
}


export class SaveConfirmedConsents {
  public static readonly type = '[MSI] SaveConfirmedConsents';


  constructor(
    public readonly confirmedConsents: string[]) {
  }
}


export class ClearMsiCredentials {
  public static readonly type = '[MSI] ClearMsiCredentials';
}
