<div class="popup">
  <div class="popup-title">
    <div *ngIf="checkMarkTitle" class="check-mark-title">
      <div class="error-popup-header_title__icon">
        <i class="icon icon-Check-21"></i>
      </div>
      <span class="popup-title_text">{{ data.title | translate}}</span>
    </div>
    <span *ngIf="!checkMarkTitle" class="popup-title_text">{{ data.title | translate}}</span>
    <div *ngIf="!data.notClosable" class="popup-title_icon">
      <i class="icon icon-cross" (click)="closePopup()"></i>
    </div>
  </div>
  <span *ngIf="data.subTitle"
        class="popup_subtitle"
  >
    {{ data.subTitle | translate: {value: data.subTitleValue || ''} }}
  </span>

  <span *ngIf="data.subTitleHtml"
        class="popup_subtitle white-space-pre popup_center"
        [innerHTML]="data.subTitleHtml | translate"
  ></span>

  <div class="popup-form" *ngIf="isInputNeed" [formGroup]="form">
    <div class="popup-form_input"
         *ngIf="form.controls.name as control"
    >
      <app-text-input
        [label]="(label ? label : '') | translate"
        [withClear]="true"
        [maxLength]="maxLength"
        [mask]="mask"
        [invalid]="control.invalid && submitted"
        [formControlCustom]="control"
        (focusin)="focusIn()"
        (focusout)="focusOut()"
      ></app-text-input>
      <app-error *ngIf="control.invalid && submitted">
        {{ getError(control) }}
      </app-error>
    </div>

    <app-button
      [type]="type"
      [ngClass]="'popup-form_btn'"
      [disabled]="disableButton"
      (click)="submitForm()"
    >
      {{ computeButtonText() | translate }}
    </app-button>
  </div>

  <app-button *ngIf="!isInputNeed" [type]="type" [ngClass]="'popup-btn'" (click)="submitPopup()">{{ data.buttonText | translate}}</app-button>
</div>
