import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Store } from '@ngxs/store';
import { catchError, forkJoin, map, Observable, tap, throwError } from 'rxjs';
import { SnackbarType } from '../enums/snackbar.enum';
import { HttpError } from '../interfaces/http.interface';
import { PreloaderActions } from '../models/preloader/state/preloader.actions';
import { UserActions } from '../models/user/state/user.actions';
import { ProductService } from '../services/product.service';
import { SessionService } from '../services/session.service';
import { matchErrorMessageText } from '../utils/error.utils';
import { SnackbarService } from '../views/lib/snackbar/services/snackbar.service';


@Injectable({
  providedIn: 'root'
})
export class MainResolver implements Resolve<boolean> {


  constructor(
    private readonly store: Store,
    private readonly sb: SnackbarService,
    private readonly sessionService: SessionService,
    private productService: ProductService) {
  }


  public resolve(): Observable<boolean> {
    this.productService.loading = true;

    const getClientAction = new UserActions.GetClient();
    const getConfirmationsAction = new UserActions.GetConfirmations();
    // const getCustomizeAction = new ServicesActions.GetCustomizeByUser();

    return forkJoin([
      this.store.dispatch(getClientAction),
      this.store.dispatch(getConfirmationsAction)
      // this.store.dispatch(getCustomizeAction)
    ])
      .pipe(
        tap(() => this.sessionService.createSessionTimer()),
        map((): boolean => true),
        catchError((errorResponse) => {
          this.productService.loading = false;
          this.store.dispatch(new PreloaderActions.Stop());
          this.handleError(errorResponse.error);
          return throwError(errorResponse.error);
        })
      );
  }


  private handleError(error: HttpError): void {
    // if (error.code === HttpErrorCode.SC_MAP_GET_EXCHANGE_RATES_EXCEPTION) {
    //   this.sb.show(matchErrorMessageText(error.message), {
    //     type: SnackbarType.Danger,
    //     duration: 5000
    //   });
    // } // TODO: пока не удаляю
    this.sb.show(matchErrorMessageText(error.message), {
      type: SnackbarType.Danger,
      duration: 5000
    });
  }

}
