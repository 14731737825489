import { InjectionToken } from '@angular/core';
import { DateEnum } from '../enums/date.enum';


export const msiDateFormat = 'LL.MM.YYYY';


export const COMMON_DATE_FORMAT: string = 'ddd MMM DD YYYY 00:00:00 [GMT]ZZ (zz)';


export const UNIQ_DATE_FORMAT: string = 'ddd MMM DD YYYY HH:mm:ss [GMT]Z';


export const DATE_FORMAT = {
  parse: {
    dateInput: 'DD.MM.YYYY'
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};


export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


export const hourList: string[] = [ '21', '22', '23', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20' ];


export const minutesList: string[] = [ '57', '58', '59', '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24',
  '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44',
  '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56' ];


export const MAT_DATEPICKER_TIME_CONTROL_NAME = new InjectionToken<string>('custom form control name for datepicker header input');


export const monthToNumberMap: { [key: string]: number } = {
  [DateEnum.DateMonthEndings.JANUARY]: 1,
  [DateEnum.DateMonthEndings.FEBRUARY]: 2,
  [DateEnum.DateMonthEndings.MARCH]: 3,
  [DateEnum.DateMonthEndings.APRIL]: 4,
  [DateEnum.DateMonthEndings.MAY]: 5,
  [DateEnum.DateMonthEndings.JUNE]: 6,
  [DateEnum.DateMonthEndings.JULY]: 7,
  [DateEnum.DateMonthEndings.AUGUST]: 8,
  [DateEnum.DateMonthEndings.SEPTEMBER]: 9,
  [DateEnum.DateMonthEndings.OCTOBER]: 10,
  [DateEnum.DateMonthEndings.NOVEMBER]: 11,
  [DateEnum.DateMonthEndings.DECEMBER]: 12
};


export const monthCountNumberMap: { [key: string]: number } = {
  [DateEnum.MonthCount.JANUARY]: 1,
  [DateEnum.MonthCount.FEBRUARY]: 2,
  [DateEnum.MonthCount.MARCH]: 3,
  [DateEnum.MonthCount.APRIL]: 4,
  [DateEnum.MonthCount.MAY]: 5,
  [DateEnum.MonthCount.JUNE]: 6,
  [DateEnum.MonthCount.JULY]: 7,
  [DateEnum.MonthCount.AUGUST]: 8,
  [DateEnum.MonthCount.SEPTEMBER]: 9,
  [DateEnum.MonthCount.OCTOBER]: 10,
  [DateEnum.MonthCount.NOVEMBER]: 11,
  [DateEnum.MonthCount.DECEMBER]: 12,
}


export const monthRange = {
  min: new Date(),
  max: ((): Date => {
    const date: Date = new Date();
    date.setDate(date.getDate() + 30);
    return date;
  })()
};


export const selectionRange = [
  {
    label: DateEnum.DateRange.WEEK,
    range: {
      start: (() => {
        const date: Date = new Date();
        date.setDate(date.getDate() - 6);
        return date;
      })(),
      end: new Date(),
    },
  },
  {
    label: DateEnum.DateRange.MONTH,
    range: {
      start: ((): Date => {
        const date: Date = new Date();
        date.setDate(date.getDate() - 30);
        return date;
      })(),
      end: new Date(),
    },
  },
  {
    label: DateEnum.DateRange.THREE_MONTH,
    range: {
      start: (() => {
        const date: Date = new Date();
        date.setDate(date.getDate() - 90);
        return date;
      })(),
      end: new Date(),
    },
  },
  {
    label: DateEnum.DateRange.SIX_MONTH,
    range: {
      start: (() => {
        const date: Date = new Date();
        date.setDate(date.getDate() - 180);
        return date;
      })(),
      end: new Date(),
    },
  },
  {
    label: DateEnum.DateRange.YEAR,
    range: {
      start: (() => {
        const today: Date = new Date();
        const start: Date = new Date();
        start.setFullYear(today.getFullYear() - 1);
        start.setDate(start.getDate() + 1);
        return start;
      })(),
      end: new Date(),
    },
  },
  {
    label: DateEnum.DateRange.OTHER_PERIOD
  }
];


export const productRange = [
  {
    range: {
      start: (() => {
        const date: Date = new Date();
        date.setDate(date.getDate() - 4);
        return date;
      })(),
      end: new Date()
    },
  }
];


export const clientRange = [
  {
    range: {
      start: (() => {
        const date: Date = new Date();
        date.setDate(date.getDate() - 2);
        return date;
      })(),
      end: new Date(),
    },
  }
];


export const dateMonthRange = {
  label: DateEnum.DateRange.MONTH,
  range: {
    start: ((): Date => {
      const date: Date = new Date();
      date.setDate(date.getDate() - 30);
      return date;
    })(),
    end: new Date(),
  }
};


export const timeOptions = {
  hour: 'numeric',
  minute: 'numeric',
  hour12: false
};
