import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import {
  CurrencyRateUtilDto,
  ExchangeRatesListRequestDto,
  ExchangeRatesPairRequestDto,
  PairExchangeRateResponseDto
} from '../../../interfaces/exchange-rates-list-dto.interface';
import { ServiceResource } from '../resources/service.resource';
import { BlockCustomizeUnits } from '../../../interfaces/settings.interface';
import {
  CardActionConfirmDto,
  CardActionDto,
  CardBlockDto,
  CardLimitDto,
  GetLimitsRs
} from '../../../interfaces/products.interface';
import { HttpError } from '../../../interfaces/http.interface';
import {
  BankDivisionsDto,
  BankExchangeRateDto,
  InfoCountriesRs,
  OrderCallRequestDto
} from '../../../interfaces/questionnaire.interface';
import { Store } from '@ngxs/store';
import { ServicesActions } from '../state/services.actions';
import { ServicesState } from '../state/services.state';
import { HttpHeaders } from '@angular/common/http';
import { DivisionType } from '../../../enums/questionnaire.enum';


@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  constructor(private readonly store: Store,
              private readonly resources: ServiceResource) {
  }


  public getExchangeRatesAll(requestData: ExchangeRatesListRequestDto): Observable<CurrencyRateUtilDto> {
    return this.resources.getExchangeRatesAll(requestData);
  }


  public getExchangeRatesPair(requestData: ExchangeRatesPairRequestDto): Observable<PairExchangeRateResponseDto> {
    return this.resources.getExchangeRatesPair(requestData);
  }


  public getCustomizeByUser(): Observable<{ blockCustomizeUnits: BlockCustomizeUnits[] }> {
    return this.resources.getCustomizeByUser();
  }


  public createOrUpdateCustomize(requestData: { blockCustomizeUnits: BlockCustomizeUnits[] }): Observable<string> {
    return this.resources.createOrUpdateCustomize(requestData);
  }


  public sendCardCvv(requestData: CardActionDto, headers: HttpHeaders): Observable<HttpError> {
    return this.resources.sendCardCvv(requestData, headers);
  }


  public sendCardLimits(requestData: CardLimitDto): Observable<HttpError> {
    return this.resources.sendCardLimits(requestData);
  }


  public receiveCardLimits(requestData: CardActionConfirmDto): Observable<GetLimitsRs> {
    return this.resources.receiveCardLimits(requestData);
  }


  public blockCard(requestData: CardBlockDto, headers: HttpHeaders): Observable<HttpError> {
    return this.resources.blockCard(requestData, headers);
  }


  public sendSmsConfirmation(): Observable<HttpError> {
    return this.resources.sendSmsConfirmation();
  }


  public infoCountriesList(): Observable<InfoCountriesRs | undefined> {
    return this.store.dispatch(new ServicesActions.SendInfoCountries()).pipe(
      switchMap(() => this.store.select(ServicesState.selectInfoCountries))
    )
  }


  public infoCountries(): Observable<InfoCountriesRs | undefined> {
    return this.resources.sendInfoCountries();
  }


  public getBankDivisions(divisionTypes?: DivisionType[]): Observable<BankDivisionsDto> {
    return this.resources.getBankDivisions(divisionTypes);
  }


  public getBranches() {
    return this.resources.getBranches();
  }


  public getATMS() {
    return this.resources.getATMS();
  }


  public getATMDetail(id: string) {
    return this.resources.getATMDetail(id);
  }


  public getBankDivisionById(id: string): Observable<BankExchangeRateDto> {
    return this.resources.getBankDivisionById(id);
  }


  public getOrderCall(requestData: OrderCallRequestDto): Observable<HttpError> {
    return this.resources.getOrderCall(requestData);
  }
}
