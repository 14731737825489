import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParamsResponse } from '../../../interfaces/params.interface';
import { ParamsResource } from '../resources/params.resource';


@Injectable({
  providedIn: 'root'
})
export class ParamsService {

  constructor(
    private readonly resource: ParamsResource) {
  }


  public getParams(): Observable<ParamsResponse> {
    return this.resource.getParams();
  }

}
