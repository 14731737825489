export enum Currency {
  BYN = 'BYN',
  RUB = 'RUB',
  USD = 'USD',
  EUR = 'EUR',
  GBP = 'GBP',
  PLN = 'PLN',
  BYR = 'BYR',
  RUR = 'RUR',
  CNY = 'CNY',
  AED = 'AED'
}


export enum CurrencyCode {
  BYN = '933',
  RUB = '643',
  USD = '840',
  EUR = '978',
  CNY = '156',
  AED = '784'
}


export enum CurrencyRateTypes {
  IPARITET = 'iParitet',
  CARDS = 'CARDS',
  CASH = 'CASH',
  NBRB = 'NBRB'
}


export enum ClientKind {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  HUAWEI = 'HUAWEI',
  WEB = 'WEB'
}


export enum CurrenciesExchangeTypes {
  SDBO = 'SDBO',
  CARD = 'CARD',
  CASH_PARITET = 'CASH_PARITET',
  CBNK = 'CBNK'
}


export enum CurrencyRequestType {
  BYN = 'BYN',
  BYN_USD_EUR_RUB = 'BYN_USD_EUR_RUB',
  ALL = 'ALL'
}


export enum CurrencyInfo {
  INFO = 'BANK_CAN_CHANGE_RATES'
}


export enum CurrencyIcons {
  USD = 'icon icon-currency-dollar',
  EUR = 'icon icon-currency-euro',
  RUB = 'icon icon-currency-ruble'
}
