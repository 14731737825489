import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiConfig } from '../../../config/api.config';
import { CatalogInterface } from '../../../interfaces/catalog.interface';


@Injectable({
  providedIn: 'root'
})
export class CatalogResource {

  constructor(private readonly http: HttpClient) {}


  public getDepositProducts(): Observable<CatalogInterface.CoreProductCommonResponseDto> {
    return this.http.get<CatalogInterface.CoreProductCommonResponseDto>(ApiConfig.DEPOSIT_PRODUCTS);
  }


  public getCurrentAccountProducts(): Observable<CatalogInterface.CoreProductCommonResponseDto> {
    return this.http.get<CatalogInterface.CoreProductCommonResponseDto>(ApiConfig.CURRENT_ACCOUNT_PRODUCTS);
  }


  public depositOpenInfo(requestData: CatalogInterface.DepositOpenRequestDto): Observable<CatalogInterface.DepositOpenResponseDto> {
    return this.http.post<CatalogInterface.DepositOpenResponseDto>(ApiConfig.DEPOSIT_OPEN_INFO, requestData);
  }


  public depositCalculateIncomeForecast(requestData: CatalogInterface.CalculateIncomeForecastRequestDto): Observable<CatalogInterface.CalculateIncomeForecastResponseDto> {
    return this.http.post<CatalogInterface.CalculateIncomeForecastResponseDto>(ApiConfig.CALCULATE_INCOME_FORECAST, requestData);
  }


  public cardOpen(data: string): Observable<CatalogInterface.OpenBpkResponseDto> {
    const params: HttpParams = new HttpParams().set('type', data);

    return this.http.get<CatalogInterface.OpenBpkResponseDto>(ApiConfig.CARD_OPEN, { params });
  }


  public cardUnpCheck(data: string): Observable<CatalogInterface.CardUnpCheckInfo> {
    const params: HttpParams = new HttpParams().set('entityUnp', data);

    return this.http.get<CatalogInterface.CardUnpCheckInfo>(ApiConfig.CARD_UNP_CHECK, { params });
  }


  public openCardGpl(requestData: CatalogInterface.OpenCardGplResponse): Observable<CatalogInterface.OpenCardGpl> {
    return this.http.post<CatalogInterface.OpenCardGpl>(ApiConfig.OPEN_CARD_GPL, requestData);
  }


  public openCardRoc(requestData: CatalogInterface.OpenProductRunOperation, headers: HttpHeaders): Observable<CatalogInterface.CheckBaseResponseDto> {
    return this.http.post<CatalogInterface.CheckBaseResponseDto>(ApiConfig.OPEN_CARD_ROC, requestData, { headers });
  }


  public openAccountRoc(requestData: CatalogInterface.OpenProductRunOperation, headers: HttpHeaders): Observable<CatalogInterface.CheckBaseResponseDto> {
    return this.http.post<CatalogInterface.CheckBaseResponseDto>(ApiConfig.OPEN_ACCOUNT_ROC, requestData, { headers });
  }


  public closeAccountRoc(requestData: CatalogInterface.OpenProductRunOperation): Observable<CatalogInterface.CheckBaseResponseDto> {
    return this.http.post<CatalogInterface.CheckBaseResponseDto>(ApiConfig.CLOSE_ACCOUNT_ROC, requestData);
  }
}
