import { DeviceUUID } from 'device-uuid';
import * as platform from 'platform';
import { v4 as uuidv4 } from 'uuid';
import { environment } from '../../environments/environment';
import { ClientKind, PlatformInfo, SystemInfo } from '../interfaces/system.interface';


export const systemInfo = (): SystemInfo => {
  const uuid = new DeviceUUID();

  return {
    appID: environment.appID,
    clientKind: environment.clientKind as ClientKind,
    deviceUDID: uuid.get()
  };
};

export const platformInfo = (): PlatformInfo => {
  const { name, version, os } = platform.parse(navigator.userAgent);

  return {
    platform: os?.family,
    platformVersion: os?.version,
    browser: name,
    browserVersion: version,
    macAddress: 'rehyhc'
  };
};


export const deviceInfo = (): string => {
  const { name, version, os } = platform.parse(navigator.userAgent);

  return `${ os?.family } ${ name }`;
}


export const trackingId = (): string => {
  return uuidv4();
}
