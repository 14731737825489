import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { map } from 'rxjs';
import { AmountConfig } from '../../../config/amount.config';
import { FinanceInterface } from '../../../interfaces/finance.interface';
import { FinanceService } from '../services/finance.service';
import { FinanceActions } from './finance.actions';


interface FinanceStateModel {
  amounts: FinanceInterface.Finance,
  balance: FinanceInterface.BalanceAmountResponseDto
}


@State<FinanceStateModel>({
  name: 'finance'
})
@Injectable()
export class FinanceState {

  constructor(private readonly financeService: FinanceService) {
  }


  @Selector()
  public static selectTotalAmounts(state: FinanceStateModel) {
    const { totalAmounts } = state.amounts ? state.amounts : AmountConfig.TotalAmountsDefault;

    return totalAmounts;
  }


  @Action(FinanceActions.GetTotalAmounts)
  public getTotalAmounts(ctx: StateContext<FinanceStateModel>) {
    return this.financeService.getTotalAmounts().pipe(
      map(amounts => {
        ctx.patchState({
          amounts
        });
      })
    );
  }


  @Action(FinanceActions.SetTotalAmounts)
  public setTotalAmounts(ctx: StateContext<FinanceStateModel>, action: FinanceActions.SetTotalAmounts): void {
    const state = ctx.getState();

    ctx.setState({
      ...state,
      amounts: action.payload
    });
  }


  @Action(FinanceActions.ClearTotalAmount)
  public clearTotalAmount(ctx: StateContext<FinanceStateModel>) {
    ctx.patchState({
      amounts: undefined
    });
  }


  @Action(FinanceActions.GetProductBalanceAmount)
  public getProductBalanceAmount(ctx: StateContext<FinanceStateModel>, { requestData }: FinanceActions.GetProductBalanceAmount) {
    return this.financeService.getProductBalanceAmount(requestData).pipe(
      map(balance => {
        ctx.patchState({
          balance
        });
      })
    );
  }

}
