import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { environment } from '../../environments/environment';
import { AcceptedHeaderNames } from '../config/http.config';
import { SettingsState } from '../models/settings/store/settings.state';


export function langInterceptor(request: HttpRequest<any>, next: HttpHandlerFn) {
  const store = inject(Store);

  const isAuthApiUrl = request.url.startsWith(environment.api.auth);
  const isCoreApiUrl = request.url.startsWith(environment.api.core);

  if (isAuthApiUrl || isCoreApiUrl) {
    request = request.clone({
      setHeaders: {
        [AcceptedHeaderNames.AcceptLanguage]: store.selectSnapshot(SettingsState.selectLang)
      }
    });
  }
  return next(request);
}