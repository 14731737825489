import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CardStatus } from '../../../enums/card.enum';
import { CardKomplatStatus, SelectCustom, SelectCustomOptions } from '../../../interfaces/products.interface';
import { DateFormat } from '../../../utils/date.utils';
import { NumberUtils } from '../../../utils/number.utils';
import { ProductsUtils } from '../../../utils/product.utils';


@Component({
  selector: 'app-multi-select-option',
  templateUrl: './multi-select-icon.component.html',
  styleUrls: [ './multi-select-icon.component.scss' ]
})
export class MultiSelectIconComponent {

  public cardStatus = CardStatus;

  public selectedUniqVal: string[] = [];

  public selectedItemsCount: number = 0;

  public selectUniq: boolean = false;

  @Input()
  public multiSelect: boolean = false;

  @Input()
  public selectListUniq!: SelectCustom[];

  @Output()
  public emitCountSelect: EventEmitter<number> = new EventEmitter<number>();

  @Output()
  public emitSelectedList: EventEmitter<string[]> = new EventEmitter<string[]>();


  public cardStatusNotBlocked(card: string | undefined): boolean {
    if (card) {
      return ProductsUtils.cardStatus(card as string) !== CardKomplatStatus.TOUNBLOCK;
    } else {
      return true;
    }
  }


  public checkExpireDate(expireDate: number): boolean {
    return DateFormat.checkExpireDate(expireDate);
  }


  public getNumberMask(mask: string): string {
    return mask.slice(-4);
  }


  public transformValue(amount: number, type: string): string {
    return NumberUtils.transformAmount(amount, type);
  }


  public selectAllUniq(): void {
    this.selectUniq = !this.selectUniq;

    if (this.selectUniq) {
      this.reflectAllSelectValue(true);
    } else {
      this.reflectAllSelectValue(false);
    }
  }


  public clearAll(): void {
    this.selectUniq = false;
    this.reflectAllSelectValue(false);
  }


  public onOptionUniq(option: SelectCustomOptions): void {
    option.selected = !option.selected;

    const selectedValueIndex = this.selectedUniqVal.indexOf(option.contractNumber as string);

    if (option.selected && selectedValueIndex === -1) {
      this.selectedUniqVal.push(option.contractNumber as string);
    } else if (!option.selected && selectedValueIndex !== -1) {
      this.selectedUniqVal.splice(selectedValueIndex, 1);
    }

    this.selectedItemsCount = this.selectListUniq.reduce((count: number, el: SelectCustom) => {
      return count + el.items.filter((item: SelectCustomOptions) => item.selected).length;
    }, 0);

    this.emitSelectedList.emit(this.selectedUniqVal);
    this.emitCountSelect.emit(this.selectedItemsCount);
  }


  private reflectAllSelectValue(option: boolean): void {
    const allValue: boolean = option;

    this.selectListUniq.forEach((el: any): void => {
      el.items.forEach((item: any): void => {
        item.selected = allValue;

        if (item.selected) {
          this.selectedUniqVal.push(item.contractNumber);
        } else {
          this.selectedUniqVal = [];
        }
      });
    });

    this.selectedItemsCount = this.selectListUniq.reduce((count: number, el: SelectCustom) => {
      return count + el.items.filter((item: SelectCustomOptions) => item.selected).length;
    }, 0);

    this.emitSelectedList.emit(this.selectedUniqVal);
    this.emitCountSelect.emit(this.selectedItemsCount);
  }
}
