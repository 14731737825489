import { MsiDictNames } from '../enums/msi.enum';
import { DictionaryItem } from '../interfaces/msi.interface';


export const dictToMap = (dict: { [name: string]: DictionaryItem[] }): Map<MsiDictNames, DictionaryItem[]> => {
  const map = new Map<MsiDictNames, DictionaryItem[]>();
  for (const dictKey in dict) {
    map.set(dictKey as MsiDictNames, dict[dictKey]);
  }
  return map;
};
