import { CardNumberType } from '../enums/card.enum';
import {
  Account,
  Card,
  ProductMainCards,
  ProductNestingCards, SelectCustomOptions
} from '../interfaces/products.interface';


export namespace CustomizeUtils {


  export function sortCards(cardAccounts: Account[], visible: boolean, isMainPayProduct: boolean): ProductMainCards[] {

    const combinedAndSortedCards = cardAccounts.flatMap(account => {
      return account.cards.map((card: Card) => ({ card, openDate: account.openDate, contractNumber: account.contractNumber }));
    });

    const cardsWithSort = combinedAndSortedCards.filter(({ card }) =>
      card.sortIndex !== undefined &&
      !card.isMainPayProduct &&
      (visible ? card.displayOnMain : !card.displayOnMain) && !card.isAdditional);

    cardsWithSort.sort((accountA, accountB) => {
      if (accountA.card.sortIndex === accountB.card.sortIndex && accountA.openDate !== accountB.openDate) {
        const openDateA = accountA.openDate as number;
        const openDateB = accountB.openDate as number;

        return openDateA < openDateB ? -1 : openDateA > openDateB ? 1 : 0;
      } else {
        return accountA.card.sortIndex - accountB.card.sortIndex;
      }
    });

    return (isMainPayProduct ? combinedAndSortedCards.filter(({ card }) => !card.isMainPayProduct) : cardsWithSort)
      .map(account => ({ card: [account.card], contractNumber: account.contractNumber }));
  }


  export function sortCardWithoutSortIdx(cardAccounts: Account[], visible: boolean): ProductMainCards[] {
    const combinedAndSortedCards = cardAccounts.flatMap(account => {
      return account.cards.map(card => ({ card, openDate: account.openDate, contractNumber: account.contractNumber }));
    });

    const cardsWithSort = combinedAndSortedCards.filter(({ card }) =>
      (visible ? card.displayOnMain : !card.displayOnMain) && card.sortIndex === undefined && !card.isAdditional);

    cardsWithSort.sort((accountA, accountB) => {
      const openDateA = accountA.openDate as number;
      const openDateB = accountB.openDate as number;

      return openDateA - openDateB;
    });

    return cardsWithSort.map(account => ({ card: [account.card], contractNumber: account.contractNumber }));
  }


  export function sortingCardProduct(cardAccounts: Account[]): Account[] {
    const productList: Account[] = [...cardAccounts];

    const isMainCardsShown = productList.filter(account => account.cards.some(card => card.displayOnMain));
    const isMainCardsHidden = productList.filter(account => account.cards.every(card => !card.displayOnMain));

    const sortedMainCardsShown = isMainCardsShown.sort(customCardSort);
    const sortedMainCardsHidden = isMainCardsHidden.sort(customHiddenCardSort);

    return [...sortedMainCardsShown, ...sortedMainCardsHidden];
  }


  export function sortingCards(cardAccounts: Account[]): Account[] {
    const productList: Account[] = [...cardAccounts];

    return productList.sort(sortProductCards);
  }


  export function sortingOtherProducts(cardAccounts: Account[]): Account[] {
    const productList: Account[] = [...cardAccounts];

    return productList.sort(sortProducts);
  }


  export function sortingCreditProducts(cardAccounts: Account[]): Account[] {
    return cardAccounts.sort(sortCreditProducts);
  }


  export function shuffle(list: Account[]): Account[] {
    for (let i: number = list.length - 1; i > 0; i--) {
      const j: number = Math.floor(Math.random() * (i + 1));
      [list[i], list[j]] = [list[j], list[i]];
    }
    return list;
  }


  export function sortingProducts(cardAccounts: Account[]): Account[] {
    const productList: Account[] = [...cardAccounts];

    const isMainCardsShown = productList.filter(account => account.displayOnMain);
    const isMainCardsHidden = productList.filter(account => !account.displayOnMain);

    const sortedMainProductShown = isMainCardsShown.sort(customProductsSort);
    const sortedMainProductsHidden = isMainCardsHidden.sort(customHiddenProductsSort);

    return [...sortedMainProductShown, ...sortedMainProductsHidden];
  }


  export function customCardSort(accountA: Account, accountB: Account): number {
    const hasMainPayProduct = (account: Account) => account.cards.some((card) => card.displayOnMain);

    if (hasMainPayProduct(accountA) && !hasMainPayProduct(accountB)) {
      return -1;
    } else if (!hasMainPayProduct(accountA) && hasMainPayProduct(accountB)) {
      return 1;
    } else {
      const mainPayProductA = accountA.cards.find((card) => card.displayOnMain);
      const mainPayProductB = accountB.cards.find((card) => card.displayOnMain);

      if (mainPayProductA && mainPayProductB) {
        return mainPayProductA.sortIndex - mainPayProductB.sortIndex;
      } else {
        return accountA.openDate < accountB.openDate ? -1 : 1;
      }
    }
  }


  export function customHiddenCardSort(accountA: Account, accountB: Account): number {
    const secondaryPayProductA = accountA.cards.find((card) => !card.displayOnMain);
    const secondaryPayProductB = accountB.cards.find((card) => !card.displayOnMain);

    if (secondaryPayProductA && secondaryPayProductB) {
      return secondaryPayProductA.sortIndex - secondaryPayProductB.sortIndex;
    } else {
      return accountA.openDate < accountB.openDate ? -1 : 1;
    }
  }


  export function customProductsSort(accountA: Account, accountB: Account): number {
    if (accountA.displayOnMain && !accountB.displayOnMain) {
      return -1;
    } else if (!accountA.displayOnMain && accountB.displayOnMain) {
      return 1;
    } else if (accountA.sortIndex !== undefined && accountB.sortIndex !== undefined) {
      return accountA.sortIndex - accountB.sortIndex;
    } else if (accountA.sortIndex !== undefined) {
      return -1;
    } else if (accountB.sortIndex !== undefined) {
      return 1;
    } else {
      if (accountA.openDate === accountB.openDate) {
        return 0;
      } else {
        return accountA.openDate < accountB.openDate ? -1 : 1;
      }
    }
  }


  export function customHiddenProductsSort(accountA: Account, accountB: Account): number {
    if (!accountA.displayOnMain && !accountB.displayOnMain) {
      if (accountA.sortIndex !== undefined && accountB.sortIndex !== undefined) {
        return accountA.sortIndex - accountB.sortIndex;
      } else if (accountA.sortIndex !== undefined) {
        return -1;
      } else if (accountB.sortIndex !== undefined) {
        return 1;
      } else {
        if (accountA.openDate === accountB.openDate) {
          return 0;
        } else {
          return accountA.openDate < accountB.openDate ? -1 : 1;
        }
      }
    } else {
      return 0;
    }
  }


  export function sortProductCards(accountA: Account, accountB: Account): number {
    const hasMainPayA = accountA.cards.some(card => card.isMainPayProduct && card.displayOnMain && !card.isAdditional);
    const hasMainPayB = accountB.cards.some(card => card.isMainPayProduct && card.displayOnMain && !card.isAdditional);

    if (hasMainPayA && !hasMainPayB) return -1; // accountA имеет isMainPayProduct: true, accountB - нет
    if (!hasMainPayA && hasMainPayB) return 1; // accountB имеет isMainPayProduct: true, accountA - нет

    const cardA = accountA.cards.find(el => el.sortIndex !== undefined && el.displayOnMain);
    const cardB = accountB.cards.find(el => el.sortIndex !== undefined && el.displayOnMain);

    if (cardA && cardB) {
      return cardA.sortIndex - cardB.sortIndex; // сравнение по sortIndex, если оба имеют подходящие карты
    }

    if (!cardA && !cardB) {
      return (accountA.openDate as number) - (accountB.openDate as number); // сравнение по openDate, если у обоих нет подходящих карт
    }

    return 0; // если ни одно из условий не выполнено, возвращаем 0
  }


  export function sortProducts(accountA: Account, accountB: Account): number {
    if (accountA.isMainPayProduct && !accountB.isMainPayProduct) {
      return -1; // accountA имеет isMainPayProduct: true, accountB - нет
    } else if (!accountA.isMainPayProduct && accountB.isMainPayProduct) {
      return 1; // accountB имеет isMainPayProduct: true, accountA - нет
    }

    // Если оба объекта имеют одинаковое значение isMainPayProduct или оба равны false,
    // следует выполнить дополнительное сравнение на основе sortIndex и openDate
    if (accountA.sortIndex !== null && accountB.sortIndex !== null) {
      if (accountA.sortIndex === accountB.sortIndex) {
        return (accountA.openDate as number) - (accountB.openDate as number); // сравнить openDate, если sortIndex равны
      } else {
        return accountA.sortIndex - accountB.sortIndex; // сравнить sortIndex
      }
    } else if (accountA.sortIndex === null && accountB.sortIndex === null) {
      return (accountA.openDate as number) - (accountB.openDate as number); // если оба sortIndex равны null, сравнить openDate
    } else if (accountA.sortIndex === null) {
      return 1; // если у accountA sortIndex равен null, он должен быть в конце списка
    } else {
      return -1; // если у accountB sortIndex равен null, он должен быть в конце списка
    }
  }


  export function sortCreditProducts(accountA: Account, accountB: Account): number {
    if (accountA.sortIndex !== undefined && accountB.sortIndex !== undefined) {
      if (accountA.sortIndex < accountB.sortIndex) {
        return -1;
      } else if (accountA.sortIndex > accountB.sortIndex) {
        return 1;
      } else {
        return accountA.openDate < accountB.openDate ? -1 : 1;
      }
    } else if (accountA.sortIndex !== undefined) {
      return -1;
    } else if (accountB.sortIndex !== undefined) {
      return 1;
    } else {
      return accountA.openDate < accountB.openDate ? -1 : 1;
    }
  }




  export function sortByOpenDate(account: Account[], visible: boolean): Account[] {
    return account
      .filter(account => (visible ? account.displayOnMain : !account.displayOnMain) && account.sortIndex === undefined)
      .sort((accA: Account, accB: Account) => {
        const openDateA = accA.openDate as number;
        const openDateB = accB.openDate as number;

        return openDateA - openDateB;
      })
  }


  export function getMainProductCard(cardAccounts: Account[], visible: boolean) {
    const combinedCards = cardAccounts.flatMap(account => {
      return account.cards.map((card: Card) => ({ card, openDate: account.openDate, contractNumber: account.contractNumber }));
    });

    const mainProduct = combinedCards.filter(({ card }) =>
      card.sortIndex !== undefined && (visible ? card.displayOnMain : !card.displayOnMain) && card.isMainPayProduct);

    return mainProduct.map(account => ({ card: [account.card], contractNumber: account.contractNumber }));
  }


  export function prepareMainPayCards(cards: [ProductMainCards[], ProductMainCards[]]): ProductMainCards[] {
    const productCards: [ProductMainCards[], ProductMainCards[]] = cards;
    const updatedCards: ProductMainCards[] = [...productCards[0]];

    productCards[0].forEach(item => {
      const contractNumber: string = item.contractNumber;

      const cardsToAdd = productCards[1].filter((el: ProductMainCards): boolean => el.contractNumber === contractNumber);

      if (cardsToAdd.length) {
        updatedCards.push(...cardsToAdd);
      }
    });

    return updatedCards;
  }


  export function transformCardProducts(cards: [ProductMainCards[], ProductMainCards[], ProductMainCards[], ProductMainCards[], ProductNestingCards[]]) {
    const [, , ...remainingCards]: [ProductMainCards[], ProductMainCards[], ProductMainCards[], ProductMainCards[], ProductNestingCards[]] = cards;
    const productCards: [ProductMainCards[], ProductMainCards[], ProductNestingCards[]] = remainingCards;

    const mainPayCards = cards.slice(0, 2);

    const deepCopyMainPayCards: [ProductMainCards[], ProductMainCards[]] = [
      (mainPayCards[0] as ProductMainCards[]).map((card) => ({ ...card })),
      (mainPayCards[1] as ProductMainCards[]).map((card) => ({ ...card }))
    ];

    const prepareMainPayList = prepareMainPayCards(deepCopyMainPayCards);

    productCards[2].forEach((item: ProductNestingCards): void => {
      const contractNumber: string = item.contractNumber;

      const indexToAdd: number = productCards[0].findIndex((el: ProductMainCards): boolean => el.contractNumber === contractNumber);

      if (indexToAdd !== -1) {
        if (!productCards[0][indexToAdd].card.some((el: Card): boolean => el.cardHash === item.card.cardHash))
          productCards[0][indexToAdd].card.push(item.card);
      }

      const secondIndexToAdd: number = productCards[1].findIndex((el: ProductMainCards): boolean => el.contractNumber === contractNumber);

      if (secondIndexToAdd !== -1) {
        if (!productCards[1][secondIndexToAdd].card.some((el: Card) => el.cardHash === item.card.cardHash))
          productCards[1][secondIndexToAdd].card.push(item.card);
      }
    });

    productCards.pop();

    let mergeData = productCards.flat();

    let combinedLists = [combineObjectList(prepareMainPayList), ...mergeData]
      .filter((products, idx, self) => (
        idx === self.findIndex((product) => (
          product.contractNumber === products.contractNumber
        ))
      ));

    return combinedLists;
  }


  export function findAdditionalObj(data: Account): Card {
    const cards: Card[] = data.cards;
    const cardItem: Card | undefined = cards.find((card: Card) => card.isAdditional);

    if (cardItem) {
      return cardItem;
    } else {
      return cards.length > 1 ? cards[1] : cards[0];
    }
  }


  export function combineObjectList(data: ProductMainCards[]): ProductMainCards {
    if (data.length === 0) {
      return { card: [], contractNumber: '' };
    }

    return data.reduce((acc, obj) => {
      return { card: [...acc.card, ...obj.card], contractNumber: obj.contractNumber };
    }, { card: [], contractNumber: data[0].contractNumber });
  }


  export function remapCards(product: Account[]): SelectCustomOptions[] {
    const _product = [...product];
    let remappedCards: SelectCustomOptions[] = [];

    _product.forEach(account => {
      account.cards.forEach(card => {
        const remappedCard: SelectCustomOptions = {
          id: card.cardHash,
          text: card.name,
          image: card.imageUrls?.onMainImageUrl || getCardImg(card),
          isCard: true,
          amount: card.balance ? card.balance : 0,
          currency: card.currency,
          expireDate: card.expireDate,
          itemStatus: card.cardStatus,
          sortIndex: card.sortIndex,
          selected: false,
          komplatStatus: card.komplatStatus,
          displayBalance: card.displayBalance,
          displayOnMain: card.displayOnMain,
          isMainItem: card.isMainPayProduct,
          cardNumberHash: card.cardNumberHash,
          paySystemName: card.paySystemName,
          cardNumberMasked: card.cardNumberMasked,
          contractNumber: account.contractNumber,
          contractType: account.contractType,
          contractNumberCard: card.contractNumber,
          cardAvailableActions: card.cardAvailableActions,
          itemNumberMasked: card.cardNumberMasked
        };
        remappedCards.push(remappedCard);
      });
    });

    return remappedCards;
  }


  export function getCardImg(card: Card): string {
    const cardIdx: string = card.cardNumberMasked.charAt(0);

    switch (cardIdx) {
      case CardNumberType.BEL:
        return '/assets/images/cards/default/belcard48.png';
      case CardNumberType.VISA:
        return '/assets/images/cards/default/visa48.png';
      case CardNumberType.MASTER:
        return '/assets/images/cards/default/master48.png';
      case CardNumberType.MAESTRO:
        return '/assets/images/cards/default/maestro48.png';
      case CardNumberType.MIR:
        return '/assets/images/cards/default/mir48.png';
      default:
        return '/assets/images/cards/default/default48.png';
    }
  }



  export function remapProducts(product: Account[], icon: string): SelectCustomOptions[] {
    const _product = [...product];

    return _product.map(el => {
      return {
        id: el.contractNumberHash,
        text: el.productName,
        icon,
        isCard: false,
        amount: el.balanceAmount ? el.balanceAmount : 0,
        contractNumberHash: el.contractNumberHash,
        currency: el.currency,
        selected: false,
        sortIndex: el.sortIndex,
        displayBalance: el.displayBalance,
        displayOnMain: el.displayOnMain,
        contractType: el.contractType,
        isMainItem: el.isMainPayProduct ? el.isMainPayProduct : false,
        contractNumber: el.contractNumber
      }
    })
  }

}
