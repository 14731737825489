import { Component, Input } from '@angular/core';
import { FormControlValue } from '../../../../../interfaces/control.interface';


@Component({
  selector: 'app-option',
  templateUrl: './option.component.html',
  styleUrls: [ './option.component.scss' ]
})
export class OptionComponent {

  @Input()
  public label?: string;

  @Input()
  public value!: FormControlValue;

  @Input()
  public selected!: boolean;

  @Input()
  public hidden: boolean = false;
}
