import { AfterViewInit, Directive, ElementRef, Renderer2 } from '@angular/core';


@Directive({
  selector: '[setDefaultActive]',
  standalone: true
})
export class SetDefaultActiveDirective implements AfterViewInit {
  constructor(private el: ElementRef, private renderer: Renderer2) {}


  public ngAfterViewInit(): void {
    const elements = this.el.nativeElement.querySelectorAll('.hour, .minute');
    if (elements.length > 0) {
      this.renderer.addClass(elements[0], 'scrolled');
    }
  }
}
