import { Currency } from '../enums/currency.enum';


const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

export namespace StringUtils {

  export function generateString(length: number) {
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }


  export function replaceDotWithComma(value: number): string {
    const amount: string = value.toString();

    if (amount && amount.includes('.')) {
      return amount.replace(/\./g, ',');
    }
    return amount;
  }


  export function roundAndReplaceDotWithComma(value: number): string {
    const amount: string = value.toFixed(2);

    if (amount && amount.includes('.')) {
      return amount.replace(/\./g, ',');
    }
    return amount;
  }


  export const isUpperCase = (value: string) => /^[A-Z]*$/.test(value);


  export function decodeString(encodedString: string): string {
    return atob(encodedString);
  }


  export function encodeString(decodeString: string): string {
    return btoa(decodeString);
  }


  export function configurateBase64Str(base64: string): string {
    const base64Index: number = base64.indexOf('base64,');

    if (base64Index !== -1) {
      const base64Start: number = base64Index + 7;

      return base64.substring(base64Start);
    } else {
      return base64;
    }
  }


  export function transformAndFormatFilename(originalFilename: string): string {
    const dotIndex: number = originalFilename.lastIndexOf('.');

    if (dotIndex !== -1) {
      return originalFilename.substring(dotIndex + 1).toUpperCase();
    } else {
      return originalFilename.toUpperCase();
    }
  }


  export function checkStartsWithValidCharacters(inputString: string): boolean {
    if (inputString === undefined) {
      return false;
    }

    const validStartingCharacters: string[] = ['9', '6', '8'];

    return validStartingCharacters.some((char: string) => inputString.startsWith(char));
  }


  export function isString(value: any): boolean {
    return typeof value === 'string';
  }


  export function processCurrencyString(inputString: string): string {
    const currencies: string[] = [Currency.BYN, Currency.EUR, Currency.USD, Currency.RUB];

    currencies.forEach((currency: string): void => {
      inputString = inputString.split(currency).join('');
    });

    inputString = inputString.replace(/,/g, '.');

    return inputString.trim();
  }


  export function isImageUrl(url: string) {
    return /(http|https).*\.(jpeg|jpg|gif|png)$/.test(url);
  }


  export function replaceStringUniq(data: string, uniqItem: string): string {
    // Находим индекс числа uniqItem в строке
    const index: number = data.indexOf(uniqItem);

    if (index !== -1) {
      return data.slice(0, index + 3) + "+" + data.slice(index + 3);
    } else {
      return data;
    }
  }


  export function getPositiveAmountToStr(data: string | number): string {
    if (typeof data === 'number' && data < 0) {
      return (Math.abs(data)).toString();
    } else if (typeof data === 'number' && data > 0) {
      return data.toString();
    } else {
      return data.toString();
    }
  }


  export function pluralizeWord(count: number, words: string[]): string {
    if (count % 100 > 10 && count % 100 < 20) {
      return words[2];
    }
    if (count % 10 === 1) {
      return words[0];
    }
    if (count % 10 > 1 && count % 10 < 5) {
      return words[1];
    }
    return words[2];
  }
}
