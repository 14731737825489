import { HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';
import { Store } from '@ngxs/store';
import { catchError, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpErrorCode } from '../enums/error.enum';
import { SnackBarTextList, SnackbarType } from '../enums/snackbar.enum';
import { HttpError } from '../interfaces/http.interface';
import { PreloaderActions } from '../models/preloader/state/preloader.actions';
import { SettingsState } from '../models/settings/store/settings.state';
import {
  dialogErrorData,
  errorUtils,
  openDialogBlockPlatformConfirm,
  showErrorConnectionDialog
} from '../utils/error.utils';
import { SnackbarService } from '../views/lib/snackbar/services/snackbar.service';
import { MatDialog } from '@angular/material/dialog';
import { ErrorPopupComponent } from '../views/shared/components/popups/error-popup/error.popup.component';


export function errorInterceptor(
  request: HttpRequest<any>,
  next: HttpHandlerFn,
) {
  const sb = inject(SnackbarService);
  const store = inject(Store);
  const dialog = inject(MatDialog);

  const isAuthApiUrl = request.url.startsWith(environment.api.auth);
  const isCoreApiUrl = request.url.startsWith(environment.api.core);
  const isPaymentApiUrl = request.url.startsWith(environment.api.payment);

  const errorUtilsObj = errorUtils(request);

  const conditions = {
    isAuthApiUrl: (isAuthApiUrl || isCoreApiUrl || isPaymentApiUrl),
    noIdentify: !errorUtilsObj.identify,
    noExchangeRates: !errorUtilsObj.exchangeRates,
    noConsent: !errorUtilsObj.consent,
    noParams: !errorUtilsObj.params,
    paymentDeposit: !errorUtilsObj.paymentDeposit,
    popupError: errorUtilsObj.popupError
  };

  return next(request).pipe(
    catchError(errorResponse => {
      store.dispatch(new PreloaderActions.Stop());
      const error = errorResponse.error as HttpError;
      const language = store.selectSnapshot(SettingsState.selectLang);

      if (Object.values(conditions).every(condition => condition)) {
        if (conditions.noParams && error?.message && conditions.popupError) {
          dialog.open(ErrorPopupComponent, {data: dialogErrorData(error.message, error.code)})
        } else if (conditions.noParams && error?.message && conditions.paymentDeposit) {
          sb.show(error.message, {
            type: SnackbarType.Danger,
            duration: 5000
          });
        } else if (conditions.noParams) {
          sb.show(`${error.status}: ${SnackBarTextList.UNKNOWN_ERROR}`, {
            type: SnackbarType.Danger,
            duration: 5000
          });
        }
      } else if (error.code === HttpErrorCode.CURRENT_PLATFORM_IS_BLOCKED) {
        openDialogBlockPlatformConfirm(error, dialog, language);
      } else if (error.code === HttpErrorCode.CONFIRMATION_CODE_NOT_VALID) {
        sb.show(error.message, {
          type: SnackbarType.Danger,
          duration: 5000
        });
      } else if (error.code === HttpErrorCode.VALIDATION_EXCEPTION) {
        sb.show(error.message, {
          type: SnackbarType.Danger,
          duration: 5000
        });
      } else if (!navigator.onLine) {
        showErrorConnectionDialog(dialog);
      }

      return throwError(() => errorResponse);
    })
  );

  return next(request);
}
