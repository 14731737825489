import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { ApplicationConfig, importProvidersFrom, isDevMode, provideZoneChangeDetection } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { REMOVE_STYLES_ON_COMPONENT_DESTROY } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
import { NgxsModule } from '@ngxs/store';
import { LottieModule } from 'ngx-lottie';
import { provideNgxMask } from 'ngx-mask';
import { environment } from '../environments/environment';

import { routes } from './app.routing';
import { Lang } from './enums/lang.enum';
import { cacheInterceptor } from './interceptors/cache.interceptor';
import { errorInterceptor } from './interceptors/error.interceptor';
import { langInterceptor } from './interceptors/lang.interceptor';
import { jwtInterceptor } from './interceptors/token.interceptor';
import { AuthState } from './models/auth/state/auth.state';
import { CatalogState } from './models/catalog/state/catalog.state';
import { ConsentState } from './models/consent/state/consent.state';
import { CreditState } from './models/credit/state/credit.state';
import { DepositState } from './models/deposit/state/deposit.state';
import { EripState } from './models/erip/state/erip.state';
import { FinanceState } from './models/finance/state/finance.state';
import { MenuState } from './models/menu/state/menu.state';
import { MsiState } from './models/msi/state/msi.state';
import { OperationsState } from './models/operations/state/operation.state';
import { ParamsState } from './models/params/state/params.state';
import { PaymentState } from './models/payment/state/payment.state';
import { PreloaderState } from './models/preloader/state/preloader.state';
import { ProductsState } from './models/products/state/products.state';
import { ReceiptState } from './models/receipt/state/receipt.state';
import { ServicesState } from './models/services/state/services.state';
import { SettingsState } from './models/settings/store/settings.state';
import { UserState } from './models/user/state/user.state';
import { PreloaderModule } from './views/preloader/preloader.module';


// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}


export function playerFactory(): any {
  return import('lottie-web');
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(
      withInterceptors([
        cacheInterceptor,
        jwtInterceptor,
        errorInterceptor,
        langInterceptor
      ])
    ),
    importProvidersFrom(
      PreloaderModule,
      NgxsModule.forRoot([
        SettingsState,
        PreloaderState,
        ParamsState,
        AuthState,
        UserState,
        ProductsState,
        ServicesState,
        FinanceState,
        CreditState,
        DepositState,
        PaymentState,
        EripState,
        ReceiptState,
        CatalogState,
        MsiState,
        OperationsState,
        ConsentState,
        MenuState
      ], {
        developmentMode: !environment.production
      }),
      NgxsLoggerPluginModule.forRoot({
        disabled: !environment.logs.ngxs
      }),
      NgxsStoragePluginModule.forRoot({
        key: [
          'settings.theme',
          'settings.lang',
          'auth.registrationToken',
          'auth.codeHeader',
          'auth.credentials',
          'auth.sessionToken',
          'services.services',
          'finance.amounts',
          'erip.eripPayments',
          'msi',
          'payment.paymentSources',
          'menu'
        ],
        storage: StorageOption.SessionStorage
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [ HttpClient ]
        },
        defaultLanguage: Lang.RU
      }),
      LottieModule.forRoot({ player: playerFactory }),
      MatDialogModule
    ),
    provideNgxMask(),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideZoneChangeDetection({ eventCoalescing: true }),
    {
      provide: REMOVE_STYLES_ON_COMPONENT_DESTROY,
      useValue: true
    }
  ]
};
