<div class="preloader-container" [class.preloader-container_priority]="showPreloaderPriority">
<!--   <mat-progress-spinner -->
<!--     [color]="config.color" -->
<!--     [diameter]="config.diameter" -->
<!--     [mode]="config.mode" -->
<!--     [value]="config.value" -->
<!--   > -->
<!--   </mat-progress-spinner> -->

  <ng-lottie
    [options]="options"
    [width]="'150px'"
    [height]="'150px'"
    (animationCreated)="onAnimate($event)">
  </ng-lottie>
</div>
