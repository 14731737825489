<div class="app-control" [class.disabled]="disabled" [style.width]="width" [class.inline-control]="inlineControl">
  <label
    *ngIf="!invisible && label"
    class="app-control-label" [class.error]="invalid" [for]="id">{{ label }}</label>

  <div
    class="app-control-field"
    [class.error]="invalid"
    [class.select-opened]="selectOpened"
    (click)="onControlClick($event)"
  >
    <div class="app-control-field__prefix">
      <ng-content select="[prefix]"></ng-content>
    </div>

    <input class="app-control-field__input"
           *ngIf="isPhoneMask; else amountInput"
           [class.invisible]="invisible"
           #inputElement
           phoneMask
           [id]="id"
           [formControl]="formControl"
           [placeholder]="placeholder"
           [(ngModel)]="value"
           [mask]="mask"
           [readOnly]="readOnly"
           [maxlength]="maxLength"
           [minlength]="minLength"
           (keyup)="onKeyUp($event)"
           (focus)="onInputFocus()"
           (blur)="onBlurEvent()"
    ><!--Разница в добавлении директивы phoneMask-->

    <ng-template #amountInput>
      <input class="app-control-field__input"
             *ngIf="isAmountInput; else defaultInput"
             [class.invisible]="invisible"
             #inputElement
             [id]="id"
             appValidAmount
             [type]="type ?? ''"
             [placeholder]="placeholder | translate"
             [formControl]="formControl"
             [(ngModel)]="value"
             [mask]="mask"
             [readOnly]="readOnly"
             [maxlength]="maxLength"
             [minlength]="minLength"
             (keyup)="onKeyUp($event)"
             (focus)="onInputFocus()"
             (blur)="onBlurEvent()"
      >
    </ng-template>
    <ng-template #defaultInput>
      <input class="app-control-field__input"
             [class.invisible]="invisible"
             #inputElement
             [id]="id"
             [type]="type ?? ''"
             [placeholder]="placeholder | translate"
             [formControl]="formControl"
             [(ngModel)]="value"
             [mask]="mask"
             [readOnly]="readOnly"
             [maxlength]="maxLength"
             [minlength]="minLength"
             (keyup)="onKeyUp($event)"
             (focus)="onInputFocus()"
             (blur)="onBlurEvent()"
      >
    </ng-template>


    <div class="app-control-field__suffix" *ngIf="!withClear && !dropMode">
      <ng-content select="[suffix]"></ng-content>
    </div>

    <div class="app-control-field__suffix" *ngIf="withClear && showClear && !dropMode && isFocus">
      <i class="icon icon-round-close" (click)="onClear()"></i>
    </div>

    <div class="app-control-field__suffix app-control-field__suffix_drop" *ngIf="dropMode">
      <i class="icon icon-arrow-drop-{{ dropDirection }}"></i>
    </div>

    <div class="app-control-field__info"
         *ngIf="tooltipInfo"
         [tooltip]="tooltipInfo"
         [hideDelay]="hideDelay"
         [class.disabled-info]="disabled">
      <i class="icon icon-round-info"></i>
    </div>
  </div>
</div>
