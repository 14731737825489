export enum RegistrationType {
  SIMPLE = 'SIMPLE',
  MSI = 'MSI',
}


export enum BannerInfo {
  ON_SOFA_EASY = 'ON_SOFA_EASY',
  WHY_GO = 'WHY_GO',
  DONT_WORRY = 'DONT_WORRY',
  GO_REGISTRATION = 'GO_REGISTRATION',
  VIRTUAL_CARD_BANNER = 'VIRTUAL_CARD_BANNER',
  SOME_CLICK = 'SOME_CLICK'
}


export enum AuthButtonText {
  LOGIN = 'LOGIN',
  CONTINUE = 'CONTINUE',
  RETRY_CODE = 'REQUEST_CODE_AGAIN',
  RESTORE_ACCESS = 'RESTORE_ACCESS'
}


export enum AuthInfoMassagesMSI {
  HEADER = 'TRANSFER_TO_MSI',
  MESSAGE = 'MSI_ALLLOW_BECOME_BANK_CLIENT'
}
